export function defaultStateForEmployment(employmentId, employmentIsMod) {
  return {
    ministry_of_defence: employmentIsMod,
    person_employment_id: employmentId,
    expected_time_at_post: null,
    complex_routine: null,
    travel_home_frequency: null,
    quantitative_frequency: employmentIsMod ? null : 5,
    qualitative_frequency: employmentIsMod ? null : 'Per week',
    expected_time_at_post_years: employmentIsMod ? 2 : null,
    expected_time_at_post_months: employmentIsMod ? 0 : null,
    expected_time_at_post_weeks: employmentIsMod ? 0 : null,
    expected_time_at_post_changed_at: null,
    travel_home_quantitative_frequency: employmentIsMod ? 5 : null,
    travel_home_qualitative_frequency: employmentIsMod ? 'Per week' : null,
    name: null,
    received_reimbursements: employmentIsMod ? false : null
  }
}
