import React from 'react'
import PropTypes from 'prop-types'

const APRIL = 3

export default function TimelineStep({ date, filled }) {
  const humanDateMonth = date.format('MMMM')
  const humanDateYear = date.format('YYYY')

  const classes = ['timeline__step']

  if (date.month() == APRIL) {
    classes.push('timeline__step--marker')
  }

  if (filled) {
    classes.push('timeline__step--filled')
  }

  return (
    <li className={classes.join(' ')} title={`${humanDateMonth} ${humanDateYear}`}>
      <time dateTime={date.format('YYYY-MM-DD')}>
        <span className="timeline__month">{humanDateMonth}</span>
        <span className="timeline__year">{humanDateYear}</span>
      </time>
    </li>
  )
}

TimelineStep.propTypes = {
  date: PropTypes.object.isRequired,
  filled: PropTypes.bool.isRequired
}
