import React, { useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'

import { ButtonGroup, Emoji } from '../layout/Wrapper'
import ButtonGroupItem from './ButtonGroupItem'
import Question from './Question'
import EmojiImage from '../images/Icon'
import idleTimeoutData from '../../data/idleTimeoutData.json'

import { fetch, isIterable } from '../../modules/utils'
import { trackEvent } from '../../modules/tracking'

export default function IdleHelpPanel({ page, location }) {
  const STUCK_TIMEOUT = idleTimeoutData[page].stuck
  const IDLE_TIMEOUT = idleTimeoutData[page].idle
  const AUTO_HIDE_TIMEOUT = idleTimeoutData.settings.autoHide
  const MAX_NUMBER_OF_DISMISSALS = 2
  const THROTTLE_SPEED = 100

  const panelIsDisabled = useRef(true)
  const [panelIsVisible, setPanelIsVisible] = useState(false)
  const [triggeredIdleTimeout, setTriggeredIdleTimeout] = useState(null)
  const [triggeredStuckTimeout, setTriggeredStuckTimeout] = useState(null)
  const userInputThrottle = useRef(new Date().getTime())

  const testMode = false

  useEffect(() => {
    fetch('GET', '/idle_help_panel', 'application/json').then(response => {
      disablePanel(response.count >= MAX_NUMBER_OF_DISMISSALS)

      if (testMode) {
        testLog(`This user has dismissed the idle panel ${response.count} times.`)
      }
    })

    return () => {
      removeEventListeners()
    }
  }, [])

  useEffect(() => {
    triggerIdleTimeout()
    triggerStuckTimeout()
  }, [location])

  useEffect(() => {
    let timer = {}

    if (triggeredIdleTimeout) {
      testLog('Started the idle timeout')
      timer = setTimeout(
        () => {
          testLog('User was idle')
          showPanel()
        },
        testMode ? IDLE_TIMEOUT / 2 : IDLE_TIMEOUT
      )
    }

    return () => {
      clearTimeout(timer)
    }
  }, [triggeredIdleTimeout])

  useEffect(() => {
    let timer = {}

    if (triggeredStuckTimeout) {
      testLog('Started the stuck timeout')
      timer = setTimeout(
        () => {
          testLog('User did not interact with a form')
          showPanel()
        },
        testMode ? STUCK_TIMEOUT / 3 : STUCK_TIMEOUT
      )
    }

    return () => {
      clearTimeout(timer)
    }
  }, [triggeredStuckTimeout])

  useEffect(() => {
    let timer = {}

    if (panelIsVisible && !panelIsDisabled.current) {
      timer = setTimeout(
        () => {
          testLog('User did not interact with the panel after 15 seconds, disabling it')
          disablePanel(true)
        },
        testMode ? AUTO_HIDE_TIMEOUT / 3 : AUTO_HIDE_TIMEOUT
      )
    }

    return () => {
      clearTimeout(timer)
    }
  }, [panelIsVisible])

  const onUserDismissed = dismissForever => {
    testLog('User dismissed the panel, updating the dismissed count')
    fetch('PUT', '/idle_help_panel', 'application/json', null, { dismiss_forever: dismissForever })
      .then(() => {
        disablePanel(true)
      })
      .catch(e => {
        if (isIterable(e)) {
          const [message] = e
          console.error('Fetch failed: ' + message)
        }
      })
  }

  const showPanel = () => {
    testLog(`The panel is ${panelIsDisabled.current ? '' : 'not'} disabled`)
    if (document.querySelector('.live-chat-panel--panel-minimized') && !panelIsDisabled.current) {
      trackEvent('Idle help panel displayed')
      testLog(`Showing the panel`)
      setPanelIsVisible(true)
    }
  }

  const addEventListeners = () => {
    ;['click', 'touchstart', 'mousemove', 'keydown', 'scroll'].forEach(evt =>
      document.addEventListener(evt, triggerIdleTimeout)
    )

    document.getElementById('onInteractionWithForm').addEventListener('change', () => triggerStuckTimeout())
  }

  const removeEventListeners = () => {
    ;['click', 'touchstart', 'mousemove', 'keydown', 'scroll'].forEach(evt =>
      document.removeEventListener(evt, triggerIdleTimeout)
    )

    document.getElementById('onInteractionWithForm').removeEventListener('change', () => triggerStuckTimeout())
  }

  const triggerIdleTimeout = () => {
    if (!panelIsDisabled.current) {
      throttle().then(() => {
        setTriggeredIdleTimeout(new Date())
      })
    }
  }

  const triggerStuckTimeout = () => {
    if (!panelIsDisabled.current) {
      setTriggeredStuckTimeout(new Date())
    }
  }

  const disablePanel = disabled => {
    testLog(disabled ? 'Disabling the panel' : 'Enabling the panel')
    panelIsDisabled.current = disabled

    if (disabled) {
      setPanelIsVisible(false)
      removeEventListeners()
      return
    }

    addEventListeners()
    triggerIdleTimeout()
    triggerStuckTimeout()
  }

  const testLog = message => {
    if (testMode) {
      console.log('IDLE PANEL LOG: ' + message)
    }
  }

  const throttle = () => {
    const now = new Date().getTime()
    return new Promise(resolve => {
      if (now >= userInputThrottle.current) {
        userInputThrottle.current = now + THROTTLE_SPEED
        resolve()
      }
    })
  }

  const onPrimaryButtonClick = e => {
    e.preventDefault()
    trackEvent('Clicked on Idle Panel Chat button')
    window.myRIFT.openLiveChatPanel()
    onUserDismissed(true)
  }

  const onCancelButtonClick = e => {
    e.preventDefault()
    trackEvent('Clicked on Idle Panel Not Now button')
    onUserDismissed(false)
  }

  return (
    <div className={`idle-help-panel ${panelIsVisible ? 'idle-help-panel--visible' : ''}`}>
      <div className="idle-help-panel__content">
        <div>
          <Question heading="Need a hand?" text="Need a hand? We’re here to help and answer your questions." />
        </div>
        <div>
          <Emoji className="idle-help-panel__icon">
            <EmojiImage name="bubble" />
          </Emoji>
        </div>
      </div>
      <ButtonGroup>
        <ButtonGroupItem
          buttonModifiers={['primary', 'small']}
          element="button"
          disabled={false}
          onClick={onPrimaryButtonClick}
        >
          Chat now
        </ButtonGroupItem>

        <ButtonGroupItem
          buttonModifiers={['cancel', 'small']}
          element="button"
          disabled={false}
          onClick={onCancelButtonClick}
        >
          Not now
        </ButtonGroupItem>
      </ButtonGroup>
    </div>
  )
}

IdleHelpPanel.propTypes = {
  page: PropTypes.string.isRequired
}
