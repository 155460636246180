import React, { useEffect } from 'react'

import QuestionnaireStage from './QuestionnaireStage'

const QuestionnaireStages = function ({ industryAnswers }) {
  useEffect(() => {
    window.myRIFT.initialiseQuestionnaires()
    document.querySelector('.questionnaire__wrapper').classList.add('questionnaire__wrapper--loaded')
  }, [])

  return (
    <>
      <QuestionnaireStage
        id={'1'}
        track={'q1'}
        question={'Which industry are you in?'}
        helperText={{
          title: 'Choosing your industry',
          body: 'HMRC rules are not the same for all industry sectors so we need to know what you do to calculate your refund correctly.'
        }}
        answers={industryAnswers}
      />

      <QuestionnaireStage
        id={'2'}
        track={'q2a'}
        question={'Have you completed phase 1 training?'}
        helperText={{
          title: 'Phase 1 training',
          body: "You have to complete Phase 1 training to have a career in the Forces. HMRC won't consider you permanently employed without it, so you won't qualify for a refund until you've passed."
        }}
        answers={[
          {
            label: 'Yes',
            'data-goto-stage': '3'
          },
          {
            label: 'No',
            'data-goto-stage': '4'
          }
        ]}
      />

      <QuestionnaireStage
        id={'3'}
        track={'q3a'}
        question={'Have you been posted to the same base for under 2 years?'}
        helperText={{
          title: 'Posting duration',
          body: "HMRC rules allow Armed Forces personnel to claim against travel expenses on to temporary postings of under 24 months, even outside the UK so it's important we know how long your assignment orders are for."
        }}
        answers={[
          {
            label: 'Yes',
            'data-goto-stage': '5'
          },
          {
            label: 'No',
            'data-goto-stage': '6'
          }
        ]}
      />

      <QuestionnaireStage
        id={'4'}
        track={'q2b'}
        question={'Do you need to...'}
        helperText={{
          title: 'Other services',
          body: "If you need to complete a self-assessment tax return for any reason we can help you. Prices start from £95. Additionally, we can help you claim a tax refund for all your qualifying travel expenses in all jobs you've had in the last 4 years."
        }}
        answers={[
          {
            label: 'Do a tax return',
            'data-answer-data': 'tax-return',
            'data-redirect-to':
              'https://www.riftrefunds.co.uk/form-settings/eligibility-form/end-state-2b-self-employed-but-not-construction-cis/'
          },
          {
            label: 'Claim for a different job',
            'data-goto-stage': '1'
          },
          {
            label: 'Neither',
            'data-redirect-to':
              'https://www.riftrefunds.co.uk/form-settings/eligibility-form/end-state-3-a-armed-forces-new-recruit-has-not-completed-phase-1-training/'
          }
        ]}
      />

      <QuestionnaireStage
        id={'5'}
        track={'q4a'}
        question={'Did you use your own vehicle or public transport to get to these bases?'}
        helperText={{
          title: 'Transport methods',
          body: "If you had to pay to use your own vehicle or public transport and weren't reimbursed by your employer you could claim a tax refund on those costs."
        }}
        answers={[
          {
            label: 'Yes',
            'data-goto-stage': '17'
          },
          {
            label: 'No',
            'data-goto-stage': '7'
          }
        ]}
      />

      <QuestionnaireStage
        id={'6'}
        track={'q3b'}
        question={'Were you expecting a posting to be under 2 years, but had it extended?'}
        helperText={{
          title: 'Postings under 24 months',
          body: "It's important we know how long your assignment orders are for as HMRC rules mean we can claim refunds for postings of under 24 months."
        }}
        answers={[
          {
            label: 'Yes',
            'data-goto-stage': '5'
          },
          {
            label: 'No',
            'data-goto-stage': '18'
          }
        ]}
      />

      <QuestionnaireStage
        id={'18'}
        track={'q3c'}
        question={'Have you attended a training establishment for under 2 years?'}
        helperText={{
          title: 'Posting under 24 months',
          body: "It's important we know how long your assignment orders are for as HMRC rules mean we can claim refunds for postingsof under 24 months"
        }}
        answers={[
          {
            label: 'Yes',
            'data-goto-stage': '5'
          },
          {
            label: 'No',
            'data-goto-stage': '7'
          }
        ]}
      />

      <QuestionnaireStage
        id={'7'}
        track={'q4b'}
        question={'Do you have to file a tax return?'}
        helperText={{
          title: 'Tax returns',
          body: 'If you need to complete a self-assessment tax return for any reason we can help you. Prices start from £95.'
        }}
        answers={[
          {
            label: 'Yes',
            'data-redirect-to':
              'https://www.riftrefunds.co.uk/form-settings/eligibility-form/end-state-2a-all-paye-inc-construction-paye-but-need-to-submit-a-tax-return/'
          },
          {
            label: 'No',
            'data-redirect-to':
              'https://www.riftrefunds.co.uk/form-settings/eligibility-form/end-state-3-a-armed-forces-new-recruit-has-not-completed-phase-1-training/'
          }
        ]}
      />

      <QuestionnaireStage
        id={'8'}
        track={'q2'}
        question={'Which best describes your employment status in the last 4 years?'}
        helperText={{
          title: 'Employment status',
          body: "There are different rules depending on whether you're employed, self-employed, or on the CIS (Construction Industry Scheme). We need to know your employment status so that we can correctly calculate your refund."
        }}
        answers={[
          {
            label: 'Self Employed or CIS',
            'data-answer-data': 'title=CIS',
            'data-goto-stage': '17'
          },
          {
            label: ' Employed (PAYE)',
            'data-answer-data': 'title=PAYE',
            'data-goto-stage': '9'
          }
        ]}
      />

      <QuestionnaireStage
        id={'9'}
        track={'q3'}
        question={'During the last 4 years have you worked at 2 or more workplaces for any one company?'}
        helperText={{
          title: 'Temporary workplaces',
          body: 'HMRC rules allow tax refunds to be claimed for travel to "temporary workplaces " (under 24 months at one location) going back 4 years.'
        }}
        answers={[
          {
            label: 'Yes',
            'data-goto-stage': '10'
          },
          {
            label: 'No',
            'data-goto-stage': '11'
          }
        ]}
      />

      <QuestionnaireStage
        id={'10'}
        track={'q4a'}
        question={'Did you use your own vehicle or public transport to get to those workplaces?'}
        helperText={{
          title: 'Transport methods',
          body: "If you had to pay to use your own vehicle or public transport and weren't reimbursed by your employer you could claim a tax refund on those costs."
        }}
        answers={[
          {
            label: 'Yes',
            'data-goto-stage': '17'
          },
          {
            label: 'No',
            'data-goto-stage': '11'
          }
        ]}
      />

      <QuestionnaireStage
        id={'11'}
        track={'q4-5b'}
        question={'Do you need to file a tax return? Do you have any other money coming in?'}
        helperText={{
          title: 'Tax returns',
          body: 'If you need to complete a self-assessment tax return for any reason we can help you. Prices start from £95.'
        }}
        answers={[
          {
            label: 'Yes',
            'data-redirect-to':
              'https://www.riftrefunds.co.uk/form-settings/eligibility-form/end-state-2a-all-paye-inc-construction-paye-but-need-to-submit-a-tax-return/'
          },
          {
            label: 'No',
            'data-redirect-to':
              'https://www.riftrefunds.co.uk/form-settings/eligibility-form/end-state-3-b-all-other-not-eligible/'
          }
        ]}
      />

      <QuestionnaireStage
        id={'12'}
        track={'q2'}
        question={'Which best describes your employment status in the last 4 years?'}
        helperText={{
          title: 'Employment status',
          body: "There are different rules depending on whether you're employed, self-employed, or on the CIS (Construction Industry Scheme). We need to know your employment status so that we can correctly calculate your refund."
        }}
        answers={[
          {
            label: 'Self Employed',
            'data-answer-data': 'title=CIS',
            'data-redirect-to':
              'https://www.riftrefunds.co.uk/form-settings/eligibility-form/end-state-2b-self-employed-but-not-construction-cis/'
          },
          {
            label: 'Employed (PAYE)',
            'data-answer-data': 'title=PAYE',
            'data-goto-stage': '13'
          }
        ]}
      />

      <QuestionnaireStage
        id={'13'}
        track={'q3a'}
        question={'Did you use your own vehicle or public transport to get to your heliports?'}
        helperText={{
          title: 'Transport method',
          body: "If you drive or get public transport to the airport or heliport then you'll have paid tax on those costs and so could be due a refund."
        }}
        answers={[
          {
            label: 'Yes',
            'data-goto-stage': '14'
          },
          {
            label: 'No',
            'data-goto-stage': '11'
          }
        ]}
      />

      <QuestionnaireStage
        id={'14'}
        track={'q4a'}
        question={'During the last 4 years have you worked at 2 or more oil rigs or oil fields for any one company?'}
        helperText={{
          title: 'Temporary workplaces',
          body: 'HMRC rules allow tax refunds to be claimed for travel to "temporary workplaces" (under 24 months at one location) going back 4 years.'
        }}
        answers={[
          {
            label: 'Yes',
            'data-goto-stage': '17'
          },
          {
            label: 'No',
            'data-goto-stage': '11'
          }
        ]}
      />

      <QuestionnaireStage
        id={'15'}
        track={'q2'}
        question={'Which best describes your employment status in the last 4 years?'}
        helperText={{
          title: 'Employment status',
          body: "There are different rules depending on whether you're employed, self-employed, or on the CIS (Construction Industry Scheme). We need to know your employment status so that we can correctly calculate your refund."
        }}
        answers={[
          {
            label: 'Self Employed',
            'data-answer-data': 'title=CIS',
            'data-redirect-to':
              'https://www.riftrefunds.co.uk/form-settings/eligibility-form/end-state-2b-self-employed-but-not-construction-cis/'
          },
          {
            label: 'Employed (PAYE)',
            'data-answer-data': 'title=PAYE',
            'data-goto-stage': '9'
          }
        ]}
      />
    </>
  )
}

export default QuestionnaireStages
