import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import TypeAhead from './TypeAhead'
import FormLink from './FormLink'
import FormGroupHeader from './FormGroupHeader'
import FormGroupInput from './FormGroupInput'
import OptionsItemLabel from './OptionsItemLabel'
import OptionsItemText from './OptionsItemText'
import Drawer from '../layout/Drawer'
import DrawerHeader from '../layout/DrawerHeader'
import DrawerFooter from '../layout/DrawerFooter'
import Map from './Map'

import { DrawerBody, FormGroupErrorMessage, Options, OptionsItem } from '../layout/Wrapper'

import { locationLookup } from '../../modules/api'
import { mapSrc, methodFromString, hasValues } from '../../modules/utils'

const ADDRESS_INPUTS = [
  { name: 'name', label: 'Building or company name' },
  { name: 'first_line', label: 'Address line 1' },
  { name: 'second_line', label: 'Address line 2' },
  { name: 'town', label: 'Town' },
  { name: 'county', label: 'County' },
  { name: 'postcode', label: 'Postcode' }
]

const DEFAULT_STATE = {
  manual: false,
  selectionMade: false,
  selectionConfirmed: false,
  drawerClosed: false,
  address: {
    name: null,
    country: null,
    county: null,
    first_line: null,
    latitude: null,
    longitude: null,
    postcode: null,
    second_line: null,
    town: null
  }
}

class NonModLocationInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = this.getInitialState()

    this.toggleManualEntry = this.toggleManualEntry.bind(this)
    this.setAddressField = this.setAddressField.bind(this)
    this.setAddressState = this.setAddressState.bind(this)
    this.setPartialAddressState = this.setPartialAddressState.bind(this)
    this.handleAddressChange = this.handleAddressChange.bind(this)
    this.cancelConfirmation = this.cancelConfirmation.bind(this)
    this.confirmSelection = this.confirmSelection.bind(this)
    this.reset = this.reset.bind(this)
  }

  getInitialState() {
    const { address } = this.props
    const hasErrors = this.hasErrors()
    const hasAddressValues = this.hasAddressValues()
    const addressMissingCoordinates = hasAddressValues && (!address.latitude || !address.longitude)
    const lookupSelectionConfirmed = !hasErrors && !addressMissingCoordinates && hasAddressValues

    return {
      ...DEFAULT_STATE,
      manual: (hasErrors && hasAddressValues) || addressMissingCoordinates,
      selectionMade: lookupSelectionConfirmed,
      selectionConfirmed: lookupSelectionConfirmed,
      ...{ address }
    }
  }

  hasErrors() {
    const { errors } = this.props
    return !!Object.keys(errors).length
  }

  hasAddressValues() {
    const { address } = this.props
    return hasValues(address)
  }

  toggleManualEntry(e) {
    e.preventDefault()
    const { manual } = this.state
    this.setState({ manual: !manual })
  }

  reset(e) {
    if (e) {
      e.preventDefault()
    }
    this.setState(DEFAULT_STATE, this.handleAddressChange)
  }

  setAddressField(e) {
    const { name, value } = e.target
    const { address } = this.state

    this.setState(
      {
        address: {
          ...address,
          [name]: value
        }
      },
      this.handleAddressChange
    )
  }

  setPartialAddressState(partialAddressState) {
    const { address } = this.state

    this.setState(
      {
        address: {
          ...address,
          ...partialAddressState
        }
      },
      this.handleAddressChange
    )
  }

  setAddressState(address) {
    this.setState(
      {
        address,
        selectionMade: true,
        selectionConfirmed: false,
        drawerClosed: false
      },
      this.handleAddressChange
    )
  }

  handleAddressChange() {
    const { onChange } = this.props
    const { address } = this.state
    return methodFromString(onChange)(address)
  }

  closeDrawer(afterAction) {
    this.setState({ drawerClosed: true })
    if (afterAction) {
      setTimeout(afterAction, 400) // The drawer takes 500ms to animate closed
    }
  }

  cancelConfirmation() {
    this.closeDrawer(this.reset)
  }

  confirmSelection() {
    this.closeDrawer(() => {
      this.setState({ selectionConfirmed: true })
    })
  }

  renderManualEntry() {
    const { address } = this.state
    const { errors } = this.props

    return ADDRESS_INPUTS.map((input, index) => {
      const last = index === ADDRESS_INPUTS.length - 1
      const error = errors[input.name]

      return (
        <Fragment>
          <FormGroupHeader label={input.label} />
          <FormGroupInput
            name={input.name}
            type="text"
            defaultValue={address[input.name]}
            onChange={this.setAddressField}
          />
          {error && <FormGroupErrorMessage>{error}</FormGroupErrorMessage>}
          {last && <FormLink text="Use the map lookup instead" onClick={this.toggleManualEntry} />}
        </Fragment>
      )
    })
  }

  renderTypeahead() {
    const typeaheadError =
      this.hasErrors() && !this.hasAddressValues()
        ? 'We need to know where you were based to work out travel distances.'
        : false

    return (
      <TypeAhead
        lookupFunction={locationLookup}
        onSelection={this.setAddressState}
        label="Map lookup"
        placeholder="Search by company, postcode or location"
        error={typeaheadError}
        throttleMs={1500}
      >
        <FormLink text="Enter the address instead" onClick={this.toggleManualEntry} />
      </TypeAhead>
    )
  }

  renderSelectedAddress() {
    const { address } = this.state
    const { disabled } = this.props
    const label = address.name || address.first_line
    const microcopy = [address.town, address.county, address.postcode].filter(Boolean).join(', ')

    return (
      <Fragment>
        <Options>
          <OptionsItem modifiers={disabled ? 'disabled' : null}>
            <OptionsItemLabel modifiers={['uninteractive', 'image']}>
              <img
                className="options__item__image"
                src={mapSrc(`${address.latitude},${address.longitude}`, 90, 110, 12)}
                alt={label}
              />
              <OptionsItemText label={label} microcopy={microcopy} />
            </OptionsItemLabel>
          </OptionsItem>
        </Options>
        {!disabled && <FormLink text="This isn't the correct location" onClick={this.reset} />}
      </Fragment>
    )
  }

  renderConfirmationDrawer() {
    const { drawerClosed, address } = this.state

    return (
      <Drawer id="Drawer" visible={!drawerClosed} onClose={this.cancelConfirmation}>
        <DrawerHeader onClose={this.cancelConfirmation}>Workplace location</DrawerHeader>
        <DrawerBody modifiers="nopadding">
          <Map
            latitude={address.latitude}
            longitude={address.longitude}
            onChange={this.setPartialAddressState}
            query={address.first_line}
          />
        </DrawerBody>
        <DrawerFooter
          cancelLabel="Cancel"
          confirmLabel="Confirm location"
          onCancel={this.cancelConfirmation}
          onConfirm={this.confirmSelection}
        />
      </Drawer>
    )
  }

  render() {
    const { manual, selectionMade, selectionConfirmed } = this.state
    const showConfirmationDrawer = selectionMade && !selectionConfirmed

    return (
      <Fragment>
        {manual ? this.renderManualEntry() : selectionMade ? this.renderSelectedAddress() : this.renderTypeahead()}
        {showConfirmationDrawer && this.renderConfirmationDrawer()}
      </Fragment>
    )
  }
}

NonModLocationInput.propTypes = {
  onChange: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  address: PropTypes.object,
  errors: PropTypes.object,
  disabled: PropTypes.bool
}

export default NonModLocationInput
