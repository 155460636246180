import React from 'react'

const OptionsItemButton = function ({ children, onClick }) {
  return (
    <a href="#" className="options__item__button button button--default button--small" onClick={onClick}>
      {children}
    </a>
  )
}

export default OptionsItemButton
