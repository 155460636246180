import React from 'react'

import OptionsList from '../OptionsList'
import { FormGroup } from '../../layout/Wrapper'
import Select from '../Select'

const ContentForm = props => {
  const {
    callCentreWillOpenToday,
    todaysDay,
    otherDaySelectedValue,
    callbackPreference,
    timeSelectedValue,
    callbackTimeOptions,
    otherDayOptions,
    handleDateChange
  } = props.data
  const callbackPreferences = []
  const modifiers = ['columns']

  if (callCentreWillOpenToday) {
    callbackPreferences.splice(0, 0, { label: 'Today', value: 'Today' })
  }

  if (todaysDay !== 'Saturday') {
    callbackPreferences.splice(1, 0, { label: 'Tomorrow', value: 'Tomorrow' })
  }

  if ((callCentreWillOpenToday && todaysDay === 'Saturday') || todaysDay !== 'Saturday') {
    callbackPreferences.push({ label: 'Other', value: 'Other' })
  }

  switch (callbackPreferences.length) {
    case 2:
      modifiers.push('columns-2')
      break
    case 3:
      modifiers.push('columns-3')
  }

  return (
    <form>
      <FormGroup>
        <OptionsList
          name="callbackPreference"
          modifiers={modifiers}
          options={callbackPreferences}
          resource={{}}
          resourceName="user"
          value={callbackPreference}
          onChange={e => {
            handleDateChange(e)
          }}
        />
      </FormGroup>

      {callbackPreference === 'Other' && (
        // If the user has selected 'Other' preference, then we need to show day selector
        <FormGroup>
          <Select
            disabled={false}
            name="callbackDay"
            value={otherDaySelectedValue}
            options={otherDayOptions}
            onChange={e => handleDateChange(e)}
          />
        </FormGroup>
      )}

      <FormGroup>
        <Select
          disabled={false}
          name="callbackTime"
          value={timeSelectedValue}
          options={callbackTimeOptions}
          onChange={e => handleDateChange(e)}
        />
      </FormGroup>
    </form>
  )
}

const ContentBasic = props => {
  const {
    selectedDayAsString,
    timeSelectedValue,
    callbackPreference,
    nextOpenDayString,
    nextOpenTimeString,
    currentStep,
    steps
  } = props.data
  const timeSelectedValueSplit = timeSelectedValue.split(' - ')
  return (
    <React.Fragment>
      {currentStep === steps.intro && (
        <p>
          We'll be back in the office {nextOpenDayString} at <strong>{nextOpenTimeString}</strong> GMT, however you can
          request that we call you back at a time and day that's convenient to you.
        </p>
      )}
      {currentStep === steps.confirmation && (
        <p>
          Sit back and relax. We'll give you a ring{' '}
          <strong>{callbackPreference === 'Other' ? selectedDayAsString : callbackPreference.toLowerCase()}</strong>{' '}
          between{' '}
          <strong>
            {timeSelectedValueSplit[0]} and {timeSelectedValueSplit[1]}
          </strong>{' '}
          GMT
        </p>
      )}
    </React.Fragment>
  )
}

const ScheduledCallbackBody = props => {
  const { currentStep, steps } = props
  if (currentStep === steps.intro || currentStep == steps.confirmation) {
    return <ContentBasic data={props} />
  }
  return <ContentForm data={props} />
}

export default ScheduledCallbackBody
