import React from 'react'
import PropTypes from 'prop-types'

class Question extends React.Component {
  heading() {
    const { heading, headingContent, small } = this.props
    if (!heading) return null
    if (small) {
      return (
        <h3 className="question__heading">
          {heading}
          {headingContent}
        </h3>
      )
    } else {
      return (
        <h2 className="question__heading">
          {heading}
          {headingContent}
        </h2>
      )
    }
  }

  render() {
    const { text, children, small } = this.props
    const classes = `question${small ? ' mt-5' : ''}`
    return (
      <div className={classes}>
        {this.heading()}
        {text || children ? (
          <div className="question__body">
            {text && <p className="question__text">{text}</p>}
            {children && children}
          </div>
        ) : null}
      </div>
    )
  }
}

Question.propTypes = {
  heading: PropTypes.string,
  headingContent: PropTypes.node,
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  small: PropTypes.bool
}

Question.defaultProps = {
  headingContent: null,
  small: false
}

export default Question
