import { useMemo } from 'react'
import { groupBy, uuidv4, deDupe, pluck, includes, flatten, removeIndex } from './utils'

export function memoisedGroupedGeneralExpenses(expenses) {
  return useMemo(() => {
    const workplaceExpenses = expenses.filter(ex => !ex.display_data.workplace).map(ex => ex.display_data)
    return groupBy(workplaceExpenses, ['sector'])
  }, [expenses])
}

export function memoisedGroupedWorkplaceExpenses(expenses) {
  return useMemo(() => {
    const workplaceExpenses = expenses.filter(ex => ex.display_data.workplace).map(ex => ex.display_data)
    return groupBy(workplaceExpenses, ['kind'])
  }, [expenses])
}

export function defaultExpenseData(expenseKindId, expenseDurationId, personLocationId, dailyFoodCharge) {
  return {
    id: uuidv4(),
    expense_kind_id: expenseKindId,
    cost: '',
    person_location_id: personLocationId,
    expense_duration_id: expenseDurationId,
    description: '',
    started_at: null,
    ended_at: null,
    claimable_percentage: 100,
    person_id: null,
    daily_food_charge: dailyFoodCharge || false
  }
}

export function frequenciesForExpenseKind(expenseKind, allFrequencies) {
  const permittedFrequencyIds = expenseKind.permitted_expense_duration_ids
  return allFrequencies.map(freq => {
    const index = permittedFrequencyIds.indexOf(freq.id.toString())

    return Object.assign({}, freq, {
      disabled: index === -1,
      default: index === 0
    })
  })
}

export function uniqueExpenseKindNames(expenseKinds, workplace = true) {
  let result = deDupe(
    pluck(
      expenseKinds.filter(kind => (workplace ? kind.workplace : !kind.workplace)),
      'name'
    )
  ).sort()

  // We want meals put first, as they're almost always claimed:
  const mealsIndex = result.indexOf('Meals')
  if (mealsIndex !== -1) {
    result = removeIndex(result, mealsIndex)
    result.unshift('Meals')
  }

  return result
}

export function sectorsForExpenseKindName(expenseKinds, expenseSectors, kindName) {
  const sectorIds = pluck(
    expenseKinds.filter(k => k.name === kindName),
    'sector_id'
  )
  return expenseSectors.filter(s => includes(sectorIds, s.id))
}

export function locationsForSector(locations, employments, sector) {
  const rawLocations = flatten(pluck(locations, 'data'))
  const selfEmployedEmployments = employments.filter(e => e.self_employed)
  const modEmployments = employments.filter(e => e.mod_branch)
  const generalEmployments = employments.filter(e => !e.self_employed && !e.mod_branch)

  if (sector.code === 'MOD') {
    return rawLocations.filter(l => includes(pluck(modEmployments, 'id'), l.person_employment_id))
  }
  if (sector.code === 'SELF-EMPLOYED') {
    return rawLocations.filter(l => includes(pluck(selfEmployedEmployments, 'id'), l.person_employment_id))
  }
  return rawLocations.filter(l => includes(pluck(generalEmployments, 'id'), l.person_employment_id))
}

export function expenseKindNamesWithExpenses(expenses, expenseKinds) {
  const existingExpenseKindIds = pluck(expenses, 'expense_kind_id')
  const existingKinds = expenseKinds.filter(k => k.workplace && includes(existingExpenseKindIds, k.id))
  return deDupe(pluck(existingKinds, 'name'))
}

export function expenseKindForSectorAndKindName(expenseKinds, sector, kindName) {
  return expenseKinds.find(k => k.name === kindName && k.sector_id === sector.id)
}

export function expensesForKindAndLocation(expenses, expenseKind, location) {
  return expenses.filter(ex => ex.expense_kind_id === expenseKind.id && ex.person_location_id === location.id)
}

export function locationIdsWithFoodChargeExpenses(expenses) {
  return deDupe(expenses.filter(e => e.daily_food_charge).map(e => e.person_location_id))
}

export function foodChargeEligibleExpenses(expenses, modMealsExpenseKindId, locationId) {
  return expenses.filter(
    e => e.expense_kind_id === modMealsExpenseKindId && e.person_location_id === parseInt(locationId, 10)
  )
}
