import React from 'react'
import PropTypes from 'prop-types'

export default function ImageLinkList({ heading, items, onLinkClick }) {
  return (
    <div className="image-link-list">
      {heading && <h2>{heading}</h2>}
      <ul>
        {items.map(item => {
          return (
            <li key={item.title}>
              <a onClick={onLinkClick} href={item.link.url} target="_blank" className="image-link-list__image">
                <img src={item.image.src} alt={item.image.alt} />
              </a>
              <p className="image-link-list__title">{item.title}</p>
              <p className="image-link-list__copy">{item.copy}</p>
              <a onClick={onLinkClick} href={item.link.url} target="_blank">
                {item.link.text}
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

ImageLinkList.propTypes = {
  heading: PropTypes.string,
  items: PropTypes.array.isRequired,
  onLinkClick: PropTypes.func
}

ImageLinkList.defaultProps = {
  heading: null,
  onLinkClick: () => {}
}
