import React from 'react'
import PropTypes from 'prop-types'

import EmojiImage from '../images/EmojiImage'

import { Emoji } from '../layout/Wrapper'

import { omit } from '../../modules/utils'

function EmptyState(props) {
  const { heading, detail, href, buttonText, icon } = props

  return (
    <div className="empty-state">
      <Emoji className={icon.className}>
        <EmojiImage {...omit(icon, 'className')} />
      </Emoji>
      <div className="empty-state__content">
        <h4 className="empty-state__heading">{heading}</h4>
        <p className="empty-state__detail">{detail}</p>
        {href && buttonText ? (
          <a className="empty-state__button button--primary button--small button" href={href}>
            {buttonText}
          </a>
        ) : null}
      </div>
    </div>
  )
}

EmptyState.propTypes = {
  heading: PropTypes.string.isRequired,
  detail: PropTypes.string.isRequired,
  href: PropTypes.string,
  buttonText: PropTypes.string,
  icon: PropTypes.shape({
    alt: PropTypes.string,
    classsName: PropTypes.string,
    modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    name: PropTypes.string.isRequired
  })
}

export default EmptyState
