import React from 'react'
import PropTypes from 'prop-types'

import Picker from '../Picker'

import { buildDate, datePickerDateOptions } from '../../../modules/dates'

class DatePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.stateFromProps()

    this.handleChange = this.handleChange.bind(this)
    this.handleComplete = this.handleComplete.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    const dateValue = this.getDateValue()
    const { year } = prevState
    const valueNeedsClearing = (!dateValue && year) || prevProps.name !== this.props.name

    if (valueNeedsClearing) {
      this.setState(this.stateFromProps())
    }
  }

  getDateValue() {
    const { resource, name, defaultDate } = this.props
    let date = defaultDate

    if (resource && resource[name]) {
      date = resource[name]
    }

    if (typeof date !== 'string') {
      return date
    }

    if (date.indexOf('-') >= 0) {
      return new Date(date)
    }

    // Sometimes we'll receive a date as a string we can't just pass to the Date constructor:
    const parts = date.split(' ').reverse()
    const year = parts[0]
    const month = parseInt(parts[1], 10) - 1 // Months are counted from 0
    const day = parts[2]
    return new Date(year, month, day, 0, 0, 0, 0)
  }

  stateFromProps() {
    const { includeDay } = this.props
    const dateValue = this.getDateValue()

    const state = {
      day: null,
      month: 0,
      year: null
    }

    if (dateValue) {
      if (includeDay) {
        state.day = dateValue.getDate()
      }
      state.month = dateValue.getMonth()
      state.year = dateValue.getFullYear()
    }

    return state
  }

  clearValues() {
    this.setState({ day: null, month: null, year: null })
  }

  handleChange(partialState) {
    const { day, month, year } = this.state
    const nextDateState = Object.assign({ day, month, year }, partialState)
    this.setState(partialState, () => {
      const { day, month, year } = nextDateState
      const { defaultDay, name, onChange } = this.props
      onChange({ [name]: buildDate(day, month, year, defaultDay) })
    })
  }

  handleComplete() {
    const { onComplete } = this.props
    this.setState(this.stateFromProps(), onComplete)
  }

  render() {
    const { day, month, year } = this.state
    const { minDate, minYear, maxYear, name, includeDay, showNav } = this.props

    return (
      <Picker
        name={name}
        onChange={this.handleChange}
        options={datePickerDateOptions(day, month, year, minDate, minYear, maxYear, includeDay)}
        onComplete={this.handleComplete}
        showNav={showNav}
      />
    )
  }
}

DatePicker.propTypes = {
  defaultDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  name: PropTypes.string.isRequired,
  resource: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  includeDay: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  minYear: PropTypes.number,
  maxYear: PropTypes.number,
  showNav: PropTypes.bool,
  defaultDay: PropTypes.oneOf(['first', 'last']).isRequired
}

DatePicker.defaultProps = {
  defaultDay: 'first'
}

export default DatePicker
