import React from 'react'
import PropTypes from 'prop-types'

import TextQuestion from '../TextQuestion'
import FormLink from '../FormLink'

class AddressLookupManualEntryFields extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(name, value) {
    const { onChange } = this.props

    onChange({
      [name]: value
    })
  }

  render() {
    const { address, errors, disabled, onTryLookupClick } = this.props

    const defaultProps = {
      className: 'mt-3',
      resourceName: 'api_person_address',
      resource: address,
      handlePropagation: this.handleChange,
      disabled: disabled
    }

    return (
      <React.Fragment>
        <TextQuestion
          {...defaultProps}
          className=""
          name="first_line"
          error={errors.first_line}
          label="Address line 1"
        />
        <TextQuestion {...defaultProps} name="second_line" error={errors.second_line} label="Address line 2" />
        <TextQuestion {...defaultProps} name="town" error={errors.town} label="Town" />
        <TextQuestion {...defaultProps} name="county" error={errors.county} label="County" />
        <TextQuestion {...defaultProps} name="postcode" error={errors.postcode} label="Postcode" />
        <FormLink text="Try the lookup again?" onClick={onTryLookupClick} />
      </React.Fragment>
    )
  }
}

AddressLookupManualEntryFields.propTypes = {
  address: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  errors: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onTryLookupClick: PropTypes.func.isRequired
}

export default AddressLookupManualEntryFields
