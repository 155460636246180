import React from 'react'
import PropTypes from 'prop-types'

import { generateModifierClasses } from '../../modules/utils'

function FactsItem({ title, text, imgAlt, imgSrc, modifiers }) {
  return (
    <li className={generateModifierClasses('facts__item', modifiers)}>
      <div className="facts__item__inner">
        <p className="facts__title">{title}</p>
        {text && <p className="facts__text">{text}</p>}
        {imgAlt && imgSrc && <img alt={imgAlt} src={imgSrc} />}
      </div>
    </li>
  )
}

FactsItem.propTypes = {
  text: PropTypes.string,
  title: PropTypes.string.isRequired,
  imgAlt: PropTypes.string,
  imgSrc: PropTypes.string,
  modifiers: PropTypes.array
}

export default FactsItem
