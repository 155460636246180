import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import OptionsList from '../OptionsList'
import FormLink from '../FormLink'
import ActionBar from '../ActionBar'
import ErrorSpan from '../ErrorSpan'

class Selection extends React.Component {
  actionBarActions() {
    const { addText, addClick } = this.props
    return [
      {
        componentName: 'Button',
        children: addText,
        onClick: addClick,
        modifiers: ['primary'],
        classes: ['button-group__item']
      }
    ]
  }

  renderLinks() {
    const { addText, addClick, disabled, editText, editClick } = this.props

    if (disabled) {
      return null
    }

    return (
      <Fragment>
        <FormLink text={addText} onClick={addClick} />
        <FormLink additionalClasses={['float-right']} text={editText} onClick={editClick} />
      </Fragment>
    )
  }

  render() {
    const { options, error } = this.props

    if (options.length) {
      return (
        <Fragment>
          <OptionsList modifiers="columns" {...this.props} />
          {this.renderLinks()}
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <ActionBar actions={this.actionBarActions()} />
          <ErrorSpan error={error} />
        </Fragment>
      )
    }
  }
}
Selection.propTypes = {
  options: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.bool]),
  resourceName: PropTypes.string,
  editAction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  editText: PropTypes.string,
  editClick: PropTypes.func,
  addText: PropTypes.string,
  addClick: PropTypes.func,
  disabled: PropTypes.bool
}
export default Selection
