import React from 'react'
import PropTypes from 'prop-types'

function ErrorSpan({ error }) {
  if (!error || error.length == 0) return null
  let spanProps = {
    className: 'form-group__error-message'
  }
  let errorChildren
  if (Array.isArray(error)) {
    errorChildren = error.map((error, index) => {
      return (
        <span className="help-block" key={index}>
          {error}
        </span>
      )
    })
  } else {
    spanProps.dangerouslySetInnerHTML = { __html: error }
  }
  return <span {...spanProps}>{errorChildren}</span>
}

ErrorSpan.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.bool])
}

export default ErrorSpan
