import React from 'react'

import { usePolledLocation } from '../../../modules/hooks'
import { pageConfigForPathname } from '../../../modules/pages'

import MastheadTimelineStages from './MastheadTimelineStages'
import MastheadNavList from './MastheadNavList'

const NAV_LINKS = [
  { label: 'Workplaces', href: '/my_rift/work_locations', active: false },
  { label: 'Expenses', href: '/my_rift/expenses', active: false },
  { label: 'Documents', href: '/documents', active: false }
]

export default function MastheadNav() {
  const { pathname } = usePolledLocation()
  const pageConfig = pageConfigForPathname(pathname)

  const links = NAV_LINKS.map(link => {
    if (location.pathname === link.href) {
      link.active = true
    }
    return link
  })

  return (
    <nav className="masthead__nav nav">
      {pageConfig && pageConfig.timelineStage ? (
        <MastheadTimelineStages activeStage={pageConfig.name} />
      ) : (
        <MastheadNavList links={links} />
      )}
    </nav>
  )
}
