import React from 'react'
import PropTypes from 'prop-types'

import OptionsItem from './legacy/OptionsItem'
import Question from './Question'
import ErrorSpan from './ErrorSpan'
import Helper from '../display/Helper'
import HelperTrigger from '../display/HelperTrigger'
import FormGroupHeader from './FormGroupHeader'

import { FormGroup, Options } from '../layout/Wrapper'

import { inputName, inputId, propagateValue, helperId } from '../../modules/utils'

class OptionsList extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: this.value() }
    this.updateValue = this.updateValue.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.hiddenInputRef = React.createRef()
  }

  static getDerivedStateFromProps(props, state) {
    if (props.value && props.value !== state.prevPropsValue) {
      return {
        prevPropsValue: props.value,
        value: state.prevPropsValue ? props.value : state.value
      }
    }
    return null
  }

  value() {
    const { resource, value, name } = this.props
    const resourceValue = resource && resource[name]
    const val = (this.state && this.state.value) || value || resourceValue || ''
    return parseInt(val) || val
  }

  updateValue(value) {
    this.setState({ value: value }, propagateValue.apply(this, [this.props.name, value]))

    // Hidden inputs don't trigger a 'change' event by default, so
    // we manually trigger one here.
    setTimeout(() => {
      const event = new CustomEvent('change')
      const hiddenInput = this.hiddenInputRef.current
      hiddenInput && hiddenInput.dispatchEvent(event)
    }, 0)
  }

  handleSelectChange({ target }) {
    this.updateValue(target.value)
  }

  optionItems() {
    const { options, resourceName, name, modifiers, editAction, disabled } = this.props

    return (
      <Options modifiers={modifiers}>
        {options.map((option, index) => (
          <OptionsItem
            label={option.label}
            microcopy={option.microcopy}
            checked={this.optionChecked(option)}
            key={index}
            name={`${name}_option`}
            resourceName={resourceName}
            value={String(option.value)}
            onChange={disabled ? () => {} : this.updateValue}
            data={option.data}
            locked={option.locked && option.endedAtLocked}
            editAction={editAction}
            disabled={disabled}
          />
        ))}
      </Options>
    )
  }

  optionChecked(option) {
    if ([option.name, option.value].indexOf('Other') != -1 && this.showSelect()) {
      return true
    }
    return this.value() == (parseInt(option.value) || option.value)
  }

  showSelect() {
    const ids = this.selectionIds()
    if (!ids) return false
    return this.value() == 'Other' || this.selectionIds().indexOf(this.value()) >= 0
  }

  selectionIds() {
    const { selections } = this.props
    return selections && selections.map(selection => selection.value)
  }

  hiddenInput() {
    return (
      <input
        type="hidden"
        value={this.value()}
        name={inputName.apply(this)}
        id={inputId.apply(this, [false])}
        ref={this.hiddenInputRef}
      />
    )
  }

  renderSelect() {
    if (!this.showSelect()) return false
    const { selections, name } = this.props
    return (
      <div className="form-group__input select">
        <select className="select__element mt-2" name={name} onChange={this.handleSelectChange} value={this.value()}>
          {selections.map((select_option, index) => (
            <option key={index} value={String(select_option.value)}>
              {select_option.label}
            </option>
          ))}
        </select>
      </div>
    )
  }

  renderHelperTrigger(questionHeading) {
    const { helperText, onHelpClick } = this.props

    if (helperText || onHelpClick) {
      return (
        <HelperTrigger
          helperId={helperId.apply(this)}
          onClick={onHelpClick}
          eventLabel={`the '${questionHeading}' question on the Options List`}
        />
      )
    }
  }

  render() {
    const { classes, error, questionHeading, questionText, helperText, label } = this.props
    return (
      <div className={classes && classes.join(' ')}>
        {helperText && <Helper id={helperId.apply(this)} body={helperText} />}
        {this.hiddenInput()}
        <FormGroup modifiers={error ? 'error' : null}>
          {(questionHeading || questionText) && (
            <Question heading={questionHeading} text={questionText}>
              {this.renderHelperTrigger(questionHeading)}
            </Question>
          )}
          <FormGroupHeader label={label} />
          {this.optionItems()}
          {this.renderSelect()}
        </FormGroup>
        <ErrorSpan error={error} />
      </div>
    )
  }
}
OptionsList.propTypes = {
  options: PropTypes.array,
  selections: PropTypes.array,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.bool]),
  helperText: PropTypes.string,
  classes: PropTypes.array,
  resource: PropTypes.object,
  resourceName: PropTypes.string,
  label: PropTypes.string,
  questionHeading: PropTypes.string,
  questionText: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  editAction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  handlePropagation: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabled: PropTypes.bool,
  onHelpClick: PropTypes.func
}

OptionsList.defaultProps = {
  disabled: false
}
export default OptionsList
