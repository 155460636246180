import { fetch } from './utils'

function processResults(lookupResults, houseNumber) {
  const knownData = {
    town: lookupResults.town,
    county: lookupResults.traditional_county || lookupResults.postal_county,
    postcode: lookupResults.postcode
  }

  const deliveryPoints = lookupResults.delivery_points

  if (lookupResults.delivery_point_count !== 1 && houseNumber) {
    for (let i = 0; i < deliveryPoints.length; i++) {
      const deliveryPoint = deliveryPoints[i]
      const firstLine = deliveryPoint.line_1.toLocaleLowerCase()
      const matcher = houseNumber.toLocaleLowerCase()

      if (firstLine.startsWith(matcher)) {
        return [knownData, [deliveryPoint]]
      }
    }
  }

  return [knownData, deliveryPoints]
}

export function buildFirstAndSecondLines(deliveryPoint) {
  const firstLine = [deliveryPoint.organisation_name, deliveryPoint.line_1, deliveryPoint.department_name]
    .filter(Boolean)
    .join(', ')

  return {
    first_line: firstLine,
    second_line: deliveryPoint.line_2
  }
}

export function postcodeLookup(postcode, houseNumber) {
  return new Promise((success, failure) => {
    return fetch(
      'POST',
      '//pcls1.craftyclicks.co.uk/json/rapidaddress',
      'application/json',
      'application/json',
      {
        key: window.crafty_postcode_api_key,
        postcode: postcode,
        response: 'data_formatted'
      },
      true
    ).then(data => {
      if (data.error_code) {
        return failure(data.error_msg)
      } else {
        return success(processResults(data, houseNumber))
      }
    })
  })
}
