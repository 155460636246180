import React from 'react'
import PropTypes from 'prop-types'

import Icon from '../images/Icon'

export default function QuestionnairePanel() {
  return (
    <div className="questionnaire__panel">
      <div className="questionnaire__panel-content">
        <span className="questionnaire-sub-heading">Why use Rift Refunds?</span>
        <ul className="questionnaire__panel-items">
          <li className="questionnaire__panel-trustpilot">
            <span>
              <Icon name="eye" alt="Eye icon" />
            </span>
            <h2 className="questionnaire__panel-heading">Highly recommended</h2>
            <p>We're rated excellent on Trustpilot</p>
          </li>
          <li className="questionnaire__panel-costs">
            <span>
              <Icon name="cost" alt="Cost icon" />
            </span>
            <h2 className="questionnaire__panel-heading">No hidden costs</h2>
            <p>It costs nothing to find out if you have a claim</p>
          </li>
          <li className="questionnaire__panel-experts">
            <span>
              <Icon name="experts" alt="Experts icon" />
            </span>
            <h2 className="questionnaire__panel-heading">Expert Tax Specialists</h2>
            <p>Our experts are here to help</p>
          </li>
        </ul>
      </div>
    </div>
  )
}
