import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import OverlayWrapper from '../layout/OverlayWrapper'

export default function FloatingContainer({ children, onClose, mini, open, direction }) {
  if (!open) {
    return null
  }

  const classes = `floating-container ${mini ? 'floating-container--mini' : ''} floating-container--${direction}`

  return (
    <Fragment>
      <OverlayWrapper onClick={onClose} />
      <div className={classes}>{children}</div>
    </Fragment>
  )
}

FloatingContainer.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  mini: PropTypes.bool,
  direction: PropTypes.oneOf(['left', 'right'])
}

FloatingContainer.defaultProps = {
  mini: false,
  direction: 'right'
}
