import React from 'react'
import PropTypes from 'prop-types'
import { List } from 'react-content-loader'
import {
  FormGroup,
  ContentWrapper,
  ContentWrapperInner,
  ContentFooter,
  ButtonGroup,
  AlertText
} from '../layout/Wrapper'
import Question from './Question'
import ButtonGroupItem from './ButtonGroupItem'
import OptionsList from './OptionsList'
import CheckBoxGroupInput from './CheckBoxGroupInput'
import ActionBar from '../interactive/ActionBar'
import Bank from '../display/Bank'
import Alert from '../display/alert/Alert'
import AlertHeader from '../display/alert/AlertHeader'

import { updatePageTitle, changeNavVisibility } from '../../modules/legacyUtils'

class PreferencesForm extends React.Component {
  constructor(props) {
    super(props)

    this.renderForm = this.renderForm.bind(this)
    this.handleRefundPreferenceChange = this.handleRefundPreferenceChange.bind(this)
    this.handleCommunicationPreferenceChange = this.handleCommunicationPreferenceChange.bind(this)
  }

  componentDidMount() {
    updatePageTitle('Preferences')
    changeNavVisibility(true)
  }

  communicationPreferences() {
    const { email, sms, phone, post } = this.props.preferences.communication

    return [
      { label: 'Email', value: 'email', checked: email },
      { label: 'Text message', value: 'sms', checked: sms },
      { label: 'Phone', value: 'phone', checked: phone },
      { label: 'Post', value: 'post', checked: post }
    ]
  }

  handleRefundPreferenceChange(name, value) {
    const { onChange } = this.props
    return onChange({
      gm_refund_method: value
    })
  }

  handleCommunicationPreferenceChange(name, value) {
    const { onChange, preferences } = this.props
    const { communication } = preferences

    return onChange({
      communication: {
        ...communication,
        [name]: value
      }
    })
  }

  renderAddBankAccountButton() {
    const { onEditBankAccountClick } = this.props
    const action = [
      {
        componentName: 'Button',
        children: 'Add bank account',
        onClick: onEditBankAccountClick,
        modifiers: ['primary'],
        classes: ['button-group__item']
      }
    ]

    return <ActionBar actions={action} />
  }

  renderBankAccountInfo() {
    const { bank, bankAccount, onEditBankAccountClick } = this.props
    const bankInfoLoaded = !!bank.name && !!bankAccount.id

    if (!bankInfoLoaded) {
      return null
    }

    return (
      <Bank
        label={bank.name}
        microcopy={`${bankAccount.account_number} - ${bankAccount.account_holder}`}
        onEdit={onEditBankAccountClick}
      />
    )
  }

  renderCheckDeliveryMessage() {
    const { preferences } = this.props
    const { current_address_strings } = preferences

    if (current_address_strings.length === 1) {
      return (
        <AlertText>
          Unless you tell us otherwise, any refunds will be sent by cheque to{' '}
          <strong>{current_address_strings[0]}</strong>. If you'd like it sent somewhere else, please call us on{' '}
          <a href="tel:+441233628648">01233 628648</a>.
        </AlertText>
      )
    }

    if (current_address_strings.length === 0) {
      return (
        <AlertText>
          You have <strong>no current address set</strong>. Please <a href="/addresses/new">add your current address</a>{' '}
          so we know where to send the cheque. Alternatively, you can call us on{' '}
          <a href="tel:+441233628648">01233 628648</a> to discuss.
        </AlertText>
      )
    }

    return (
      <AlertText>
        As you have <strong>more than one current address</strong>, please call us on{' '}
        <a href="tel:+441233628648">01233 628648</a> to discuss where you'd like us to send the cheque.
      </AlertText>
    )
  }

  renderForm() {
    const { preferences } = this.props
    const showBankForm = preferences.gm_refund_method === 'Direct'
    const hasBankAccount = !!preferences.bank_account_id

    const BankForm = () => {
      if (showBankForm) {
        return (
          <FormGroup>
            <Question
              heading="Bank details"
              text="Which bank account would you like us to transfer your refund to?"
              small={true}
            />
            {hasBankAccount ? this.renderBankAccountInfo() : this.renderAddBankAccountButton()}
          </FormGroup>
        )
      } else {
        return (
          <Alert modifiers="info">
            <AlertHeader>Delivery address</AlertHeader>
            {this.renderCheckDeliveryMessage()}
          </Alert>
        )
      }
    }

    return (
      <>
        <FormGroup modifiers="margin">
          <OptionsList
            questionHeading="Refund method"
            questionText="How would you like to receive any claim refunds?"
            modifiers={['columns']}
            name="gm_refund_method"
            options={[
              { label: 'Bank transfer (BACS)', value: 'Direct' },
              { label: 'Cheque', value: 'Cheque' }
            ]}
            resourceName={'user'}
            resource={preferences}
            value={preferences.gm_refund_method}
            handlePropagation={this.handleRefundPreferenceChange}
          />
        </FormGroup>
        <BankForm />
        <hr />
        <FormGroup>
          <Question
            heading="Marketing"
            text="From time-to-time, we'd like to send you offers, news, and tax-saving tips. How would you prefer us to get in touch?"
          />
          <CheckBoxGroupInput
            modifiers="columns"
            onChange={this.handleCommunicationPreferenceChange}
            options={this.communicationPreferences()}
          />
        </FormGroup>
      </>
    )
  }

  render() {
    const { onCancel, onSubmit, submitting, fetching } = this.props
    const buttonModifiers = submitting ? ['primary', 'loading'] : 'primary'

    return (
      <ContentWrapper modifiers="small">
        <ContentWrapperInner modifiers="longform">
          {fetching ? (
            <FormGroup modifiers="margin">
              <List />
              <List />
              <List />
            </FormGroup>
          ) : (
            this.renderForm()
          )}
        </ContentWrapperInner>
        <ContentFooter modifiers="actions">
          <ButtonGroup modifiers="spread">
            <ButtonGroupItem buttonModifiers="default" element="anchor" href="#" onClick={onCancel}>
              Cancel
            </ButtonGroupItem>
            <ButtonGroupItem buttonModifiers={buttonModifiers} element="anchor" href="#" onClick={onSubmit}>
              Save changes
            </ButtonGroupItem>
          </ButtonGroup>
        </ContentFooter>
      </ContentWrapper>
    )
  }
}

PreferencesForm.propTypes = {
  onEditBankAccountClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  bank: PropTypes.object,
  bankAccount: PropTypes.object,
  preferences: PropTypes.object.isRequired,
  submitting: PropTypes.bool,
  fetching: PropTypes.bool
}

PreferencesForm.defaultProps = {
  submitting: false,
  fetching: false
}

export default PreferencesForm
