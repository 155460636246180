import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import Button from './Button'
import FloatingContainer from '../display/FloatingContainer'
import { FloatingContainerInner, InputGroup, InputGroupItem, FormGroup, Options } from '../layout/Wrapper'
import FormGroupInput from './FormGroupInput'
import OptionsItem from './legacy/OptionsItem'
import DatePicker from './date/DatePicker'

import { timelineDateRange } from '../../modules/timelines'
import { areSameDates } from '../../modules/dates'

const DATE_FORMAT = 'MMM YYYY'

const CLOSED_STATE = 'closed'
const OPEN_STATE = 'open'
const OPEN_DATE_STATE = 'open-dates'

const chosenFrequency = (frequencyId, frequencies) => {
  if (!frequencyId) {
    return frequencies[0]
  }
  return frequencies.find(f => f.id.toString() === frequencyId.toString())
}

const frequencyLabel = (expense, frequency) => {
  const { unit } = frequency
  if (unit === 'one-off') {
    return unit
  }
  return `per ${frequency.unit}`
}

const durationLabel = (expense, frequency, longLabel) => {
  const { unit } = frequency
  const { started_at, ended_at } = expense

  if (unit === 'one-off') {
    if (!started_at || !ended_at || started_at !== ended_at) {
      if (longLabel) {
        return "I don't know the date"
      } else {
        return 'on an unspecified date'
      }
    }
    return `${longLabel ? 'O' : 'o'}n ${dayjs(started_at).format(DATE_FORMAT)}`
  }

  if (started_at) {
    return `${longLabel ? 'From ' : ''}${dayjs(started_at).format(DATE_FORMAT)} to ${dayjs(ended_at).format(
      DATE_FORMAT
    )}`
  }

  if (longLabel) {
    return 'True for the entire claim period'
  } else {
    return 'for the claim period'
  }
}

function FrequencySection({ expense, frequency, frequencies, onChange, setVisualState }) {
  const handleFrequencyChange = id => {
    onChange({ expense_duration_id: id })
  }

  return (
    <FloatingContainerInner>
      <FormGroup modifiers="margin">
        <Options modifiers="columns">
          {frequencies.map(freq => {
            const { name, unit, id, disabled } = freq

            return (
              <OptionsItem
                label={name}
                checked={frequency.id === id}
                locked={disabled}
                disabled={disabled}
                key={unit}
                name={'duration_id'}
                resourceName={'expense'}
                value={id.toString()}
                onChange={id => !disabled && handleFrequencyChange(id)}
              />
            )
          })}
        </Options>
      </FormGroup>
      <FormGroup modifiers="margin">
        <FormGroupInput
          type="text"
          name="description"
          placeholder={durationLabel(expense, frequency, true)}
          id="description"
          onClick={() => setVisualState(OPEN_DATE_STATE)}
          modifiers="clickable"
          value=""
          readOnly
        />
      </FormGroup>
      <Button modifiers={['default', 'block']} onClick={() => setVisualState(CLOSED_STATE)}>
        Done
      </Button>
    </FloatingContainerInner>
  )
}

function DurationSection({ expense, frequency, onChange, setVisualState }) {
  const [focusedField, setFocusedField] = useState('started_at')
  const { started_at, ended_at } = expense
  const [startOfClaimDate, endOfClaimDate] = timelineDateRange().map(d => d.toDate())

  const oneOff = frequency.name === 'One-off'
  const currentYear = new Date().getFullYear()
  const startedAtVal = started_at ? dayjs(started_at).format(DATE_FORMAT) : null
  const endedAtVal = ended_at ? dayjs(ended_at).format(DATE_FORMAT) : null

  const startFocused = focusedField === 'started_at'
  const endFocused = focusedField === 'ended_at'

  const handleChange = expenseData => {
    if (oneOff) {
      return onChange(Object.assign({ ended_at: expenseData.started_at }, expenseData))
    }

    return onChange(expenseData)
  }

  const handleDateCompletion = () => {
    if (oneOff || focusedField === 'ended_at') {
      setVisualState(CLOSED_STATE)
    } else {
      setFocusedField('ended_at')
    }
  }

  const handleStartEndClaimPeriodClick = () => {
    if (oneOff) {
      onChange({ started_at: null, ended_at: null })
      setVisualState(CLOSED_STATE)
    }

    if (startFocused) {
      if (areSameDates(new Date(expense.ended_at), endOfClaimDate)) {
        onChange({ started_at: null, ended_at: null })
      } else {
        onChange({ started_at: startOfClaimDate })
      }
      setFocusedField('ended_at')
      return
    }

    if (areSameDates(new Date(expense.started_at), startOfClaimDate)) {
      onChange({ started_at: null, ended_at: null })
    } else {
      onChange({ ended_at: endOfClaimDate })
    }
    setVisualState(CLOSED_STATE)
  }

  const startEndClaimPeriodLabel = () => {
    if (oneOff) {
      return "I don't know the date"
    }

    return `${startFocused ? 'From the start' : 'Until the end'} of the claim
          period`
  }

  return (
    <Fragment>
      <FloatingContainerInner>
        <InputGroup>
          <InputGroupItem>
            <FormGroup>
              <FormGroupInput
                autoFocus={startFocused}
                className={startFocused ? 'focused' : null}
                type="text"
                name="started_at"
                placeholder={oneOff ? "I don't know the date" : 'Start of the claim'}
                onChange={() => {}}
                onFocus={() => setFocusedField('started_at')}
                value={startedAtVal || ''}
                modifiers="clickable"
                readOnly
              />
            </FormGroup>
          </InputGroupItem>
          {!oneOff && (
            <InputGroupItem>
              <FormGroup>
                <FormGroupInput
                  autoFocus={endFocused}
                  className={endFocused ? 'focused' : null}
                  type="text"
                  name="ended_at"
                  placeholder="End of the claim"
                  onChange={() => {}}
                  onFocus={() => setFocusedField('ended_at')}
                  value={endedAtVal || ''}
                  modifiers="clickable"
                  readOnly
                />
              </FormGroup>
            </InputGroupItem>
          )}
        </InputGroup>
      </FloatingContainerInner>
      <DatePicker
        defaultDay={startFocused ? 'first' : 'last'}
        name={focusedField}
        resource={expense}
        minDate={endFocused ? new Date(started_at) : null}
        minYear={currentYear - 11}
        maxYear={currentYear}
        showNav={false}
        includeDay={false}
        onChange={handleChange}
        onComplete={handleDateCompletion}
      />
      <FloatingContainerInner>
        <Button modifiers={['default', 'block']} onClick={handleStartEndClaimPeriodClick}>
          {startEndClaimPeriodLabel()}
        </Button>
      </FloatingContainerInner>
    </Fragment>
  )
}

export default function FrequencyDurationInput({ expense, frequencies, onChange }) {
  const [visualState, setVisualState] = useState(CLOSED_STATE)
  const { expense_duration_id } = expense
  const frequency = chosenFrequency(expense_duration_id, frequencies)
  const open = [OPEN_STATE, OPEN_DATE_STATE].indexOf(visualState) !== -1

  return (
    <div className="frequency-duration-input">
      <div className="frequency-duration-input__display" onClick={() => setVisualState(OPEN_STATE)}>
        <span className="frequency-duration-input__frequency">{frequencyLabel(expense, frequency)}</span>
        <br />
        <span className="frequency-duration-input__duration">{durationLabel(expense, frequency)}</span>
      </div>
      <FloatingContainer mini={true} open={open} onClose={() => setVisualState(CLOSED_STATE)}>
        {visualState === OPEN_STATE && (
          <FrequencySection
            expense={expense}
            frequency={frequency}
            frequencies={frequencies}
            onChange={onChange}
            setVisualState={setVisualState}
          />
        )}
        {visualState === OPEN_DATE_STATE && (
          <DurationSection
            expense={expense}
            frequency={frequency}
            onChange={onChange}
            setVisualState={setVisualState}
          />
        )}
      </FloatingContainer>
    </div>
  )
}

FrequencyDurationInput.propTypes = {
  expense: PropTypes.object.isRequired,
  frequencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  onChange: PropTypes.func.isRequired
}
