import React, { Fragment, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import Lead from '../../display/Lead'
import ConfigurableDisplayList from '../../display/ConfigurableDisplayList'
import ActionHeader from '../../interactive/ActionHeader'
import { updatePageTitle } from '../../../modules/legacyUtils'
import { generateDisplayListActions } from '../../../modules/timelines'
import { BulletList } from 'react-content-loader'
export default function EmploymentsIndex({ lead, employments, startDate, gaps }) {
  useEffect(() => {
    updatePageTitle('Employments')
  }, [])

  const resourcesLoaded = !!employments
  const list = useMemo(() => generateDisplayListActions(employments, gaps), [employments, gaps])

  return (
    <Fragment>
      <Lead dangerouslySetInnerHTML={{ __html: lead }} />
      <hr />
      {resourcesLoaded ? (
        <>
          <ActionHeader text={`${startDate.format('MMMM YYYY')} - Now`}>
            <a href="/employments/new" className="button button--mini button--default">
              Add an employment
            </a>
          </ActionHeader>
          <ConfigurableDisplayList list={list} placeholderName="employments" small={true} />
        </>
      ) : (
        <BulletList style={{ width: '66.6%' }} />
      )}
    </Fragment>
  )
}

EmploymentsIndex.propTypes = {
  lead: PropTypes.string.isRequired,
  employments: PropTypes.arrayOf(PropTypes.object),
  startDate: PropTypes.object.isRequired,
  gaps: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.object,
      endDate: PropTypes.object
    })
  ).isRequired
}
