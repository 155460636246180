import React from 'react'
import PropTypes from 'prop-types'

import { trackEvent } from '../../modules/tracking'

class HelperTrigger extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(e) {
    const { helperId, onClick } = this.props

    e.preventDefault()

    if (this.props.eventLabel) {
      trackEvent(`Clicked Helper Text for ${this.props.eventLabel}`)
    }

    if (onClick) {
      onClick(e)
    } else {
      var helper = document.getElementById(helperId)
      if (helper) helper.classList.toggle('helper--visible')
    }
  }

  render() {
    let { className } = this.props

    return (
      <a className={className} onClick={this.handleClick} tabIndex="0">
        <span className="sr-only">More information</span>
      </a>
    )
  }
}

HelperTrigger.propTypes = {
  helperId: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func,
  eventLabel: PropTypes.string
}

HelperTrigger.defaultProps = {
  className: 'helper__info',
  eventLabel: null
}

export default HelperTrigger
