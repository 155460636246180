import React from 'react'
import PropTypes from 'prop-types'
import Question from './Question'

import ButtonGroupItem from './ButtonGroupItem'
import DateGroup from './DateGroup'
import FormGroupHeader from './FormGroupHeader'
import FormGroupInput from './FormGroupInput'
import Helper from '../display/Helper'
import HelperTrigger from '../display/HelperTrigger'
import MilitaryDataQuestions from './MilitaryDataQuestions'
import OptionsList from './OptionsList'

import {
  ContentFooter,
  ContentWrapper,
  ContentWrapperInner,
  ButtonGroup,
  FormGroup,
  FormGroupErrorMessage
} from '../layout/Wrapper'

import helperTextData from '../../data/helperTextData.json'

class BasicDetailsForm extends React.Component {
  formatNinoValue(v) {
    const currentValue = this.props.user?.national_insurance_number

    if (currentValue === null || currentValue.length > v.length) {
      return v.toUpperCase()
    } else if (currentValue.length === 13) {
      return currentValue.toUpperCase()
    }

    const re = /\s/g
    const ninoValue = v.replace(re, '')
    let formattedValue = ''

    for (let i = 0; i <= ninoValue.length - 1; i += 2) {
      formattedValue += i >= ninoValue.length - 1 ? `${ninoValue.substring(i)}` : `${ninoValue.substring(i, i + 2)} `
    }

    return formattedValue.toUpperCase()
  }

  renderMODQuestions() {
    const { user, onChange } = this.props
    return (
      <React.Fragment>
        <hr />
        <FormGroup>
          <Question heading="Formation" text="What is your role within the Armed Forces?" />
          <MilitaryDataQuestions user={user} onChange={onChange} />
        </FormGroup>
      </React.Fragment>
    )
  }

  renderTrades() {
    const { trades, user, onChange } = this.props
    const mainTrades = [
      'Carpenter',
      'Electrician',
      'Groundworker',
      'Labourer',
      'Plant',
      'Operator',
      'Plumber',
      'Scaffolder',
      'Other'
    ]

    const tradeOptions = trades.filter(function (trade) {
      return mainTrades.indexOf(trade.label) >= 0
    })

    const tradeSelections = trades.filter(function (trade) {
      return mainTrades.indexOf(trade.label) == -1 || trade.label == 'Other'
    })
    return (
      <React.Fragment>
        <hr />
        <FormGroup>
          <Question heading="Trade" text="Which line of work best matches your trade?" />
          <OptionsList
            name="trade_id"
            onChange={onChange}
            options={tradeOptions}
            selections={tradeSelections}
            resourceName="user"
            resource={user}
            value={user.trade_id}
            modifiers={['columns']}
          />
        </FormGroup>
      </React.Fragment>
    )
  }

  render() {
    const { disabled, errors, modEmployee, onCancel, onChange, onSubmit, trades, user } = this.props
    const { full_name, email, phone, national_insurance_number, unique_tax_reference, reference_number } = user

    return (
      <ContentWrapper modifiers="small">
        <ContentWrapperInner modifiers="longform">
          <FormGroup>
            <Question heading="About you" text="Change your name, date of birth, and update your contact details." />

            <FormGroup modifiers={errors.full_name ? 'error' : ''}>
              <FormGroupHeader label="Full name" />
              <FormGroupInput
                value={full_name || ''}
                onChange={({ target }) => onChange({ full_name: target.value })}
                id="full_name"
              />
              {errors && errors.full_name && <FormGroupErrorMessage>{errors.full_name}</FormGroupErrorMessage>}
            </FormGroup>

            <FormGroup modifiers={errors.email ? 'error' : ''} className="mt-4">
              <FormGroupHeader label="Email address" />
              <FormGroupInput
                value={email || ''}
                onChange={({ target }) => onChange({ email: target.value })}
                id="email"
              />
              {errors && errors.email && <FormGroupErrorMessage>{errors.email}</FormGroupErrorMessage>}
            </FormGroup>

            <FormGroup modifiers={errors.phone ? 'error' : ''} className="mt-4">
              <FormGroupHeader label="Phone" />
              <FormGroupInput
                value={phone || ''}
                onChange={({ target }) => onChange({ phone: target.value })}
                id="phone"
              />
              {errors && errors.phone && <FormGroupErrorMessage>{errors.phone}</FormGroupErrorMessage>}
            </FormGroup>

            <FormGroup modifiers={errors.date_of_birth ? 'error' : ''} className="mt-4">
              <FormGroupHeader label="Date of Birth" />
              <DateGroup
                resourceName="user"
                resource={user}
                showDay={true}
                dateChanged={date => onChange({ date_of_birth: date })}
                name="date_of_birth"
                minYear={new Date().getFullYear() - 100}
                maxYear={new Date().getFullYear()}
              />
              {errors && errors.date_of_birth && <FormGroupErrorMessage>{errors.date_of_birth}</FormGroupErrorMessage>}
            </FormGroup>

            <FormGroup className="mt-4">
              <FormGroupHeader label="Reference number" />
              <FormGroupInput value={reference_number || ''} id="reference-number" disabled={true} />
            </FormGroup>
          </FormGroup>
          <hr />
          <FormGroup>
            <Helper id="helper-identifiers" body={helperTextData.misc.identifiers} />
            <Question heading="Identifiers" text="Your UTR and NI number ensures that any refunds go only to you.">
              <HelperTrigger
                helperId="helper-identifiers"
                eventLabel="the 'Identifiers' question on the Basic Details Form"
              />
            </Question>
            <FormGroup modifiers={errors.unique_tax_reference ? 'error' : ''}>
              <FormGroupHeader label="Unique tax reference (UTR)" />
              <FormGroupInput
                value={unique_tax_reference || ''}
                onChange={({ target }) => onChange({ unique_tax_reference: target.value })}
                id="unique_tax_reference"
              />
              {errors && errors.unique_tax_reference && (
                <FormGroupErrorMessage>{errors.unique_tax_reference}</FormGroupErrorMessage>
              )}
            </FormGroup>
            <FormGroup modifiers={errors.national_insurance_number ? 'error' : ''} className="mt-4">
              <FormGroupHeader label="National insurance (NI) number" />
              <FormGroupInput
                value={national_insurance_number || ''}
                onChange={({ target }) => onChange({ national_insurance_number: this.formatNinoValue(target.value) })}
                id="national_insurance_number"
              />
              {errors && errors.national_insurance_number && (
                <FormGroupErrorMessage>{errors.national_insurance_number}</FormGroupErrorMessage>
              )}
            </FormGroup>
          </FormGroup>
          {modEmployee && this.renderMODQuestions()}
          {trades && trades.length > 0 && this.renderTrades()}
        </ContentWrapperInner>
        <ContentFooter modifiers="actions">
          <ButtonGroup modifiers="spread">
            <ButtonGroupItem buttonModifiers="default" element="anchor" href="#" onClick={onCancel}>
              Cancel
            </ButtonGroupItem>
            <ButtonGroupItem buttonModifiers="primary" element="anchor" href="#" onClick={onSubmit} disabled={disabled}>
              Save changes
            </ButtonGroupItem>
          </ButtonGroup>
        </ContentFooter>
      </ContentWrapper>
    )
  }
}

BasicDetailsForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  modEmployee: PropTypes.bool,
  trades: PropTypes.array
}

export default BasicDetailsForm
