import React from 'react'
import PropTypes from 'prop-types'

import FormLink from '../FormLink'
import FormGroupHeader from '../FormGroupHeader'
import ErrorSpan from '../ErrorSpan'
import AnchorButton from '../AnchorButton'
import Helper from '../../display/Helper'

import AddressLookupAddressSelect from './AddressLookupAddressSelect'

import { FormGroup, FormGroupCombo, FormGroupComboItem } from '../../layout/Wrapper'

const ENTER_KEY = 13

class AddressLookupAutomatedLookupFields extends React.Component {
  constructor(props) {
    super(props)

    this.lookupNumberRef = React.createRef()
    this.lookupPostcodeRef = React.createRef()

    this.lookupInputKeyDown = this.lookupInputKeyDown.bind(this)
    this.handleLookupClick = this.handleLookupClick.bind(this)
  }

  lookupInputKeyDown(e) {
    if (e.keyCode === ENTER_KEY) {
      this.handleLookupClick(e)
    }
  }

  buttonModifiers() {
    const { lookupError } = this.props
    return lookupError ? ['primary', 'disabled'] : ['primary']
  }

  handleLookupClick(e) {
    e.preventDefault()

    const { onLookup } = this.props

    const postcode = this.lookupPostcodeRef.current.value
    const houseNumber = this.lookupNumberRef.current.value

    onLookup(postcode, houseNumber)
  }

  render() {
    const { deliveryPoints, lookupError, errors, disabled, onAddressSelect, onInputChange, onTryManualEntryClick } =
      this.props

    const showAddressSelect = deliveryPoints && deliveryPoints.length > 1
    const showPostcodeError = errors && errors.postcode

    return (
      <FormGroup modifiers={errors && Object.keys(errors).length ? 'error' : null}>
        <FormGroupCombo>
          <FormGroupComboItem modifiers={['large']}>
            <FormGroupHeader label="House name or number" />
            <input
              className="form-group__input"
              id="address-lookup-number-input"
              type="text"
              onChange={onInputChange}
              onKeyDown={this.lookupInputKeyDown}
              ref={this.lookupNumberRef}
              placeholder="e.g. 'The Willows', or 96"
            />
          </FormGroupComboItem>
          <FormGroupComboItem>
            <FormGroupHeader label="Postcode" />
            <input
              className="form-group__input"
              id="address-lookup-postcode-input"
              type="text"
              onChange={onInputChange}
              onKeyDown={this.lookupInputKeyDown}
              ref={this.lookupPostcodeRef}
              placeholder="AB12 3CD"
            />
          </FormGroupComboItem>
          <AnchorButton
            children="Find Address"
            onClick={this.handleLookupClick}
            modifiers={this.buttonModifiers()}
            classes={['form-group__combo__button', 'display-flex', 'mt-5']}
            disabled={disabled}
          />
          {showPostcodeError && <ErrorSpan error={errors.postcode} />}
        </FormGroupCombo>

        {showAddressSelect && (
          <AddressLookupAddressSelect className="mt-4" deliveryPoints={deliveryPoints} onChange={onAddressSelect} />
        )}

        {lookupError ? (
          <Helper
            title="We didn't recognise your postcode"
            body="Check that the postcode is correct, or enter the address yourself."
            closeable={false}
            modifiers={['warning', 'visible']}
            additionalInnerClasses={['mt-4', 'mb-0']}
            helperLink={{
              text: 'Enter the address manually instead',
              handleClick: onTryManualEntryClick
            }}
            iconName="grimace"
          />
        ) : (
          <FormLink text="Enter the address manually instead" onClick={onTryManualEntryClick} />
        )}
      </FormGroup>
    )
  }
}

AddressLookupAutomatedLookupFields.propTypes = {
  deliveryPoints: PropTypes.array,
  lookupError: PropTypes.bool.isRequired,
  errors: PropTypes.object,
  disabled: PropTypes.bool.isRequired,
  onAddressSelect: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onLookup: PropTypes.func.isRequired,
  onTryManualEntryClick: PropTypes.func.isRequired
}

export default AddressLookupAutomatedLookupFields
