/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Keep an ajax counter (useful for knowing when the page
// has loaded in tests. Set it to 0 if no ajax is performed.

window.ajaxCount = -1
window.loaded = false

window.addEventListener('load', function () {
  window.loaded = true
  if (window.ajaxCount === -1) {
    window.ajaxCount = 0
  }
})

window.addEventListener('unload', function () {
  window.loaded = false
})

// Support component names relative to this directory:
var myCustomContext = require.context('components', true)
var ReactRailsUJS = require('react_ujs')
ReactRailsUJS.useContext(myCustomContext)
