import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import ErrorSpan from './ErrorSpan'
import Helper from '../display/Helper'
import HelperTrigger from '../display/HelperTrigger'
import OptionsItemLabel from './OptionsItemLabel'
import OptionsItemInput from './OptionsItemInput'
import OptionsItemText from './OptionsItemText'

import { Options, OptionsItem, OptionsItemIndicator } from '../layout/Wrapper'

class CheckBoxGroupInput extends React.Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(e) {
    const { onChange } = this.props
    const { value, checked } = e.target
    onChange(value, checked)
  }

  renderCheckBoxes() {
    const { disabled, options } = this.props

    const optionsItemModifiers = disabled ? 'disabled' : null

    return options.map(option => {
      const id = `checkbox-${option.value}`
      const helperId = `helper-${option.value}`

      return (
        <React.Fragment key={id}>
          {option.helperText && <Helper id={helperId} body={option.helperText} />}
          <OptionsItem modifiers={optionsItemModifiers}>
            <OptionsItemLabel modifiers="checkbox" htmlFor={id}>
              <OptionsItemInput
                id={id}
                disabled={disabled}
                checked={option.checked || false}
                onChange={this.handleChange}
                value={option.value}
              />
              <OptionsItemText label={option.label} microcopy={option.microcopy} />
              {option.helperText && (
                <HelperTrigger helperId={helperId} eventLabel={`the '${option.label}' option on a Checkbox Group`} />
              )}
              <OptionsItemIndicator />
            </OptionsItemLabel>
          </OptionsItem>
        </React.Fragment>
      )
    })
  }

  render() {
    const { error, modifiers } = this.props
    return (
      <Fragment>
        <Options modifiers={modifiers}>{this.renderCheckBoxes()}</Options>
        <ErrorSpan error={error} />
      </Fragment>
    )
  }
}

CheckBoxGroupInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      checked: PropTypes.bool,
      helperText: PropTypes.string,
      label: PropTypes.string,
      microcopy: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired
}

CheckBoxGroupInput.defaultProps = {
  disabled: false
}

export default CheckBoxGroupInput
