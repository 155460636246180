import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Button from './Button'
import { FormGroup, FormGroupErrorMessage, InputGroup, InputGroupItem } from '../layout/Wrapper'

import FrequencyDurationInput from './FrequencyDurationInput'
import FormGroupInput from './FormGroupInput'

import { isUuidv4 } from '../../modules/utils'
import { defaultExpenseData } from '../../modules/expense'
import { cleanInputPrice, formatPrice } from '../../modules/formatter'
import { iconFromString } from '../../helpers/images'

function ExpenseInputContainer({ children, sub = false, expanded = false, first = false, last = false }) {
  const classes = ['expense-input']

  if (sub) {
    classes.push('expense-input--sub')
  }

  if (expanded) {
    classes.push('expense-input--expanded')
  }

  if (first) {
    classes.push('expense-input--first')
  }

  if (last) {
    classes.push('expense-input--last')
  }

  return <div className={classes.join(' ')}>{children}</div>
}

function ExpenseInputTitle({ title, subtitle }) {
  return (
    <div className="expense-input__label">
      <h4>{title}</h4>
      {subtitle && <small>{subtitle}</small>}
    </div>
  )
}

function ExpenseInputForm({ onChange, frequencies = [], expense, showDescription = false }) {
  const { id, cost, description, errors } = expense

  const handleChange = e => {
    const expenseData = { id, description, cost }
    const { name, value } = e.target

    expenseData[name] = name === 'cost' ? cleanInputPrice(value) : value

    if (expense.errors && expense.errors[name]) {
      expenseData.errors = Object.assign({}, expense.errors, { [name]: null })
    }

    onChange(Object.assign({}, expense, expenseData))
  }

  const handleCostChange = e => {
    handleChange(e)
    e.target.value = formatPrice(e.target.value)
  }

  const handleFrequencyDurationChange = expenseData => {
    onChange(Object.assign({}, expense, expenseData))
  }

  return (
    <div className="expense-input__form">
      <InputGroup modifiers="tight">
        {showDescription && (
          <InputGroupItem>
            <FormGroup modifiers={errors?.description ? 'error' : ''}>
              <FormGroupInput
                type="text"
                name="description"
                placeholder="Description"
                id="description"
                onChange={handleChange}
                value={description}
              />
            </FormGroup>
          </InputGroupItem>
        )}
        <InputGroupItem>
          <FormGroup modifiers={errors?.cost ? 'error' : ''}>
            <div className="form-group__pound-mask">
              <FormGroupInput
                type="text"
                name="cost"
                placeholder="0.00"
                id="cost"
                onBlur={handleCostChange}
                defaultValue={formatPrice(cost)}
              />
            </div>
          </FormGroup>
        </InputGroupItem>
        <InputGroupItem modifiers={showDescription ? null : 'md'}>
          <FrequencyDurationInput
            expense={expense}
            frequencies={frequencies}
            onChange={handleFrequencyDurationChange}
          />
        </InputGroupItem>
      </InputGroup>
      {errors?.cost && <FormGroupErrorMessage>{errors.cost[0]}</FormGroupErrorMessage>}
      {errors?.description && <FormGroupErrorMessage>{errors.description[0]}</FormGroupErrorMessage>}
    </div>
  )
}

function ExpenseInputAction({ onClick, icon }) {
  return (
    <div className="expense-input__action">
      <Button modifiers={['square', 'default']} onClick={onClick}>
        <img className="expense-input__action-image" alt={'Add another expense'} src={iconFromString(icon)} />
      </Button>
    </div>
  )
}

export default function ExpenseInput({
  dailyFoodCharge,
  expenses,
  expenseKindId,
  first,
  last,
  onAdd,
  onUpdate,
  onRemove,
  personLocationId,
  frequencies,
  subtitle,
  title
}) {
  const subExpensesVisible = expenses.length > 1 || expenses[0]?.description
  const defaultFrequency = frequencies.find(freq => freq.default)

  const newExpense = () => {
    return defaultExpenseData(expenseKindId, defaultFrequency.id, personLocationId, dailyFoodCharge)
  }

  const addExpense = () => {
    if (!expenses.length) {
      onAdd([newExpense(), newExpense()])
    } else {
      onAdd(newExpense())
    }
  }

  const removeExpense = removeIndex => {
    onRemove(expenses[removeIndex])
  }

  const updateExpense = (index, expense) => {
    const isNew = isUuidv4(expense.id)
    const hasCost = !!(expense.cost && expense.cost !== '0')
    const hasDescription = expense.description
    const hasDates = expense.started_at || expense.ended_at
    const hasUpdatedFrequency = expense.expense_duration_id !== defaultFrequency.id.toString()
    const newEmptyExpense = isNew && !hasCost && !hasDates && !hasDescription && !hasUpdatedFrequency
    const existingPricelessExpense = !isNew && !hasCost

    if (!expenses.length) {
      onAdd(expense)
    } else if (newEmptyExpense || existingPricelessExpense) {
      onRemove(expense)
    } else {
      onUpdate(expense)
    }
  }

  return (
    <Fragment>
      <ExpenseInputContainer expanded={subExpensesVisible} first={first} last={!subExpensesVisible && last}>
        <ExpenseInputTitle title={title} subtitle={subtitle} />
        {!subExpensesVisible && (
          <ExpenseInputForm
            expense={expenses.length ? expenses[0] : newExpense()}
            onChange={expenseFields => updateExpense(0, expenseFields)}
            frequencies={frequencies}
          />
        )}
        <ExpenseInputAction onClick={addExpense} icon="plusBlue" />
      </ExpenseInputContainer>
      {subExpensesVisible &&
        expenses.map((ex, i) => {
          return (
            <ExpenseInputContainer
              key={`expenses-input-container-${i}`}
              sub={true}
              last={last && i === expenses.length - 1}
            >
              <ExpenseInputForm
                onChange={expenseFields => updateExpense(i, expenseFields)}
                showDescription={true}
                expense={ex}
                frequencies={frequencies}
              />
              <ExpenseInputAction onClick={() => removeExpense(i)} icon="trashGrey" />
            </ExpenseInputContainer>
          )
        })}
    </Fragment>
  )
}

ExpenseInput.propTypes = {
  dailyFoodCharge: PropTypes.bool.isRequired,
  expenses: PropTypes.arrayOf(PropTypes.object).isRequired,
  expenseKindId: PropTypes.number.isRequired,
  first: PropTypes.bool.isRequired,
  last: PropTypes.bool.isRequired,
  onAdd: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  personLocationId: PropTypes.number,
  frequencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  subtitle: PropTypes.string,
  title: PropTypes.string.isRequired
}

ExpenseInput.defaultProps = {
  dailyFoodCharge: false,
  first: false,
  last: false,
  subtitle: '',
  workplaceExpense: false
}
