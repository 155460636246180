import React from 'react'
import PropTypes from 'prop-types'

import DescriptionList from './DescriptionList'

const e = React.createElement
import { generateModifierClasses } from '../../modules/utils'

const TitledDescriptionList = function ({ titleEl, list, modifiers, title }) {
  const classes = generateModifierClasses('titled-description-list', modifiers)

  return (
    <div className={classes}>
      {e(titleEl, null, title)}
      <DescriptionList list={list} />
    </div>
  )
}

TitledDescriptionList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      label: PropTypes.string,
      href: PropTypes.string
    })
  ).isRequired,
  titleEl: PropTypes.oneOf(['h2', 'h3', 'h4']),
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  title: PropTypes.string.isRequired
}

TitledDescriptionList.defaultProps = {
  titleEl: 'h2'
}

export default TitledDescriptionList
