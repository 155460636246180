import React from 'react'
import PropTypes from 'prop-types'

import { iconFromString } from '../../helpers/images'
import { generateModifierClasses } from '../../modules/utils'

const Icon = function ({ alt, modifiers, name }) {
  return <img className={generateModifierClasses('icon', modifiers)} alt={alt} src={iconFromString(name)} />
}

Icon.propTypes = {
  alt: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  name: PropTypes.string
}

export default Icon
