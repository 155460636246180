import React from 'react'
import ButtonGroupItem from './ButtonGroupItem'
import EmojiImage from '../images/EmojiImage'

import { ButtonGroup, Emoji } from '../layout/Wrapper'

import { handleBreakpoint, omit, setStateFromPath } from '../../modules/utils'

const BREAKPOINT = 864

// TODO: Move this data to state passed down from the
// server so we're not hard-coding step values in this
// component.
const REFUND_METHOD_STEPS = ['refund_method']
const YES_NO_STEPS = ['check_bank_details']

class ClaimWizard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      blank: false,
      fetchingState: false,
      currentData: false,
      notCurrent: false,
      pickerDirection: window.innerWidth <= BREAKPOINT ? 'right' : 'left'
    }

    this.deferQueueableStep = this.deferQueueableStep.bind(this)
    this.setNotCurrent = this.setNotCurrent.bind(this)
    this.setEndDate = this.setEndDate.bind(this)
    this.handlePropagation = this.handlePropagation.bind(this)
    this.setRefundMethod = this.setRefundMethod.bind(this)
  }

  componentDidMount() {
    setStateFromPath.apply(this, ['GET', '/claim_wizard/fetch_state', { blank: true }])
    handleBreakpoint.apply(this, [BREAKPOINT, this.setDirectionRight, this.setDirectionLeft])
  }

  setDirectionRight() {
    this.setState({ pickerDirection: 'right' })
  }

  setDirectionLeft() {
    this.setState({ pickerDirection: 'left' })
  }

  skipStep() {
    this.setState({ fetchingState: true })
    setStateFromPath.apply(this, ['PUT', '/claim_wizard/skip_step', { blank: true }])
  }

  deferQueueableStep() {
    this.setState({ fetchingState: true })
    setStateFromPath.apply(this, ['PUT', '/claim_wizard/defer_queueable_step', { blank: true }])
  }

  setNotCurrent() {
    this.setState({
      text: this.state.secondaryText,
      notCurrent: true,
      date: new Date()
    })
  }

  setEndDate() {
    const { date } = this.state
    setStateFromPath.apply(this, ['PUT', `/claim_wizard/set_end_date?date=${date}`, { blank: true }])
  }

  setRefundMethod(refundMethod) {
    setStateFromPath.apply(this, [
      'PUT',
      `/claim_wizard/set_refund_method?refund_method=${refundMethod}`,
      { blank: true }
    ])
  }

  setStageComplete(complete) {
    const previousStep = this.state.step
    setStateFromPath
      .apply(this, ['PUT', `/claim_wizard/set_stage_complete?complete=${complete}`, { blank: true }])
      .then(() => {
        const { step, secondaryText } = this.state
        if (previousStep === step && secondaryText) {
          this.setNotCurrent()
        }
      })
  }

  handlePropagation(name, date) {
    this.setState({ date: date })
  }

  createButtonGroupItem(options, key) {
    const { fetchingState } = this.state
    let onClick
    const buttonType = options.buttonType || 'primary'
    const props = omit(options, ['skipStep', 'buttonType', 'buttonModifiers'])

    if (options.skipStep) {
      onClick = () => {
        this.setStageComplete(true)
      }
    }

    return (
      <ButtonGroupItem
        buttonModifiers={[buttonType, 'block', 'small']}
        className="wizard__button"
        element="anchor"
        disabled={fetchingState}
        onClick={onClick}
        key={key}
        {...props}
      />
    )
  }

  renderQueueButtonGroupItems() {
    const { action } = this.state

    return [
      this.createButtonGroupItem(action, 1),
      this.createButtonGroupItem(
        {
          children: 'Not now',
          buttonType: 'wizard',
          onClick: this.deferQueueableStep
        },
        2
      )
    ]
  }

  renderRefundMethodButtonGroupItems() {
    const { action, skipText } = this.state

    return [
      this.createButtonGroupItem(
        {
          children: action.children,
          buttonType: 'primary',
          onClick: () => this.setRefundMethod('Direct')
        },
        1
      ),
      this.createButtonGroupItem(
        {
          children: skipText,
          buttonType: 'wizard',
          onClick: () => this.setRefundMethod('Cheque')
        },
        2
      )
    ]
  }

  renderYesNoButtonGroupItems() {
    const { action, skipText } = this.state

    return [
      this.createButtonGroupItem(
        {
          children: action.children,
          buttonType: 'wizard',
          onClick: () => this.setStageComplete(true)
        },
        1
      ),
      this.createButtonGroupItem(
        {
          children: skipText,
          buttonType: 'wizard',
          onClick: () => this.setStageComplete(false)
        },
        2
      )
    ]
  }

  renderPrimaryButtonGroupItemWithOptionalSkip() {
    const { action, skipText } = this.state
    const items = []

    items.push(this.createButtonGroupItem(action, items.length))

    if (skipText) {
      items.push(
        this.createButtonGroupItem(
          {
            children: skipText,
            buttonType: 'wizard',
            onClick: () => this.setStageComplete(true)
          },
          1
        )
      )
    }

    return items
  }

  renderButtonGroupItems() {
    const { action, queueable, step } = this.state

    if (queueable) {
      return this.renderQueueButtonGroupItems()
    }

    if (REFUND_METHOD_STEPS.indexOf(step) !== -1) {
      return this.renderRefundMethodButtonGroupItems()
    }

    if (YES_NO_STEPS.indexOf(step) !== -1) {
      return this.renderYesNoButtonGroupItems()
    }

    if (action && action.children) {
      return this.renderPrimaryButtonGroupItemWithOptionalSkip()
    }
  }

  renderButtonGroup() {
    return (
      <ButtonGroup className="wizard__actions" modifiers={['inline--lg-down', 'stacked--lg-up']}>
        {this.renderButtonGroupItems()}
      </ButtonGroup>
    )
  }

  render() {
    const loaded = this.state?.blank || this.state.iconName

    const { heading, text, iconName } = this.state

    return (
      <div className="wizard mb-0--sm-down mb-4--sm mb-4--md mb-0--lg-up wizard--new">
        {loaded && (
          <div className="wizard__inner">
            <Emoji className="wizard__icon">
              <EmojiImage name={iconName} alt={heading} />
            </Emoji>
            <div className="wizard__content">
              <h2 className="wizard__title">{heading}</h2>
              <p className="wizard__text">{text}</p>
            </div>
            {this.renderButtonGroup()}
          </div>
        )}
      </div>
    )
  }
}
export default ClaimWizard
