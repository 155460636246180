import React, { Fragment, useMemo } from 'react'
import PropTypes from 'prop-types'

import { updatePageTitle } from '../../../modules/legacyUtils'
import { generateDisplayListActions } from '../../../modules/timelines'

import Lead from '../../display/Lead'
import ConfigurableDisplayList from '../../display/ConfigurableDisplayList'
import EmptyState from '../../display/EmptyState'
import ActionHeader from '../../interactive/ActionHeader'

export default function VehiclesIndex({ startDate, vehicles }) {
  updatePageTitle('Vehicles')

  const list = useMemo(() => {
    return generateDisplayListActions(vehicles, [], [], true)
  }, [vehicles])

  return (
    <Fragment>
      <Lead>
        Knowing which vehicles you used for your journeys helps us calculate your refund{' '}
        <strong>more accurately.</strong>
      </Lead>
      {vehicles?.length ? (
        <Fragment>
          <hr />
          <ActionHeader text={`${startDate.format('MMMM YYYY')} - Now`}>
            <a href="/vehicles/new" className="button button--mini button--default">
              Add a vehicle
            </a>
          </ActionHeader>
          <ConfigurableDisplayList list={list} placeholderName="vehicles" small={true} />
        </Fragment>
      ) : (
        <Fragment>
          <hr />
          <EmptyState
            heading="No vehicles"
            detail={`Which vehicles have you used for work since ${startDate.format('MMMM YYYY')}?`}
            href="/vehicles/new"
            buttonText="Add a vehicle"
            icon={{
              className: 'mb-1',
              name: 'car',
              alt: 'No vehicles'
            }}
          />
        </Fragment>
      )}
    </Fragment>
  )
}

VehiclesIndex.propTypes = {
  vehicles: PropTypes.arrayOf(PropTypes.object),
  startDate: PropTypes.object.isRequired
}
