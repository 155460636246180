import React from 'react'
import PropTypes from 'prop-types'

import { buttonClasses, methodCallFromString } from '../../modules/utils'

class Button extends React.Component {
  render() {
    const { classes, modifiers, type, disabled, onClick, children } = this.props
    return (
      <button
        type={type}
        className={buttonClasses(classes, modifiers, type)}
        onClick={methodCallFromString(onClick)}
        disabled={disabled}
      >
        <span className="button__inner">{children}</span>
      </button>
    )
  }
}

Button.propTypes = {
  classes: PropTypes.array,
  modifiers: PropTypes.array,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
}

Button.defaultProps = {
  classes: [],
  disabled: false,
  modifiers: [],
  onClick: '',
  type: 'button'
}

export default Button
