import React from 'react'
import PropTypes from 'prop-types'

import { generateModifierClasses } from '../../modules/utils'

class Link extends React.Component {
  render() {
    const { href, modifiers, target } = this.props
    return (
      <a className={generateModifierClasses('link', modifiers)} href={href} target={target}>
        {this.props.children}
      </a>
    )
  }
}

Link.propTypes = {
  href: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  target: PropTypes.string,
  children: PropTypes.string
}
Link.defaultProps = {
  href: '#'
}
export default Link
