import React from 'react'
import PropTypes from 'prop-types'

import { placeholderFromString } from '../../helpers/images'

export default function CardPlaceholder({ name }) {
  return (
    <div className="card card--placeholder">
      <img className="card__icon" alt="Card Placeholder" src={placeholderFromString(name)} />
    </div>
  )
}

CardPlaceholder.propTypes = {
  name: PropTypes.oneOf(['addresses', 'employments', 'workplaces', 'documents'])
}
