export function showIdleHelpPanel(referrer) {
  const isDirectFromDashboard = document.referrer.includes('user')
  const isDirectFromTimelines = document.referrer.includes('timelines')
  const willRedirectToTimelines = referrer?.includes('timelines')
  const show = isDirectFromTimelines || willRedirectToTimelines || !isDirectFromDashboard

  return show
}

export function onInteractionWithForm() {
  const input = document.getElementById('onInteractionWithForm')
  if (input) {
    input.dispatchEvent(new Event('change'))
  }
}
