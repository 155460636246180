import React from 'react'
import PropTypes from 'prop-types'

class Drawer extends React.Component {
  constructor(props) {
    super(props)

    this.handleClose = this.handleClose.bind(this)
  }

  handleClose(e) {
    const { onClose } = this.props
    const classList = e.target.classList

    e.preventDefault()

    if (classList.contains('drawer__overlay') || classList.contains('drawer__overlay__container')) {
      onClose(e)
    }
  }

  render() {
    const { children, visible } = this.props
    const classes = visible ? 'drawer drawer--visible' : 'drawer'

    return (
      <div className={classes} onClick={this.handleClose}>
        <div className="drawer__overlay">
          <div className="drawer__overlay__container">
            <div className="drawer__overlay__content">{children}</div>
          </div>
        </div>
      </div>
    )
  }
}
Drawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired
}
export default Drawer
