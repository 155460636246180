import arrow from '../../assets/images/icons/arrow.svg'
import arrowWhite from '../../assets/images/arrow-white.svg'
import bank from '../../assets/images/icons/bank.svg'
import beers from '../../assets/images/icons/beers.svg'
import bell from '../../assets/images/icons/bell.svg'
import brokenHeart from '../../assets/images/icons/broken-heart.svg'
import bubble from '../../assets/images/icons/bubble.svg'
import burger from '../../assets/images/icons/burger.svg'
import car from '../../assets/images/icons/car.svg'
import cash from '../../assets/images/icons/cash.svg'
import chevron from '../../assets/images/icons/chevron.svg'
import claim from '../../assets/images/icons/claim.svg'
import close from '../../assets/images/icons/close.svg'
import computer from '../../assets/images/icons/computer.svg'
import cool from '../../assets/images/icons/cool.svg'
import cost from '../../assets/images/icons/cost.svg'
import createAccount from '../../assets/images/icons/create_account.svg'
import cross from '../../assets/images/icons/cross.svg'
import crown from '../../assets/images/icons/crown.svg'
import envelope from '../../assets/images/icons/envelope.svg'
import eye from '../../assets/images/icons/eye.svg'
import examine from '../../assets/images/icons/examine.svg'
import experts from '../../assets/images/icons/experts.svg'
import folder from '../../assets/images/icons/folder.svg'
import greenTick from '../../assets/images/icons/green-tick.svg'
import grimace from '../../assets/images/icons/grimace.svg'
import home from '../../assets/images/icons/home.svg'
import hundred from '../../assets/images/icons/hundred.svg'
import info from '../../assets/images/icons/info.svg'
import key from '../../assets/images/icons/key.svg'
import letter from '../../assets/images/icons/letter.svg'
import lock from '../../assets/images/icons/lock.svg'
import man from '../../assets/images/icons/man.svg'
import mapPin from '../../assets/images/icons/map-pin.svg'
import mapPinTip from '../../assets/images/icons/map-pin-tip.svg'
import minus from '../../assets/images/icons/minus.svg'
import partyPopper from '../../assets/images/icons/party-popper.svg'
import pin from '../../assets/images/icons/pin.svg'
import plus from '../../assets/images/icons/plus.svg'
import plusWhite from '../../assets/images/icons/plus-white.svg'
import plusBlue from '../../assets/images/icons/plus-blue.svg'
import rocket from '../../assets/images/icons/rocket.svg'
import receiveRefund from '../../assets/images/icons/receive_refund.svg'
import scroll from '../../assets/images/icons/scroll.svg'
import shirt from '../../assets/images/icons/shirt.svg'
import smartphone from '../../assets/images/icons/smartphone.svg'
import smug from '../../assets/images/icons/smug.svg'
import snap from '../../assets/images/icons/snap.svg'
import speakToUs from '../../assets/images/icons/speak_to_us.svg'
import telephone from '../../assets/images/icons/telephone.svg'
import telephoneOld from '../../assets/images/icons/telephone-old.svg'
import thumbUp from '../../assets/images/icons/thumb-up.svg'
import timer from '../../assets/images/icons/timer.svg'
import trash from '../../assets/images/icons/trash.svg'
import trashGrey from '../../assets/images/icons/trash-grey.svg'
import zzz from '../../assets/images/icons/zzz.svg'

import accountPlaceholder from '../../assets/images/account-placeholder.svg'
import addressesPlaceholder from '../../assets/images/icons/placeholders/addresses.svg'
import documentsPlaceholder from '../../assets/images/icons/placeholders/documents.svg'
import employersPlaceholder from '../../assets/images/icons/placeholders/employers.svg'
import expensesPlaceholder from '../../assets/images/icons/placeholders/expenses.svg'
import vehiclesPlaceholder from '../../assets/images/icons/placeholders/vehicles.svg'
import workplacesPlaceholder from '../../assets/images/icons/placeholders/workplaces.svg'

import fileSVG from '../../assets/images/svgs/file.svg'
import folderSVG from '../../assets/images/svgs/folder.svg'
import imageSVG from '../../assets/images/svgs/image.svg'
import slideshowSVG from '../../assets/images/svgs/slideshow.svg'
import spreadsheetSVG from '../../assets/images/svgs/spreadsheet.svg'
import textSVG from '../../assets/images/svgs/text.svg'

import bankOfIrelandBank from '../../assets/images/icons/banks/bank-of-ireland.svg'
import bankOfScotlandBank from '../../assets/images/icons/banks/bank-of-scotland.svg'
import barclaysBank from '../../assets/images/icons/banks/barclays.svg'
import clydesdaleBank from '../../assets/images/icons/banks/clydesdale-bank.svg'
import danskeBank from '../../assets/images/icons/banks/danske-bank.svg'
import firstDirectBank from '../../assets/images/icons/banks/first-direct.svg'
import firstTrustBank from '../../assets/images/icons/banks/first-trust-bank.svg'
import halifaxBank from '../../assets/images/icons/banks/halifax.svg'
import hsbcBank from '../../assets/images/icons/banks/hsbc.svg'
import lloydsBank from '../../assets/images/icons/banks/lloyds-bank.svg'
import metroBank from '../../assets/images/icons/banks/metro-bank.svg'
import monzoBank from '../../assets/images/icons/banks/monzo.svg'
import nationwideBank from '../../assets/images/icons/banks/nationwide.svg'
import natwestBank from '../../assets/images/icons/banks/natwest.svg'
import royalBankOfScotlandBank from '../../assets/images/icons/banks/royal-bank-of-scotland.svg'
import santanderBank from '../../assets/images/icons/banks/santander.svg'
import starlingBank from '../../assets/images/icons/banks/starling-bank.svg'
import tescoBank from '../../assets/images/icons/banks/tesco-bank.svg'
import theCoOperativeBank from '../../assets/images/icons/banks/the-co-operative-bank.svg'
import thinkMoneyBank from '../../assets/images/icons/banks/thinkmoney.svg'
import tsbBank from '../../assets/images/icons/banks/tsb.svg'
import ulsterBank from '../../assets/images/icons/banks/ulster-bank.svg'
import yorkshireBank from '../../assets/images/icons/banks/yorkshire-bank.svg'

const icons = {
  arrowWhite,
  arrow,
  bank,
  beers,
  bell,
  brokenHeart,
  bubble,
  burger,
  car,
  cash,
  chevron,
  claim,
  close,
  computer,
  cool,
  cost,
  createAccount,
  cross,
  crown,
  envelope,
  eye,
  examine,
  experts,
  folder,
  greenTick,
  grimace,
  home,
  hundred,
  info,
  key,
  letter,
  lock,
  man,
  mapPin,
  mapPinTip,
  minus,
  partyPopper,
  pin,
  plus,
  plusWhite,
  plusBlue,
  rocket,
  receiveRefund,
  scroll,
  shirt,
  smartphone,
  smug,
  snap,
  speakToUs,
  telephone,
  telephoneOld,
  thumbUp,
  timer,
  trash,
  trashGrey,
  zzz
}

const placeholders = {
  accountPlaceholder,
  addressesPlaceholder,
  documentsPlaceholder,
  employersPlaceholder,
  expensesPlaceholder,
  vehiclesPlaceholder,
  workplacesPlaceholder
}

const svgs = {
  fileSVG,
  folderSVG,
  imageSVG,
  slideshowSVG,
  spreadsheetSVG,
  textSVG
}

export function iconFromString(string) {
  return icons[string]
}

export function svgFromString(string) {
  return svgs[`${string}SVG`]
}

export function placeholderFromString(string) {
  return placeholders[`${string}Placeholder`]
}

export const banks = {
  'Bank of Ireland': bankOfIrelandBank,
  'Bank of Scotland': bankOfScotlandBank,
  Barclays: barclaysBank,
  'Clydesdale Bank': clydesdaleBank,
  'Danske Bank': danskeBank,
  'First Direct': firstDirectBank,
  'First Trust Bank': firstTrustBank,
  Halifax: halifaxBank,
  'HSBC Bank': hsbcBank,
  'Lloyds Bank': lloydsBank,
  'Metro Bank': metroBank,
  Monzo: monzoBank,
  'Nationwide Building S': nationwideBank,
  NatWest: natwestBank,
  'Royal Bank of Scotlan': royalBankOfScotlandBank,
  Santander: santanderBank,
  'Starling Bank': starlingBank,
  'Tesco Bank': tescoBank,
  'The Co-operative Bank': theCoOperativeBank,
  thinkmoney: thinkMoneyBank,
  'TSB Bank': tsbBank,
  'Ulster Bank': ulsterBank,
  'Yorkshire Bank': yorkshireBank
}
