import React from 'react'
import PropTypes from 'prop-types'

import FormGroupInput from './FormGroupInput'
import FrequencyInput from './FrequencyInput'

import { FormGroupErrorMessage } from '../layout/Wrapper'

const DEFAULT_QUANTITATIVE_FREQUENCY = 5
const DEFAULT_QUALITATIVE_FREQUENCY = 'Per week'

class UnstructuredFrequencyInput extends React.Component {
  constructor(props) {
    super(props)

    const { textValue, error } = props
    this.state = { showTextInput: !!textValue || !!error }

    this.toggleStructuredInput = this.toggleStructuredInput.bind(this)
    this.handleUnstructuredValueChange = this.handleUnstructuredValueChange.bind(this)
    this.handleStructuredValueChange = this.handleStructuredValueChange.bind(this)
  }

  toggleStructuredInput(e) {
    e.preventDefault()

    const showTextInput = !this.showTextInput()

    const {
      textValue,
      qualitativeFrequency,
      quantitativeFrequency,
      onUnstructuredValueChange,
      onStructuredValueChange
    } = this.props

    this.setState({ showTextInput })

    if (showTextInput) {
      onStructuredValueChange(null, null)
      onUnstructuredValueChange(textValue)
    } else {
      onUnstructuredValueChange(null)
      onStructuredValueChange(
        quantitativeFrequency || DEFAULT_QUANTITATIVE_FREQUENCY,
        qualitativeFrequency || DEFAULT_QUALITATIVE_FREQUENCY
      )
    }
  }

  handleUnstructuredValueChange(e) {
    const textValue = e.target.value
    const { onUnstructuredValueChange } = this.props
    onUnstructuredValueChange(textValue)
  }

  handleStructuredValueChange(quantitativeFrequency, qualitativeFrequency) {
    const { onStructuredValueChange } = this.props
    onStructuredValueChange(quantitativeFrequency, qualitativeFrequency)
  }

  showTextInput() {
    const { quantitativeFrequency, qualitativeFrequency } = this.props
    return this.state.showTextInput && !quantitativeFrequency && !qualitativeFrequency
  }

  render() {
    const { disabled, error, textValue, quantitativeFrequency, qualitativeFrequency, showDeprecatedOptions } =
      this.props

    const showTextInput = this.showTextInput()
    const toggleLabel = showTextInput ? "It's not as complicated as I thought" : "It's complicated; I'll explain"

    return (
      <React.Fragment>
        {showTextInput ? (
          <React.Fragment>
            <FormGroupInput
              disabled={disabled}
              defaultValue={textValue}
              onChange={this.handleUnstructuredValueChange}
            />
            {error && <FormGroupErrorMessage>{error}</FormGroupErrorMessage>}
          </React.Fragment>
        ) : (
          <FrequencyInput
            disabled={disabled}
            qualitativeFrequency={qualitativeFrequency}
            quantitativeFrequency={quantitativeFrequency}
            onChange={this.handleStructuredValueChange}
            showDeprecatedOptions={showDeprecatedOptions}
          />
        )}
        {!disabled && (
          <a className="link link--sm display-inline-block mt-2" onClick={this.toggleStructuredInput} href="#">
            {toggleLabel}
          </a>
        )}
      </React.Fragment>
    )
  }
}

UnstructuredFrequencyInput.propTypes = {
  disabled: PropTypes.bool,
  onUnstructuredValueChange: PropTypes.func.isRequired,
  onStructuredValueChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  textValue: PropTypes.string,
  quantitativeFrequency: PropTypes.number,
  qualitativeFrequency: PropTypes.string,
  showDeprecatedOptions: PropTypes.bool
}

UnstructuredFrequencyInput.defaultProps = {
  error: false,
  disabled: false,
  quantitativeFrequency: DEFAULT_QUANTITATIVE_FREQUENCY,
  qualitativeFrequency: DEFAULT_QUALITATIVE_FREQUENCY
}

export default UnstructuredFrequencyInput
