import React from 'react'

import { updatePageTitle } from '../../../modules/legacyUtils'

import ReviewForm from '../../interactive/ReviewForm'

export default function ReviewTimelinesIndex() {
  updatePageTitle('Review your claim')

  return <ReviewForm submissionPath="foobar" submitText="FOOO" submittable={true} />
}
