import React from 'react'
import PropTypes from 'prop-types'

import Action from '../../interactive/Action'
import ActionList from '../../interactive/ActionList'
import ContentFooterLink from '../../interactive/ContentFooterLink'
import HeadingDivider from '../../display/HeadingDivider'
import Link from '../../interactive/Link'

import { ContentFooter, ContentWrapper, List, ListItem } from '../../layout/Wrapper'

class Account extends React.Component {
  render() {
    const {
      addressesPath,
      basicDetailsPath,
      claimsPath,
      closeAccountPath,
      employersPath,
      financesPath,
      passwordPath,
      preferencesPath,
      vehiclesPath
    } = this.props
    return (
      <ContentWrapper>
        <HeadingDivider>General</HeadingDivider>
        <ActionList>
          <Action
            heading="Basic Details"
            href={basicDetailsPath}
            icon={{
              alt: 'Basic Details',
              modifiers: ['sm'],
              name: 'cool'
            }}
            text="Change your name or contact details."
          />
          <Action
            heading="Finances"
            href={financesPath}
            icon={{
              alt: 'Finances',
              modifiers: ['sm'],
              name: 'cash'
            }}
            text="Your income and financial history."
          />
          {claimsPath != '#' && (
            <Action
              heading="Claims"
              href={claimsPath}
              icon={{
                alt: 'Claims',
                modifiers: ['sm'],
                name: 'claim'
              }}
              text="Browse your current and previous claims."
            />
          )}
        </ActionList>
        <HeadingDivider>Saved Items</HeadingDivider>
        <ActionList>
          <Action
            heading="Addresses"
            href={addressesPath}
            icon={{
              alt: 'Addresses',
              modifiers: ['sm'],
              name: 'home'
            }}
            text="Where have you been living?"
          />
          <Action
            heading="Vehicles"
            href={vehiclesPath}
            icon={{
              alt: 'Vehicles',
              modifiers: ['sm'],
              name: 'car'
            }}
            text="What have you been driving?"
          />
          <Action
            heading="Employers"
            href={employersPath}
            icon={{
              alt: 'Employers',
              modifiers: ['sm'],
              name: 'shirt'
            }}
            text="Who have you been working for?"
          />
          <Action
            heading="Bank account"
            href={preferencesPath}
            icon={{
              alt: 'Bank account',
              modifiers: ['sm'],
              name: 'bank'
            }}
            text="Where should we send your refund?"
          />
        </ActionList>
        <HeadingDivider>Settings</HeadingDivider>
        <ActionList>
          {passwordPath != '#' && (
            <Action
              heading="Change password"
              href={passwordPath}
              icon={{
                alt: 'Change password',
                modifiers: ['sm'],
                name: 'lock'
              }}
              text="Keep your personal information secure."
            />
          )}
          <Action
            heading="Preferences"
            href={preferencesPath}
            icon={{
              alt: 'Preferences',
              modifiers: ['sm'],
              name: 'envelope'
            }}
            text="How would you like us to contact you?"
          />
          {closeAccountPath != '#' && (
            <Action
              heading="Close account"
              href={closeAccountPath}
              icon={{
                alt: 'Close account',
                modifiers: ['sm'],
                name: 'brokenHeart'
              }}
              text="Browse your current and previous claims."
            />
          )}
        </ActionList>
        <ContentFooter>
          <ContentFooterLink href="/">Back to Home</ContentFooterLink>
          <List className="content__footer__list" modifiers="inline">
            <ListItem>
              <Link href="//www.riftrefunds.co.uk/privacy-cookies-policy/" modifiers="sm" target="_blank">
                Privacy
              </Link>
            </ListItem>
            <ListItem>
              <Link href="//www.riftrefunds.co.uk/privacy-cookies-policy/" modifiers="sm" target="_blank">
                Cookies
              </Link>
            </ListItem>
            <ListItem>
              <Link href="//www.riftrefunds.co.uk/terms-and-conditions/" modifiers="sm" target="_blank">
                Terms
              </Link>
            </ListItem>
          </List>
        </ContentFooter>
      </ContentWrapper>
    )
  }
}

Account.propTypes = {
  addressesPath: PropTypes.string.isRequired,
  basicDetailsPath: PropTypes.string.isRequired,
  claimsPath: PropTypes.string.isRequired,
  closeAccountPath: PropTypes.string.isRequired,
  employersPath: PropTypes.string.isRequired,
  financesPath: PropTypes.string.isRequired,
  passwordPath: PropTypes.string.isRequired,
  preferencesPath: PropTypes.string.isRequired,
  vehiclesPath: PropTypes.string.isRequired
}

Account.defaultProps = {
  claimStatus: 'unsubmitted'
}
export default Account
