import React from 'react'
import PropTypes from 'prop-types'

import { methodCallFromString } from '../../modules/utils'

class FormWrapper extends React.Component {
  render() {
    const { onSubmit, children, id } = this.props
    return (
      <form noValidate="novalidate" onSubmit={methodCallFromString(onSubmit)} id={id}>
        {children}
      </form>
    )
  }
}

FormWrapper.propTypes = {
  id: PropTypes.string,
  onSubmit: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)])
}
export default FormWrapper
