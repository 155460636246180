import React from 'react'
import PropTypes from 'prop-types'

import RadioOption from './RadioOption'
import Question from './Question'
import FormGroupHeader from './FormGroupHeader'
import Helper from '../display/Helper'
import HelperTrigger from '../display/HelperTrigger'

import { FormGroup } from '../layout/Wrapper'

import { propagateValue, helperId } from '../../modules/utils'

class YesNoQuestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: this.valueOnLoad() }
    this.handleChange = this.handleChange.bind(this)
  }

  valueOnLoad() {
    const { resource, name, checked, allowNil, includeDontKnow } = this.props
    if (checked) return true
    if (allowNil || includeDontKnow) return resource[name]
    const nameValue = resource[name] || 'false'
    return nameValue == 'true'
  }

  handleChange(inputValue) {
    const { name } = this.props
    const value = inputValue ? inputValue === 'true' : null
    this.setState({ value }, propagateValue.apply(this, [name, value]))
  }

  renderHeading() {
    const { questionHeading, questionText, helperText, label } = this.props

    const helper = helperText && (
      <HelperTrigger
        helperId={helperId.apply(this)}
        eventLabel={`the '${questionText}' question on a Yes-No component`}
      />
    )

    if (questionHeading || questionText) {
      return (
        <Question heading={questionHeading} text={questionText}>
          {helper}
        </Question>
      )
    } else if (label) {
      return <FormGroupHeader label={label}>{helper}</FormGroupHeader>
    }
  }

  render() {
    const { className, modifiers, attributes, name, resourceName, disabled, helperText, includeDontKnow } = this.props
    const { value } = this.state
    const baseParams = {
      onChange: this.handleChange,
      name,
      resourceName,
      disabled
    }

    return (
      <FormGroup className={className} modifiers={modifiers}>
        {helperText && <Helper id={helperId.apply(this)} body={helperText} />}
        {this.renderHeading()}

        <ul className="option-group option-group--buttons">
          <li className="option-group__item">
            <RadioOption
              value={'true'}
              additionalAttributes={attributes && attributes.yes}
              label={'Yes'}
              checked={includeDontKnow ? value === true : value}
              {...baseParams}
            />
          </li>
          <li className="option-group__item">
            <RadioOption
              value={'false'}
              additionalAttributes={attributes && attributes.no}
              label="No"
              checked={includeDontKnow ? value === false : !value}
              {...baseParams}
            />
          </li>
          {includeDontKnow && (
            <li className="option-group__item">
              <RadioOption
                value={''}
                additionalAttributes={attributes && attributes.dontKnow}
                label="I don't know"
                checked={value === null}
                {...baseParams}
              />
            </li>
          )}
        </ul>
      </FormGroup>
    )
  }
}

YesNoQuestion.propTypes = {
  className: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  attributes: PropTypes.object,
  checked: PropTypes.bool,
  allowNil: PropTypes.bool,
  helperText: PropTypes.string,
  handlePropagation: PropTypes.func,
  label: PropTypes.string,
  questionHeading: PropTypes.string,
  questionText: PropTypes.string,
  name: PropTypes.string,
  resource: PropTypes.object,
  resourceName: PropTypes.string,
  includeDontKnow: PropTypes.bool,
  disabled: PropTypes.bool
}

YesNoQuestion.defaultProps = {
  disabled: false,
  includeDontKnow: false
}

export default YesNoQuestion
