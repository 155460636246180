import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'

import ErrorSpan from './ErrorSpan'
import FormGroupHeader from './FormGroupHeader'
import FormGroupInput from './FormGroupInput'

import { goToEndOfInput } from '../../modules/utils'
import { iconFromString } from '../../helpers/images'

export default function TypeAhead({
  error,
  label,
  placeholder,
  lookupFunction,
  onSelection,
  disabled,
  children,
  throttleMs
}) {
  const [loading, setLoading] = useState(false)
  const [typedValue, setTypedValue] = useState('')
  const [results, setResults] = useState([])
  const [lookupError, setLookupError] = useState(null)
  const [timeout, storeTimeout] = useState(null)

  const runSearch = e => {
    e.persist()

    if (timeout) {
      clearTimeout(timeout)
    }

    if (e.target.value.length >= 5) {
      setLoading(true)

      storeTimeout(
        setTimeout(() => {
          lookupFunction(e.target.value)
            .then(data => {
              setResults(data.results)
              setLoading(false)
            })
            .catch(error => {
              if (typeof error === 'string') {
                setLookupError(error)
                setLoading(false)
              }
              console.error(error)
            })
        }, throttleMs)
      )
    }

    setResults([])
    setTypedValue(e.target.value)
  }

  useEffect(() => {
    return () => clearTimeout(timeout) // Ensure any active timeouts are cleared if the component unmounts.
  }, [timeout])

  const clearInput = e => {
    if (e) {
      e.preventDefault()
    }
    setResults([])
    setTypedValue('')
  }

  const incrementSelected = (value = 1) => {
    const noOfResults = results ? results.length : 0
    let nextFocusKey = (currentFocusKey() + value) % (noOfResults + 1)

    if (nextFocusKey < 0) {
      nextFocusKey = noOfResults
    }

    const focusElement = document.querySelector(`[focuskey="${nextFocusKey}"]`)
    focusElement.focus()
  }

  const inputFocus = e => {
    goToEndOfInput(e.target)
    setTypedValue(e.target.value)
    if (!e.target.value) {
      setResults([])
    }
  }

  const focusOnHover = e => {
    e.target.closest('.search-results__item').focus()
  }

  const currentFocusKey = () => {
    // A small fix for IE11, which identifies the wrong element as focussed:
    let activeElement = document.activeElement
    if (activeElement.getAttribute('focuskey') === null) {
      activeElement = activeElement.parentElement
    }

    return parseInt(activeElement.getAttribute('focuskey'))
  }

  const resultSelected = () => {
    const currentFocus = currentFocusKey()
    const selectedResult = results[currentFocus - 1]

    if (currentFocus <= 0) {
      return
    }

    setResults([])
    setTypedValue('')
    onSelection(selectedResult)
  }

  const handleKeyDown = e => {
    const code = e.keyCode

    switch (code) {
      case 27:
        return clearInput()
      case 38:
        e.preventDefault()
        return incrementSelected(-1)
      case 40:
        e.preventDefault()
        return incrementSelected()
      case 13:
        e.preventDefault()
        return resultSelected()
    }
  }

  const resultHeading = name => {
    const match = name.toLowerCase().match(typedValue.toLowerCase())

    let beforeMatch = name
    let afterMatch = null
    let matchedSpan = null

    if (match) {
      const index = match.index
      beforeMatch = name.substring(0, index)
      afterMatch = name.substring(index + typedValue.length, name.length)
      matchedSpan = (
        <span className="search-results__heading__matched">{name.substring(index, index + typedValue.length)}</span>
      )
    }

    return (
      <span className="search-results__heading">
        {beforeMatch}
        {matchedSpan}
        {afterMatch}
      </span>
    )
  }

  const inputClasses = loading ? 'string search__input search__input--loading' : 'string search__input'

  return (
    <Fragment>
      <FormGroupHeader label={label} />
      <div className="search" onKeyDown={handleKeyDown}>
        <FormGroupInput
          className={inputClasses}
          type="text"
          value={typedValue}
          autoComplete="none"
          autoFocus={true}
          onChange={runSearch}
          onFocus={inputFocus}
          focuskey="0"
          disabled={disabled}
          placeholder={placeholder}
        />
        {!disabled && typedValue && (
          <div className="search__action">
            <button className="search__button" onClick={clearInput}>
              <img className="search__icon" alt="Clear" src={iconFromString('cross')} />
            </button>
          </div>
        )}
        {(results && results.length && (
          <div className="search-results">
            {results.map((result, index) => {
              const detail = [result.first_line, result.town, result.postcode, result.country, result.trading_name]
                .filter(Boolean)
                .join(', ')

              return (
                <div
                  className="search-results__item"
                  focuskey={index + 1}
                  key={index + 1}
                  tabIndex="0"
                  onMouseOver={focusOnHover}
                  onClick={resultSelected}
                >
                  {resultHeading(result.name || result.first_line)}
                  <span className="search-results__detail">{detail}</span>
                </div>
              )
            })}
          </div>
        )) ||
          null}
      </div>
      <ErrorSpan error={error || lookupError} />
      {children}
    </Fragment>
  )
}

TypeAhead.defaultProps = {
  throttleMs: 500
}

TypeAhead.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.bool]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  lookupFunction: PropTypes.func.isRequired,
  onSelection: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  disabled: PropTypes.bool,
  throttleMs: PropTypes.number
}
