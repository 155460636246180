import React from 'react'
import PropTypes from 'prop-types'

import { generateModifierClasses } from '../../modules/utils'

const TabsLink = function ({ children, className, href, modifiers, onClick, value }) {
  let classes = generateModifierClasses('tabs__link', modifiers)

  if (className) {
    classes += ` ${className}`
  }

  return (
    <a className={classes} href={href} onClick={onClick} value={value}>
      {children}
    </a>
  )
}

TabsLink.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}
TabsLink.defaultProps = {
  href: '#'
}

export default TabsLink
