import { fetch } from './utils'

// @TODO: Put this here to de-duplicate code, we should probably move it
// to a as_json_for_react method in the address model at some point.
export function deriveStateFromProps(props, state) {
  let { resource } = props
  const { address, endedAtLocked } = props
  const { unchangedAddress } = state

  if (!resource) {
    resource = address
  }

  if (resource && resource != unchangedAddress) {
    const addedValues = {
      current: !Boolean(resource.ended_at),
      first_line: resource.first_line || '',
      second_line: resource.second_line || '',
      town: resource.town || '',
      county: resource.county || '',
      postcode: resource.postcode || ''
    }

    const nextAddress = Object.assign(resource, addedValues)

    return {
      address: nextAddress,
      unchangedAddress: resource,
      action: resource.id ? 'edit' : 'new',
      disabled: !nextAddress.first_line || endedAtLocked
    }
  }

  return null
}

export function createOrUpdate(address, authenticityToken) {
  const method = address.id ? 'PUT' : 'POST'
  const path = address.id ? `/addresses/${address.id}` : '/addresses'

  const data = {
    api_person_address: address,
    utf8: '✓',
    authenticity_token: authenticityToken
  }

  return fetch(method, path, 'application/json', 'application/json', data)
}
