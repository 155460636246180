import React from 'react'
import PropTypes from 'prop-types'

import FloatingContainer from '../display/FloatingContainer'
import FormGroupHeader from './FormGroupHeader'
import ErrorSpan from './ErrorSpan'
import Picker from './Picker'

import { inputName, inputId } from '../../modules/utils'

class FloatingPicker extends React.Component {
  constructor(props) {
    super(props)

    this.state = { showPicker: false }

    this.inputRef = React.createRef()
    this.showPicker = this.showPicker.bind(this)
    this.hidePicker = this.hidePicker.bind(this)
  }

  showPicker() {
    this.inputRef.current.blur()
    this.setState({ showPicker: true })
  }

  hidePicker() {
    this.setState({ showPicker: false })
  }

  input() {
    const { disabled, disabledText, value } = this.props
    const displayValue = disabled && disabledText && !value ? disabledText : value
    let classes = ['form-group__input', 'picker__input']
    if (this.state.showPicker) classes.push('focused')
    return (
      <input
        className={classes.join(' ')}
        type="text"
        name={inputName.apply(this)}
        id={inputId.apply(this, [false])}
        value={displayValue}
        readOnly={true}
        onClick={this.showPicker}
        disabled={disabled}
        key="0"
        ref={this.inputRef}
      />
    )
  }

  render() {
    const { showPicker } = this.state
    const { id, className, disabled, direction, error, handlePropagation, label, options } = this.props

    let pickerClasses = ['form-group', 'position-relative']
    if (error && !disabled) {
      pickerClasses.push('form-group--error')
    }

    return (
      <div className={className} id={id}>
        <div className={pickerClasses.join(' ')}>
          <FormGroupHeader label={label} />
          {this.input()}
          <FloatingContainer onClose={this.hidePicker} direction={direction} open={showPicker}>
            <Picker onChange={handlePropagation} onComplete={this.hidePicker} options={options} />
          </FloatingContainer>
          {error && typeof error != 'boolean' && !disabled && <ErrorSpan error={error} />}
        </div>
      </div>
    )
  }
}

FloatingPicker.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  disabled: PropTypes.bool,
  disabledText: PropTypes.string,
  options: PropTypes.array,
  value: PropTypes.string,
  resourceName: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.object]),
  handlePropagation: PropTypes.func,
  direction: PropTypes.string.isRequired,
  className: PropTypes.string
}
FloatingPicker.defaultProps = {
  className: 'input-group__item'
}
export default FloatingPicker
