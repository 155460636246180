import React from 'react'
import PropTypes from 'prop-types'

import ButtonGroupItem from './ButtonGroupItem'
import DateRangeQuestion from './date/DateRangeQuestion'
import FlashMessage from '../display/FlashMessage'

import { ButtonGroup, ContentFooter, ContentWrapper, ContentWrapperInner } from '../layout/Wrapper'

import { fetch, omit, redirectTo, highlightFirstError } from '../../modules/utils'

class WorkLocationGapForm extends React.Component {
  constructor(props) {
    super(props)
    let flash = false
    if (props.locked) {
      flash = props.endedAtLocked
        ? 'You cannot update this work location gap'
        : "You can only update the <strong>'Until date'</strong> on this work location gap"
      flash =
        flash +
        ' because it has been used in one of your previous claim submissions. Please <a data-trigger-modal="contact-modal" href="">contact us</a> if you need anything else corrected.</p>'
    }
    this.state = {
      errors: {},
      disabled: props.endedAtLocked,
      flash: flash,
      flashType: 'info'
    }
    this.handlePropagation = this.handlePropagation.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    const { resource } = props
    const { unchangedLocationGap } = state

    if (resource && resource != unchangedLocationGap) {
      const addedValues = {
        current: !Boolean(resource.ended_at)
      }
      return {
        locationGap: Object.assign(resource, addedValues),
        unchangedLocationGap: resource,
        action: resource.id ? 'edit' : 'new'
      }
    }
    return null
  }

  handlePropagation(name, value, current) {
    const { locationGap, errors } = this.state
    let locationGapUpdate = { [name]: value }

    if (current !== undefined) {
      locationGapUpdate['current'] = current
    }

    this.setState({
      disabled: false,
      errors: omit(errors, name),
      locationGap: Object.assign(locationGap, locationGapUpdate)
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ disabled: true })
    const { action, locationGap } = this.state
    const { referrer, onAfterSubmit, authenticityToken } = this.props

    let method = 'POST'
    let path = '/work_location_gaps'
    const formData = {
      work_location_gap: locationGap,
      utf8: '✓',
      authenticity_token: authenticityToken
    }
    if (action == 'edit') {
      path = path + '/' + locationGap.id
      method = 'PATCH'
    }

    fetch(method, path, 'application/json', 'application/json', formData)
      .then(data => {
        if (referrer) {
          redirectTo(referrer)
        } else {
          onAfterSubmit ? onAfterSubmit(data) : this.setState({ locationGap: null })
        }
      })
      .catch(errResponse => {
        const data = errResponse[1]
        console.error(JSON.stringify(data.errors))
        this.setState(data, function () {
          highlightFirstError()
        })
      })
  }

  dates() {
    const { resourceName, locked, endedAtLocked } = this.props
    const { locationGap, errors } = this.state
    return (
      <DateRangeQuestion
        resourceName={resourceName}
        resource={locationGap}
        questionText="Tell us the dates you didn't travel for work"
        currentChecked={locationGap.current}
        fromError={errors.started_at}
        toError={errors.ended_at}
        includeDay={true}
        currentText="I'm still not travelling to work"
        handlePropagation={this.handlePropagation}
        fromDisabled={locked}
        toDisabled={endedAtLocked}
      />
    )
  }

  render() {
    const { flash, flashType, disabled } = this.state
    const { referrer } = this.props

    return (
      <ContentWrapper modifiers="small">
        <ContentWrapperInner modifiers="longform">
          {flash && <FlashMessage type={flashType} body={flash} />}
          {this.dates()}
        </ContentWrapperInner>
        <ContentFooter modifiers="actions">
          <ButtonGroup modifiers="spread">
            <ButtonGroupItem buttonModifiers="default" element="anchor" href={referrer || '#'} disabled={false}>
              Cancel
            </ButtonGroupItem>
            <ButtonGroupItem buttonModifiers="primary" element="button" disabled={disabled} onClick={this.handleSubmit}>
              Save
            </ButtonGroupItem>
          </ButtonGroup>
        </ContentFooter>
      </ContentWrapper>
    )
  }
}
WorkLocationGapForm.propTypes = {
  resourceName: PropTypes.string,
  resource: PropTypes.object.isRequired,
  authenticityToken: PropTypes.string.isRequired,
  referrer: PropTypes.string,
  locked: PropTypes.bool,
  endedAtLocked: PropTypes.bool
}
WorkLocationGapForm.defaultProps = {
  resourceName: 'work_location_gap',
  locked: false,
  endedAtLocked: false
}
export default WorkLocationGapForm
