import React from 'react'
import PropTypes from 'prop-types'

import { PreviewSvgWrapper, PreviewSvgWrapperInner } from '../layout/Wrapper'

import { svgFromString } from '../../helpers/images'

function PreviewSvg({ className, name, text }) {
  return (
    <PreviewSvgWrapper className={className} modifiers={name}>
      <PreviewSvgWrapperInner className={`${className}__inner`}>
        <img src={svgFromString(name)} alt={`${name} preview`} />
        {text && <p className="preview-svg__text">{text}</p>}
      </PreviewSvgWrapperInner>
    </PreviewSvgWrapper>
  )
}

PreviewSvg.propTypes = {
  className: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  text: PropTypes.string
}

export default PreviewSvg
