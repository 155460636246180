import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import Modal from '../../display/Modal'
import ScheduledCallback from './ScheduledCallback'

const ScheduledCallbackModal = props => {
  const { modalId, openingData, callCentreWillOpenToday, todaysDay, stepsContent } = props
  const scheduledCallback = useRef()

  const onModalClose = () => {
    scheduledCallback.current.init()
  }

  return (
    <Modal id={modalId} onClose={onModalClose} classNames={'scheduled-callback-modal'}>
      <ScheduledCallback
        ref={scheduledCallback}
        openingData={openingData}
        callCentreWillOpenToday={callCentreWillOpenToday}
        todaysDay={todaysDay}
        stepsContent={stepsContent}
      />
    </Modal>
  )
}

ScheduledCallback.propTypes = {
  modalId: PropTypes.string,
  openingData: PropTypes.array.isRequired,
  callCentreWillOpenToday: PropTypes.bool.isRequired,
  todaysDay: PropTypes.string.isRequired,
  stepsContent: PropTypes.array.isRequired
}

export default ScheduledCallbackModal
