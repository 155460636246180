import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import FinanceForm from '../../interactive/FinanceForm'
import ActionsModal from '../../interactive/ActionsModal'
import IdleHelpPanel from '../../interactive/IdleHelpPanel'

import { fetch, omit, redirectTo } from '../../../modules/utils'
import { showIdleHelpPanel, onInteractionWithForm } from '../../../modules/idleHelpPanel'

class Edit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      confirmationGiven: false,
      showModal: false,
      disabled: false,
      flash: false,
      finance: props.resource,
      flashType: 'info'
    }

    this.onChange = this.onChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.dismissConfirmationModal = this.dismissConfirmationModal.bind(this)
    this.askForConfirmation = this.askForConfirmation.bind(this)
  }

  onChange(name, value) {
    const { finance } = this.state
    const updateObj = typeof name == 'object' ? name : { [name]: value }
    this.setState({
      disabled: false,
      finance: Object.assign(finance, updateObj)
    })
    onInteractionWithForm()
  }

  dismissConfirmationModal() {
    this.setState({ showModal: false })
  }

  askForConfirmation() {
    this.setState({ showModal: true, confirmationGiven: true })
  }

  handleSubmit(e) {
    e.preventDefault()

    const { authenticityToken, requireConfirmation, referrer } = this.props
    const { finance, confirmationGiven } = this.state

    if (requireConfirmation && !confirmationGiven) {
      return this.askForConfirmation()
    }

    this.setState({ disabled: true, showModal: false })

    fetch('PATCH', `/finance/${finance.id}`, 'application/json', 'application/json', {
      finance: finance,
      utf8: '✓',
      authenticity_token: authenticityToken
    })
      .then(data => {
        if (referrer) {
          redirectTo(referrer)
        } else if (data.after_update_path) {
          redirectTo(data.after_update_path)
        }
      })
      .catch(errResponse => {
        const data = errResponse[1]
        console.error(JSON.stringify(data.errors))
        this.setState(data)
      })
  }

  render() {
    const { showModal } = this.state

    return (
      <Fragment>
        <ActionsModal
          id="confirm-finance-modal"
          title="All done?"
          displayed={showModal}
          body="<p>The questions about your financial history are vital in making sure we can calculate your refund correctly and get it paid out to you as quickly as possible. If there were any you weren’t sure of, get in touch and we can talk you through so nothing gets delayed later on.<br/><br/>Do you need to double check before you submit your answers?</p>"
          actions={[
            {
              label: "I'll double check",
              onClick: this.dismissConfirmationModal,
              href: '#'
            },
            {
              label: 'Submit my answers',
              onClick: this.handleSubmit,
              href: '#'
            }
          ]}
          onClose={this.dismissConfirmationModal}
        />

        <FinanceForm
          onSubmit={this.handleSubmit}
          onChange={this.onChange}
          {...this.state}
          {...omit(this.props, ['authenticityToken', 'resource', 'requireConfirmation'])}
        />
        {showIdleHelpPanel(this.props.referrer) && <IdleHelpPanel page="finance" />}
      </Fragment>
    )
  }
}

Edit.defaultProps = {
  requireConfirmation: false,
  showCancelButton: true
}

Edit.propTypes = {
  leadText: PropTypes.string,
  showCancelButton: PropTypes.bool,
  requireConfirmation: PropTypes.bool,
  authenticityToken: PropTypes.string,
  options: PropTypes.array,
  submitButtonLabel: PropTypes.string,
  referrer: PropTypes.string,
  resource: PropTypes.object
}
export default Edit
