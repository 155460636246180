import React from 'react'
import PropTypes from 'prop-types'

function DrawerHeader({ onClose, children }) {
  return (
    <header className="drawer__header">
      <h2 className="drawer__title">{children}</h2>
      <button className="drawer__overlay__close" onClick={onClose} />
    </header>
  )
}

DrawerHeader.propTypes = {
  onClose: PropTypes.func.isRequired
}

export default DrawerHeader
