import React from 'react'

import { generateModifierClasses } from '../../modules/utils'

const OptionsItemLabel = function (props) {
  const modifierClasses = generateModifierClasses('options__item__label', props.modifiers)

  return <label {...props} className={modifierClasses} />
}

export default OptionsItemLabel
