import React from 'react'
import PropTypes from 'prop-types'

import ModLocationInput from './ModLocationInput'
import NonModLocationInput from './NonModLocationInput'

class LocationInput extends React.Component {
  render() {
    const { ministryOfDefence } = this.props
    if (ministryOfDefence) {
      return <ModLocationInput {...this.props} />
    } else {
      return <NonModLocationInput {...this.props} />
    }
  }
}

LocationInput.propTypes = {
  onChange: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  address: PropTypes.object,
  errors: PropTypes.object,
  ministryOfDefence: PropTypes.bool,
  disabled: PropTypes.bool
}

export default LocationInput
