import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import PillCarousel from '../../display/PillCarousel'
import ImageTrustpilotCombo from '../../display/trustpilot/ImageTrustpilotCombo'
import FeaturesList from '../../display/FeaturesList'

import { trackEvent } from '../../../modules/tracking'

export default function IntroToMyRift({
  imageTrustpilotComboProps,
  pillCarouselProps,
  featuresListProps,
  authenticityToken
}) {
  useEffect(() => {
    trackEvent('Viewed Intro to MyRIFT page')
    console.log('Tracked "Viewed Intro to MyRIFT page"')
  }, [])

  return (
    <div className="intro-to-myrift">
      <ImageTrustpilotCombo data={imageTrustpilotComboProps} />
      <PillCarousel slides={pillCarouselProps} />
      <FeaturesList features={featuresListProps} />

      <div className="intro-to-myrift__sticky-footer">
        <div className="intro-to-myrift__sticky-footer-container">
          <h2>Start your claim today</h2>
          <form method="post" action="/welcome">
            <input type="hidden" name="authenticity_token" value={authenticityToken} />
            <button type="submit" className="button button--primary">
              Start my claim
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

IntroToMyRift.propTypes = {
  imageTrustpilotComboProps: PropTypes.object.isRequired,
  pillCarouselProps: PropTypes.array.isRequired,
  featuresListProps: PropTypes.array.isRequired,
  authenticityToken: PropTypes.string.isRequired
}
