import React from 'react'
import PropTypes from 'prop-types'

import Button from './Button'
import AnchorButton from './AnchorButton'
import FloatingDatePicker from './date/FloatingDatePicker'
import ErrorSpan from './ErrorSpan'

import { omit, generateModifierClasses } from '../../modules/utils'

class ActionBar extends React.Component {
  buttonGroupClasses() {
    const { buttonGroupClasses } = this.props
    let classes = buttonGroupClasses || ['button-group']
    const { buttonGroupMods } = this.props
    if (buttonGroupMods) {
      const actionBarModClasses = buttonGroupMods.map(mod => `button-group--${mod}`)
      classes = classes.concat(actionBarModClasses)
    }
    return classes.join(' ')
  }

  actions() {
    const componentList = {
      AnchorButton: AnchorButton,
      Button: Button,
      DatePicker: FloatingDatePicker,
      ErrorSpan: ErrorSpan
    }
    let actionComponents = []
    const { actions } = this.props
    actions.forEach(function (action, index) {
      const ActionComponent = componentList[action.componentName]
      actionComponents.push(<ActionComponent {...omit(action, 'componentName')} key={index} />)
    })
    return actionComponents
  }

  render() {
    return (
      <div className={generateModifierClasses('action-bar', this.props.modifiers)}>
        <div className="action-bar__inner">
          <div className={this.buttonGroupClasses()}>{this.actions()}</div>
        </div>
      </div>
    )
  }
}
ActionBar.propTypes = {
  modifiers: PropTypes.array,
  buttonGroupMods: PropTypes.array,
  buttonGroupClasses: PropTypes.array,
  actions: PropTypes.array.isRequired
}
export default ActionBar
