import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import TypeAhead from './TypeAhead'
import FormLink from './FormLink'
import FormGroupHeader from './FormGroupHeader'
import FormGroupInput from './FormGroupInput'
import OptionsItemLabel from './OptionsItemLabel'
import OptionsItemText from './OptionsItemText'

import { FormGroupErrorMessage, Options, OptionsItem } from '../layout/Wrapper'

import { commonLocationLookup } from '../../modules/api'
import { mapSrc, methodFromString, hasValues } from '../../modules/utils'

const ADDRESS_INPUTS = [
  { name: 'name', label: 'Base name' },
  { name: 'first_line', label: 'Address line 1' },
  { name: 'second_line', label: 'Address line 2' },
  { name: 'town', label: 'Town' },
  { name: 'county', label: 'County' },
  { name: 'postcode', label: 'Postcode' }
]

const DEFAULT_STATE = {
  manual: false,
  selectionMade: false,
  address: {
    country: null,
    county: null,
    first_line: null,
    latitude: null,
    longitude: null,
    postcode: null,
    second_line: null,
    town: null
  }
}

class ModLocationInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = this.getInitialState()

    this.toggleManualEntry = this.toggleManualEntry.bind(this)
    this.setAddressField = this.setAddressField.bind(this)
    this.setAddressState = this.setAddressState.bind(this)
    this.handleAddressChange = this.handleAddressChange.bind(this)
    this.reset = this.reset.bind(this)
  }

  getInitialState() {
    const { address } = this.props
    const hasErrors = this.hasErrors()
    const hasAddressValues = this.hasAddressValues()
    const addressMissingCoordinates = hasAddressValues && (!address.latitude || !address.longitude)
    const selectionMade = !hasErrors && !addressMissingCoordinates && hasAddressValues

    return {
      ...DEFAULT_STATE,
      manual: (hasErrors && hasAddressValues) || addressMissingCoordinates,
      selectionMade,
      ...{ address }
    }
  }

  hasErrors() {
    const { errors } = this.props
    return !!Object.keys(errors).length
  }

  hasAddressValues() {
    const { address } = this.props
    return hasValues(address)
  }

  toggleManualEntry(e) {
    e.preventDefault()
    const { manual } = this.state
    this.setState({ manual: !manual })
  }

  reset(e) {
    e.preventDefault()
    this.setState(DEFAULT_STATE, this.handleAddressChange)
  }

  setAddressField(e) {
    const { name, value } = e.target
    const { address } = this.state

    this.setState(
      {
        address: {
          ...address,
          [name]: value
        }
      },
      this.handleAddressChange
    )
  }

  setAddressState(address) {
    this.setState(
      {
        address,
        selectionMade: true
      },
      this.handleAddressChange
    )
  }

  handleAddressChange() {
    const { onChange } = this.props
    const { address } = this.state
    return methodFromString(onChange)(address)
  }

  renderManualEntry() {
    const { address } = this.state
    const { errors } = this.props

    return ADDRESS_INPUTS.map((input, index) => {
      const last = index === ADDRESS_INPUTS.length - 1
      const error = errors[input.name]

      return (
        <Fragment>
          <FormGroupHeader label={input.label} />
          <FormGroupInput
            name={input.name}
            type="text"
            defaultValue={address[input.name]}
            onChange={this.setAddressField}
          />
          {error && <FormGroupErrorMessage>{error}</FormGroupErrorMessage>}
          {last && <FormLink text="Use the base lookup instead" onClick={this.toggleManualEntry} />}
        </Fragment>
      )
    })
  }

  renderTypeahead() {
    const typeaheadError = this.hasErrors()
      ? 'We need to know where you were based to work out travel distances.'
      : false

    return (
      <TypeAhead
        lookupFunction={commonLocationLookup}
        label="Base lookup"
        placeholder="Search by name, garrison or location"
        onSelection={this.setAddressState}
        error={typeaheadError}
      >
        <FormLink text="Enter the base manually instead" onClick={this.toggleManualEntry} />
      </TypeAhead>
    )
  }

  renderSelectedAddress() {
    const { address } = this.state
    const { disabled } = this.props
    const label = address.name || address.first_line
    const microcopy = [address.town, address.county, address.postcode].filter(Boolean).join(', ')

    return (
      <Fragment>
        <Options>
          <OptionsItem modifiers={disabled ? 'disabled' : null}>
            <OptionsItemLabel modifiers={['uninteractive', 'image']}>
              <img
                className="options__item__image"
                src={mapSrc(`${address.latitude},${address.longitude}`, 90, 110, 12)}
                alt={label}
              />
              <OptionsItemText label={label} microcopy={microcopy} />
            </OptionsItemLabel>
          </OptionsItem>
        </Options>
        {!disabled && <FormLink text="This isn't the correct address" onClick={this.reset} />}
      </Fragment>
    )
  }

  render() {
    const { manual, selectionMade } = this.state
    if (manual) {
      return this.renderManualEntry()
    } else if (selectionMade) {
      return this.renderSelectedAddress()
    } else {
      return this.renderTypeahead()
    }
  }
}

ModLocationInput.propTypes = {
  onChange: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  address: PropTypes.object,
  errors: PropTypes.object,
  disabled: PropTypes.bool
}

export default ModLocationInput
