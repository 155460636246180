import React from 'react'
import PropTypes from 'prop-types'

import Button from './Button'
import QuestionnaireQuestion from './QuestionnaireQuestion'

class QuestionnaireStage extends React.Component {
  helperId() {
    return `questionnaire-${this.props.id}-helper`
  }

  backButton() {
    const { id } = this.props
    if (id == 1) return null
    return (
      <nav className="questionnaire__navigation">
        <Button
          children="Back to last question"
          modifiers={['secondary']}
          classes={['questionnaire__navigation__button', 'questionnaire__navigation__button--back']}
        />
      </nav>
    )
  }

  answers() {
    const { answers } = this.props
    if (!answers || !answers.length) return null

    let classes = 'questionnaire__answers'
    if (answers.length == 2) classes += ' questionnaire__answers--2-col'
    return (
      <ul className={classes}>
        {answers.map((answer, index) => (
          <li className="questionnaire__answers__item" key={index}>
            <a className="questionnaire__answers__item__link" {...answer}>
              {this.answerImage(answer)}
              <span className="questionnaire__answers__item__link__text">{answer.label}</span>
            </a>
          </li>
        ))}
      </ul>
    )
  }

  answerImage(answer) {
    if (!answer || !answer.image) return null
    return <img className="questionnaire__answers__item__link__icon" alt={answer.label} src={answer.image} />
  }

  render() {
    const { id, track, question, helperText } = this.props
    return (
      <div className="questionnaire-container">
        <div className="questionnaire__stage animated animated--slow" data-stage-id={id} data-track={track}>
          <QuestionnaireQuestion subheading="Question 1" question={question} helperText={helperText} />
          {this.answers()}
          {this.backButton()}
        </div>
      </div>
    )
  }
}

QuestionnaireStage.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  helperText: PropTypes.object,
  track: PropTypes.string,
  question: PropTypes.string,
  answers: PropTypes.array
}

export default QuestionnaireStage
