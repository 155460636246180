export const cleanInputPrice = value => {
  if (!value) {
    return ''
  }

  const match = value.match(/[0-9]{1,3}\.?[0-9]{0,2}/)
  return match ? match[0] : ''
}

export const formatPrice = value => {
  if (!value) {
    return value
  }

  return Number.parseFloat(value).toFixed(2)
}
