import React from 'react'
import PropTypes from 'prop-types'

function OverlayWrapper({ onClick }) {
  return <div className="overlay-wrapper" onClick={onClick} />
}

OverlayWrapper.propTypes = {
  onClick: PropTypes.func
}

export default OverlayWrapper
