import React from 'react'
import PropTypes from 'prop-types'

import { iconFromString } from '../../helpers/images'
import { generateModifierClasses } from '../../modules/utils'

const EmojiImage = function ({ alt, modifiers, name }) {
  return <img className={generateModifierClasses('emoji__image', modifiers)} alt={alt} src={iconFromString(name)} />
}

EmojiImage.propTypes = {
  alt: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  name: PropTypes.string
}

export default EmojiImage
