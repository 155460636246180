import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import TimelineStep from './TimelineStep'

import { timelineMonths } from '../../modules/timelines'

export default function Timeline({ loaded, startDate, endDate, gaps }) {
  const months = useMemo(() => timelineMonths(startDate, endDate, gaps, loaded), [startDate, endDate, gaps])

  return (
    <ol className="timeline">
      {months.map(({ date, filled }, i) => (
        <TimelineStep date={date} filled={filled} key={`timeline-step-${i}`} />
      ))}
    </ol>
  )
}

Timeline.propTypes = {
  loaded: PropTypes.bool.isRequired,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  gaps: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.object,
      endDate: PropTypes.o
    })
  ).isRequired
}

Timeline.defaultProps = {
  loaded: false
}
