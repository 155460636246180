import React from 'react'
import PropTypes from 'prop-types'

export default function ActionHeader({ children, text }) {
  return (
    <div className="action-header">
      <h2>{text}</h2>
      <div className="action-header__actions">{children}</div>
    </div>
  )
}

ActionHeader.propTypes = {
  text: PropTypes.string.isRequired
}
