import React from 'react'
import PropTypes from 'prop-types'

import FloatingPicker from '../FloatingPicker'

import { pick, propagateValue } from '../../../modules/utils'
import { buildDate, datePickerDateOptions, dateInputValue } from '../../../modules/dates'

class FloatingDatePicker extends React.Component {
  constructor(props) {
    super(props)
    this.state = this.stateFromProps()
    this.pickerRef = React.createRef()

    this.handlePropagation = this.handlePropagation.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    const dateValue = this.getDateValue()
    const { year } = prevState
    const valueNeedsClearing = !dateValue && year

    if (valueNeedsClearing) {
      this.setState(this.stateFromProps())
    }
  }

  getDateValue() {
    const { resource, name, defaultDate } = this.props
    let date = defaultDate

    if (resource && resource[name]) {
      date = resource[name]
    }

    if (typeof date !== 'string') {
      return date
    }

    if (date.indexOf('-') >= 0) {
      return new Date(date)
    }

    // Sometimes we'll receive a date as a string we can't just pass to the Date constructor:
    const parts = date.split(' ').reverse()
    const year = parts[0]
    const month = parseInt(parts[1], 10) - 1 // Months are counted from 0
    const day = parts[2]
    return new Date(year, month, day, 0, 0, 0, 0)
  }

  stateFromProps() {
    const { includeDay } = this.props
    const dateValue = this.getDateValue()

    const state = {
      day: null,
      month: 0,
      year: null
    }

    if (dateValue) {
      if (includeDay) {
        state.day = dateValue.getDate()
      }
      state.month = dateValue.getMonth()
      state.year = dateValue.getFullYear()
    }

    return state
  }

  clearValues() {
    this.setState({ day: null, month: null, year: null })
  }

  propagateDate() {
    const { day, month, year } = this.state
    const { defaultDay, name } = this.props
    const currentDate = buildDate(day, month, year, defaultDay)
    if (currentDate) {
      const pickerInput = this.pickerRef.current.inputRef.current
      window.myRIFT.helpers.triggerElementChange(pickerInput)
    }

    propagateValue.apply(this, [name, currentDate])
  }

  handlePropagation(partialState) {
    this.setState(partialState, this.propagateDate)
  }

  render() {
    const { day, month, year } = this.state
    const { minDate, minYear, maxYear, includeDay } = this.props

    return (
      <FloatingPicker
        value={dateInputValue(day, month, year)}
        handlePropagation={this.handlePropagation}
        options={datePickerDateOptions(day, month, year, minDate, minYear, maxYear, includeDay)}
        ref={this.pickerRef}
        {...pick(this.props, [
          'label',
          'name',
          'id',
          'disabled',
          'disabledText',
          'resourceName',
          'error',
          'direction',
          'className'
        ])}
      />
    )
  }
}

FloatingDatePicker.propTypes = {
  defaultDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  disabledText: PropTypes.string,
  disabled: PropTypes.bool,
  resource: PropTypes.object,
  resourceName: PropTypes.string.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool, PropTypes.array]),
  handlePropagation: PropTypes.func,
  includeDay: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  minYear: PropTypes.number,
  maxYear: PropTypes.number,
  defaultDay: PropTypes.oneOf(['first', 'last']).isRequired,
  direction: PropTypes.string,
  className: PropTypes.string
}
FloatingDatePicker.defaultProps = {
  direction: 'right',
  defaultDay: 'first'
}
export default FloatingDatePicker
