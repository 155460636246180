import React from 'react'
import PropTypes from 'prop-types'

import EmojiImage from '../images/EmojiImage'

import { Emoji } from '../layout/Wrapper'

import { generateModifierClasses } from '../../modules/utils'

function ClaimTrackerStep(props) {
  const { expectedCompleted, iconName, modifiers, text } = props
  return (
    <div className={generateModifierClasses('step', modifiers)}>
      <h4 className="step__text">{text}</h4>
      {expectedCompleted && (
        <p className="step__detail">
          Expected step completion: <strong>{new Date(expectedCompleted).toLocaleDateString('en-GB')}</strong>
        </p>
      )}
      {iconName && (
        <Emoji className={'p-2'}>
          <EmojiImage modifiers="sm" name={iconName} />
        </Emoji>
      )}
    </div>
  )
}

ClaimTrackerStep.propTypes = {
  expectedCompleted: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  text: PropTypes.string.isRequired,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  iconName: PropTypes.string
}

export default ClaimTrackerStep
