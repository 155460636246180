import { capitalize, fetch } from './utils'
import { formatPrice } from './formatter'

function getJSON(path) {
  return fetch('GET', path, 'application/json', 'application/json')
}

function postJSON(path, data) {
  return fetch('POST', path, 'application/json', 'application/json', data)
}

function putJSON(path, data) {
  return fetch('PUT', path, 'application/json', 'application/json', data)
}

function deleteJSON(path) {
  return fetch('DELETE', path, 'application/json', 'application/json')
}

export function fetchAddresses() {
  return getJSON('/addresses.json')
}

export async function updateAddress(addressData) {
  return putJSON(`/addresses/${addressData.id}.json`, {
    api_person_address: addressData
  })
}

export function confirmAddresses() {
  return putJSON('/addresses/confirm.json')
}

export async function confirmAddress(addressId) {
  return putJSON(`/addresses/${addressId}/confirm.json`)
}

export function fetchEmployments() {
  return getJSON('/employments.json')
}

export async function updateEmployment(employmentData) {
  return putJSON(`/employments/${employmentData.id}.json`, {
    api_person_employment: employmentData
  })
}

export function confirmEmployments() {
  return putJSON('/employments/confirm.json')
}

export async function confirmEmployment(employmentId) {
  return putJSON(`/employments/${employmentId}/confirm.json`)
}

export async function updateEmploymentGap(employmentGapData) {
  return putJSON(`/employment_gaps/${employmentGapData.id}.json`, {
    api_person_employment_gap: employmentGapData
  })
}

export async function confirmEmploymentGap(employmentGapId) {
  return putJSON(`/employment_gaps/${employmentGapId}/confirm.json`)
}

export function fetchLocations() {
  return getJSON('/work_locations.json')
}

export async function updateLocation(locationData) {
  console.log('here', locationData)

  return putJSON(`/work_locations/${locationData.id}.json`, {
    api_person_location: locationData
  })
}

export function confirmLocations() {
  return putJSON('/work_locations/confirm.json')
}

export async function confirmLocation(locationId) {
  return putJSON(`/work_locations/${locationId}/confirm.json`)
}

export async function updateLocationGap(locationGapData) {
  return putJSON(`/work_location_gaps/${locationGapData.id}.json`, {
    work_location_gap: locationGapData
  })
}

export async function confirmLocationGap(locationGapId) {
  return putJSON(`/work_location_gaps/${locationGapId}}/confirm.json`)
}

export function fetchExpenses() {
  return getJSON('/expenses.json').then(expenses => {
    // Format prices for 2 decimal place display now:
    return expenses.map(expense => {
      expense.cost = formatPrice(expense.cost)
      return expense
    })
  })
}

export function fetchVehicles() {
  return getJSON('/vehicles.json')
}

export function fetchDocuments() {
  return getJSON('/documents.json').then(resp => resp.documents)
}

export function fetchClaimSummary() {
  return getJSON('/claim_summaries.json').then(resp => resp[0])
}

export async function fetchTimelineGaps() {
  return getJSON('/timeline_gaps.json')
}

export async function fetchExpenseKinds() {
  return getJSON('/expense/kinds.json')
}

export async function fetchExpenseSectors() {
  return getJSON('/expense/sectors.json')
}

export async function fetchExpenseFrequencies() {
  return getJSON('/expense/durations.json') // Todo: rename durations in the API to frequencies
}

export async function createExpense(expenseData) {
  return postJSON('/expenses.json', Object.assign({}, expenseData, { id: null }))
}

export async function updateExpense(expenseData) {
  return putJSON(`/expenses/${expenseData.id}.json`, expenseData)
}

export async function deleteExpense(expenseData) {
  return deleteJSON(`/expenses/${expenseData.id}.json`)
}

export async function locationLookup(query) {
  return fetch('GET', `/addresses/lookup?query=${query}`)
}

export async function commonLocationLookup(keyword) {
  return fetch('GET', `/common_locations_search?query=${keyword}&kind=MOD`)
}

export async function companyLookup(keyword) {
  return fetch('GET', `/companies_search?query=${keyword}`)
}

export async function bankLookup(keyword) {
  return fetch('GET', `/banks_search?query=${keyword.replace(/bank/i, '').trim()}`)
}

// Todo: Deprecated - replace with individual calls to endpoints for
// review data.
export async function fetchReviewPageState() {
  return getJSON('/review.json')
}

export async function createSubmission() {
  return getJSON('/my_rift/claims/submit.json')
}

const fetchers = {
  fetchClaimSummary,
  fetchDocuments,
  fetchTimelineGaps,
  fetchAddresses,
  fetchEmployments,
  fetchLocations,
  fetchExpenses,
  fetchVehicles,
  fetchExpenseKinds,
  fetchExpenseSectors,
  fetchExpenseFrequencies
}

export function fetchAndSetResources(resourcesRequired, setResources, resourceState) {
  const promisedResources = []
  const nextState = resourceState ? { ...resourceState } : {}

  resourcesRequired.forEach(function (resourceName) {
    const resourceNotFetched = !resourceState || !resourceState[resourceName]

    if (resourceNotFetched) {
      const fetchResource = fetchers[`fetch${capitalize(resourceName)}`]

      promisedResources.push(fetchResource().then(resource => (nextState[resourceName] = resource)))
    }
  })

  if (promisedResources.length) {
    return Promise.all(promisedResources).then(() => setResources(nextState))
  }
}

export function confirmResource(stepName) {
  // This looks odd but is deliberate, we're confirming the resource we've just moved
  // from. TODO: Remove coupling here between this module and the ordering of
  // steps in the timeline as deinfed in modules/pages.js
  if (stepName === 'timelineEmployments') {
    confirmAddresses()
  } else if (stepName === 'timelineWorkLocations') {
    confirmEmployments()
  } else if (stepName === 'timelineExpenses') {
    confirmLocations()
  }
}
