import React from 'react'

function AlertHeader({ children }) {
  return (
    <header className="alert__header">
      <h4 className="alert__title">{children}</h4>
    </header>
  )
}

export default AlertHeader
