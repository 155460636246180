import React from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)

import ScheduledCallbackBody from './ScheduledCallbackBody'
import { Emoji } from '../../layout/Wrapper'
import EmojiImage from '../../images/EmojiImage'
import { fetch, getOrdinalIndicator } from '../../../modules/utils'
import { trackEvent } from '../../../modules/tracking'

const steps = {
  intro: 0,
  form: 1,
  confirmation: 2
}

const initialState = {
  currentStep: steps.intro,
  callbackPreference: 'Today',
  otherDaySelectedValue: '',
  timeSelectedValue: '',
  nextOpenDayString: '',
  nextOpenTimeString: '',
  callbackTimeOptions: [],
  otherDayOptions: [],
  callbackScheduled: false,
  selectedDayIndex: 0,
  submissionErrorText: ''
}

class ScheduledCallback extends React.Component {
  constructor(props) {
    super(props)
    this.state = initialState

    this.init = this.init.bind(this)
    this.nextStep = this.nextStep.bind(this)
    this.onClickNext = this.onClickNext.bind(this)
    this.handleDateChange = this.handleDateChange.bind(this)
  }

  componentDidMount() {
    this.init()
  }

  init() {
    this.setState(initialState)
    this.initCallbackPreference()
    this.initOtherDayOptions()
  }

  initCallbackPreference() {
    const { callCentreWillOpenToday, todaysDay } = this.props
    let newCallbackPreference = ''
    let newNextOpenDayString = ''
    let newSelectedDayIndex = 0

    // If open today, preference is today
    if (callCentreWillOpenToday) {
      newCallbackPreference = 'Today'
      newNextOpenDayString = 'today'
    } else if (todaysDay === 'Saturday') {
      newCallbackPreference = 'Other'
      newSelectedDayIndex = 1
      newNextOpenDayString = 'Monday'
    } else if (todaysDay === 'Sunday') {
      newCallbackPreference = 'Tomorrow'
      newSelectedDayIndex = 0
      newNextOpenDayString = 'Monday'
    } else {
      newCallbackPreference = 'Tomorrow'
      newNextOpenDayString = 'tomorrow'
      newSelectedDayIndex = 1
    }

    this.setSelectedDayAsString(newSelectedDayIndex)
    this.setCallbackTimeOptions(newSelectedDayIndex)

    this.setState({
      callbackPreference: newCallbackPreference,
      nextOpenDayString: newNextOpenDayString,
      selectedDayIndex: newSelectedDayIndex
    })
  }

  initOtherDayOptions() {
    const { openingData, todaysDay } = this.props
    let sliceIndex = 2

    if (todaysDay === 'Saturday' || todaysDay === 'Sunday') {
      sliceIndex = 1
    }

    // Map 7 days after 'tomorrow' by slicing since this is for 'other'
    const days = openingData.slice(sliceIndex, openingData.length).map((day, index) => {
      return [
        `${this.createDayString(day.date)}#${index + sliceIndex}`, // Key/Value
        this.createDayWithOridinalString(day.date) // Label
      ]
    })

    this.setState({
      otherDayOptions: days
    })
  }

  setCallbackTimeOptions(newSelectedDayIndex) {
    const { openingData } = this.props
    const openingTimesToday = openingData[newSelectedDayIndex].openingTimes
    const newNextOpenTimeString = this.createTimeString(openingTimesToday[0].from)

    // Filter to skip the first opening slot (to enable the ops team to prepare each morning)
    const newCallbackTimeOptions = openingTimesToday
      .filter((times, index) => {
        if (index === 0) {
          return false
        }
        return true
      })
      .map(times => {
        return this.createTimeRangeString(times.from, times.to)
      })
    const newTimeSelectedValue = newCallbackTimeOptions[0]

    this.setState({
      callbackTimeOptions: newCallbackTimeOptions,
      timeSelectedValue: newTimeSelectedValue,
      nextOpenTimeString: newNextOpenTimeString
    })
  }

  onClose() {
    this.init()
  }

  nextStep() {
    this.setState(prevstate => ({
      currentStep: prevstate.currentStep + 1
    }))
  }

  handleDateChange(e) {
    this.hideError()
    if (e.currentTarget) {
      this.onCallbackDayChange(e)
    } else {
      this.onCallbackPreferenceChange(e)
    }
  }

  handleSubmit() {
    const { openingData } = this.props
    const { selectedDayIndex, timeSelectedValue } = this.state
    const date = openingData[selectedDayIndex].date
    const time = timeSelectedValue.split('-')[0].trim()

    const payload = {
      scheduled_at: `${date}T${time}Z`
    }

    this.hideError()
    this.scheduleCallback('POST', payload)
  }

  scheduleCallback(method, payload) {
    this.callAPI(method, payload)
      .then(data => {
        data.created_at ? this.nextStep() : this.showError()
      })
      .catch(() => {
        this.showError()
      })
  }

  callAPI(method, data) {
    return new Promise((resolve, reject) => {
      fetch(method, `/scheduled_callbacks.json`, 'application/json', null, data)
        .then(data => resolve(data))
        .catch((message, data) => reject([message, data]))
    })
  }

  showError() {
    this.setState({
      submissionErrorText: 'There was an error, please try again.'
    })
  }

  hideError() {
    this.setState({
      submissionErrorText: ''
    })
  }

  onCallbackDayChange(e) {
    switch (e.currentTarget.name) {
      case 'callbackDay':
        const { openingData } = this.props
        const newSelectedDayIndex = parseInt(e.currentTarget.value.split('#')[1])
        const newOtherDaySelectedValue = `${this.createDayString(
          openingData[newSelectedDayIndex].date
        )}#${newSelectedDayIndex}`

        this.setSelectedDayAsString(newSelectedDayIndex)
        this.setCallbackTimeOptions(newSelectedDayIndex)

        this.setState({
          otherDaySelectedValue: newOtherDaySelectedValue,
          selectedDayIndex: newSelectedDayIndex
        })
        break
      case 'callbackTime':
        this.setState({
          timeSelectedValue: e.currentTarget.value
        })
        break
    }
  }

  onCallbackPreferenceChange(e) {
    const { openingData, todaysDay } = this.props
    let newSelectedDayIndex = 0

    switch (e.callbackPreference.toLowerCase()) {
      case 'today':
        break
      case 'tomorrow':
        if (todaysDay === 'Sunday') {
          newSelectedDayIndex = 0
        } else {
          newSelectedDayIndex = 1
        }
        break
      case 'other':
        if (todaysDay === 'Saturday' || todaysDay === 'Sunday') {
          newSelectedDayIndex = 1
        } else {
          newSelectedDayIndex = 2
        }
    }

    const newOtherDaySelectedValue = `${this.createDayString(
      openingData[newSelectedDayIndex].date
    )}#${newSelectedDayIndex}`

    this.setSelectedDayAsString(newSelectedDayIndex)
    this.setCallbackTimeOptions(newSelectedDayIndex)
    this.setState({
      callbackPreference: e.callbackPreference,
      selectedDayIndex: newSelectedDayIndex,
      otherDaySelectedValue: newOtherDaySelectedValue
    })
  }

  onClickNext(e) {
    const { currentStep, selectedDayIndex } = this.state
    e.preventDefault()

    if (currentStep < steps.form) {
      this.nextStep()
      trackEvent('Chose callback contact')
    } else {
      this.setSelectedDayAsString(selectedDayIndex)
      this.handleSubmit()
    }
  }

  setSelectedDayAsString(selectedDayIndex) {
    const { openingData } = this.props
    const newSelectedDayString = this.createDayWithOridinalString(openingData[selectedDayIndex].date)
    this.setState({
      selectedDayAsString: newSelectedDayString
    })
  }

  formattableDateUTC(date) {
    return dayjs.utc(date)
  }

  createTimeRangeString(from, to) {
    const fromTime = this.formattableDateUTC(from).format('HH:mm')
    const toTime = this.formattableDateUTC(to).format('HH:mm')
    return `${fromTime} - ${toTime}`
  }

  createTimeString(time) {
    return this.formattableDateUTC(time).format('HH:mm')
  }
  createDayString(date) {
    return this.formattableDateUTC(date).format('dddd')
  }

  createDayWithOridinalString(date) {
    const dateStr = this.createDayString(date)
    const dayStr = this.formattableDateUTC(date).format('D')
    const ordinalStr = getOrdinalIndicator(this.formattableDateUTC(date).format('dd'))
    return `${dateStr} ${dayStr}${ordinalStr}`
  }

  renderHeader() {
    const { icon, title } = this.props.stepsContent[this.state.currentStep]

    return (
      <header className="scheduled-callback__header">
        <div className="scheduled-callback__icon">
          {icon && (
            <Emoji>
              <EmojiImage name={icon} alt={icon} />
            </Emoji>
          )}
        </div>
        <h2 className="scheduled-callback__title">{title}</h2>
      </header>
    )
  }

  renderFooter() {
    const { submissionErrorText } = this.state
    let { linkTarget, linkText, linkHref, buttonText, trackingEvent } = this.props.stepsContent[this.state.currentStep]
    let target = linkTarget ? (linkTarget = linkTarget) : ''

    const onLinkClick = e => {
      e.preventDefault()

      // If there's an event to track, track it and then follow any links:
      if (trackingEvent) {
        trackEvent(trackingEvent).then(() => {
          // If there's a target, follow it
          if (target) {
            window.open(linkHref, target)
            return
          }
          window.location = linkHref
        })
      }
    }

    return (
      <footer className="scheduled-callback__footer">
        {submissionErrorText !== '' && <p className="scheduled-callback__error">{submissionErrorText}</p>}
        {buttonText !== '' && (
          <button
            onClick={this.onClickNext}
            type="submit"
            value="Submit"
            className="button--primary button--block button"
          >
            {buttonText}
          </button>
        )}
        <a href={linkHref} target={target} onClick={onLinkClick} className="link--sm link">
          {linkText}
        </a>
      </footer>
    )
  }

  render() {
    const { callCentreWillOpenToday, todaysDay } = this.props
    const {
      callbackPreference,
      otherDaySelectedValue,
      selectedDayAsString,
      timeSelectedValue,
      callbackTimeOptions,
      otherDayOptions,
      currentStep,
      nextOpenDayString,
      nextOpenTimeString
    } = this.state

    return (
      <React.Fragment>
        <div className="scheduled-callback">
          {this.renderHeader()}
          <div className="scheduled-callback__body">
            <ScheduledCallbackBody
              steps={steps}
              todaysDay={todaysDay}
              callCentreWillOpenToday={callCentreWillOpenToday}
              callbackPreference={callbackPreference}
              otherDaySelectedValue={otherDaySelectedValue}
              selectedDayAsString={selectedDayAsString}
              timeSelectedValue={timeSelectedValue}
              callbackTimeOptions={callbackTimeOptions}
              otherDayOptions={otherDayOptions}
              currentStep={currentStep}
              nextOpenDayString={nextOpenDayString}
              nextOpenTimeString={nextOpenTimeString}
              handleDateChange={this.handleDateChange}
            />
          </div>
          {this.renderFooter()}
        </div>
      </React.Fragment>
    )
  }
}

ScheduledCallback.propTypes = {
  openingData: PropTypes.array,
  callCentreWillOpenToday: PropTypes.bool,
  todaysDay: PropTypes.string,
  stepsContent: PropTypes.array
}

export default ScheduledCallback
