import React from 'react'
import PropTypes from 'prop-types'

import EmojiImage from '../images/EmojiImage'

import { Emoji } from '../layout/Wrapper'

function Action(props) {
  const { heading, href, icon, text } = props

  return (
    <a className="action" href={href}>
      {icon && (
        <Emoji className={icon.className}>
          <EmojiImage {...icon} />
        </Emoji>
      )}
      <div className="action__content">
        <h4 className="action__heading">{heading}</h4>
        {text && <p className="action__text">{text}</p>}
      </div>
    </a>
  )
}

Action.propTypes = {
  heading: PropTypes.string.isRequired,
  href: PropTypes.string,
  icon: PropTypes.shape({
    alt: PropTypes.string,
    classsName: PropTypes.string,
    modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    name: PropTypes.string.isRequired
  }),
  text: PropTypes.string
}

Action.defaultProps = {
  href: '#'
}

export default Action
