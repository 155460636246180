import React from 'react'
import PropTypes from 'prop-types'

import Question from '../Question'
import FloatingDatePicker from './FloatingDatePicker'
import Helper from '../../display/Helper'
import HelperTrigger from '../../display/HelperTrigger'
import { uuidv4 } from '../../../modules/utils'

import { FormGroup } from '../../layout/Wrapper'

export default function DateQuestion({
  error,
  resource,
  resourceName,
  includeDay,
  minYear,
  maxYear,
  name,
  disabled,
  minDate,
  onChange,
  questionHeading,
  questionHeadingSmall,
  questionText,
  helperText
}) {
  const helperId = `helper-${resourceName}-${uuidv4()}`

  return (
    <FormGroup modifiers={error ? 'error' : null}>
      {helperText && <Helper id={helperId} body={helperText} modifiers={[questionHeadingSmall ? 'margins' : '']} />}
      {(questionHeading || questionText) && (
        <Question heading={questionHeading} text={questionText} small={questionHeadingSmall}>
          {helperText && (
            <HelperTrigger
              helperId={helperId}
              eventLabel={`the '${questionText}' question on a Date Picker component`}
            />
          )}
        </Question>
      )}
      <FloatingDatePicker
        label="Date"
        name={name}
        resource={resource}
        resourceName={resourceName}
        error={error}
        minDate={minDate}
        minYear={minYear}
        maxYear={maxYear}
        includeDay={includeDay}
        onChange={onChange}
        disabled={disabled}
      />
    </FormGroup>
  )
}

DateQuestion.propTypes = {
  questionHeading: PropTypes.string,
  questionHeadingSmall: PropTypes.bool,
  questionText: PropTypes.string,
  resource: PropTypes.object,
  resourceName: PropTypes.string,
  name: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  includeDay: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  minYear: PropTypes.number,
  maxYear: PropTypes.number,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
}

DateQuestion.defaultProps = {
  questionHeading: 'Date',
  name: 'started_at',
  minYear: new Date().getFullYear() - 44,
  maxYear: new Date().getFullYear(),
  disabled: false
}
