import React from 'react'

const Select = function ({ disabled, name, options, value, onChange }) {
  return (
    <select disabled={disabled} name={name} className="select__element" onChange={onChange} value={value || ''}>
      {options.map(option => {
        const isArray = typeof option === 'object'
        const value = isArray ? option[0] : option
        const label = isArray ? option[1] : option
        const disabled = isArray ? option[2] : false

        return (
          <option key={value} value={value} disabled={disabled}>
            {label}
          </option>
        )
      })}
    </select>
  )
}

export default Select
