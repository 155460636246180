import React from 'react'
import PropTypes from 'prop-types'

export default function DisplayList({ children, small }) {
  const classes = ['display-list']
  if (small) {
    classes.push('display-list--small')
  }

  return <div className={classes.join(' ')}>{children}</div>
}

DisplayList.propTypes = {
  small: PropTypes.bool
}

DisplayList.defaultProps = {
  small: false
}
