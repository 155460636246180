import React from 'react'
import PropTypes from 'prop-types'

import ClaimTracker from '../../display/ClaimTracker'

import { fetch } from '../../../modules/utils'

class DashboardClaimTracker extends React.Component {
  fetchClaims() {
    fetch('GET', '/dashboard/claim_tracker.json').then(trackers => {
      this.setState({
        trackers: trackers
      })
    })
  }

  componentDidMount() {
    this.fetchClaims()
  }

  render() {
    return <ClaimTracker {...this.props} trackers={this.state ? this.state.trackers : []} />
  }
}

DashboardClaimTracker.propTypes = {
  footerLink: PropTypes.shape({
    href: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired
  })
}

export default DashboardClaimTracker
