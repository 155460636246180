import React from 'react'

import { generateModifierClasses } from '../../modules/utils'

const OptionsItemImage = function (props) {
  const classes = generateModifierClasses('options__item__image', props.modifiers)

  return <img {...props} className={classes} />
}

export default OptionsItemImage
