import React from 'react'

const BlockHeader = function ({ children, actionHref, actionLabel }) {
  const link =
    actionHref && actionLabel ? (
      <div className="block__action">
        <a className="block__link" href={actionHref}>
          <span className="block__link__inner">{actionLabel}</span>
        </a>
      </div>
    ) : null
  return (
    <header className="block__header">
      <h3 className="block__title display-label">{children}</h3>
      {link}
    </header>
  )
}

export default BlockHeader
