import React from 'react'
import PropTypes from 'prop-types'

import DisplayList from './DisplayList'
import Card from './Card'
import TimelineGap from './TimelineGap'
import CardPlaceholder from './CardPlaceholder'
import TimelineEndedAtPrompt from '../interactive/TimelineEndedAtPrompt'

import { useViewport } from '../../modules/hooks'
import { BREAKPOINTS } from '../../modules/utils'

export default function ConfigurableDisplayList({ list, placeholderName, small }) {
  const { width } = useViewport()

  const columnCount = width < BREAKPOINTS.LARGE ? 2 : 3
  const mediumScreen = !small && width < BREAKPOINTS.MEDIUM

  const renderPlaceholders = () => {
    if (small || mediumScreen) {
      return
    }

    return list.map(
      (_item, i) => i % columnCount !== 0 && <CardPlaceholder key={`placeholder_${i}`} name={placeholderName} />
    )
  }

  return (
    <DisplayList small={small}>
      {list.map((item, i) => {
        let keyText = `display-list_item_${i}`
        if (item.componentName === 'TimelineEndedAtPrompt') {
          return <TimelineEndedAtPrompt key={keyText} {...item} />
        } else if (item.timelineString) {
          return <TimelineGap key={keyText} {...item} />
        }
        return <Card key={keyText} {...item} />
      })}
      {!small && renderPlaceholders()}
    </DisplayList>
  )
}

ConfigurableDisplayList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      heading: PropTypes.string,
      label: PropTypes.string,
      microcopy: PropTypes.string,
      missingInfoLink: PropTypes.string,
      modifiers: PropTypes.array,
      onClick: PropTypes.func,
      preview: PropTypes.shape({
        imgSrc: PropTypes.string,
        modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
        svg: PropTypes.shape({
          name: PropTypes.string,
          text: PropTypes.string
        })
      }),
      progress: PropTypes.shape({
        max: PropTypes.number,
        text: PropTypes.string,
        value: PropTypes.number
      }),
      timelineString: PropTypes.string
    })
  ).isRequired,
  placeholderName: PropTypes.string,
  small: PropTypes.bool
}

ConfigurableDisplayList.defaultProps = {
  list: [],
  small: false
}
