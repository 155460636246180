import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { generateModifierClasses } from '../../modules/utils'

export default function FlashMessage({ body, messageList, title, type }) {
  const [dismissed, setDismissed] = useState(false)

  const validType = () => {
    if (type == 'notice') {
      return 'info'
    } else if (['success', 'info', 'failure'].indexOf(type) === -1) {
      return 'failure'
    } else {
      return type
    }
  }

  const validTitle = () => {
    if (!title) {
      switch (validType()) {
        case 'success':
          return 'Success'
        case 'info':
          return 'Info'
        default:
          return 'Whoops!'
      }
    }
    return title
  }

  const alertClasses = () => {
    let modifiers = [validType()]
    if (!dismissed) modifiers.push('visible')
    return generateModifierClasses('alert', modifiers)
  }

  const bodyChildren = () => {
    let children = []
    if (body) {
      children.push(<p className="alert__text" key="body" dangerouslySetInnerHTML={{ __html: body }} />)
    }
    if (messageList && messageList.length) {
      children.push(
        <ul className="alert__list" key="ul">
          {messageList.map((message, index) => (
            <li key={`li-${index}`}>{message}</li>
          ))}
        </ul>
      )
    }

    if (!children.length) return null
    return children
  }

  return (
    <div className={alertClasses()}>
      <div className="alert__inner">
        <div className="alert__content">
          <header className="alert__header">
            <h4 className="alert__title">{validTitle()}</h4>
          </header>
          <div className="alert__body">{bodyChildren()}</div>
        </div>
        <button className="alert__close button--close" type="button" onClick={() => setDismissed(true)} />
      </div>
    </div>
  )
}

FlashMessage.propTypes = {
  messageList: PropTypes.array,
  type: PropTypes.string,
  title: PropTypes.string,
  body: PropTypes.string
}
