import { useEffect, useState } from 'react'

export const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth)

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)
    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return { width }
}

// Poll for changes to the pathname every 250ms, because React router
// doesn't work with the popstate event.
export const usePolledLocation = () => {
  const [pathname, setPathname] = useState(window.location.pathname)

  useEffect(() => {
    const interval = setInterval(() => {
      if (pathname !== window.location.pathname) {
        clearInterval(interval)
        setPathname(window.location.pathname)
      }
    }, 250)
    return () => clearInterval(interval)
  }, [pathname, window.location.pathname])

  return { pathname }
}
