import React from 'react'
import { Link } from 'react-router-dom'

function ContentFooterLink({ children, href, onClick, to }) {
  if (to) {
    return (
      <Link className="content__footer__link" to={to}>
        <span className="content__footer__text">{children}</span>
      </Link>
    )
  }

  return (
    <a className="content__footer__link" href={href || '#'} onClick={onClick}>
      <span className="content__footer__text">{children}</span>
    </a>
  )
}

export default ContentFooterLink
