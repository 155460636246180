import React from 'react'
import PropTypes from 'prop-types'

import { generateModifierClasses } from '../../modules/utils'

import EmojiImage from '../images/EmojiImage'

import { Emoji } from '../layout/Wrapper'

class Helper extends React.Component {
  handleClickClose(e) {
    const helper = e.target.closest('.helper--visible')
    if (helper) {
      helper.classList.remove('helper--visible')
    }
  }

  header() {
    const { title } = this.props
    if (!title) return null
    return <h4 className="helper__title">{title}</h4>
  }

  innerClasses() {
    let innerClasses = ['helper__inner']
    const { additionalInnerClasses } = this.props
    if (additionalInnerClasses) {
      innerClasses = innerClasses.concat(additionalInnerClasses)
    }
    return innerClasses.join(' ')
  }

  helperLink() {
    const { helperLink } = this.props
    return (
      helperLink && (
        <a className="helper__link link link--sm" href={helperLink.href || '#'} onClick={helperLink.handleClick}>
          {helperLink.text}
        </a>
      )
    )
  }

  icon() {
    const { iconName, title } = this.props
    if (!iconName) return false
    return (
      <Emoji className="helper__emoji emoji">
        <EmojiImage modifiers={['sm']} alt={title} name={iconName} />
      </Emoji>
    )
  }

  render() {
    const { id, body, closeable, modifiers, additionalContent } = this.props
    return (
      <div className={generateModifierClasses('helper', modifiers)} id={id}>
        <div className={this.innerClasses()}>
          <div className="helper__content">
            {this.header()}
            <p className="helper__text">{body}</p>
            {additionalContent && <span dangerouslySetInnerHTML={{ __html: additionalContent }} />}
            {this.helperLink()}
          </div>
          {this.icon()}
          {closeable && (
            <button className="helper__close button--close" type="button" onClick={this.handleClickClose} />
          )}
        </div>
      </div>
    )
  }
}

Helper.propTypes = {
  id: PropTypes.string,
  modifiers: PropTypes.array,
  additionalInnerClasses: PropTypes.array,
  helperLink: PropTypes.object,
  iconName: PropTypes.string,
  closeable: PropTypes.bool,
  title: PropTypes.string,
  body: PropTypes.string,
  additionalContent: PropTypes.string
}

Helper.defaultProps = {
  title: 'Why do we ask this?',
  closeable: true
}

export default Helper
