import React from 'react'
import PropTypes from 'prop-types'

export default function MastheadNavList({ links }) {
  return (
    <ul className="masthead__nav__list nav__list">
      {links.map((link, i) => (
        <li className="masthead__nav__item nav__item nav__item--visible-md" key={`masthead-nav-item-${i}`}>
          <a href={link.href} className={`masthead__nav__link nav__link ${link.active ? 'nav__link--current' : ''}`}>
            {link.label}
          </a>
        </li>
      ))}
    </ul>
  )
}

MastheadNavList.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      active: PropTypes.bool
    })
  ).isRequired
}
