export const BREAKPOINTS = {
  LARGE: 1120,
  MEDIUM: 768,
  SMALL: 576
}

export function prefersReducedMotion() {
  return window.matchMedia('(prefers-reduced-motion: reduce)').matches
}

export function preloadImages(urls) {
  urls.forEach(url => {
    const img = new Image()
    img.src = url
  })
}

export function generateModifierClasses(componentClass, modifiers) {
  if (!modifiers) {
    return componentClass
  }

  return []
    .concat(modifiers)
    .map(modifier => `${componentClass}--${modifier}`)
    .concat([componentClass])
    .join(' ')
}

export function buttonClasses(classes, modifiers, type) {
  let buttonClasses = classes || []
  modifiers = modifiers || []
  if (type == 'submit') {
    modifiers.push('primary')
  }
  buttonClasses = buttonClasses.concat(generateModifierClasses('button', modifiers))
  return buttonClasses.join(' ')
}

export function fetch(method, railsRoute, contentType = null, accept = null, data = null, noAuth = false) {
  return new Promise(function (resolve, reject) {
    let xhr = new XMLHttpRequest()
    xhr.open(method, railsRoute)
    window.ajaxCount = window.ajaxCount == -1 ? 1 : window.ajaxCount + 1

    if (['POST', 'PATCH', 'PUT', 'DELETE'].indexOf(method) >= 0 && !noAuth) {
      const tokenElement = document.querySelector('meta[name="csrf-token"]')
      const auth = tokenElement && tokenElement.getAttribute('content')
      xhr.setRequestHeader('X-CSRF-Token', auth)
    }

    if (contentType) {
      xhr.setRequestHeader('Content-Type', contentType)
    }

    if (accept) {
      xhr.setRequestHeader('Accept', contentType)
    }

    xhr.onload = function ({ target }) {
      // Sometimes we see errors arising from JSON endpoints not returning
      // JSON. This is just to log those responses when that happens to help
      // with debugging:
      if (!includes(['{', '['], target?.response[0]) && window.rg4js) {
        rg4js('recordBreadcrumb', 'Unparseable success JSON', {
          response: target.response
        })
      }

      window.ajaxCount -= 1
      console.log(`Fetched ${railsRoute} - ${window.ajaxCount || 'None'} remain.`)

      if (target.status === 500) {
        return reject([target.statusText], 500)
      }

      // We're logged out and Rails is returning us the login screen HTML.
      if (target && target.response.indexOf('You need to sign in or sign up') !== -1) {
        window.location = '/users/sign_in?session_expired=t'
      }

      if (target.status !== 200) {
        return reject([target.statusText, JSON.parse(target.response)])
      }

      resolve(JSON.parse(target.response))
    }

    xhr.onerror = function (e) {
      window.ajaxCount -= 1
      console.log(`Failed to fetch ${railsRoute} - ${window.ajaxCount || 'None'} remain.`)
      if (window.rg4js) {
        rg4js('send', e)
      }
      return reject(e)
    }

    xhr.send(data && JSON.stringify(data))
  })
}

export function fullPath(path, queryObject) {
  const queryString = Object.keys(queryObject)
    .map(key => key + '=' + queryObject[key])
    .join('&')
  return queryString ? `${path}?${queryString}` : path
}

export function redirectTo(path) {
  window.location = path
}

export function propagateValue(name, value) {
  const { handlePropagation, onChange } = this.props
  if (handlePropagation) {
    const method = methodFromString(handlePropagation)
    method && method(name, value)
  }
  if (onChange) {
    onChange({ [name]: value })
  }
}

export function methodCallFromString(originalOnClick) {
  let onClick = originalOnClick

  if (typeof originalOnClick === 'string') {
    onClick = function () {
      try {
        const callableFunction = new Function('event', originalOnClick)
        callableFunction.call(this)
      } catch (error) {
        console.error('Error executing the function:', error)
      }
    }
  }

  return onClick
}

export function handleBreakpoint(breakpoint, smallerFunction, largerFunction) {
  let biggerThanBreakpoint = this.state._biggerThanBreakpoint || {}
  biggerThanBreakpoint[breakpoint] = window.innerWidth >= breakpoint
  this.setState({ _biggerThanBreakpoint: biggerThanBreakpoint })

  window.addEventListener('resize', e => {
    let biggerThanBreakpoint = this.state._biggerThanBreakpoint || {}
    const { innerWidth } = e.target

    if (innerWidth >= breakpoint && !biggerThanBreakpoint[breakpoint]) {
      biggerThanBreakpoint[breakpoint] = true
      this.setState({ _biggerThanBreakpoint: biggerThanBreakpoint }, largerFunction)
    } else if (innerWidth < breakpoint && biggerThanBreakpoint[breakpoint]) {
      biggerThanBreakpoint[breakpoint] = false
      this.setState({ _biggerThanBreakpoint: biggerThanBreakpoint }, smallerFunction)
    }
  })
}

export function methodFromString(string) {
  if ('function' === typeof string) {
    return string
  }
  const keys = string.replace('window.', '').split('.')
  let object = window
  for (var i = 0; i < keys.length; i++) {
    object = object[keys[i]]
  }
  if ('function' === typeof object) {
    return object
  }
  return false
}

// If you are requesting the same path as your current path, you may need to add `.json`
// to the path so that browsers don't cache the request when navigating back and forward
export function setStateFromPath(method, path, emptyState = {}) {
  return fetch(method, path, 'application/json', 'application/json')
    .then(data => this.setState(data || emptyState))
    .catch(e => {
      if (e) {
        console.error('Fetch failed: ' + e)
      }
    })
}

export function setStateValueFromPath(method, path, value) {
  fetch(method, path, 'application/json', 'application/json')
    .then(data => this.setState({ [value]: data }))
    .catch(e => {
      if (e) {
        console.error('Fetch failed: ' + e)
      }
    })
}

export function isIterable(obj) {
  if (!obj) {
    console.error('Expected an iterable, got nothing.')
    return false
  }
  if (typeof obj[Symbol.iterator] === 'function') {
    return true
  } else {
    console.error(`Expected an iterable, got: ${obj}.`)
    return false
  }
}

export function helperId() {
  const { name, id, helperId } = this.props
  return helperId || `helper-${name || id}`
}

export function inputName() {
  const { name, resourceName } = this.props
  return `${resourceName}[${name}]`
}

export function inputId(includeValue = true) {
  const { name, value, resourceName } = this.props
  let niceValue = value
  if (typeof niceValue == 'string') niceValue = niceValue.toLowerCase()
  const prefix = resourceName.replace('[', '_').replace(']', '')
  return includeValue ? `${prefix}_${name}_${niceValue}` : `${prefix}_${name}`
}

export function highlightFirstError() {
  setTimeout(function () {
    const errorItem = document.querySelector('.form-group--error')

    if (!errorItem) return false

    errorItem.closest('.form-group--error').scrollIntoView({ behavior: 'smooth', block: 'start' })

    errorItem.classList.remove('shake')
    setTimeout(function () {
      errorItem.classList.add('animated', 'animated--snail', 'shake')
    }, 100)
  }, 100)
}

export function mapSrc(center, width, height, zoom = null) {
  const { google_api_key } = window
  let src = `https://maps.googleapis.com/maps/api/staticmap?key=${google_api_key}&center=${center}&size=${width}x${height}&scale=2`
  if (zoom) src = `${src}&zoom=${zoom}`
  return src
}

export function hasValues(obj) {
  if (!obj) {
    return false
  }

  const values = Object.keys(obj).map(key => obj[key])
  return values.some(value => !!value)
}

export function existsByProperty(array, value, property) {
  return array.find(currentItem => currentItem[property] == value)
}

export function mergeOrAddByProperty(array, item, property) {
  const existingItem = existsByProperty(array, item[property], property)

  if (!existingItem) {
    return array.concat(item)
  }

  array.splice(array.indexOf(existingItem), 1, item)
  return array
}

export function getOrdinalIndicator(date) {
  if (date > 3 && date < 21) return 'th'
  switch (date % 10) {
    case 1:
      return 'st'
    case 2:
      return 'nd'
    case 3:
      return 'rd'
    default:
      return 'th'
  }
}

export function omit(obj, omittedKeys) {
  if (!obj) {
    return {}
  }

  const result = {}

  const keys = Object.keys(obj)

  keys.forEach(key => {
    if (omittedKeys.indexOf(key) === -1) {
      result[key] = obj[key]
    }
  })

  return result
}

export function capitalize(string) {
  return `${string[0].toUpperCase()}${string.slice(1)}`
}

export function pick(obj, pickedKeys) {
  if (!obj) {
    return {}
  }

  if (!pickedKeys || !pickedKeys.length) {
    return { ...obj }
  }

  const result = {}

  pickedKeys.forEach(key => {
    if (obj[key] !== undefined) {
      result[key] = obj[key]
    }
  })

  return result
}

export function snakeCase(str) {
  return (
    str &&
    str
      .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
      .map(x => x.toLowerCase())
      .join('_')
  )
}

export function flatten(array) {
  let result = []
  for (let i = 0; i < array.length; i++) {
    result = result.concat(array[i])
  }
  return result
}

export function groupBy(items, key) {
  const result = {}

  items.forEach(item => {
    if (result[item[key]]) {
      result[item[key]].push(item)
    } else {
      result[item[key]] = [item]
    }
  })

  return result
}

export function uuidv4() {
  const str = () => ('00000000000000000' + (Math.random() * 0xffffffffffffffff).toString(16)).slice(-16)
  const a = str()
  const b = str()
  return a.slice(0, 8) + '-' + a.slice(8, 12) + '-4' + a.slice(13) + '-a' + b.slice(1, 4) + '-' + b.slice(4)
}

export function isUuidv4(value) {
  return (
    typeof value === 'string' &&
    new RegExp('^[0-9A-F]{8}-[0-9A-F]{4}-4[0-9A-F]{3}-[89AB][0-9A-F]{3}-[0-9A-F]{12}$', 'i').test(value)
  )
}

export const findBy = (items, property, value) => {
  return items.find(e => e[property] == value)
}

export const findIndexBy = (items, property, value) => {
  return items.findIndex(e => e[property] == value)
}

export const removeIndex = (items, index) => {
  const result = [].concat(items)
  result.splice(index, 1)
  return result
}

export const updateIndex = (items, index, newEntry) => {
  const result = [].concat(items)
  result[index] = newEntry
  return result
}

export const deDupe = items => {
  return [...new Set(items)]
}

export const pluck = (items, key) => {
  return items.map(item => item[key])
}

export const includes = (items, item) => {
  return items.indexOf(item) !== -1
}

export const values = obj => {
  if (!obj) {
    return []
  }

  return Object.keys(obj).map(e => obj[e])
}

export const toggleInclusion = (items, item) => {
  const index = items.indexOf(item)
  if (index === -1) {
    return [item].concat(items)
  }
  return removeIndex(items, index)
}

export const goToEndOfInput = input => {
  if (input.createTextRange) {
    const fieldRange = input.createTextRange()
    fieldRange.collapse(false)
    fieldRange.select()
  } else {
    const length = input.value.length
    // Waits for the cursor to be placed.
    setTimeout(function () {
      input.setSelectionRange(length, length)
    }, 0)
  }
}
