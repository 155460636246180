import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import TypeAhead from './TypeAhead'
import FormLink from './FormLink'
import { FormGroupErrorMessage } from '../layout/Wrapper'
import FormGroupHeader from './FormGroupHeader'
import FormGroupInput from './FormGroupInput'
import { bankLookup } from '../../modules/api'
import Bank from '../display/Bank'

const DEFAULT_STATE = {
  manual: false,
  selectionMade: false,
  bank: {}
}

class BankInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = this.getInitialState()

    this.toggleManualEntry = this.toggleManualEntry.bind(this)
    this.setBankState = this.setBankState.bind(this)
    this.setCustomBank = this.setCustomBank.bind(this)
    this.handleBankChange = this.handleBankChange.bind(this)
    this.reset = this.reset.bind(this)
  }

  getInitialState() {
    const { bank } = this.props

    if (!bank || !bank.name) {
      return DEFAULT_STATE
    }

    return {
      ...DEFAULT_STATE,
      selectionMade: true,
      bank
    }
  }

  toggleManualEntry(e) {
    e.preventDefault()
    const { manual } = this.state
    this.setState({ selectionMade: false, bank: {}, manual: !manual })
  }

  reset(e) {
    e.preventDefault()
    this.setState(DEFAULT_STATE, this.handleBankChange)
  }

  setBankState(bank) {
    this.setState(
      {
        bank,
        selectionMade: true
      },
      this.handleBankChange
    )
  }

  setCustomBank(e) {
    e.preventDefault()
    this.setBankState({
      name: e.target.value,
      id: null,
      organisation: null
    })
  }

  handleBankChange() {
    const { onChange } = this.props
    const { bank } = this.state
    onChange(bank)
  }

  renderManualEntry() {
    const { bank } = this.state
    const { error } = this.props

    return (
      <Fragment>
        <FormGroupHeader label="Bank name" />
        <FormGroupInput
          name="name"
          type="text"
          id={'bank_name'}
          defaultValue={bank.name}
          onChange={this.setCustomBank}
        />
        {error && <FormGroupErrorMessage>{error}</FormGroupErrorMessage>}
        <FormLink text="Use the bank lookup instead" onClick={this.toggleManualEntry} />
      </Fragment>
    )
  }

  renderSelectedBank() {
    const { bank } = this.state
    const label = bank.name
    const microcopy = bank.trading_name || ''

    return (
      <Fragment>
        <Bank label={label} microcopy={microcopy} />
        <FormLink text="This isn't the correct bank" onClick={this.reset} />
      </Fragment>
    )
  }

  render() {
    const { selectionMade, manual } = this.state
    const { error } = this.props

    if (manual) {
      return this.renderManualEntry()
    } else if (selectionMade) {
      return this.renderSelectedBank()
    } else {
      return (
        <TypeAhead
          lookupFunction={bankLookup}
          label="Bank lookup"
          onSelection={this.setBankState}
          placeholder="Search by name (e.g. NatWest)"
          error={error}
        >
          <FormLink text="Enter the name manually instead" onClick={this.toggleManualEntry} />
        </TypeAhead>
      )
    }
  }
}

BankInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  bank: PropTypes.object,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default BankInput
