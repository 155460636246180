import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSwipeable } from 'react-swipeable'

import { Emoji } from '../../layout/Wrapper'
import EmojiImage from '../../images/EmojiImage'
import Stars from '../../../../assets/images/trustpilot/trustpilot-stars-4.5.svg'
import Logo from '../../../../assets/images/trustpilot/trustpilot-logo.svg'

import { animate, getNextIndex } from '../../../modules/carousel'
import { prefersReducedMotion } from '../../../modules/utils'
import { trackEvent } from '../../../modules/tracking'

const ANIMATION_DURATION = prefersReducedMotion() ? 1 : 500

export default function TrustpilotCarousel({ slides }) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [transition, setTransition] = useState(false)
  const isSingleSlide = slides.length === 1

  const slideTo = (nextIndex, direction) => {
    if (transition || nextIndex == activeIndex) {
      return
    }

    animate(
      () => {
        setTransition(`${direction}-out`)
      },
      () => {
        setActiveIndex(nextIndex)
        setTransition(`${direction}-in`)
      },
      () => {
        setTransition(null)
      },
      ANIMATION_DURATION
    )
  }

  const slide = direction => {
    return slideTo(getNextIndex(activeIndex, slides.length, direction), direction)
  }

  const onLinkToTrustpilot = () => {
    trackEvent('Clicked TrustPilot link')
  }

  return (
    <div
      className={`trustpilot-carousel ${isSingleSlide && 'trustpilot-carousel--single-slide'}`}
      {...useSwipeable({
        onSwipedLeft: () => slide('right'),
        onSwipedRight: () => slide('left')
      })}
    >
      <div className="trustpilot-carousel__wrap">
        <div className={`trustpilot-carousel__review trustpilot-carousel__transition--${transition}`}>
          <p
            className="trustpilot-carousel__review-title"
            dangerouslySetInnerHTML={{ __html: slides[activeIndex].title }}
          />
          <p
            className="trustpilot-carousel__review-copy"
            dangerouslySetInnerHTML={{ __html: slides[activeIndex].copy }}
          />
          <p
            className="trustpilot-carousel__review-person"
            dangerouslySetInnerHTML={{ __html: slides[activeIndex].person }}
          />
        </div>

        <div className="trustpilot-carousel__footer">
          <div className="trustpilot-carousel__trustpilot">
            <a
              onClick={() => onLinkToTrustpilot()}
              href="https://uk.trustpilot.com/review/riftrefunds.co.uk"
              target="_blank"
            >
              <img className="trustpilot-carousel__trustpilot-logo" src={Logo} alt="Trustpilot" />
            </a>
            <img className="trustpilot-carousel__trustpilot-stars" src={Stars} alt="4.5 stars: Excellent" />
            <p>
              TrustScore <strong>4.7</strong> <span>|</span>
              <b>
                <a
                  onClick={() => onLinkToTrustpilot()}
                  href="https://uk.trustpilot.com/review/riftrefunds.co.uk"
                  target="_blank"
                >
                  See our reviews
                </a>
              </b>
            </p>
          </div>

          {!isSingleSlide && (
            <nav className="trustpilot-carousel__nav">
              <ul>
                <li onClick={() => slide('left')} tabIndex="0">
                  <Emoji>
                    <EmojiImage name="arrow" alt="arrow" />
                  </Emoji>
                </li>
                <li onClick={() => slide('right')} className="trustpilot-carousel__nav-arrow--right" tabIndex="0">
                  <Emoji>
                    <EmojiImage name="arrow" alt="arrow" />
                  </Emoji>
                </li>
              </ul>
            </nav>
          )}
        </div>
      </div>
    </div>
  )
}

TrustpilotCarousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      copy: PropTypes.string,
      person: PropTypes.string
    })
  ).isRequired
}
