import React from 'react'
import PropTypes from 'prop-types'

class FormLink extends React.Component {
  render() {
    const { additionalClasses, onClick, text } = this.props
    const classes = ['link', 'link--sm', 'display-inline-block', 'mt-2'].concat(additionalClasses)
    return (
      <a className={classes.join(' ')} onClick={onClick} href="#">
        {text}
      </a>
    )
  }
}

FormLink.propTypes = {
  additionalClasses: PropTypes.array,
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}
export default FormLink
