import React from 'react'
import PropTypes from 'prop-types'

import { FormGroup } from '../../layout/Wrapper'

import Select from '../Select'

class AddressLookupAddressSelect extends React.Component {
  selectOptions() {
    const { deliveryPoints } = this.props

    return [['default', 'Select an address', true]].concat(
      deliveryPoints
        .map((option, index) => [
          index,
          [option.organisation_name, option.line_1, option.line_2, option.department_name].filter(Boolean).join(', ')
        ])
        .sort((a, b) => a[1].toUpperCase().localeCompare(b[1].toUpperCase()))
    )
  }

  render() {
    const { className, modifiers, onChange } = this.props

    return (
      <FormGroup className={className} modifiers={modifiers}>
        <div className="form-group__input select">
          <Select
            name="address-lookup-address-select"
            value="default"
            options={this.selectOptions()}
            onChange={onChange}
          />
        </div>
      </FormGroup>
    )
  }
}

AddressLookupAddressSelect.propTypes = {
  className: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  deliveryPoints: PropTypes.array.isRequired
}

export default AddressLookupAddressSelect
