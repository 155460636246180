import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { updatePageTitle } from '../../../modules/legacyUtils'
import { flatten } from '../../../modules/utils'

import { ActionList, ContentWrapper, ContentWrapperInner, TileList } from '../../layout/Wrapper'

import ClaimWizard from '../../interactive/ClaimWizard'
import Action from '../../interactive/Action'
import Tile from '../../display/Tile'

export default function Dashboard({ claimSummary, locations, expenses, documents }) {
  const submitHeading = (function (status) {
    switch (status) {
      case 'complete':
        return 'Claim complete'
      case 'in-progress':
        return 'Claim review started'
      default:
        return 'Added everything?'
    }
  })(claimSummary?.status)

  const submitText = (function (status) {
    switch (status) {
      case 'complete':
        return 'You can still submit updates for review.'
      case 'in-progress':
        return 'A Tax Specialist will be in touch soon.'
      default:
        return 'Submit your claim for review.'
    }
  })(claimSummary?.status)

  const locationsCount = flatten((locations || []).map(loc => loc.data)).length
  const expensesCount = flatten((expenses || []).map(loc => loc.data)).length
  const documentsCount = documents?.length || 0

  updatePageTitle(claimSummary?.greeting || 'Welcome')

  return (
    <Fragment>
      <ClaimWizard />
      <ContentWrapper>
        <ContentWrapperInner modifiers="stretched">
          <TileList modifiers="3-col">
            <Tile
              className="workplaces-tile"
              heading="Workplaces"
              to="/my_rift/work_locations"
              icon={{
                alt: 'Workplaces',
                name: 'pin'
              }}
              status={{
                modifier: locationsCount > 0 ? 'positive' : 'negative',
                label: `${locationsCount} location${locationsCount !== 1 ? 's' : ''}`
              }}
              modifiers="stretched"
              tileAction={{
                href: '/work_locations/new',
                imgAlt: 'Add',
                imgName: 'plusWhite'
              }}
            />
            <Tile
              className="expenses-tile"
              heading="Expenses"
              to="/my_rift/expenses"
              icon={{
                alt: 'Expenses',
                name: 'burger'
              }}
              status={{
                modifier: expensesCount > 0 ? 'positive' : 'negative',
                label: `${expensesCount} expense${expensesCount !== 1 ? 's' : ''}`
              }}
              modifiers="stretched"
              tileAction={{
                href: '/expenses/new',
                imgAlt: 'Add',
                imgName: 'plusWhite'
              }}
            />
            <Tile
              className="documents-tile"
              heading="Documents"
              href="/documents"
              icon={{
                alt: 'Documents',
                name: 'folder'
              }}
              status={{
                modifier: documentsCount > 0 ? 'positive' : 'negative',
                label: `${documentsCount} upload${documentsCount !== 1 ? 's' : ''}`
              }}
              modifiers="stretched"
            />
          </TileList>
        </ContentWrapperInner>
      </ContentWrapper>
      <ContentWrapper className="mt-2--sm-down mt-4--sm mt-4--md">
        <ContentWrapperInner>
          <ActionList small={true}>
            <Action
              heading="Your account"
              href="/users/1"
              icon={{
                alt: 'Your account',
                modifiers: ['sm'],
                name: 'key'
              }}
              text="Manage claims, defaults, and settings."
            />
            <Action
              heading={submitHeading}
              href="/review"
              icon={{
                alt: 'Added everythinng?',
                modifiers: ['sm'],
                name: 'hundred'
              }}
              text={submitText}
            />
          </ActionList>
        </ContentWrapperInner>
      </ContentWrapper>
    </Fragment>
  )
}

Dashboard.propTypes = {
  claimSummary: PropTypes.object,
  locations: PropTypes.arrayOf(PropTypes.object),
  expenses: PropTypes.arrayOf(PropTypes.object),
  documents: PropTypes.arrayOf(PropTypes.object)
}
