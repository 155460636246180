import React from 'react'
import PropTypes from 'prop-types'
import BasicDetailsForm from '../../interactive/BasicDetailsForm'

import { fetch, omit, redirectTo, highlightFirstError } from '../../../modules/utils'

class Edit extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: {},
      user: {},
      disabled: true
    }
    this.onCancel = this.onCancel.bind(this)
    this.onChange = this.onChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    fetch('GET', '/users/0.json').then(user =>
      this.setState({
        user,
        disabled: false
      })
    )
  }

  onCancel() {
    redirectTo(this.props.referrer)
  }

  onChange(attributeObject) {
    const { user, errors } = this.state
    this.setState({
      disabled: false,
      user: Object.assign(user, attributeObject),
      errors: omit(errors, Object.keys(attributeObject))
    })
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ disabled: true })
    const { user } = this.state
    const { referrer, authenticityToken } = this.props

    const formData = {
      user: user,
      utf8: '✓',
      authenticity_token: authenticityToken
    }

    fetch('PATCH', `/users/${user.id}`, 'application/json', 'application/json', formData)
      .then(() => {
        if (referrer) {
          redirectTo(referrer)
        }
      })
      .catch(errResponse => {
        const data = errResponse[1]
        console.error(data)
        this.setState(data, function () {
          highlightFirstError()
        })
      })
  }

  render() {
    const { modEmployee, trades } = this.props
    return (
      <BasicDetailsForm
        modEmployee={modEmployee}
        onCancel={this.onCancel}
        onChange={this.onChange}
        onSubmit={this.handleSubmit}
        trades={trades}
        {...this.state}
      />
    )
  }
}
Edit.propTypes = {
  authenticityToken: PropTypes.string,
  referrer: PropTypes.string,
  modEmployee: PropTypes.bool,
  trades: PropTypes.array
}
export default Edit
