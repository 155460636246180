const HEADER_BUTTON_CLASS = 'page-header__button'

export function updatePageTitle(titleText) {
  document.querySelector('.page-header__title').innerHTML = titleText
}

export function setHeaderAction(label, href) {
  if (document.querySelector('.page-header__button')) {
    return
  }

  document.querySelector('#page-header').insertAdjacentHTML(
    'beforeend',
    `<a class="${HEADER_BUTTON_CLASS} button button--delete button--small" href="${href}">
      <span class="button__inner">${label}</span>
    </a>`
  )
}

export function removeHeaderAction() {
  document.querySelector(`.${HEADER_BUTTON_CLASS}`)?.remove()
}

export function changeNavVisibility(visible) {
  const nav = document.querySelector('.masthead__nav')

  if (!nav) return

  nav.classList.toggle('hidden', !visible)
}
