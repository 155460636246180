import React from 'react'

import Icon from '../images/Icon'

export default function QuestionnaireBanner() {
  return (
    <div className="questionnaire__banner">
      <div className="questionnaire__banner-strip">
        <p>
          Takes <span>under a minute</span>
        </p>
      </div>
    </div>
  )
}
