import React from 'react'
import PropTypes from 'prop-types'

import ButtonGroupItem from '../interactive/ButtonGroupItem'

import { ButtonGroup } from './Wrapper'

function DrawerFooter({ onCancel, onConfirm, cancelLabel, confirmLabel }) {
  return (
    <footer className="drawer__footer">
      <ButtonGroup modifiers="spread">
        <ButtonGroupItem buttonModifiers="default" element="anchor" href="#" onClick={onCancel}>
          {cancelLabel}
        </ButtonGroupItem>
        <ButtonGroupItem buttonModifiers="primary" element="anchor" href="#" onClick={onConfirm}>
          {confirmLabel}
        </ButtonGroupItem>
      </ButtonGroup>
    </footer>
  )
}

DrawerFooter.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  cancelLabel: PropTypes.string.isRequired,
  confirmLabel: PropTypes.string.isRequired
}

export default DrawerFooter
