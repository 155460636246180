import React, { useState, useEffect } from 'react'
import { pageConfigForPathname } from '../../../modules/pages'
import { timelineDateRange, dateRangesForTimelineGaps } from '../../../modules/timelines'
import { fetchAndSetResources, confirmResource } from '../../../modules/api'

import { pick } from '../../../modules/utils'
import { setHeaderAction, removeHeaderAction } from '../../../modules/legacyUtils'

import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

import { ButtonGroup, ContentFooter, ContentWrapper, ContentWrapperInner, Grid } from '../../layout/Wrapper'
import ContentFooterLink from '../../interactive/ContentFooterLink'
import Dashboard from '../../pages/my_rift/Dashboard'
import AddressesIndex from '../../pages/my_rift/AddressesIndex'
import EmploymentsIndex from '../../pages/my_rift/EmploymentsIndex'
import WorkLocationsIndex from '../../pages/my_rift/WorkLocationsIndex'
import ExpensesIndex from '../../pages/my_rift/ExpensesIndex'
import VehiclesIndex from '../../pages/my_rift/VehiclesIndex'
import ReviewTimelinesIndex from '../../pages/dashboard/ReviewTimelinesIndex'
import Timeline from '../../display/Timeline'
import IdleHelpPanel from '../../interactive/IdleHelpPanel'

function MyRIFT() {
  const location = useLocation()
  const [resources, setResources] = useState(null)

  const {
    timelineResource,
    resourcesRequired,
    prevHref,
    name,
    nextHref,
    showFooter,
    showGrid,
    showTimeline,
    showWrapper,
    showIdleUserPanel,
    href
  } = pageConfigForPathname(location.pathname)

  useEffect(() => {
    const { state } = location
    if (state?.prevPathname && state.prevPathname === prevHref) {
      confirmResource(name)
    }
  }, [location])

  fetchAndSetResources(resourcesRequired, setResources, resources)

  const [timelineStartDate, timelineEndDate] = timelineDateRange()
  const gaps = dateRangesForTimelineGaps(resources?.timelineGaps, timelineResource)

  const loaded = !!Object.keys(pick(resources, resourcesRequired)).length
  const showTrackMyClaimLink = name === 'dashboard' && resources?.claimSummary?.trackable

  if (showTrackMyClaimLink) {
    setHeaderAction('Track my claim', '/dashboard/claim_tracker')
  } else {
    removeHeaderAction()
  }

  return (
    <ConfigureableContentWrapper
      loaded={loaded}
      prevHref={prevHref}
      nextHref={nextHref}
      href={href}
      showFooter={showFooter}
      showGrid={showGrid}
      showWrapper={showWrapper}
    >
      <Routes>
        <Route
          path="/my_rift/dashboard"
          element={
            <Dashboard
              claimSummary={resources?.claimSummary}
              locations={resources?.locations}
              expenses={resources?.expenses}
              documents={resources?.documents}
              claimStatus={'null'}
            />
          }
        />
        <Route
          path="/my_rift/addresses"
          element={
            <AddressesIndex
              lead="Complete a full address history to help us to calculate any claimable journeys you made for work."
              addresses={resources?.addresses}
              startDate={timelineStartDate}
              endDate={timelineEndDate}
              gaps={gaps}
            />
          }
        />
        <Route
          path="/my_rift/timelines/addresses"
          element={
            <AddressesIndex
              lead={
                resources?.addresses?.length
                  ? `We need to know <strong>where you've lived</strong> from ${timelineStartDate.format(
                      'MMMM YYYY'
                    )} until now.`
                  : "<strong>Welcome to MyRIFT</strong>, and your first claim. Let's start by taking your current home address."
              }
              addresses={resources?.addresses}
              startDate={timelineStartDate}
              endDate={timelineEndDate}
              gaps={gaps}
            />
          }
        />
        <Route
          path="/my_rift/employments"
          element={
            <EmploymentsIndex
              lead={`We need to know <strong>who you've worked for</strong> from ${timelineStartDate.format(
                'MMMM YYYY'
              )} until now.`}
              employments={resources?.employments}
              startDate={timelineStartDate}
              endDate={timelineEndDate}
              gaps={gaps}
            />
          }
        />
        <Route
          path="/my_rift/timelines/employments"
          element={
            <EmploymentsIndex
              lead={`Next, we need to know <strong>who you've worked for</strong> from ${timelineStartDate.format(
                'MMMM YYYY'
              )} until now.`}
              employments={resources?.employments}
              startDate={timelineStartDate}
              endDate={timelineEndDate}
              gaps={gaps}
            />
          }
        />

        <Route
          path="/my_rift/work_locations"
          element={
            <WorkLocationsIndex
              lead="Tell us about <strong>the locations you worked at</strong> during your employments."
              locationsGroupedByEmployment={resources?.locations}
              startDate={timelineStartDate}
              endDate={timelineEndDate}
              gaps={gaps}
            />
          }
        />

        <Route
          path="/my_rift/timelines/work_locations"
          element={
            <WorkLocationsIndex
              lead="Tell us about <strong>the locations you worked at</strong> during these employments."
              locationsGroupedByEmployment={resources?.locations}
              startDate={timelineStartDate}
              endDate={timelineEndDate}
              gaps={gaps}
            />
          }
        />

        <Route
          path="/my_rift/expenses"
          element={<ExpensesIndex expenses={resources?.expenses} locationsGroupedByEmployment={resources?.locations} />}
        />

        <Route
          path="/my_rift/timelines/expenses"
          element={<ExpensesIndex expenses={resources?.expenses} locationsGroupedByEmployment={resources?.locations} />}
        />

        <Route
          path="/my_rift/vehicles"
          element={<VehiclesIndex startDate={timelineStartDate} vehicles={resources?.vehicles} />}
        />
        <Route path="/my_rift/timelines/review" element={<ReviewTimelinesIndex />} />
      </Routes>
      {showTimeline && (
        <Timeline
          loaded={!!resources?.timelineGaps}
          startDate={timelineStartDate}
          endDate={timelineEndDate}
          gaps={gaps}
        />
      )}
      {showIdleUserPanel && <IdleHelpPanel page={name} location={location} />}
    </ConfigureableContentWrapper>
  )
}

function ConfigurableContentFooter({ prevHref, nextHref, href }) {
  if (prevHref || nextHref) {
    return (
      <ContentFooter modifiers="actions">
        <ButtonGroup modifiers="spread">
          <Link className="button button-group__item button--default" to={prevHref || '/'} disabled={!prevHref}>
            Back
          </Link>
          <Link
            className="button button-group__item button--primary"
            to={{ pathname: nextHref || '/', state: { prevPathname: href } }}
            disabled={!nextHref}
          >
            Next step
          </Link>
        </ButtonGroup>
      </ContentFooter>
    )
  }

  return (
    <ContentFooter>
      <ContentFooterLink to="/my_rift/dashboard">Back to Home</ContentFooterLink>
    </ContentFooter>
  )
}

function ConfigureableContentWrapper({ children, showGrid, showFooter, showWrapper, prevHref, nextHref, href }) {
  if (!showWrapper && !showGrid) {
    return children
  }

  if (showGrid) {
    return <Grid modifiers={['simple']}>{children}</Grid>
  }

  return (
    <ContentWrapper modifiers="small">
      <ContentWrapperInner modifiers="longform" className="timelines">
        {children}
      </ContentWrapperInner>
      {showFooter && <ConfigurableContentFooter prevHref={prevHref} nextHref={nextHref} href={href} />}
    </ContentWrapper>
  )
}

export default function RouterWrapper() {
  return (
    <Router>
      <MyRIFT />
    </Router>
  )
}
