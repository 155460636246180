import React from 'react'
import PropTypes from 'prop-types'

import { methodCallFromString, inputName, inputId, omit, pick } from '../../modules/utils'

class RadioOption extends React.Component {
  constructor(props) {
    super(props)
    this.handleLabelClick = this.handleLabelClick.bind(this)
  }

  additionalAttributes() {
    let { additionalAttributes } = this.props
    if (additionalAttributes && additionalAttributes.onClick) {
      additionalAttributes.onClick = methodCallFromString(additionalAttributes.onClick)
    }
    return additionalAttributes
  }

  input() {
    return (
      <input
        className="option-group__radio"
        placeholder="false"
        hint="false"
        type="radio"
        name={inputName.apply(this)}
        id={inputId.apply(this)}
        readOnly={true}
        {...pick(this.props, ['value', 'checked'])}
      />
    )
  }

  handleLabelClick(e) {
    e.preventDefault()

    const { additionalAttributes, disabled, value, onChange } = this.props

    if (disabled) {
      return
    }

    if (additionalAttributes && additionalAttributes.onClick) {
      const onClick = methodCallFromString(additionalAttributes.onClick)
      onClick(e)
    }

    onChange(value)
  }

  label() {
    const { label, additionalAttributes, disabled } = this.props

    return (
      <label
        className={`option-group__label ${disabled ? 'option-group__label--disabled' : ''}`}
        htmlFor={inputId.apply(this)}
        onClick={this.handleLabelClick}
        {...omit(additionalAttributes, 'onClick')}
      >
        <span className="option-group__label__text">
          <span className="option-group__label__text__heading">{label}</span>
        </span>
        <span className="option-group__label__indicator" />
      </label>
    )
  }

  render() {
    return (
      <React.Fragment>
        {this.input()}
        {this.label()}
      </React.Fragment>
    )
  }
}

RadioOption.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  resourceName: PropTypes.string,
  onChange: PropTypes.func,
  additionalAttributes: PropTypes.object,
  label: PropTypes.string,
  checked: PropTypes.bool,
  disabled: PropTypes.bool
}

RadioOption.defaultProps = {
  disabled: false
}

export default RadioOption
