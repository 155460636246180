import { fetch } from './utils'

export function trackEvent(name, data) {
  const postData = { event: { name, data } }
  return fetch('POST', '/events', 'application/json', null, postData).catch(() => {
    const windowMixpanelPresent = window.myRIFT.helpers.windowMixpanelPresent()
    if (windowMixpanelPresent) {
      window.mixpanel.track(name, data)
      return true
    }
  })
}
