import React from 'react'
import PropTypes from 'prop-types'

const Collection = function ({ media, heading, text }) {
  return (
    <div className="collection">
      {media && (
        <div className="collection__media">
          <img className="collection__image" alt={heading} src={media.src} id={media.id} />
        </div>
      )}
      <div className="collection__content">
        <h4 className="collection__heading">{heading}</h4>
        {text && <p className="collection__text">{text}</p>}
      </div>
    </div>
  )
}

Collection.propTypes = {
  media: PropTypes.shape({
    src: PropTypes.string,
    id: PropTypes.string
  }),
  heading: PropTypes.string,
  text: PropTypes.string
}

export default Collection
