import React from 'react'
import PropTypes from 'prop-types'

import Modal from '../display/Modal'

import { methodCallFromString, omit } from '../../modules/utils'
import { trackEvent } from '../../modules/tracking'

class ActionsModal extends React.Component {
  constructor(props) {
    super(props)
  }

  handleOnClick(e, action) {
    e.preventDefault()

    // If the action is a method, call it
    const actionOnClick = methodCallFromString(action.onClick)
    if (typeof actionOnClick !== 'undefined' && actionOnClick !== false) {
      actionOnClick(e)
    }

    // If there's an event to track, track it and then follow any links.
    if (typeof action.trackingEvent !== 'undefined') {
      trackEvent(action.trackingEvent).then(() => {
        // If there's a target, follow it
        if (action.target) {
          window.open(action.href, action.target)
          return
        }
        window.location = action.href
      })
    } else {
      window.location = action.href
    }
  }

  footer() {
    const { actions } = this.props
    if (!actions || !actions.length) return null
    return (
      <footer className="actions-modal__footer">
        {actions.map((action, index) => (
          <a
            className="actions-modal__action"
            key={`a-${index}`}
            onClick={e => this.handleOnClick(e, action)}
            {...omit(action, ['onClick', 'trackingEvent'])}
          >
            <span className="actions-modal__action__text">{action.label}</span>
          </a>
        ))}
      </footer>
    )
  }

  render() {
    const { id, title, body, displayed, onClose } = this.props

    return (
      <Modal id={id} onClose={onClose} classNames={'actions-modal'} displayed={displayed}>
        {title && (
          <header className="actions-modal__header">
            <h2 className="actions-modal__title">{title}</h2>
          </header>
        )}
        <div className="actions-modal__body" dangerouslySetInnerHTML={{ __html: body }} />
        {this.footer()}
      </Modal>
    )
  }
}

ActionsModal.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  displayed: PropTypes.bool,
  body: PropTypes.string,
  actions: PropTypes.array,
  onClose: PropTypes.func
}

export default ActionsModal
