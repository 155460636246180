import React, { Fragment, useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

import Question from './Question'
import ButtonGroupItem from './ButtonGroupItem'

import Selection from './embedded/Selection'
import LocationInput from './LocationInput'
import TextQuestion from './TextQuestion'
import UnstructuredFrequencyInput from './UnstructuredFrequencyInput'
import UnstructuredDurationInput from './UnstructuredDurationInput'
import DateRangeInput from './DateRangeInput'
import CheckBoxGroupInput from './CheckBoxGroupInput'
import HelperTrigger from '../display/HelperTrigger'
import Helper from '../display/Helper'
import YesNoQuestion from './YesNoQuestion'
import DateQuestion from './date/DateQuestion'
import OptionalStepper from '../interactive/OptionalStepper'

import helperTextData from '../../data/helperTextData.json'

import { ButtonGroup, ContentFooter, ContentWrapper, ContentWrapperInner, FormGroup } from '../layout/Wrapper'

import { findBy, includes } from '../../modules/utils'
import { getRangeDuration, dateRangeString, inDateRange, parseDate } from '../../modules/dates'
import { defaultStateForEmployment } from '../../modules/workLocation'
import { trackEvent } from '../../modules/tracking'

const TRANSPORT_OPTIONS = [
  { label: 'Own Vehicle', value: 'own_vehicle', checked: false },
  { label: 'Public Transport', value: 'public_transport', checked: false },
  { label: 'Car Share', value: 'car_share', checked: false },
  { label: 'Company Vehicle', value: 'company_vehicle', checked: false },
  { label: 'Pickup Point', value: 'pickup_point', checked: false },
  { label: 'Other', value: 'other', checked: false }
]
const INITIAL_REIMBURSEMENTS_QUANTITY = 1

export default function WorkLocationForm({
  employments,
  errors,
  onCancel,
  onChange,
  onSubmit,
  onShowEmploymentForm,
  onShowVehicleForm,
  resource,
  vehicles
}) {
  const [editableEmployments, setEditableEmployments] = useState(false)
  const [editableVehicles, setEditableVehicles] = useState(false)
  const [receivedReimbursements, setReceivedReimbursements] = useState(resource.received_reimbursements)
  const [reimbursementsQuantity, setReimbursementsQuantity] = useState(
    resource.reimbursements_quantity || INITIAL_REIMBURSEMENTS_QUANTITY
  )
  const lastTrackingEventRef = useRef(null)
  const {
    current,
    person_employment_id,
    address,
    person_vehicle_id,
    transport,
    ministry_of_defence,
    expected_time_at_post,
    expected_time_at_post_years,
    expected_time_at_post_months,
    expected_time_at_post_weeks,
    complex_routine,
    quantitative_frequency,
    qualitative_frequency,
    travel_home_frequency,
    travel_home_quantitative_frequency,
    travel_home_qualitative_frequency,
    locked,
    ended_at_locked,
    name,
    started_at,
    ended_at
  } = resource
  const { own_vehicle, company_vehicle } = transport

  useEffect(() => {
    if (!receivedReimbursements) {
      setReimbursementsQuantity(null)
      handleChange({ reimbursements_quantity: null })
    }
  }, [receivedReimbursements])

  // Only track each event type once per interaction
  const trackEventOnce = type => {
    if (lastTrackingEventRef.current === type) {
      return
    }

    lastTrackingEventRef.current = type
    trackEvent(`Work location ${type} filled in`)
  }

  const durationString = (years, months) => {
    if (!started_at) {
      return null
    }

    const yearBit = years ? (years > 1 ? `${years} years` : '1 year') : ''
    const includeAnd = years && months
    let monthBit = ''
    if (months || !years) {
      monthBit = months > 1 || months === 0 ? `${months} months` : '1 month'
    }
    return `You worked here for <strong>${yearBit}${includeAnd ? ' and ' : ''}${monthBit}</strong>`
  }

  const selectedEmployment = findId => {
    return findBy(employments, 'id', findId || person_employment_id)
  }

  const transportOptions = () => {
    return TRANSPORT_OPTIONS.map(option => {
      option.checked = transport[option.value]
      return option
    })
  }

  const addressErrors = () => {
    const result = errors['address'] || {}

    if (errors['address_id']) {
      result['address_id'] = errors['address_id']
    }

    return result
  }

  const routineError = () => {
    return (errors['travel_home_frequency'] || errors['complex_routine'] || [])[0] || null
  }

  const showExpectationChangeQuestion = locationAttributes => {
    const { started_at, ended_at, expected_time_at_post_years, expected_time_at_post_months, ministry_of_defence } =
      locationAttributes

    const rangeDuration = getRangeDuration(parseDate(started_at), parseDate(ended_at, new Date()))

    return (
      (ministry_of_defence && rangeDuration.years !== expected_time_at_post_years) ||
      rangeDuration.months !== expected_time_at_post_months
    )
  }

  const expectedStayError = () => {
    return (
      (errors['expected_time_at_post'] ||
        (errors['expected_time_at_post_years'] ? [`Years ${errors['expected_time_at_post_years']}`] : null) ||
        (errors['expected_time_at_post_months'] ? [`Months ${errors['expected_time_at_post_months']}`] : null) ||
        (errors['expected_time_at_post_weeks'] ? [`Weeks ${errors['expected_time_at_post_weeks']}`] : null) ||
        [])[0] || null
    )
  }

  const attachLocalErrors = (newAttributes, nextLocationData, nextLocationErrors) => {
    const updatedAttributeNames = Object.keys(newAttributes)
    const employmentUpdated = includes(updatedAttributeNames, 'person_employment_id')
    const startedAtUpdated = includes(updatedAttributeNames, 'started_at')
    const endedAtUpdated = includes(updatedAttributeNames, 'ended_at')
    const expectedTimeAtPostChangedAtUpdated = includes(updatedAttributeNames, 'expected_time_at_post_changed_at')
    const { person_employment_id, started_at, ended_at, current, expected_time_at_post_changed_at } = nextLocationData

    const employment = selectedEmployment(person_employment_id)
    const startedAt = parseDate(started_at)
    const endedAt = parseDate(ended_at, new Date())
    const expectedTimeAtPostChangedAt = parseDate(expected_time_at_post_changed_at)

    if (employment?.option_data) {
      const { label, microcopy } = employment.option_data
      const employmentStartedAt = parseDate(employment.started_at)
      const employmentEndedAt = parseDate(employment.ended_at, new Date())

      // Notify user about issues with start date:
      if (startedAt && (employmentUpdated || startedAtUpdated)) {
        if (!inDateRange(startedAt, employmentStartedAt, employmentEndedAt)) {
          nextLocationErrors['started_at'] = [
            `The "from" date should be during your ${label} employment (${microcopy}).`
          ]
        } else {
          nextLocationErrors['started_at'] = null
        }
      }

      // Notify user about issues with end date:
      if (endedAt && !current && (employmentUpdated || endedAtUpdated)) {
        if (!inDateRange(endedAt, employmentStartedAt, employmentEndedAt)) {
          nextLocationErrors['ended_at'] = [
            `The "until" date should be during your ${label} employment (${microcopy}).`
          ]
        } else {
          nextLocationErrors['ended_at'] = null
        }
      }
    }

    // Notify user about any issues with the expected time at post changed date:
    if (expectedTimeAtPostChangedAt && expectedTimeAtPostChangedAtUpdated) {
      if (!inDateRange(expectedTimeAtPostChangedAt, startedAt, endedAt)) {
        nextLocationErrors['expected_time_at_post_changed_at'] = [
          `This date should be during your time at this location (${dateRangeString(startedAt, endedAt)}).`
        ]
      } else {
        nextLocationErrors['expected_time_at_post_changed_at'] = null
      }
    }

    // Clear any errors on the vehicle if the user de-selects the vehicle as
    // a transport option.
    if (!nextLocationData.transport.own_vehicle) {
      nextLocationErrors['person_vehicle_id'] = null
    }
  }

  const updateLinkedAttributes = (newAttributes, nextLocationData) => {
    if (!showExpectationChangeQuestion(nextLocationData)) {
      nextLocationData['expected_time_at_post_changed_at'] = null
    }
  }

  const handleChange = newAttributes => {
    const nextLocationData = { ...resource, ...newAttributes }
    const nextLocationErrors = { ...errors }
    Object.keys(newAttributes).forEach(key => (nextLocationErrors[key] = null))
    attachLocalErrors(newAttributes, nextLocationData, nextLocationErrors)
    updateLinkedAttributes(newAttributes, nextLocationData)
    onChange(nextLocationData, nextLocationErrors)
  }

  const handleTransportChange = (transportMethod, checked) => {
    trackEventOnce('transport')
    handleChange({
      transport: {
        ...resource.transport,
        [transportMethod]: checked
      }
    })
  }

  const handleAddressChange = address => {
    trackEventOnce('address')
    handleChange({
      address,
      address_id: null
    })
  }

  const handleDateChange = (attribute, date, current) => {
    trackEventOnce('dates')
    handleChange({
      [attribute]: date,
      current
    })
  }

  const handleExpectedTimeAtPostChange = (years, months, weeks) => {
    trackEventOnce('expected stay')
    handleChange({
      expected_time_at_post: null,
      expected_time_at_post_years: years,
      expected_time_at_post_months: months,
      expected_time_at_post_weeks: weeks
    })
  }

  const handleExpectedTimeAtPostChangedAtChange = newAttributes => {
    trackEventOnce('expected stay changed at')
    handleChange(newAttributes)
  }

  const handleUnstructuredFrequencyChange = value => {
    trackEventOnce(ministry_of_defence ? 'home visits' : 'routine')
    handleChange({
      travel_home_qualitative_frequency: null,
      travel_home_quantitative_frequency: null,
      qualitative_frequency: null,
      quantitative_frequency: null,
      travel_home_frequency: ministry_of_defence ? value : null,
      complex_routine: ministry_of_defence ? null : value
    })
  }

  const handleStructuredFrequencyChange = (quantitativeFrequency, qualitativeFrequency) => {
    trackEventOnce(ministry_of_defence ? 'home visits' : 'routine')
    handleChange({
      travel_home_frequency: null,
      complex_routine: null,
      travel_home_quantitative_frequency: ministry_of_defence ? quantitativeFrequency : null,
      travel_home_qualitative_frequency: ministry_of_defence ? qualitativeFrequency : null,
      quantitative_frequency: ministry_of_defence ? null : quantitativeFrequency,
      qualitative_frequency: ministry_of_defence ? null : qualitativeFrequency
    })
  }

  const handleEmploymentChange = (key, id) => {
    trackEventOnce('employment')
    const employmentId = parseInt(id, 10)
    handleChange(defaultStateForEmployment(employmentId, selectedEmployment(employmentId)?.option_data?.isMOD))
  }

  const handleVehicleChange = (key, id) => {
    trackEventOnce('vehicle')
    handleChange({ person_vehicle_id: parseInt(id, 10) })
  }

  const handleNameChange = (key, name) => {
    trackEventOnce('memorable name')
    handleChange({ name: name })
  }

  const handleReceivedReimbursementsChange = value => {
    trackEventOnce('received reimbursements')
    setReceivedReimbursements(value.received_reimbursements)
    handleChange({ received_reimbursements: value.received_reimbursements })
  }

  const handleReimbursementsQuantityChange = value => {
    if (value === null || value > 0) {
      setReimbursementsQuantity(value)
      handleChange({ reimbursements_quantity: value })
    }
  }

  const rangeDuration = getRangeDuration(parseDate(started_at), parseDate(ended_at, new Date()))

  const routineHeading = ministry_of_defence ? 'Home visits' : 'Routine'
  const routineText = ministry_of_defence
    ? 'How often did you make the journey home?'
    : 'How often did you work at this location?'
  const modExpectedStayText = 'How long did you initially expect to stay at this location?'
  const datesText = 'When did you work at this location?'
  const employmentText = 'Who were you working for at this location?'
  const locationText = 'Where were you working?'
  const travelText = 'How did you get to and from this workplace?'
  const unstructuredRoutine = ministry_of_defence ? travel_home_frequency : complex_routine
  const quantitativeFrequency = ministry_of_defence ? travel_home_quantitative_frequency : quantitative_frequency
  const qualitativeFrequency = ministry_of_defence ? travel_home_qualitative_frequency : qualitative_frequency

  const deprecatedOptions = ['Every day', 'Other']
  const showDeprecatedOptions =
    includes(deprecatedOptions, travel_home_qualitative_frequency) || includes(deprecatedOptions, qualitative_frequency)

  const routineHelper = ministry_of_defence
    ? helperTextData.workplaces.mod.home_visits
    : helperTextData.workplaces.non_mod.routine

  return (
    <ContentWrapper modifiers="small">
      <ContentWrapperInner modifiers="longform">
        <FormGroup modifiers={errors['person_employment_id'] ? 'error' : null}>
          <Helper
            id="helper-employment"
            body="For RIFT to submit your claim, HMRC need to know who you were working for at this location. You'll be able to find the exact name of your employer on either your payslips or your contract of employment. "
          />
          <Question heading="Employment" text={employmentText}>
            <HelperTrigger
              helperId="helper-employment"
              eventLabel={`the '${employmentText}' question on the Work Location Form`}
            />
          </Question>
          <Selection
            value={person_employment_id}
            name="person_employment_id"
            resourceName="api_person_location"
            editText={editableEmployments ? 'Cancel' : 'Edit employers'}
            editClick={() => setEditableEmployments(!editableEmployments)}
            editAction={editableEmployments ? onShowEmploymentForm : false}
            addText="Add new employer"
            addClick={() => onShowEmploymentForm()}
            disabled={locked}
            options={employments.map(emp => emp.option_data).filter(emp => emp)}
            handlePropagation={handleEmploymentChange}
            error={errors['person_employment_id']}
          />
        </FormGroup>
        <hr />
        <FormGroup modifiers={['address', errors['address_id'] ? 'error' : null]}>
          <Helper
            id="helper-location"
            body={ministry_of_defence ? helperTextData.workplaces.mod.base : helperTextData.workplaces.non_mod.location}
          />
          <Question heading="Location" text={locationText}>
            <HelperTrigger
              helperId="helper-location"
              eventLabel={`the '${locationText}' question on the Work Location Form`}
            />
          </Question>
          <LocationInput
            onChange={handleAddressChange}
            address={address}
            errors={addressErrors()}
            ministryOfDefence={ministry_of_defence}
            disabled={locked}
          />
        </FormGroup>
        <hr />
        <FormGroup modifiers={errors['started_at'] || errors['ended_at'] ? 'error' : null}>
          <Helper id="helper-dates" body={helperTextData.workplaces.non_mod.dates} />
          <Question heading="Dates" text={datesText}>
            <HelperTrigger helperId="helper-dates" eventLabel={`'${datesText}' question on the Work Location Form`} />
          </Question>
          <DateRangeInput
            resourceName="api_person_location"
            resource={resource}
            includeDay={true}
            fromError={errors['started_at']}
            toError={errors['ended_at']}
            currentChecked={current}
            currentText="I still work here"
            fromDisabled={locked}
            toDisabled={ended_at_locked}
            handlePropagation={handleDateChange}
            helperText={durationString(rangeDuration.years, rangeDuration.months)}
          />
        </FormGroup>
        <hr />
        {ministry_of_defence && (
          <Fragment>
            <FormGroup modifiers={['expected-stay', expectedStayError() ? 'error' : null]}>
              <Helper
                id="helper-expected-stay"
                body={helperTextData.workplaces.mod.expected_stay}
                additionalContent={`<p class="helper__text"><b>You'll be able to find your original posting dates on your assignment order.</b></p>`}
              />
              <Question heading="Expected stay" text={modExpectedStayText}>
                <HelperTrigger
                  helperId="helper-expected-stay"
                  eventLabel={`the '${modExpectedStayText}' question on the Work Location Form`}
                />
              </Question>
              <UnstructuredDurationInput
                error={expectedStayError()}
                textValue={expected_time_at_post}
                years={expected_time_at_post_years || 0}
                months={expected_time_at_post_months || 0}
                weeks={expected_time_at_post_weeks || 0}
                question={modExpectedStayText}
                onChange={handleExpectedTimeAtPostChange}
                disabled={locked}
              />
            </FormGroup>
            {showExpectationChangeQuestion(resource) && (
              <DateQuestion
                questionHeading="Expectation change"
                questionHeadingSmall={true}
                questionText="When did you find out your expected stay had changed?"
                helperText={helperTextData.workplaces.mod.expected_stay_changed}
                minDate={new Date(started_at)}
                resource={resource}
                resourceName="location"
                name="expected_time_at_post_changed_at"
                error={errors['expected_time_at_post_changed_at']}
                onChange={handleExpectedTimeAtPostChangedAtChange}
                includeDay={true}
                disabled={locked}
              />
            )}
            <hr />
          </Fragment>
        )}
        <FormGroup modifiers={['routine', routineError() ? 'error' : null]}>
          <Helper id="helper-routine" body={routineHelper} />
          <Question heading={routineHeading} text={routineText}>
            <HelperTrigger
              helperId="helper-routine"
              eventLabel={`the '${routineText}' question on the Work Location Form`}
            />
          </Question>
          <UnstructuredFrequencyInput
            onUnstructuredValueChange={handleUnstructuredFrequencyChange}
            onStructuredValueChange={handleStructuredFrequencyChange}
            error={routineError()}
            textValue={unstructuredRoutine}
            quantitativeFrequency={quantitativeFrequency}
            qualitativeFrequency={qualitativeFrequency}
            disabled={locked}
            showDeprecatedOptions={showDeprecatedOptions}
          />
        </FormGroup>
        <hr />
        <FormGroup modifiers={errors['transport'] ? 'error' : null}>
          <Helper id="helper-travel" body={helperTextData.workplaces.non_mod.travel} />
          <Question heading="Travel" text={travelText}>
            <HelperTrigger
              helperId="helper-travel"
              eventLabel={`the '${travelText}' question on the Work Location Form`}
            />
          </Question>
          <CheckBoxGroupInput
            disabled={locked}
            error={errors['transport']}
            modifiers="columns"
            onChange={handleTransportChange}
            options={transportOptions()}
          />
        </FormGroup>
        {(own_vehicle || company_vehicle) && (
          <FormGroup modifiers={['vehicle', errors['person_vehicle_id'] ? 'error' : null]}>
            <Question heading="Your vehicle" text="Which vehicle did you use to travel here?" small={true} />
            <Selection
              value={person_vehicle_id}
              name="person_vehicle_id"
              resourceName="api_person_location"
              editText={editableVehicles ? 'Cancel' : 'Edit vehicles'}
              editClick={() => setEditableVehicles(!editableVehicles)}
              editAction={editableVehicles ? onShowVehicleForm : false}
              addText="Add new vehicle"
              addClick={() => onShowVehicleForm()}
              disabled={locked}
              options={vehicles}
              handlePropagation={handleVehicleChange}
              error={errors['person_vehicle_id']}
            />
          </FormGroup>
        )}
        <hr />
        {ministry_of_defence ? (
          <FormGroup>
            <YesNoQuestion
              resourceName="location"
              resource={resource}
              checked={receivedReimbursements}
              name="received_reimbursements"
              questionHeading="Reimbursements"
              questionText="Did you receive any warrants towards your travel to this base?"
              helperText={helperTextData.workplaces.mod.warrants}
              onChange={handleReceivedReimbursementsChange}
              disabled={locked}
            />
            {receivedReimbursements && (
              <>
                <OptionalStepper
                  className="stepper--reimbursements-quantity"
                  minValue={INITIAL_REIMBURSEMENTS_QUANTITY}
                  name="reimbursements_quantity"
                  onChange={handleReimbursementsQuantityChange}
                  questionText="How many did you receive?"
                  resourceName="location"
                  value={reimbursementsQuantity}
                />
              </>
            )}
          </FormGroup>
        ) : (
          <TextQuestion
            autoComplete="off"
            questionHeading="Name"
            questionText="Give this workplace a meaningful name to help jog your memory later."
            label="Workplace name"
            helperText={helperTextData.workplaces.non_mod.memorable_name}
            resourceName="api_person_location"
            resource={resource}
            name="name"
            handlePropagation={handleNameChange}
            error={errors['name']}
            placeHolder="e.g. 'Wembley Stadium' or 'Paddington Tube'"
            value={name}
            disabled={locked}
          />
        )}
      </ContentWrapperInner>
      <ContentFooter modifiers="actions">
        <ButtonGroup modifiers="spread">
          <ButtonGroupItem buttonModifiers="default" element="anchor" href="#" onClick={onCancel}>
            Cancel
          </ButtonGroupItem>
          <ButtonGroupItem
            buttonModifiers="primary"
            element="anchor"
            href="#"
            onClick={onSubmit}
            disabled={locked && ended_at_locked}
          >
            Save workplace
          </ButtonGroupItem>
        </ButtonGroup>
      </ContentFooter>
    </ContentWrapper>
  )
}

WorkLocationForm.propTypes = {
  onShowEmploymentForm: PropTypes.func.isRequired,
  onShowVehicleForm: PropTypes.func.isRequired,
  employments: PropTypes.arrayOf(PropTypes.object),
  vehicles: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired
}

WorkLocationForm.defaultProps = {
  errors: {}
}
