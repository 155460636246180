import React from 'react'
import PropTypes from 'prop-types'

class Modal extends React.Component {
  constructor(props) {
    super(props)

    this.closeModal = this.closeModal.bind(this)
  }

  closeModal(e) {
    const { onClose } = this.props
    const classList = e.target.classList

    if (
      classList.contains('modal__overlay') ||
      classList.contains('modal__overlay__container') ||
      classList.contains('modal__overlay__close') ||
      classList.contains('modal__close')
    ) {
      e.target.closest('.modal--visible').classList.remove('modal--visible')
      if (onClose) {
        onClose(e)
      }
    }
  }

  render() {
    const { children, id, displayed, classNames, padding } = this.props

    return (
      <div className={`modal ${displayed ? 'modal--visible' : ''}`} id={id}>
        <div className="modal__overlay">
          <div className="modal__overlay__container">
            <div className="modal__overlay__content">
              <div className={`${classNames ? classNames : ''} ${padding ? 'modal--padded' : ''}`}>{children}</div>
              <button className="modal__overlay__close" onClick={this.closeModal} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Modal.defaultProps = {
  padding: false
}

Modal.propTypes = {
  id: PropTypes.string,
  displayed: PropTypes.bool,
  onClose: PropTypes.func
}

export default Modal
