import React from 'react'
import PropTypes from 'prop-types'

import FormLink from './FormLink'
import FormGroupHeader from './FormGroupHeader'
import Question from './Question'
import Collection from './Collection'
import TextQuestion from './TextQuestion'
import ErrorSpan from './ErrorSpan'
import FilteredSelect from './FilteredSelect'
import AnchorButton from './AnchorButton'
import Helper from '../display/Helper'

import { FormGroup, FormGroupCombo, FormGroupComboItem } from '../layout/Wrapper'

import { fetch, propagateValue } from '../../modules/utils'

class VehicleLookup extends React.Component {
  constructor(props) {
    super(props)
    this.state = { manualEntry: false, lookupError: false }
    this.regInputRef = React.createRef()
    this.lookupInputRef = React.createRef()
    this.runLookup = this.runLookup.bind(this)
    this.manualEntry = this.manualEntry.bind(this)
    this.retryLookup = this.retryLookup.bind(this)
    this.inputChanged = this.inputChanged.bind(this)
    this.handlePropagation = this.handlePropagation.bind(this)
  }

  popularMakes(kind) {
    if (kind === 'Motorcycle') {
      return ['YAMAHA', 'HONDA', 'SUZUKI', 'TRIUMPH', 'LEXMOTO', 'BMW', 'PIAGGIO', 'Other']
    }
    return ['VAUXHALL', 'FORD', 'VOLKSWAGEN', 'BMW', 'AUDI', 'RENAULT', 'PEUGEOT', 'Other']
  }

  showCollection() {
    const { vehicle } = this.props
    return vehicle && vehicle.kind && vehicle.make && vehicle.model
  }

  showRegInput() {
    const { vehicle } = this.props
    return this.showCollection() && !vehicle.registration
  }

  runLookup() {
    const vrm = this.lookupInputRef.current.value
    fetch('GET', `/vehicle/lookup?vrm=${vrm}`)
      .then(data => {
        this.props.propagateData(data)
      })
      .catch(errResponse => {
        const data = errResponse[1]
        console.error(data)
        this.setState({
          lookupError: true
        })
      })
  }

  inputChanged() {
    this.setState({
      lookupError: false
    })
  }

  manualEntry(e) {
    e.preventDefault()
    this.setState({
      manualEntry: true
    })
  }

  retryLookup(e) {
    const { propagateData } = this.props
    e.preventDefault()
    this.setState({
      manualEntry: false
    })
    propagateData({ kind: null, make: null, model: null, registration: null })
  }

  handlePropagation(name, value) {
    this.setState({ [name]: value }, function () {
      propagateValue.apply(this, [name, value])
    })
  }

  renderVehicleLookup() {
    const { lookupError } = this.state
    const { errors, modifiers } = this.props
    const buttonMods = ['primary']
    if (lookupError) buttonMods.push('disabled')
    return (
      <FormGroup modifiers={(modifiers || []).concat(errors && Object.keys(errors).length ? 'error' : null)}>
        <Question heading="Vehicle" text="What was the registration number?" />
        <FormGroupCombo>
          <FormGroupComboItem>
            <FormGroupHeader label="Vehicle registration" />
            <input
              className={`form-group__input `}
              id="vehicle-lookup-input"
              type="text"
              onChange={this.inputChanged}
              ref={this.lookupInputRef}
            />
          </FormGroupComboItem>
          <AnchorButton
            children="Find Vehicle"
            onClick={this.runLookup}
            modifiers={buttonMods}
            classes={['form-group__combo__button', 'display-flex', 'mt-5']}
          />
          {errors && errors.registration && <ErrorSpan error={errors.registration} />}
        </FormGroupCombo>
        {lookupError ? (
          <Helper
            title="We didn't recognise your vehicle"
            body="Double check the registration is correct, or select the make and model yourself."
            closeable={false}
            modifiers={['warning', 'visible']}
            additionalInnerClasses={['mt-4', 'mb-0']}
            helperLink={{
              text: 'Enter the make and model instead',
              handleClick: this.manualEntry
            }}
            iconName="grimace"
          />
        ) : (
          this.renderManualEntryLink()
        )}
      </FormGroup>
    )
  }

  renderManualEntrySection() {
    const { vehicle, errors } = this.props
    return (
      <FilteredSelect
        pathPrefix="/vehicle"
        handlePropagation={this.handlePropagation}
        errors={errors}
        filters={[
          {
            name: 'kind',
            path: 'kinds',
            heading: 'Type',
            text: 'What type of vehicle was it?'
          },
          {
            name: 'make',
            path: 'makes',
            heading: 'Make',
            text: `What make was the ${vehicle.kind}?`,
            params: ['kind'],
            popularOptions: this.popularMakes(vehicle.kind),
            selectDefault: 'Select the vehicle make'
          },
          {
            name: 'model',
            path: 'models',
            heading: 'Model',
            params: ['kind', 'make'],
            text: `What type of ${vehicle.make} was it?`
          }
        ]}
      />
    )
  }

  renderVehicleEntry() {
    const { manualEntry } = this.state
    return <React.Fragment>{manualEntry ? this.renderManualEntrySection() : this.renderVehicleLookup()}</React.Fragment>
  }

  renderVehicle() {
    const { vehicle, errors, handlePropagation } = this.props
    return (
      <React.Fragment>
        <Question heading="Vehicle" />
        <Collection heading={`${vehicle.make} ${vehicle.model}`} text={vehicle.registration} />
        {this.renderRetryLookupLink()}
        {this.showRegInput() && (
          <React.Fragment>
            <hr />
            <TextQuestion
              className="block__item"
              resourceName="api_preson_vehicle"
              resource={vehicle}
              error={errors.registration}
              name="registration"
              questionHeading="Registration"
              questionText="What is the registration of the vehicle?"
              handlePropagation={handlePropagation}
              ref={this.regInputRef}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  renderRetryLookupLink() {
    return <FormLink text="This isn't the correct vehicle" onClick={this.retryLookup} />
  }

  renderManualEntryLink() {
    return <FormLink text="Enter the make and model instead" onClick={this.manualEntry} />
  }

  render() {
    return this.showCollection() ? this.renderVehicle() : this.renderVehicleEntry()
  }
}

VehicleLookup.propTypes = {
  modifiers: PropTypes.array,
  errors: PropTypes.object,
  propagateData: PropTypes.func,
  handlePropagation: PropTypes.func,
  vehicle: PropTypes.object
}
export default VehicleLookup
