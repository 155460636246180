import React from 'react'
import PropTypes from 'prop-types'

import ButtonGroupItem from './ButtonGroupItem'
import DateRangeQuestion from './date/DateRangeQuestion'
import VehicleLookup from './VehicleLookup'
import YesNoQuestion from './YesNoQuestion'
import FlashMessage from '../display/FlashMessage'

import { ButtonGroup, ContentFooter, ContentWrapper, ContentWrapperInner } from '../layout/Wrapper'

import { fetch, omit, redirectTo, pick, highlightFirstError } from '../../modules/utils'

import helperTextData from '../../data/helperTextData.json'

class VehicleForm extends React.Component {
  constructor(props) {
    super(props)
    let flash = false
    if (props.locked) {
      flash = props.endedAtLocked
        ? 'You cannot update this vehicle'
        : "You can only update the <strong>'Until date'</strong> on this vehicle"
      flash =
        flash +
        ' because it has been used in one of your previous claim submissions. Please <a data-trigger-modal="contact-modal" href="">contact us</a> if you need anything else corrected.</p>'
    }
    this.state = {
      errors: {},
      disabled: props.endedAtLocked,
      flash: flash,
      flashType: 'info'
    }
    this.lookupRef = React.createRef()
    this.handlePropagation = this.handlePropagation.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.updateVehicleData = this.updateVehicleData.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    const { resource } = props
    const { unchangedVehicle } = state

    if (resource && resource != unchangedVehicle) {
      const addedValues = {
        owned: !Boolean(resource.ended_at),
        company_car: resource.company_car || false,
        registration: resource.registration || '',
        kind: resource.kind || '',
        make: resource.make || '',
        model: resource.model || ''
      }
      return {
        vehicle: Object.assign(resource, addedValues),
        unchangedVehicle: resource,
        action: resource.id ? 'edit' : 'new'
      }
    }
    return null
  }

  handlePropagation(name, value, owned) {
    const { vehicle, errors } = this.state
    let newState = {
      disabled: false,
      errors: omit(errors, name)
    }

    if (name != 'registration') {
      const stateUpdates = { [name]: value }

      if (owned !== undefined) {
        stateUpdates['owned'] = owned
      }

      newState.vehicle = Object.assign(vehicle, stateUpdates)
    }
    this.setState(newState)

    this.props.onChange()
  }

  updateVehicleData(selectedVehicle) {
    const { vehicle, errors } = this.state
    this.setState({
      disabled: false,
      vehicle: Object.assign(vehicle, selectedVehicle),
      errors: omit(errors, Object.keys(selectedVehicle))
    })
    this.props.onChange()
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ disabled: true })
    const { action, vehicle } = this.state
    const { referrer, onAfterSubmit, authenticityToken } = this.props

    let method = 'POST'
    let path = '/vehicles'
    const formData = {
      api_person_vehicle: vehicle,
      utf8: '✓',
      authenticity_token: authenticityToken
    }
    if (action == 'edit') {
      path = path + '/' + vehicle.id
      method = 'PATCH'
    }

    if (!formData.api_person_vehicle.registration) {
      const regInput = this.lookupRef.current.regInputRef.current
      if (regInput) {
        const regValue = regInput.state.value
        formData.api_person_vehicle.registration = regValue
      }
    }

    fetch(method, path, 'application/json', 'application/json', formData)
      .then(data => {
        if (referrer) {
          redirectTo(referrer)
        } else {
          onAfterSubmit ? onAfterSubmit(data) : this.setState({ vehicle: null })
        }
      })
      .catch(errResponse => {
        const data = errResponse[1]
        console.error(JSON.stringify(data.errors))
        this.setState(data, function () {
          highlightFirstError()
        })
      })
  }

  companyCar() {
    const { resourceName, locked } = this.props
    const { vehicle } = this.state
    return (
      <YesNoQuestion
        resourceName={resourceName}
        resource={vehicle}
        checked={vehicle.company_car}
        name="company_car"
        questionHeading="Company car"
        questionText="Was this a company car?"
        helperText={helperTextData.vehicles.company_car}
        handlePropagation={this.handlePropagation}
        disabled={locked}
      />
    )
  }

  dates() {
    const { resourceName, locked, endedAtLocked } = this.props
    const { vehicle, errors } = this.state
    return (
      <DateRangeQuestion
        resourceName={resourceName}
        resource={vehicle}
        questionText="When did you have access to this vehicle?"
        helperText={helperTextData.vehicles.dates}
        currentName="owned"
        currentChecked={vehicle.owned}
        fromError={errors.started_at}
        toError={errors.ended_at}
        currentText="I still have the vehicle"
        handlePropagation={this.handlePropagation}
        fromDisabled={locked}
        toDisabled={endedAtLocked}
      />
    )
  }

  render() {
    const { flash, flashType, vehicle, errors, disabled } = this.state
    const { referrer, onCancel } = this.props

    return (
      <ContentWrapper modifiers="small">
        <ContentWrapperInner modifiers="longform">
          {flash && <FlashMessage type={flashType} body={flash} />}
          <VehicleLookup
            modifiers={['small']}
            errors={pick(errors, ['registration', 'kind', 'make', 'model'])}
            propagateData={this.updateVehicleData}
            handlePropagation={this.handlePropagation}
            vehicle={vehicle}
            ref={this.lookupRef}
          />
          <hr />
          {this.companyCar()}
          <hr />
          {this.dates()}
        </ContentWrapperInner>
        <ContentFooter modifiers="actions">
          <ButtonGroup modifiers="spread">
            <ButtonGroupItem
              buttonModifiers="default"
              element="anchor"
              href={referrer || '#'}
              onClick={onCancel}
              disabled={false}
            >
              Cancel
            </ButtonGroupItem>
            <ButtonGroupItem buttonModifiers="primary" element="button" disabled={disabled} onClick={this.handleSubmit}>
              Save vehicle
            </ButtonGroupItem>
          </ButtonGroup>
        </ContentFooter>
      </ContentWrapper>
    )
  }
}
VehicleForm.propTypes = {
  resourceName: PropTypes.string,
  resource: PropTypes.object,
  authenticityToken: PropTypes.string,
  referrer: PropTypes.string,
  onAfterSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func,
  locked: PropTypes.bool,
  endedAtLocked: PropTypes.bool
}

VehicleForm.defaultProps = {
  resourceName: 'api_person_vehicle',
  locked: false,
  endedAtLocked: false,
  onChange: () => {}
}

export default VehicleForm
