import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Alert from '../../display/alert/Alert'
import AlertHeader from '../../display/alert/AlertHeader'
import ButtonGroupItem from '../../interactive/ButtonGroupItem'
import Lead from '../../display/Lead'

import { AlertText, ButtonGroup, ContentFooter, ContentWrapper, ContentWrapperInner, Grid } from '../../layout/Wrapper'

import { nextOpeningTime } from '../../../modules/time'

class ErrorPage extends React.Component {
  constructor(props) {
    super(props)
    this.date = new Date()
  }

  callCentreCopy() {
    const nextOpen = nextOpeningTime(this.date)
    let whenOpen = 'now'

    if (nextOpen === 'tomorrow') {
      whenOpen = 'tomorrow at 8am'
    } else if (nextOpen === 'monday') {
      whenOpen = 'on Monday at 8am'
    }

    return (
      <Fragment>
        The call centre is open <strong>{whenOpen}</strong>
      </Fragment>
    )
  }

  render() {
    const { showSignOut, showGoHome, leadText, messageText } = this.props

    return (
      <Grid modifiers={['6-5', 'align-start']}>
        <ContentWrapper className="display-inline-table overflow-hidden">
          <ContentWrapperInner modifiers="longform">
            <Lead>{leadText}</Lead>
            <hr />
            <h2>What happens now?</h2>
            <p>{messageText}</p>
            <Alert modifiers="info">
              <AlertHeader>Want to chat?</AlertHeader>
              <AlertText>
                If you need to get hold of us, can always give us a call on{' '}
                <strong>
                  <a href="tel:01233628648">01233 628648</a>
                </strong>
                . {this.callCentreCopy()}.
              </AlertText>
            </Alert>
          </ContentWrapperInner>
          <ContentFooter modifiers="actions">
            <ButtonGroup>
              {showGoHome && (
                <ButtonGroupItem buttonModifiers="default" element="anchor" href="/">
                  Go home
                </ButtonGroupItem>
              )}
              {showSignOut && (
                <ButtonGroupItem buttonModifiers="default" element="anchor" href="/users/sign_out">
                  Sign out
                </ButtonGroupItem>
              )}
            </ButtonGroup>
          </ContentFooter>
        </ContentWrapper>
      </Grid>
    )
  }
}

ErrorPage.defaultProps = {
  showGoHome: true,
  showSignOut: true
}

ErrorPage.propTypes = {
  leadText: PropTypes.string.isRequired,
  messageText: PropTypes.string.isRequired,
  showGoHome: PropTypes.bool.isRequired,
  showSignOut: PropTypes.bool.isRequired
}

export default ErrorPage
