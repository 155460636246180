import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import FormGroupHeader from './FormGroupHeader'
import Stepper from './Stepper'

import { FormGroup, FormGroupErrorMessage, InputGroup, InputGroupItem } from '../layout/Wrapper'

class DurationInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      years: props.years,
      months: props.months,
      weeks: props.weeks
    }
  }

  handleChange(timePeriod, value) {
    const { onChange } = this.props

    const nextState = {
      ...this.state,
      ...{
        [timePeriod]: value
      }
    }
    const { years, months, weeks } = nextState

    this.setState(nextState, () => onChange(years, months, weeks))
  }

  valueErroneous(max, value) {
    const maxExceeded = max && value > max
    const minExceeded = value < 0

    return maxExceeded || minExceeded
  }

  render() {
    const MAX_MONTHS = 11
    const MAX_WEEKS = 3

    const { years, months, weeks } = this.state
    const { disabled } = this.props

    const yearsError = this.valueErroneous(false, years)
    const monthsError = this.valueErroneous(MAX_MONTHS, months)
    const weeksError = this.valueErroneous(MAX_WEEKS, weeks)

    return (
      <Fragment>
        <InputGroup>
          <InputGroupItem>
            <FormGroup modifiers={yearsError ? 'error' : null}>
              <FormGroupHeader label="Years" />
              <Stepper
                className="stepper--years"
                disabled={disabled}
                value={years}
                onChange={val => this.handleChange('years', val)}
              />
            </FormGroup>
          </InputGroupItem>
          <InputGroupItem>
            <FormGroup modifiers={monthsError ? 'error' : null}>
              <FormGroupHeader label="Months" />
              <Stepper
                className="stepper--months"
                disabled={disabled}
                value={months}
                onChange={val => this.handleChange('months', val)}
                max={MAX_MONTHS}
              />
            </FormGroup>
          </InputGroupItem>
          <InputGroupItem>
            <FormGroup modifiers={weeksError ? 'error' : null}>
              <FormGroupHeader label="Weeks" />
              <Stepper
                className="stepper--weeks"
                disabled={disabled}
                value={weeks}
                onChange={val => this.handleChange('weeks', val)}
                max={MAX_WEEKS}
              />
            </FormGroup>
          </InputGroupItem>
        </InputGroup>
        {yearsError && <FormGroupErrorMessage>Years must be a number greater than or equal to 0</FormGroupErrorMessage>}
        {monthsError && <FormGroupErrorMessage>Months must be a number between 0 and 11</FormGroupErrorMessage>}
        {weeksError && <FormGroupErrorMessage>Weeks must be a number between 0 and 3</FormGroupErrorMessage>}
      </Fragment>
    )
  }
}

DurationInput.propTypes = {
  years: PropTypes.number,
  months: PropTypes.number,
  weeks: PropTypes.number,
  disabled: PropTypes.bool,
  onChange: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
}

DurationInput.defaultProps = {
  disabled: false,
  years: 0,
  months: 0,
  weeks: 0
}

export default DurationInput
