import React from 'react'
import PropTypes from 'prop-types'

import Question from './Question'
import ErrorSpan from './ErrorSpan'
import Helper from '../display/Helper'
import HelperTrigger from '../display/HelperTrigger'
import FormGroupHeader from './FormGroupHeader'

import { FormGroup } from '../layout/Wrapper'

import { inputName, inputId, propagateValue, helperId } from '../../modules/utils'

class TextQuestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = { value: this.value() }
    this.handleChange = this.handleChange.bind(this)
  }

  value() {
    const { resource, name, value } = this.props
    const resourceValue = resource && resource[name]
    return value || (this.state && this.state.value) || resourceValue || ''
  }

  handleChange({ target }) {
    this.setState({ value: target.value }, propagateValue.apply(this, [this.props.name, target.value]))
  }

  render() {
    const { className, label, questionHeading, questionText, helperText, disabled, error, placeHolder, autoComplete } =
      this.props
    return (
      <FormGroup className={className} modifiers={error ? 'error' : null}>
        {helperText && <Helper id={helperId.apply(this)} body={helperText} />}
        {(questionHeading || questionText) && (
          <Question heading={questionHeading} text={questionText}>
            {helperText && (
              <HelperTrigger
                helperId={helperId.apply(this)}
                eventLabel={`the '${questionHeading}' question on a Text Question component`}
              />
            )}
          </Question>
        )}
        <FormGroupHeader label={label} />
        <input
          autoComplete={autoComplete}
          className="form-group__input"
          type="text"
          name={inputName.apply(this)}
          id={inputId.apply(this, [false])}
          onChange={this.handleChange}
          defaultValue={this.state.value}
          disabled={disabled}
          placeholder={placeHolder}
        />
        <ErrorSpan error={error} />
      </FormGroup>
    )
  }
}
TextQuestion.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  helperText: PropTypes.string,
  resource: PropTypes.object,
  resourceName: PropTypes.string,
  label: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.bool]),
  name: PropTypes.string,
  questionHeading: PropTypes.string,
  questionText: PropTypes.string,
  disabled: PropTypes.bool,
  placeHolder: PropTypes.string
}

TextQuestion.defaultProps = {
  disabled: false
}
export default TextQuestion
