import React from 'react'
import PropTypes from 'prop-types'

import { monthNames } from '../../modules/dates'

// 'new_application/date_group.js' is used to ensure that the `day` options are correctly set
// depending on the month and year.

class DateGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      day: '01',
      month: '01',
      year: '',
      initialised: false
    }
    this.handleChange = this.handleChange.bind(this)
  }

  initialise(nextProps) {
    // if we have initialised
    if (this.state.initialised) {
      return null
    }

    const { resource, name } = nextProps
    let dateValue = ''

    if (resource[name]) {
      dateValue = new Date(resource[name])
    } else {
      return null
    }

    let newDate = {
      day: dateValue.getDate().toString().padStart(2, '0'),
      month: (dateValue.getMonth() + 1).toString().padStart(2, '0'),
      year: dateValue.getFullYear()
    }

    this.setState({
      day: newDate.day,
      month: newDate.month,
      year: newDate.year,
      initialised: true
    })
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.initialise(nextProps)
  }

  componentDidMount() {
    this.initialise(this.props)
  }

  handleChange({ target }) {
    let stateAttribute
    if (target.classList.contains('day_input')) {
      stateAttribute = 'day'
    }
    if (target.classList.contains('month_input')) {
      stateAttribute = 'month'
    }
    if (target.classList.contains('year_input')) {
      stateAttribute = 'year'
    }
    this.setState({ [stateAttribute]: target.value }, this.propagateDate)
  }

  propagateDate() {
    const { dateChanged } = this.props
    let { year, month, day } = this.state
    if (dateChanged) {
      dateChanged(`${year}-${month}-${day}`)
    }
  }

  inputName(postfix) {
    const { name, resourceName } = this.props
    return `${resourceName}[${name}_${postfix}]`
  }

  inputId(postfix) {
    const { name, resourceName } = this.props
    const prefix = resourceName.replace('[', '_').replace(']', '')
    return `${prefix}_${name}_${postfix}`
  }

  dayInput() {
    let dayClasses = [
      'input-group__item',
      'select',
      'optional',
      'showable',
      'hideable',
      'day_select',
      this.inputId('day')
    ]
    // If the forms all get converted to React this can be done using props.
    // Until then we have to do this to allow other parts to change the visibility.
    if (!this.props.showDay) dayClasses.push('hidden')
    return (
      <div className={dayClasses.join(' ')}>
        <select
          className="select__element form-group__input select optional day_input"
          name={this.inputName('day')}
          id={this.inputId('day')}
          value={this.state.day}
          onChange={this.handleChange}
        >
          {this.dayOptions()}
        </select>
      </div>
    )
  }

  dayOptions() {
    let options = []
    let i = 1

    for (i = 1; i <= 31; i++) {
      options.push(
        <option key={i} value={i.toString().padStart(2, '0')}>
          {i}
        </option>
      )
    }
    return options
  }

  monthInput() {
    let monthClasses = ['input-group__item', 'select', 'optional', 'month_select', this.inputId('month')]
    if (this.props.showDay) monthClasses.push('input-group__item--lg')
    return (
      <div className={monthClasses.join(' ')}>
        <select
          className="select__element form-group__input select optional month_input"
          name={this.inputName('month')}
          id={this.inputId('month')}
          value={this.state.month}
          onChange={this.handleChange}
        >
          {this.monthOptions()}
        </select>
      </div>
    )
  }

  monthOptions() {
    return monthNames().map((month, index) => (
      <option key={index} value={(index + 1).toString().padStart(2, '0')}>
        {month}
      </option>
    ))
  }

  yearInput() {
    const { minYear, maxYear } = this.props
    return (
      <div className="input-group__item">
        <input
          className="input-group__item form-group__input year_input"
          type="number"
          min={minYear}
          max={maxYear}
          required="required"
          step="1"
          placeholder="YYYY"
          hint="false"
          name={this.inputName('year')}
          id={this.inputId('year')}
          value={this.state.year}
          onChange={this.handleChange}
        />
      </div>
    )
  }

  render() {
    return (
      <div className="input-group date-group">
        {this.dayInput()}
        {this.monthInput()}
        {this.yearInput()}
      </div>
    )
  }
}

DateGroup.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  resource: PropTypes.object.isRequired,
  resourceName: PropTypes.string.isRequired,
  showDay: PropTypes.bool,
  minYear: PropTypes.number.isRequired,
  maxYear: PropTypes.number.isRequired,
  dateChanged: PropTypes.func
}

export default DateGroup
