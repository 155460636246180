import React from 'react'
import PropTypes from 'prop-types'

import Helper from '../display/Helper'
import HelperTrigger from '../display/HelperTrigger'
import { uuidv4 } from '../../modules/utils'

export default function QuestionnaireQuestion({ subheading, question, helperText }) {
  const helperId = `helper-${uuidv4()}`

  return (
    <div className="questionnaire__question">
      <span className="questionnaire__question__number">{subheading}</span>
      <p className="questionnaire__question__phrase">
        <span className="questionnaire__question__phrase-text" dangerouslySetInnerHTML={{ __html: question }} />
        &nbsp;
        {helperText && (
          <HelperTrigger
            helperId={helperId}
            eventLabel={`the '${question}' question on the Questionnaire`}
            className={'questionnaire__question__phrase__info'}
          />
        )}
      </p>
      {helperText && <Helper id={helperId} {...helperText} />}
    </div>
  )
}

QuestionnaireQuestion.propTypes = {
  subheading: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  helperText: PropTypes.shape({ title: PropTypes.string.isRequired, body: PropTypes.string.isRequired })
}
