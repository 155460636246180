import React from 'react'
import PropTypes from 'prop-types'

import { TIMELINE_PAGE_CONFIGS } from '../../../modules/pages'

const stageClasses = (index, activeIndex) => {
  const result = ['timeline-stages__stage']

  if (index === activeIndex) {
    result.push('timeline-stages__stage--active')
  } else if (index < activeIndex) {
    result.push('timeline-stages__stage--complete')
  }

  return result.join(' ')
}

export default function MastheadTimelineStages({ activeStage }) {
  const activeIndex = Object.keys(TIMELINE_PAGE_CONFIGS).indexOf(activeStage)

  return (
    <ol className="timeline-stages">
      {Object.keys(TIMELINE_PAGE_CONFIGS).map((pageName, index) => {
        const { href, label, timelineStage } = TIMELINE_PAGE_CONFIGS[pageName]
        if (!timelineStage) {
          return null
        }

        return (
          <li className={stageClasses(index, activeIndex)} key={`timeline-stages__stage-${index}`}>
            <a href={href}>{label}</a>
          </li>
        )
      })}
    </ol>
  )
}

MastheadTimelineStages.propTypes = {
  activeStage: PropTypes.oneOf(Object.keys(TIMELINE_PAGE_CONFIGS)).isRequired
}
