import React from 'react'
import PropTypes from 'prop-types'

import { handleBreakpoint } from '../../modules/utils'
import { placeholderFromString } from '../../helpers/images'

const BREAKPOINT = 864

class ActionList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      columnCount: window.innerWidth <= BREAKPOINT || props.small ? 2 : 3
    }
  }

  componentDidMount() {
    const { small } = this.props
    !small && handleBreakpoint.apply(this, [BREAKPOINT, this.setTwoCol, this.unsetTwoCol])
  }

  setTwoCol() {
    this.setState({ columnCount: 2 })
  }

  unsetTwoCol() {
    this.setState({ columnCount: 3 })
  }

  renderPlaceholders() {
    const { columnCount } = this.state
    const { children } = this.props
    let placesholders = []
    let amount = children.filter(function (child) {
      return React.isValidElement(child)
    }).length

    while (amount % columnCount !== 0) {
      let keyText = `placeholder_${placesholders.length}`
      placesholders.push(
        <div key={keyText} className="action action--placeholder">
          <img className="action__icon" alt="Action Placeholder" src={placeholderFromString('account')} />
        </div>
      )
      amount++
    }

    return placesholders
  }

  render() {
    const { small } = this.props
    let classes = ['action-list']
    if (!small) classes.push('action-list--3-col')
    return (
      <div className={classes.join(' ')}>
        {this.props.children}
        {this.renderPlaceholders()}
      </div>
    )
  }
}

ActionList.propTypes = {
  small: PropTypes.bool
}

ActionList.defaultProps = {
  small: false
}

export default ActionList
