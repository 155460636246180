import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import ActionBar from '../interactive/ActionBar'

import { generateModifierClasses } from '../../modules/utils'
import { animate } from '../../modules/carousel'

export default function Picker({ options, onChange, name, onComplete, showNav }) {
  const [activeTab, setActiveTab] = useState(0)
  const [activeTabIndex, setActiveTabIndex] = useState(0)
  const [animating, setAnimating] = useState(false)
  const [fadeOut, setFadeOut] = useState(null)
  const [fadeIn, setFadeIn] = useState(null)

  const { disabledOptionTitle, miniButtons, optionName, nextText, optionList, previousText, skipOptionText } =
    options[activeTab] || {}

  const noOfTabs = options.length

  useEffect(() => {
    switchTabs(0)
  }, [name])

  const switchTabs = (nextActiveTab, callback) => {
    if (nextActiveTab === activeTab) {
      return
    }

    animate(
      () => {
        setAnimating()
        setFadeOut(nextActiveTab < activeTab ? 'right' : 'left')
      },
      () => {
        setFadeOut(null)
        setActiveTab(nextActiveTab)
        setFadeIn(nextActiveTab < activeTab ? 'left' : 'right')
      },
      () => {
        setFadeIn(null)
        setAnimating(null)
        setActiveTabIndex(0)
        if (callback) {
          callback()
        }
      },
      400
    )
  }

  const handleButtonClick = e => {
    e.preventDefault()

    const { target } = e

    if (target.classList.contains('disabled') || animating) {
      return false
    }

    const option = target.closest('a').getAttribute('option')
    const value = target.closest('a').getAttribute('value')

    if (activeTab === noOfTabs - 1) {
      onChange({ [option]: value })
      setActiveTab(0)
      setActiveTabIndex(0)
      return onComplete()
    }

    switchTabs(activeTab + 1, () => {
      onChange({ [option]: value })
    })
  }

  const handleTabClick = ({ target }) => {
    const nextTabIndex = parseInt(target.closest('li').getAttribute('value'))

    if (activeTab <= nextTabIndex) {
      return false
    }

    switchTabs(nextTabIndex)
  }

  const underlineStyles = () => {
    const width = 100 / noOfTabs / 3
    return {
      width: `${width}%`,
      left: `${(100 / noOfTabs) * activeTab + width}%`
    }
  }

  const buttonLists = () => {
    const maxNoOptionsWithoutButtons = miniButtons ? 42 : 12
    const maxNoOptionsWithButtons = miniButtons ? 35 : 9
    let actionBar = []
    let actions = []
    let optionClasses = ['picker__button-list__button']
    if (miniButtons) {
      optionClasses.push('picker__button-list__button--mini')
    }

    const actionButtonBase = {
      componentName: 'AnchorButton',
      classes: ['button-group__item', 'button--block'],
      modifiers: ['default']
    }

    let allOptions = optionList || []
    if (allOptions.length > maxNoOptionsWithoutButtons) {
      let sliceTo = allOptions.length - maxNoOptionsWithButtons * activeTabIndex
      let sliceFrom = Math.max(sliceTo - maxNoOptionsWithButtons, 0)

      allOptions = allOptions.slice(sliceFrom, sliceTo)

      if (sliceFrom !== 0) {
        actions.push(
          Object.assign(
            {
              children: previousText || 'Previous',
              onClick: e => {
                e.preventDefault()
                setActiveTabIndex(activeTabIndex + 1)
              }
            },
            actionButtonBase
          )
        )
      }
      if (activeTabIndex !== 0) {
        actions.push(
          Object.assign(
            {
              children: nextText || 'Next',
              onClick: e => {
                e.preventDefault()
                setActiveTabIndex(activeTabIndex - 1)
              }
            },
            actionButtonBase
          )
        )
      }
    }
    if (skipOptionText) {
      actions.push(
        Object.assign(
          {
            children: skipOptionText,
            option: optionName,
            value: '',
            onClick: handleButtonClick
          },
          actionButtonBase
        )
      )
    }
    actions.length > 0 && actionBar.push(<ActionBar key="action-bar" actions={actions} modifiers={['picker']} />)

    return allOptions
      .map((optionData, index) => (
        <a
          className={optionClasses
            .concat(
              optionData.disabled ? 'disabled' : '',
              optionData.active ? 'picker__button-list__button--active' : ''
            )
            .filter(Boolean)
            .join(' ')}
          key={index}
          option={optionName}
          title={optionData.disabled ? disabledOptionTitle : null}
          value={optionData.value}
          onClick={handleButtonClick}
        >
          {optionData.text}
        </a>
      ))
      .concat(actionBar)
  }

  const renderNavItem = ({ optionName }, index) => {
    let modifiers = []
    if (activeTab < index) {
      modifiers.push('disabled')
    } else if (activeTab == index) {
      modifiers.push('active')
    }

    return (
      <li
        className={generateModifierClasses('picker__nav__item', modifiers)}
        key={index}
        value={index}
        onClick={handleTabClick}
      >
        <span>{optionName}</span>
      </li>
    )
  }

  return (
    <div className="picker">
      {showNav && (
        <nav className="picker__nav">
          <ul className="picker__nav__list">
            {options.map((optionData, index) => renderNavItem(optionData, index))}
            <span className="picker__nav__underline" style={underlineStyles()} />
          </ul>
        </nav>
      )}
      <div className="picker__button-list__wrapper">
        <div className={['animated', `fade-out-${fadeOut}`, `fade-in-${fadeIn}`].join(' ')}>
          <div className={generateModifierClasses('picker__button-list', miniButtons ? ['mini'] : [])}>
            {buttonLists()}
          </div>
        </div>
      </div>
    </div>
  )
}

Picker.propTypes = {
  name: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  onComplete: PropTypes.func.isRequired,
  showNav: PropTypes.bool.isRequired
}

Picker.defaultProps = {
  showNav: true
}
