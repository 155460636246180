import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import ButtonGroupItem from './ButtonGroupItem'
import DateRangeQuestion from './date/DateRangeQuestion'
import OptionsList from './OptionsList'
import TextQuestion from './TextQuestion'
import TypeAhead from './TypeAhead'
import FlashMessage from '../display/FlashMessage'
import Helper from '../display/Helper'
import HelperTrigger from '../display/HelperTrigger'
import FormLink from './FormLink'
import Question from './Question'
import CheckBoxGroupInput from './CheckBoxGroupInput'
import YesNoQuestion from './YesNoQuestion'
import IdleHelpPanel from './IdleHelpPanel'

import { ButtonGroup, ContentFooter, ContentWrapper, ContentWrapperInner, FormGroup } from '../layout/Wrapper'
import { companyLookup } from '../../modules/api'
import { fetch, omit, redirectTo, highlightFirstError, isIterable } from '../../modules/utils'
import { onInteractionWithForm, showIdleHelpPanel } from '../../modules/idleHelpPanel'
import helperTextData from '../../data/helperTextData.json'

const BENEFITS_OPTIONS = [
  { label: 'Medical benefit', value: 'medical_benefit', checked: false },
  { label: 'Car benefit', value: 'car_benefit', checked: false },
  { label: 'Fuel benefit', value: 'fuel_benefit', checked: false },
  {
    label: 'Employer loan',
    microcopy: '(Including Forces Help to Buy)',
    value: 'employer_loan',
    checked: false
  },
  { label: 'Other', value: 'other', checked: false }
]

class EmploymentForm extends React.Component {
  constructor(props) {
    super(props)
    let flash = false
    if (props.locked) {
      flash = props.endedAtLocked
        ? 'You cannot update this employment'
        : "You can only update the <strong>'Until date'</strong> on this employment"
      flash =
        flash +
        ' because it has been used in one of your previous claim submissions. Please <a data-trigger-modal="contact-modal" href="">contact us</a> if you need anything else corrected.</p>'
    }
    this.state = {
      errors: {},
      disabled: props.endedAtLocked,
      flash: flash
    }
    this.handlePropagation = this.handlePropagation.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
    this.companySelected = this.companySelected.bind(this)
    this.toggleManualName = this.toggleManualName.bind(this)
    this.handleBenefitsChange = this.handleBenefitsChange.bind(this)
    this.benefitsOptions = this.benefitsOptions.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    const { resource, modEmployee } = props
    const { unchangedEmployment } = state

    if (resource && resource != unchangedEmployment) {
      const addedValues = {
        benefits: resource.benefits || {},
        current: !Boolean(resource.ended_at),
        self_employed: resource.self_employed || false,
        ministry_of_defence: resource.id ? Boolean(resource.mod_branch) : modEmployee
      }
      return {
        employment: Object.assign(resource, addedValues),
        unchangedEmployment: resource,
        action: resource.id ? 'edit' : 'new',
        manualName: Boolean(resource.name)
      }
    }
    return null
  }

  handlePropagation(name, value, current) {
    const { constructionEmployee } = this.props
    const { employment, errors } = this.state
    let employmentUpdate = { [name]: value }

    if (current !== undefined) {
      employmentUpdate['current'] = current
    }

    let newState = {
      disabled: false,
      errors: omit(errors, name)
    }

    if (name === 'self_employed' && value) {
      employmentUpdate.benefits = {
        medical_benefit: false,
        car_benefit: false,
        fuel_benefit: false,
        employer_loan: false,
        other: false
      }
    }

    if (name === 'received_travel_reimbursements' && (!value || !constructionEmployee)) {
      employmentUpdate.paid_under_cis = null
    }

    if (name === 'ministry_of_defence') {
      let updatedParams
      newState.manualName = false

      if (value) {
        updatedParams = {
          self_employed: undefined,
          name: undefined,
          mod_branch: '',
          received_travel_reimbursements: null,
          paid_under_cis: null
        }
      } else {
        updatedParams = {
          mod_branch: undefined,
          self_employed: false,
          name: ''
        }
      }

      employmentUpdate = Object.assign(employmentUpdate, updatedParams)
    }

    newState.employment = Object.assign(employment, employmentUpdate)
    this.setState(newState)
    onInteractionWithForm()
  }

  companySelected(company) {
    const { employment } = this.state
    this.setState({
      employment: Object.assign(employment, { name: company.name }),
      manualName: true
    })
    onInteractionWithForm()
  }

  toggleManualName(e) {
    e.preventDefault()
    const { employment } = this.state
    this.setState({
      employment: Object.assign(employment, { name: '' }),
      manualName: !this.state.manualName
    })
    onInteractionWithForm()
  }

  isMOD() {
    const { employment } = this.state
    return Boolean(employment && employment.ministry_of_defence)
  }

  handleSubmit(e) {
    e.preventDefault()
    this.setState({ disabled: true })
    const { action, employment } = this.state
    const { afterSubmitPath, onAfterSubmit, authenticityToken } = this.props

    let method = 'POST'
    let path = '/employments'
    const formData = {
      api_person_employment: employment,
      utf8: '✓',
      authenticity_token: authenticityToken
    }
    if (action == 'edit') {
      path = path + '/' + employment.id
      method = 'PATCH'
    }

    fetch(method, path, 'application/json', 'application/json', formData)
      .then(data => {
        if (afterSubmitPath) {
          redirectTo(afterSubmitPath)
        } else if (onAfterSubmit) {
          onAfterSubmit(data)
        } else {
          this.setState({ employment: null })
        }
      })
      .catch(e => {
        if (isIterable(e)) {
          const [, data] = e
          console.error(JSON.stringify(data.errors))
          this.setState(data, function () {
            highlightFirstError()
          })
        }
      })
  }

  industry() {
    const { resourceName, locked } = this.props
    const { employment } = this.state
    return (
      <YesNoQuestion
        resourceName={resourceName}
        resource={employment}
        checked={this.isMOD()}
        name="ministry_of_defence"
        questionHeading="Industry"
        questionText="Was this employment with the Armed Forces?"
        helperText={helperTextData.employments.industry}
        handlePropagation={this.handlePropagation}
        disabled={locked}
      />
    )
  }

  modQuestions() {
    const { resourceName, locked } = this.props
    const { employment, errors } = this.state
    const options = [
      { label: 'Army', value: 'Army' },
      { label: 'Navy', value: 'Navy' },
      { label: 'RAF', value: 'RAF' },
      { label: 'Other', value: 'Other' }
    ]
    return (
      <OptionsList
        modifiers={['columns']}
        questionHeading="Services"
        questionText="Which service were you in?"
        name="mod_branch"
        error={errors && errors.mod_branch}
        options={options}
        resourceName={resourceName}
        resource={employment}
        handlePropagation={this.handlePropagation}
        disabled={locked}
      />
    )
  }

  NonModQuestions() {
    const { hideSelfEmployed, resourceName, locked } = this.props
    const { employment, manualName, errors } = this.state
    const helperText = helperTextData.employments.company
    return (
      <React.Fragment>
        {manualName ? (
          <React.Fragment>
            <TextQuestion
              resourceName={resourceName}
              resource={employment}
              name="name"
              error={errors.name}
              label="Company name"
              questionHeading="Company"
              questionText="Who were you working for?"
              helperText={helperText}
              handlePropagation={this.handlePropagation}
              disabled={locked}
            />
            <FormLink text="Try the lookup again" onClick={this.toggleManualName} />
          </React.Fragment>
        ) : (
          <FormGroup modifiers={errors.name ? 'error' : null}>
            {helperText && <Helper id="helper-company" body={helperText} />}
            <Question heading="Company" text="Who were you working for?">
              {helperText && <HelperTrigger helperId="helper-company" />}
            </Question>
            <TypeAhead
              lookupFunction={companyLookup}
              label="Employer"
              placeholder="Search by company name, number or postcode"
              onSelection={this.companySelected}
              error={errors.name}
            >
              {!locked && <FormLink text="I can't find the right company" onClick={this.toggleManualName} />}
            </TypeAhead>
          </FormGroup>
        )}
        {!hideSelfEmployed && (
          <React.Fragment>
            <hr />
            <YesNoQuestion
              resourceName={resourceName}
              resource={employment}
              checked={employment.self_employed}
              name="self_employed"
              questionHeading="Self employment"
              questionText="Were you self employed in this role?"
              handlePropagation={this.handlePropagation}
              disabled={locked}
            />
          </React.Fragment>
        )}
      </React.Fragment>
    )
  }

  dates() {
    const { resourceName, locked, endedAtLocked } = this.props
    const { employment, errors } = this.state
    return (
      <DateRangeQuestion
        resourceName={resourceName}
        resource={employment}
        includeDay={true}
        questionText={
          employment.self_employed ? 'When were you self-employed?' : 'When did you work for this employer?'
        }
        helperText={helperTextData.employments.dates}
        currentChecked={employment.current}
        fromError={errors.started_at}
        toError={errors.ended_at}
        currentText="I'm still employed here"
        handlePropagation={this.handlePropagation}
        fromDisabled={locked}
        toDisabled={endedAtLocked}
      />
    )
  }

  handleCancel(e) {
    const { onCancel } = this.props
    if (onCancel) {
      onCancel(e)
    } else {
      e.preventDefault()
      window.history.back()
    }
  }

  handleBenefitsChange(key, value) {
    const { employment } = this.state
    const { benefits } = employment

    const nextBenefits = Object.assign({}, benefits, { [key]: value })

    this.setState({
      employment: Object.assign({}, employment, {
        benefits: nextBenefits
      })
    })

    onInteractionWithForm()
  }

  benefitsOptions() {
    const { employment } = this.state
    return BENEFITS_OPTIONS.map(option => {
      option.checked = employment.benefits[option.value]
      return option
    })
  }

  render() {
    const { flash, errors, employment } = this.state
    const { constructionEmployee, hideIndustry, locked, endedAtLocked } = this.props

    const showReimbursementsQuestion = !employment.ministry_of_defence && !employment.self_employed
    const showCijcQuestion = employment.received_travel_reimbursements && constructionEmployee

    return (
      <>
        <ContentWrapper modifiers="small">
          <ContentWrapperInner modifiers="longform">
            {flash && <FlashMessage type="info" body={flash} />}
            {!hideIndustry && (
              <React.Fragment>
                {this.industry()}
                <hr />
              </React.Fragment>
            )}
            {this.isMOD() ? this.modQuestions() : this.NonModQuestions()}
            <hr />
            {this.dates()}
            {!employment.self_employed && (
              <Fragment>
                <hr />
                <FormGroup modifiers={errors['benefits'] ? 'error' : null}>
                  <Helper id="helper-benefits" body={helperTextData.employments.benefits} />
                  <Question
                    heading="Benefits"
                    text="Did you receive any of the following taxable benefits whilst working here?"
                  >
                    <HelperTrigger
                      helperId="helper-benefits"
                      eventLabel="the 'Benefits' question on the Employment Form"
                    />
                  </Question>
                  <CheckBoxGroupInput
                    disabled={locked}
                    error={errors['benefits']}
                    modifiers="columns"
                    onChange={this.handleBenefitsChange}
                    options={this.benefitsOptions()}
                  />
                </FormGroup>
              </Fragment>
            )}
            {showReimbursementsQuestion && (
              <Fragment>
                <hr />
                <YesNoQuestion
                  handlePropagation={this.handlePropagation}
                  questionHeading="Reimbursements"
                  questionText="Did this employer reimburse you for travel expenses?"
                  helperText={helperTextData.employments.reimbursement}
                  name={'received_travel_reimbursements'}
                  value={employment.received_travel_reimbursements}
                  resource={employment}
                  resourceName="employment"
                  includeDontKnow={true}
                  disabled={locked}
                />
                {showCijcQuestion && (
                  <Fragment>
                    <hr />
                    <YesNoQuestion
                      handlePropagation={this.handlePropagation}
                      questionText="Were you reimbursed as per the CIJC working rule agreement?"
                      helperText={helperTextData.employments.construction.cijc}
                      name={'paid_under_cis'}
                      value={employment.paid_under_cis}
                      resource={employment}
                      resourceName="employment"
                      includeDontKnow={true}
                      disabled={locked}
                    />
                  </Fragment>
                )}
              </Fragment>
            )}
          </ContentWrapperInner>
          <ContentFooter modifiers="actions">
            <ButtonGroup modifiers="spread">
              <ButtonGroupItem
                buttonModifiers="default"
                element="anchor"
                href={'#'}
                onClick={this.handleCancel}
                disabled={false}
              >
                Cancel
              </ButtonGroupItem>
              <ButtonGroupItem
                buttonModifiers="primary"
                element="button"
                disabled={locked && endedAtLocked}
                onClick={this.handleSubmit}
              >
                Save employer
              </ButtonGroupItem>
            </ButtonGroup>
          </ContentFooter>
        </ContentWrapper>
        {showIdleHelpPanel(this.props.afterSubmitPath) && <IdleHelpPanel page="timelineEmployments" />}
      </>
    )
  }
}
EmploymentForm.propTypes = {
  resourceName: PropTypes.string,
  modEmployee: PropTypes.bool,
  constructionEmployee: PropTypes.bool,
  hideIndustry: PropTypes.bool,
  hideSelfEmployed: PropTypes.bool,
  resource: PropTypes.object,
  authenticityToken: PropTypes.string,
  onAfterSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  locked: PropTypes.bool,
  endedAtLocked: PropTypes.bool,
  afterSubmitPath: PropTypes.string
}
EmploymentForm.defaultProps = {
  resourceName: 'api_person_employment',
  locked: false,
  endedAtLocked: false
}
export default EmploymentForm
