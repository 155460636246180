import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(duration)
dayjs.extend(isBetween)

export const monthNames = () => {
  return [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
}

export const dayOptions = (day, includeDay) => {
  if (!includeDay) return null

  let days = []

  for (let i = 1; i <= 31; i++) {
    days.push(i)
  }

  return {
    optionName: 'day',
    miniButtons: true,
    skipOptionText: "I don't know",
    optionList: days.map(dayValue => ({
      text: dayValue,
      value: dayValue,
      active: day == dayValue
    }))
  }
}

export const monthOptions = (day, month, includeDay) => {
  return {
    optionName: 'month',
    loadDisabled: includeDay,
    disabledOptionTitle: 'This is not valid with the selected day and month',
    optionList: monthNames().map((monthValue, index) => ({
      text: monthValue.substring(0, 3),
      value: index,
      // 2016 is a leap year so this will allow for `29 Feb` to be selected
      // the non-leap years should be disabled if that is the case
      disabled: new Date(2016, index + 1, 0).getDate() < parseInt(day),
      active: month == monthValue
    }))
  }
}

export const yearOptions = (day, month, year, minDate, minYear, maxYear) => {
  const currentYear = new Date().getFullYear()
  const minDateYear = minDate ? minDate.getFullYear() : minYear
  const afterToday = new Date() < new Date(currentYear, month, day)
  const beforeMinDate = minDate > new Date(minDateYear, month, day)
  const leapDay = day == '29' && month == '1'
  let years = []

  for (let i = Math.max(minYear, minDateYear); i <= maxYear; i++) {
    years.push(i)
  }

  return {
    optionName: 'year',
    previousText: 'Earlier',
    nextText: 'Later',
    loadDisabled: true,
    disabledOptionTitle: 'This is not valid with the selected day/month',
    optionList: years.map(yearValue => ({
      text: yearValue,
      value: yearValue,
      disabled:
        (currentYear == yearValue && afterToday) ||
        (minDateYear == yearValue && beforeMinDate) ||
        (Boolean(yearValue % 4) && leapDay),
      active: year == yearValue
    }))
  }
}

export const buildDate = (day, month, year, defaultDay) => {
  if (!year) {
    return null
  }

  if (!day) {
    if (defaultDay === 'last') {
      day = new Date(year, month + 1, 0).getDate() // The last day of the month.
    } else {
      day = 1 // The first day of the month.
    }
  }

  return new Date(year, month, day)
}

export const dateInputValue = (day, month, year) => {
  if (!year) {
    return ''
  }

  return day ? `${day} ${monthNames()[month]} ${year}` : `${monthNames()[month]} ${year}`
}

export const datePickerDateOptions = (day, month, year, minDate, minYear, maxYear, includeDay) => {
  return [
    dayOptions(day, includeDay),
    monthOptions(day, month, includeDay),
    yearOptions(day, month, year, minDate, minYear, maxYear)
  ].filter(Boolean)
}

export const areSameDates = (date1, date2) => {
  if (!date1 || !date2) {
    return false
  }
  return date1.getTime() === date2.getTime()
}

export function getRangeDuration(startDate, endDate) {
  const diff = dayjs(endDate).diff(dayjs(startDate))

  const unroundedYears = dayjs.duration(diff).as('years')
  let roundedYears = Math.floor(unroundedYears || 0)

  const unroundedMonths = dayjs.duration(diff).subtract(roundedYears, 'year').as('months')
  let roundedMonths = Math.round(unroundedMonths || 0)

  // Sometimes we'll end up with dates like 3 years, and 12 months
  // due to the rounding above, in that case we do a little manual
  // correction:
  if (roundedMonths === 12) {
    roundedYears++
    roundedMonths = 0
  }

  return {
    years: roundedYears,
    months: roundedMonths
  }
}

export function parseDate(dateString, fallback) {
  if (!dateString) {
    return fallback || null
  }
  return new Date(dateString)
}

export function shortDateString(date) {
  return dayjs(date).format('D MMM YYYY')
}

export function dateRangeString(startDate, endDate) {
  return `${shortDateString(startDate)} - ${endDate ? shortDateString(endDate) : 'Now'}`
}

export function inDateRange(date, rangeStartDate, rangeEndDate) {
  if (!date || !rangeStartDate || !rangeEndDate) {
    throw 'Expected all parameters to be supplied.'
  }

  // https://day.js.org/docs/en/query/is-between
  return dayjs(date).isBetween(dayjs(rangeStartDate), dayjs(rangeEndDate), 'day', '[]')
}
