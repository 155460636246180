import React from 'react'
import PropTypes from 'prop-types'
import Question from './Question'
import {
  ContentWrapper,
  ContentWrapperInner,
  ContentFooter,
  ButtonGroup,
  InputGroup,
  InputGroupItem,
  FormGroup,
  FormGroupErrorMessage
} from '../layout/Wrapper'
import ButtonGroupItem from '../interactive/ButtonGroupItem'
import BankInput from './BankInput'
import FormGroupHeader from './FormGroupHeader'
import FormGroupInput from './FormGroupInput'

import { updatePageTitle, changeNavVisibility } from '../../modules/legacyUtils'
import { formatTypedAccountNumber, formatTypedSortCode } from '../../modules/bankAccount'

class BankAccountForm extends React.Component {
  constructor(props) {
    super(props)

    this.handleBankChange = this.handleBankChange.bind(this)
    this.handleAccountNumberChange = this.handleAccountNumberChange.bind(this)
    this.handleSortCodeChange = this.handleSortCodeChange.bind(this)
    this.handleAccountHolderChange = this.handleAccountHolderChange.bind(this)
  }

  componentDidMount() {
    const verb = this.props.bankAccount.id ? 'Update' : 'New'
    updatePageTitle(`${verb} bank account`)
    changeNavVisibility(false)
  }

  handleBankChange(bank) {
    const { onChange } = this.props

    if (!bank.id) {
      onChange({ bank_name: bank.name, bank_id: null }, { name: bank.name, id: null })
    } else {
      onChange({ bank_id: bank.id, bank_name: null }, bank)
    }
  }

  handleAccountNumberChange(e) {
    e.preventDefault()
    const { onChange, bankAccount } = this.props
    const previousAccountNumber = bankAccount.account_number
    const newAccountNumber = e.target.value

    onChange({
      account_number: formatTypedAccountNumber(previousAccountNumber, newAccountNumber)
    })
  }

  handleSortCodeChange(e) {
    e.preventDefault()
    const { onChange, bankAccount } = this.props
    const previousSortCode = bankAccount.sort_code
    const newSortCode = e.target.value

    onChange({ sort_code: formatTypedSortCode(previousSortCode, newSortCode) })
  }

  handleAccountHolderChange(e) {
    e.preventDefault()
    const { onChange } = this.props
    onChange({ account_holder: e.target.value })
  }

  render() {
    const { errors, onCancel, onSubmit, bank, bankAccount, submitting } = this.props
    const { account_number, sort_code, account_holder } = bankAccount

    const buttonModifiers = submitting ? ['primary', 'loading'] : 'primary'

    return (
      <ContentWrapper modifiers="small">
        <ContentWrapperInner modifiers="longform">
          <FormGroup modifiers={`bank ${errors.bank_name || errors.bank_id ? 'error' : ''}`}>
            <Question heading="Institution" text="Which bank, building society or credit union is this account with?" />
            <BankInput bank={bank} onChange={this.handleBankChange} error={errors.bank_name || errors.bank_id} />
          </FormGroup>
          <hr />
          <FormGroup modifiers="bank-account">
            <Question
              heading="Account details"
              text="Which bank account would you like us to transfer your refund to?"
            />
            <InputGroup>
              <InputGroupItem>
                <FormGroup modifiers={errors.account_number ? 'error' : ''}>
                  <FormGroupHeader label="Account number" />
                  <FormGroupInput
                    value={account_number}
                    onChange={this.handleAccountNumberChange}
                    id="bank_account_account_number"
                  />
                  {errors.account_number && <FormGroupErrorMessage>{errors.account_number[0]}</FormGroupErrorMessage>}
                </FormGroup>
              </InputGroupItem>
              <InputGroupItem>
                <FormGroup modifiers={errors.sort_code ? 'error' : ''}>
                  <FormGroupHeader label="Sort code" />
                  <FormGroupInput value={sort_code} onChange={this.handleSortCodeChange} id="bank_account_sort_code" />
                  {errors.sort_code && <FormGroupErrorMessage>{errors.sort_code[0]}</FormGroupErrorMessage>}
                </FormGroup>
              </InputGroupItem>
            </InputGroup>
          </FormGroup>
          <FormGroup modifiers={errors.account_holder ? 'error' : ''} className="mt-3">
            <FormGroupHeader label="Account holder" />
            <FormGroupInput
              value={account_holder}
              onChange={this.handleAccountHolderChange}
              id="bank_account_account_holder"
            />
            {errors.account_holder && <FormGroupErrorMessage>{errors.account_holder[0]}</FormGroupErrorMessage>}
            <p>
              <small>Your name should be entered exactly as it appears on your bank statements.</small>
            </p>
          </FormGroup>
        </ContentWrapperInner>
        <ContentFooter modifiers="actions">
          <ButtonGroup modifiers="spread">
            <ButtonGroupItem buttonModifiers="default" element="anchor" href="#" onClick={onCancel}>
              Cancel
            </ButtonGroupItem>
            <ButtonGroupItem buttonModifiers={buttonModifiers} element="anchor" href="#" onClick={onSubmit}>
              Save changes
            </ButtonGroupItem>
          </ButtonGroup>
        </ContentFooter>
      </ContentWrapper>
    )
  }
}

BankAccountForm.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
  bank: PropTypes.object.isRequired,
  bankAccount: PropTypes.object.isRequired,
  submitting: PropTypes.bool
}

BankAccountForm.defaultProps = {
  submitting: false,
  errors: {}
}

export default BankAccountForm
