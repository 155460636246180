import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import ActionBar from '../interactive/ActionBar'
import Button from '../interactive/Button'

import { iconFromString } from '../../helpers/images'
import { generateModifierClasses, redirectTo } from '../../modules/utils'

class TimelineGap extends React.Component {
  actions() {
    const { actions } = this.props
    if (!actions || !actions.length) return null
    if (actions.length == 1 && !actions[0].componentName) {
      return (
        <Fragment>
          <Button
            classes={['timeline-gap__button']}
            modifiers={actions[0].modifiers}
            onClick={() => redirectTo(actions[0].href)}
          >
            <img
              className="timeline-gap__action__image"
              alt={actions[0].imgAlt}
              src={iconFromString(actions[0].imgName)}
            />
          </Button>
          <a className="timeline-gap__link" href={actions[0].href}>
            <span className="sr-only">{actions[0].srText}</span>
          </a>
        </Fragment>
      )
    }
    return <ActionBar actions={actions} />
  }

  render() {
    const { timelineString } = this.props

    const modifiers = [].concat(this.props.modifiers)

    const timelineClasses = generateModifierClasses('timeline-gap', modifiers)

    return (
      <div className={timelineClasses}>
        <div className="timeline-gap__content">
          <span className="timeline-gap__text" dangerouslySetInnerHTML={{ __html: timelineString }} />
          {this.actions()}
        </div>
      </div>
    )
  }
}

TimelineGap.propTypes = {
  actions: PropTypes.array.isRequired,
  modifiers: PropTypes.array,
  timelineString: PropTypes.string.isRequired
}

TimelineGap.defaultProps = {
  modifiers: []
}

export default TimelineGap
