import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSwipeable } from 'react-swipeable'

import { Emoji } from '../layout/Wrapper'
import EmojiImage from '../images/EmojiImage'

import { useViewport } from '../../modules/hooks'
import { animate, getNextIndex } from '../../modules/carousel'
import { BREAKPOINTS, preloadImages, prefersReducedMotion } from '../../modules/utils'

const ANIMATION_DURATION = prefersReducedMotion() ? 1 : 500

export default function PillCarousel({ slides }) {
  const { width } = useViewport()
  const [activeIndex, setActiveIndex] = useState(0)
  const [transition, setTransition] = useState(null)

  const mobile = width < BREAKPOINTS.MEDIUM
  const imageUrls = slides.map(slide => (mobile ? slide.mobileImage : slide.image))

  useEffect(() => preloadImages(imageUrls), [mobile])

  const slideTo = (nextIndex, direction = null) => {
    if (transition || nextIndex == activeIndex) {
      return
    }

    if (!direction) {
      direction = nextIndex > activeIndex ? 'right' : 'left'
    }

    animate(
      () => {
        setTransition(`${direction}-out`)
      },
      () => {
        setActiveIndex(nextIndex)
        setTransition(`${direction}-in`)
      },
      () => {
        setTransition(null)
      },
      ANIMATION_DURATION
    )
  }

  const slide = direction => {
    return slideTo(getNextIndex(activeIndex, slides.length, direction), direction)
  }

  return (
    <div
      className="pill-carousel"
      {...useSwipeable({
        onSwipedLeft: () => slide('right'),
        onSwipedRight: () => slide('left')
      })}
    >
      <div className="pill-carousel__slide">
        <div className="pill-carousel__slide-info">
          <p className="pill-carousel__slide-count">
            <span>0{activeIndex + 1}</span> / 0{slides.length}
          </p>

          <div className={`pill-carousel__slide-content pill-carousel__transition--${transition}`}>
            <h2>{slides[activeIndex].title}</h2>
            <p>{slides[activeIndex].copy}</p>
          </div>

          <nav>
            <ul>
              <li onClick={() => slide('left')} tabIndex="0">
                <Emoji>
                  <EmojiImage name="arrow" alt="arrow" />
                </Emoji>
              </li>
              <li onClick={() => slide('right')} className="pill-carousel__slide-arrow--right" tabIndex="0">
                <Emoji>
                  <EmojiImage name="arrow" alt="arrow" />
                </Emoji>
              </li>
            </ul>
          </nav>
        </div>

        <div className={`pill-carousel__slide-image pill-carousel__transition--${transition}`}>
          <div className="pill-carousel__slide-image-inner">
            <img src={imageUrls[activeIndex]} alt={slides[activeIndex].imageAlt} />
          </div>
        </div>
      </div>

      <div className="pill-carousel__nav">
        <nav>
          <ul>
            {slides.map(({ pillLabel, pillIcon }, slideIndex) => {
              const classes = activeIndex === slideIndex ? 'active' : ''
              return (
                <li key={pillLabel} className={classes} onClick={() => slideTo(slideIndex)} tabIndex="0">
                  <Emoji>
                    <EmojiImage name={pillIcon} alt={pillIcon} />
                  </Emoji>
                  {pillLabel}
                </li>
              )
            })}
          </ul>
        </nav>
      </div>
    </div>
  )
}

PillCarousel.propTypes = {
  slides: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      copy: PropTypes.string,
      mobileImage: PropTypes.string,
      image: PropTypes.string,
      imageAlt: PropTypes.string,
      pillLabel: PropTypes.string,
      pillIcon: PropTypes.string
    })
  ).isRequired
}
