import { omit, includes } from './utils'
import { parseDate } from './dates'

import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
dayjs.extend(duration)

// Calculates a start and end date for the Timeline component.
export function timelineDateRange() {
  const today = new Date()
  const currentYear = today.getFullYear()

  const _afterNewRetentionDate = afterNewRetentionDate()
  const fromYear = currentYear - (_afterNewRetentionDate ? 4 : 5)
  const toYear = _afterNewRetentionDate ? currentYear : currentYear - 1

  return [dayjs(Date.UTC(fromYear, 3, 6)), dayjs(new Date(toYear, 3, 5))]
}

// Figures out which months on the Timeline component should be marked as filled.
export function timelineMonths(startDate, endDate, filledDateRanges, loaded) {
  const monthCount = Math.floor(dayjs.duration(endDate.diff(startDate)).as('months'))

  const result = []
  for (let i = 0; i < monthCount + 1; i++) {
    const firstEntry = i === 0
    const lastEntry = i === monthCount

    let periodStart = startDate.add(i, 'month')
    let periodEnd = toLastDayOfMonth(periodStart)

    if (lastEntry) {
      periodEnd = periodStart
      periodStart = toFirstDayOfMonth(periodStart)
    } else if (!firstEntry) {
      periodStart = toFirstDayOfMonth(periodStart)
    }

    result.push({
      date: periodStart,
      filled: loaded && !inDateRanges(periodStart, periodEnd, filledDateRanges)
    })
  }
  return result
}

export function dateRangesForTimelineGaps(timelineGaps, resourceName) {
  if (!timelineGaps) {
    return []
  }

  return timelineGaps
    .filter(g => g.resource_name === resourceName)
    .map(g => {
      const startedAtArr = g.started_at.split('-') // yyyy-MM-dd
      const endedAtArr = g.ended_at.split('-') // yyyy-MM-dd
      return {
        related_resource_id: g.related_resource_id,
        preceding_resource_id: g.preceding_resource_id,
        succeeding_resource_id: g.succeeding_resource_id,
        display_data: g.display_data,
        started_at: dayjs(new Date(startedAtArr[0], startedAtArr[1] - 1, startedAtArr[2])),
        ended_at: dayjs(new Date(endedAtArr[0], endedAtArr[1] - 1, endedAtArr[2]))
      }
    })
}

export function generateDisplayListActions(
  resources,
  timelineGaps,
  dataToOmit = [],
  skipRetentionPrompts = false,
  additionalRetentionPromptData = {}
) {
  if (!resources) {
    return []
  }

  // Join the resources and timeline gaps together, sort them by date:
  const ids = resources.map(res => res.id)
  const resourcesAndGaps = (timelineGaps || [])
    .filter(gap => isDisplayableTimelineGap(gap, ids))
    .concat(resources)
    .sort((a, b) => new Date(b.started_at) - new Date(a.started_at))

  if (!skipRetentionPrompts) {
    attachRetentionPrompts(resourcesAndGaps, additionalRetentionPromptData)
  }

  // Return the display data for the resulting list (to be passed to React components):
  return resourcesAndGaps.map(obj => omit(obj.display_data, dataToOmit))
}

// Private

function isDisplayableTimelineGap(gap, resourceIds) {
  const { succeeding_resource_id, preceding_resource_id } = gap

  if (!gap.display_data) {
    return false
  }

  const hasNoResource = !succeeding_resource_id && !preceding_resource_id
  const hasSucceedingResource = includes(resourceIds, succeeding_resource_id)
  const hasPrecedingResource = includes(resourceIds, preceding_resource_id)

  return hasNoResource || hasSucceedingResource || hasPrecedingResource
}

function attachRetentionPrompts(resourcesAndGaps, additionalRetentionPromptData) {
  for (let i = 0; i < resourcesAndGaps.length; i++) {
    const resource = resourcesAndGaps[i]

    if (needsRetentionPrompt(resource)) {
      attachRentionPrompt(resourcesAndGaps, i, resource, additionalRetentionPromptData)
      i++ // We know the next item is the retention prompt we just attached, so skip it.
    }
  }
}

function needsRetentionPrompt(resource) {
  const resourceValid = resource.valid !== false // Deliberately checking this is NOT false. Other falsy values like undefined and null are ok.
  const resourceCreatedAt = parseDate(resource.created_at)
  const resourceConfirmedAt = parseDate(resource.last_confirmed_at)
  const _retentionDate = retentionDate()

  return (
    resource.id && // Timeline gaps don't have IDs
    resourceValid &&
    !resource.ended_at &&
    ((afterNewRetentionDate() && resourceCreatedAt < _retentionDate && !resourceConfirmedAt) ||
      (resourceConfirmedAt && resourceConfirmedAt < _retentionDate))
  )
}

function attachRentionPrompt(resourcesAndGaps, index, lastResource, additionalRetentionPromptData) {
  let timelineString,
    questionHeading,
    questionText = null
  const resourceHeading = lastResource.display_data.heading
  const resourceType = inferResourceType(lastResource)

  switch (resourceType) {
    case 'address':
      timelineString = `Do you still live at <strong>${resourceHeading}</strong>?`
      questionHeading = 'Change of address'
      questionText = `When did you move out of ${resourceHeading}?`
      break
    case 'employment':
      const modEmployment = !!lastResource.mod_branch
      timelineString = `Are you still working for<strong>${modEmployment ? ' the' : ''} ${resourceHeading}</strong>?`
      questionHeading = 'End of employment'
      questionText = `When did you leave${modEmployment ? ' the' : ''} ${resourceHeading}?`
      break
    case 'employmentGap':
      timelineString = `Are you still <strong>unemployed</strong>?`
      questionHeading = 'End of unemployment'
      questionText = `When did your unemployment end?`
      break
    case 'location':
      timelineString = `Are you still working at <strong>${resourceHeading}</strong>?`
      questionHeading = 'Change of workplace'
      questionText = `When did you stop working at ${resourceHeading}?`
      break
    case 'locationGap':
      timelineString = `Are you still <strong>not travelling to work</strong>?`
      questionHeading = 'End of non-travel period'
      questionText = `When did you start travelling to work again?`
      break
    default:
      // These are never intended to display, but provide a serviceable default if for some
      // reason this ends up being accidentally used for an unsupported resource:
      timelineString = `Are you still at <strong>${resourceHeading}</strong>?`
      questionHeading = 'Change of end date'
      questionText = `When did you stop at ${resourceHeading}?`
  }

  const displayData = {
    timelineString,
    questionHeading,
    questionText,
    componentName: 'TimelineEndedAtPrompt',
    resourceType: resourceType,
    resourceId: lastResource.id
  }

  if (resourceType === 'location') {
    // These additional attributes are included so the retntion prompt can validate the supplied date
    // falls within the date of the employment this location is related to.
    displayData.relatedResourceHeading = additionalRetentionPromptData.heading
    displayData.minDate = parseDate(additionalRetentionPromptData.startedAt)
    displayData.maxDate = parseDate(additionalRetentionPromptData.endedAt, new Date())
  }

  resourcesAndGaps.splice(index, 0, {
    display_data: displayData
  })
}

function inferResourceType(resource) {
  switch (resource.class_name) {
    case 'Api::Person::Address':
      return 'address'
      break
    case 'Api::Person::Employment':
      return 'employment'
      break
    case 'Api::Person::EmploymentGap':
      return 'employmentGap'
      break
    case 'Api::Person::Location':
      return 'location'
      break
    case 'Api::Person::LocationGap':
      return 'locationGap'
      break
  }
}

function inDateRanges(periodStart, periodEnd, timelineGapDateRanges) {
  return timelineGapDateRanges.some(({ started_at, ended_at }) => {
    return periodStart >= started_at && periodEnd <= ended_at
  })
}

function toFirstDayOfMonth(date) {
  return date.date(1).hour(1).minute(0).second(0)
}

function toLastDayOfMonth(date) {
  return date.add(1, 'month').date(1).hour(1).minute(0).second(0).subtract(1, 'day')
}

function retentionDate() {
  const day = window.myRIFT.config.retentionDay
  const month = window.myRIFT.config.retentionMonth - 1
  const year = new Date().getFullYear()
  return new Date(year, month, day, 0, 0, 0, 0)
}

function afterNewRetentionDate() {
  return new Date() > retentionDate()
}
