import React from 'react'
import PropTypes from 'prop-types'

import { generateModifierClasses, omit } from '../../modules/utils'

export default function ButtonGroupItem(props) {
  const { buttonModifiers, children, className, element, modifiers } = props
  const buttonGroupClasses = generateModifierClasses('button-group__item', modifiers)
  const buttonClasses = generateModifierClasses('button', buttonModifiers)

  let classes = `${buttonGroupClasses} ${buttonClasses}`
  if (className) {
    classes += ` ${className}`
  }

  const childProps = {
    ...omit(props, ['buttonModifiers', 'children', 'className', 'element', 'modifiers']),
    className: classes
  }

  return element === 'button' ? <button {...childProps}>{children}</button> : <a {...childProps}>{children}</a>
}

ButtonGroupItem.propTypes = {
  disabled: PropTypes.bool,
  element: PropTypes.oneOf(['anchor', 'button']),
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  buttonModifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

ButtonGroupItem.defaultProps = {
  element: 'button'
}
