import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import FloatingDatePicker from './date/FloatingDatePicker'
import ErrorSpan from './ErrorSpan'

import { InputGroup } from '../layout/Wrapper'

class DateRangeInput extends React.Component {
  constructor(props) {
    super(props)

    this.toggleChange = this.toggleChange.bind(this)
    this.dateChanged = this.dateChanged.bind(this)
  }

  toggleChange() {
    const { toName } = this.props
    const currentChecked = !this.props.currentChecked
    this.dateChanged(toName, null, currentChecked)
  }

  dateChanged(name, value, currentChecked) {
    currentChecked = currentChecked === undefined ? this.props.currentChecked : currentChecked
    this.props.handlePropagation(name, value, currentChecked)
  }

  renderToggle() {
    const { currentChecked, resourceName, currentText, toDisabled } = this.props
    const prefix = resourceName.replace('[', '_').replace(']', '')

    if (toDisabled) {
      return null
    }

    return (
      <div className="toggle">
        <label className="toggle__label" htmlFor={`${prefix}_current`}>
          <input
            className="toggle__input"
            type="checkbox"
            value="true"
            name={`${resourceName}[current]`}
            id={`${prefix}_current`}
            defaultChecked={currentChecked}
            onChange={this.toggleChange}
          />
          <span className="toggle__indicator" />
          <span className="toggle__text">{currentText}</span>
        </label>
      </div>
    )
  }

  render() {
    const {
      currentChecked,
      resource,
      resourceName,
      fromError,
      fromName,
      toError,
      toName,
      fromMinYear,
      fromMaxYear,
      toMinYear,
      toMaxYear,
      includeDay,
      fromDisabled,
      toDisabled,
      helperText
    } = this.props

    const errors = [...(toError || []), ...(fromError || [])]

    return (
      <Fragment>
        <InputGroup>
          <FloatingDatePicker
            label="From"
            name={fromName}
            resource={resource}
            resourceName={resourceName}
            error={Boolean(fromError)}
            minYear={fromMinYear}
            maxYear={fromMaxYear}
            includeDay={includeDay}
            handlePropagation={this.dateChanged}
            disabled={fromDisabled}
          />
          <FloatingDatePicker
            label="Until"
            name={toName}
            resource={resource}
            resourceName={resourceName}
            disabled={toDisabled || currentChecked}
            defaultDay="last"
            disabledText="Now"
            error={Boolean(toError)}
            minYear={toMinYear}
            maxYear={toMaxYear}
            includeDay={includeDay}
            handlePropagation={this.dateChanged}
            direction="left"
            ref={this.toDatePickerRef}
          />
        </InputGroup>
        <ErrorSpan error={errors} />
        {helperText && (
          <span
            className="form-group__help-text form-group__help-text--right"
            dangerouslySetInnerHTML={{ __html: helperText }}
          />
        )}
        {this.renderToggle()}
      </Fragment>
    )
  }
}

DateRangeInput.propTypes = {
  resource: PropTypes.object,
  resourceName: PropTypes.string,
  fromError: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  toError: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  includeDay: PropTypes.bool,
  fromMinYear: PropTypes.number,
  fromMaxYear: PropTypes.number,
  toMinYear: PropTypes.number,
  toMaxYear: PropTypes.number,
  handlePropagation: PropTypes.func,
  currentChecked: PropTypes.bool,
  currentText: PropTypes.string,
  helperText: PropTypes.string,
  fromDisabled: PropTypes.bool,
  toDisabled: PropTypes.bool
}

DateRangeInput.defaultProps = {
  fromName: 'started_at',
  toName: 'ended_at',
  fromMinYear: new Date().getFullYear() - 44,
  fromMaxYear: new Date().getFullYear(),
  toMinYear: new Date().getFullYear() - 44,
  toMaxYear: new Date().getFullYear(),
  fromDisabled: false,
  toDisabled: false
}

export default DateRangeInput
