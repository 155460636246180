import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import Question from './Question'
import RadioOption from './RadioOption'
import Stepper from './Stepper'

export default function OptionalStepper({ minValue, name, onChange, questionText, resourceName, value }) {
  const [disableStepper, setDisableStepper] = useState(false)

  useEffect(() => {
    setDisableStepper(!value)
  }, [value])

  const onStepperClick = () => {
    if (disableStepper) {
      setDisableStepper(false)
      onChange(minValue)
    }
  }

  return (
    <>
      <Question text={questionText} small={true} />
      <div className="optional-stepper">
        <ul className="option-group option-group--buttons">
          <li className="option-group__item" onClick={onStepperClick}>
            <Stepper onChange={onChange} value={value || minValue} disabled={disableStepper} />
          </li>
          <li className="option-group__item">
            <RadioOption
              value={'unknown'}
              label="I don't know"
              checked={value === null}
              onChange={() => onChange(null)}
              name={name}
              resourceName={resourceName}
            />
          </li>
        </ul>
      </div>
    </>
  )
}

OptionalStepper.propTypes = {
  minValue: PropTypes.number,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  resourceName: PropTypes.string.isRequired,
  value: PropTypes.number
}

OptionalStepper.defaultProps = {
  minValue: 0,
  value: null
}
