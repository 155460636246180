import React from 'react'
import PropTypes from 'prop-types'

import Select from './Select'

import { FormGroup, InputGroup, InputGroupItem } from '../layout/Wrapper'

class FrequencyInput extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(key, value) {
    const { qualitativeFrequency, quantitativeFrequency, onChange } = this.props
    if (key === 'qualitativeFrequency') {
      onChange(quantitativeFrequency, value)
    } else {
      onChange(value, qualitativeFrequency)
    }
  }

  render() {
    const { disabled, quantitativeFrequency, qualitativeFrequency, showDeprecatedOptions } = this.props

    const options = ['Per week', 'Every 2 weeks', 'Every 3 weeks', 'Per month']
    if (showDeprecatedOptions) {
      options.unshift(['Every day', 'Every day', true])
      options.push(['Other', 'Other', true])
    }

    return (
      <InputGroup>
        <InputGroupItem>
          <FormGroup>
            <Select
              disabled={disabled}
              name="quantitative-frequency-select"
              value={quantitativeFrequency}
              options={[
                [1, 'Once'],
                [2, 'Twice'],
                [3, '3 times'],
                [4, '4 times'],
                [5, '5 times'],
                [6, '6 times'],
                [7, '7 times']
              ]}
              onChange={e => this.handleChange('quantitativeFrequency', parseInt(e.target.value, 10))}
            />
          </FormGroup>
        </InputGroupItem>
        <InputGroupItem>
          <FormGroup>
            <Select
              disabled={disabled}
              name="qualitative-frequency-select"
              value={qualitativeFrequency}
              options={options}
              onChange={e => this.handleChange('qualitativeFrequency', e.target.value)}
            />
          </FormGroup>
        </InputGroupItem>
      </InputGroup>
    )
  }
}

FrequencyInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  quantitativeFrequency: PropTypes.number,
  qualitativeFrequency: PropTypes.string,
  showDeprecatedOptions: PropTypes.bool
}

FrequencyInput.defaultProps = {
  disabled: false,
  quantitativeFrequency: 5,
  qualitativeFrequency: 'Per week',
  showDeprecatedOptions: false
}

export default FrequencyInput
