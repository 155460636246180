import React from 'react'
import PropTypes from 'prop-types'

import Base from '../../../assets/images/logo/base.svg'
import Home from '../../../assets/images/logo/home.svg'
import My from '../../../assets/images/logo/my.svg'

export default function Logo({ className, href }) {
  return (
    <a className={`${className ? className : ''} logo`} href={href} alt="RIFT Refunds">
      <img src={Home} className="logo__home" />
      <img src={My} className="logo__my" />
      <img src={Base} className="logo__base" />
    </a>
  )
}

Logo.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string.isRequired
}
