import React from 'react'
import PropTypes from 'prop-types'

import { iconFromString } from '../../helpers/images'

const addressFromGoogleGeocodeResult = function (result, query) {
  const { location } = result.geometry
  const addressParts = result.address_components

  const address = {
    latitude: location.lat(),
    longitude: location.lng()
  }

  if (addressParts) {
    const numberPart = findAddressPart(addressParts, 'street_number')
    const streetPart = findAddressPart(addressParts, 'route')

    if (numberPart || streetPart) {
      address['first_line'] = `${numberPart || ''} ${streetPart || ''}`.trim()
    } else {
      address['first_line'] = query
    }

    Object.assign(address, {
      name: result.name,
      town: findAddressPart(addressParts, ['postal_town', 'locality', 'political']),
      county: findAddressPart(addressParts, 'administrative_area_level_2'),
      country: findAddressPart(addressParts, 'country'),
      postcode: findAddressPart(addressParts, 'postal_code') || query
    })
  }

  return address
}

const findAddressPart = function (addressParts, partTypes) {
  partTypes = [].concat(partTypes)

  if (!addressParts) {
    return null
  }

  for (let i = 0; i < partTypes.length; i++) {
    const partType = partTypes[i]
    const result = addressParts.find(part => part.types.indexOf(partType) !== -1)

    if (result) {
      return result.long_name
    }
  }

  return null
}

class Map extends React.Component {
  constructor(props) {
    super(props)

    this.handleMarkerChange = this.handleMarkerChange.bind(this)
  }

  componentDidMount() {
    const { latitude, longitude } = this.props

    const latLng = {
      lat: parseFloat(latitude),
      lng: parseFloat(longitude)
    }
    const mapEl = document.querySelector('.map')

    if (!window.geocoder) {
      window.geocoder = new google.maps.Geocoder()
    }

    this.geocoder = window.geocoder
    this.map = new google.maps.Map(mapEl, {
      zoom: 17,
      center: latLng
    })

    this.marker = new google.maps.Marker({
      map: this.map,
      draggable: true,
      icon: this.mapIcon(true)
    })
    this.marker.addListener('dragend', this.handleMarkerChange)

    if (latitude && longitude) {
      this.updateMap(latLng)
    }
  }

  mapIcon(tip) {
    if (tip) {
      return {
        url: iconFromString('mapPinTip'),
        scaledSize: new google.maps.Size(99, 120),
        origin: new google.maps.Point(0, 0),
        anchor: new google.maps.Point(46, 110)
      }
    }

    return {
      url: iconFromString('mapPin'),
      scaledSize: new google.maps.Size(64, 73),
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(29, 63)
    }
  }

  updateMap(latLng) {
    this.map.setCenter(latLng)
    setTimeout(() => this.marker.setPosition(latLng), 100)
  }

  handleMarkerChange(marker) {
    const { latLng } = marker
    const { onChange, query } = this.props

    this.marker.setIcon(this.mapIcon())

    this.geocoder.geocode({ location: { lat: latLng.lat(), lng: latLng.lng() } }, results => {
      if (results && results.length) {
        return onChange(addressFromGoogleGeocodeResult(results[0], query))
      }
    })
  }

  render() {
    return <div className="map" />
  }
}

Map.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  query: PropTypes.string.isRequired
}

export default Map
