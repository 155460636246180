import React from 'react'
import PropTypes from 'prop-types'
import AddressForm from '../../interactive/AddressForm'
import IdleHelpPanel from '../../interactive/IdleHelpPanel'

import { omit, redirectTo, highlightFirstError } from '../../../modules/utils'
import { createOrUpdate, deriveStateFromProps } from '../../../modules/address'
import { showIdleHelpPanel, onInteractionWithForm } from '../../../modules/idleHelpPanel'

class NewOrEdit extends React.Component {
  constructor(props) {
    super(props)
    let flash = false
    if (props.locked) {
      flash = props.endedAtLocked
        ? 'You cannot update this address'
        : "You can only update the <strong>'Until date'</strong> on this address"
      flash =
        flash +
        ' because it has been used in one of your previous claim submissions. Please <a data-trigger-modal="contact-modal" href="">contact us</a> if you need anything else corrected.</p>'
    }
    this.state = {
      errors: {},
      disabled: !props.resource.first_line || props.endedAtLocked,
      flash: flash,
      flashType: 'info'
    }
    this.onChange = this.onChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCancel = this.handleCancel.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    return deriveStateFromProps(props, state)
  }

  onChange(attributeObject = {}) {
    const { endedAtLocked } = this.props
    const { address, errors } = this.state

    const nextAddress = Object.assign({}, address, attributeObject)

    this.setState({
      disabled: !nextAddress.first_line || endedAtLocked,
      address: nextAddress,
      errors: omit(errors, Object.keys(attributeObject))
    })

    onInteractionWithForm()
  }

  handleSubmit(e) {
    e.preventDefault()

    this.setState({ disabled: true })

    const { address } = this.state
    const { referrer, authenticityToken } = this.props

    createOrUpdate(address, authenticityToken)
      .then(() => {
        if (referrer) {
          redirectTo(referrer)
        }
      })
      .catch(errResponse => {
        const data = errResponse[1]
        console.error(JSON.stringify(data.errors))
        this.setState(data, function () {
          highlightFirstError()
        })
      })
  }

  handleCancel(e) {
    e.preventDefault()
    window.history.back()
  }

  render() {
    return (
      <>
        <AddressForm
          cancelClicked={this.handleCancel}
          handleSubmit={this.handleSubmit}
          onChange={this.onChange}
          {...this.state}
          {...omit(this.props, ['authenticityToken', 'resource'])}
        />
        {showIdleHelpPanel(this.props.referrer) && <IdleHelpPanel page="timelineAddresses" />}
      </>
    )
  }
}
NewOrEdit.propTypes = {
  resourceName: PropTypes.string,
  resource: PropTypes.object,
  authenticityToken: PropTypes.string,
  referrer: PropTypes.string,
  locked: PropTypes.bool,
  endedAtLocked: PropTypes.bool
}
export default NewOrEdit
