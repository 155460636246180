import React from 'react'
import PropTypes from 'prop-types'

import AnchorButton from './AnchorButton'

import { fetch, redirectTo, isIterable } from '../../modules/utils'

class FetchAndRedirectButton extends React.Component {
  constructor(props) {
    super(props)
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    const { path, method, redirect_path } = this.props
    fetch(method, path)
      .then(() => redirectTo(redirect_path))
      .catch(e => {
        if (isIterable(e)) {
          const [message] = e
          console.error('Fetch failed: ' + message)
        }
      })
  }

  render() {
    return (
      <AnchorButton
        children={this.props.children}
        classes={this.props.classes}
        onClick={this.handleClick}
        modifiers={this.props.modifiers}
      />
    )
  }
}

FetchAndRedirectButton.propTypes = {
  children: PropTypes.string,
  path: PropTypes.string.isRequired,
  method: PropTypes.string.isRequired,
  redirect_path: PropTypes.string.isRequired,
  modifiers: PropTypes.array,
  classes: PropTypes.array
}
FetchAndRedirectButton.defaultProps = {
  modifiers: ['block', 'primary'],
  classes: []
}
export default FetchAndRedirectButton
