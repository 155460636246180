import React from 'react'
import PropTypes from 'prop-types'

import { buttonClasses, methodCallFromString } from '../../modules/utils'

class AnchorButton extends React.Component {
  render() {
    const { classes, modifiers, type } = this.props
    return (
      <a
        {...this.props}
        className={buttonClasses(classes, modifiers, type)}
        onClick={methodCallFromString(this.props.onClick)}
      >
        <span className="button__inner">{this.props.children}</span>
      </a>
    )
  }
}

AnchorButton.propTypes = {
  href: PropTypes.string,
  classes: PropTypes.array,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  target: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  children: PropTypes.string
}
AnchorButton.defaultProps = {
  href: '#'
}
export default AnchorButton
