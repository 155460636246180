import React from 'react'
import PropTypes from 'prop-types'

import EmojiImage from '../../images/EmojiImage'
import OptionsItemButton from '../OptionsItemButton'
import OptionsItemLabel from '../OptionsItemLabel'
import OptionsItemText from '../OptionsItemText'

import { inputName, inputId, pick } from '../../../modules/utils'

class OptionsItem extends React.Component {
  constructor(props) {
    super(props)
    this.handleLabelClick = this.handleLabelClick.bind(this)
    this.handleEditClick = this.handleEditClick.bind(this)
  }

  input() {
    return (
      <input
        className="options__item__input"
        placeholder="false"
        hint="false"
        type="radio"
        name={inputName.apply(this)}
        id={inputId.apply(this)}
        readOnly={true}
        {...pick(this.props, ['value', 'checked', 'disabled'])}
      />
    )
  }

  handleLabelClick(e) {
    if (this.props.onChange) {
      e.preventDefault()
      this.props.onChange(this.props.value)
    }
  }

  handleEditClick(e) {
    if (this.props.editAction) {
      e.preventDefault()
      this.props.editAction(this.props.value)
    }
  }

  label() {
    const { label, microcopy, editAction, locked } = this.props
    let labelFunction = <span className="options__item__indicator" />
    if (editAction && locked) {
      labelFunction = <EmojiImage modifiers={['sm']} alt="locked" name="lock" />
    } else if (editAction) {
      labelFunction = <OptionsItemButton onClick={this.handleEditClick}>Edit</OptionsItemButton>
    }
    return (
      <OptionsItemLabel
        modifiers="radio"
        htmlFor={!locked && editAction ? undefined : inputId.apply(this)}
        onClick={!locked && editAction ? undefined : this.handleLabelClick}
      >
        {!editAction && this.input()}
        <OptionsItemText label={label} microcopy={microcopy} />
        {labelFunction}
      </OptionsItemLabel>
    )
  }

  render() {
    return <div className={`options__item ${this.props.disabled ? 'options__item--disabled' : ''}`}>{this.label()}</div>
  }
}
OptionsItem.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  resourceName: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  microcopy: PropTypes.string,
  checked: PropTypes.bool,
  editAction: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
  locked: PropTypes.bool,
  disabled: PropTypes.bool
}

OptionsItem.defaultProps = {
  disabled: false
}
export default OptionsItem
