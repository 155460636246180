import React, { useEffect, useState, Fragment } from 'react'
import PropTypes from 'prop-types'

import GeneralExpenseForm from '../../interactive/GeneralExpenseForm'
import WorkplaceExpenseForm from '../../interactive/WorkplaceExpenseForm'
import IdleHelpPanel from '../../interactive/IdleHelpPanel'

import { findIndexBy, removeIndex, isUuidv4, updateIndex, redirectTo } from '../../../modules/utils'
import { showIdleHelpPanel, onInteractionWithForm } from '../../../modules/idleHelpPanel'

import {
  fetchEmployments,
  fetchExpenses,
  fetchLocations,
  fetchExpenseFrequencies,
  fetchExpenseKinds,
  fetchExpenseSectors,
  createExpense,
  updateExpense,
  deleteExpense
} from '../../../modules/api'

const attachErrors = (expense, errors) => {
  return Object.assign({}, expense, errors)
}

const combineExpenses = (originalExpenses, newExpenses, updatedExpenses, removedExpenses) => {
  const result = [].concat(originalExpenses, newExpenses)

  updatedExpenses.forEach(updatedExpense => {
    const expenseIndex = result.findIndex(ex => ex.id === updatedExpense.id)
    result[expenseIndex] = updatedExpense
  })

  removedExpenses.forEach(removedExpense => {
    const expenseIndex = result.findIndex(ex => ex.id === removedExpense.id)
    result.splice(expenseIndex, 1)
  })

  return result
}

export default function ExpensesNew({ afterSubmitPath }) {
  const workplace = window.location.search.match(/workplace=t/)
  const [newExpenses, setNewExpenses] = useState([])
  const [updatedExpenses, setUpdatedExpenses] = useState([])
  const [removedExpenses, setRemovedExpenses] = useState([])

  const [{ expenseKinds, expenseSectors, expenseFrequencies, expenses, employments, locations }, setResources] =
    useState({
      expenseKinds: [],
      expenseSectors: [],
      expenseFrequencies: [],
      expenses: [],
      employments: [],
      locations: []
    })

  const combinedExpenses = combineExpenses(expenses, newExpenses, updatedExpenses, removedExpenses)

  useEffect(() => {
    Promise.all([
      fetchExpenseKinds(),
      fetchExpenseSectors(),
      fetchExpenseFrequencies(),
      fetchExpenses(),
      fetchEmployments(),
      fetchLocations()
    ]).then(([expenseKinds, expenseSectors, expenseFrequencies, expenses, employments, locations]) => {
      setResources({
        expenseKinds,
        expenseSectors,
        expenseFrequencies,
        expenses,
        employments,
        locations
      })
    })
  }, [])

  const handleAddExpenses = added => {
    setNewExpenses(newExpenses.concat(added))
    onInteractionWithForm()
  }

  const handleUpdateExpenses = updates => {
    updates = [].concat(updates)
    let nextNewExpenses = newExpenses
    let nextUpdatedExpenses = updatedExpenses

    updates.forEach(updatedExpense => {
      // If we're updating a new (unsaved) expense...
      if (isUuidv4(updatedExpense.id)) {
        const index = findIndexBy(nextNewExpenses, 'id', updatedExpense.id)
        nextNewExpenses = updateIndex(nextNewExpenses, index, updatedExpense)
        return
      }

      // If we're updating a saved expense...
      const index = findIndexBy(nextUpdatedExpenses, 'id', updatedExpense.id)
      if (index !== -1) {
        nextUpdatedExpenses = updateIndex(nextUpdatedExpenses, index, updatedExpense)
        return
      }

      nextUpdatedExpenses = nextUpdatedExpenses.concat(updatedExpense)
    })

    setNewExpenses(nextNewExpenses)
    setUpdatedExpenses(nextUpdatedExpenses)
    onInteractionWithForm()
  }

  const handleRemoveExpense = removed => {
    if (removed.id.toString().length > 15) {
      const index = findIndexBy(newExpenses, 'id', removed.id)
      return setNewExpenses(removeIndex(newExpenses, index))
    }

    const index = findIndexBy(updatedExpenses, 'id', removed.id)
    if (index !== -1) {
      setUpdatedExpenses(removeIndex(updatedExpenses, index))
    }
    setRemovedExpenses(removedExpenses.concat(removed))
    onInteractionWithForm()
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const erroneous = []
    const failedDeletes = []

    for (let i = 0; i < newExpenses.length; i++) {
      const { cost } = newExpenses[i]
      if (cost && cost !== '0') {
        await createExpense(newExpenses[i]).catch(errResponse =>
          erroneous.push(attachErrors(newExpenses[i], errResponse[1]))
        )
      }
    }

    for (let i = 0; i < updatedExpenses.length; i++) {
      await updateExpense(updatedExpenses[i]).catch(errResponse =>
        erroneous.push(attachErrors(updatedExpenses[i], errResponse[1]))
      )
    }

    for (let i = 0; i < removedExpenses.length; i++) {
      await deleteExpense(removedExpenses[i]).catch(errResponse =>
        failedDeletes.push(attachErrors(removedExpenses[i], errResponse[1]))
      )
    }

    if (erroneous.length) {
      handleUpdateExpenses(erroneous)
    }

    if (failedDeletes.length) {
      console.error('Failed to delete some expenses', failedDeletes)
    }

    if (!erroneous.length && !failedDeletes.length) {
      redirectTo(afterSubmitPath)
    }
  }

  const handleCancel = e => {
    e.preventDefault()
    window.history.back()
  }

  console.log({
    original: expenses.length,
    new: newExpenses.length,
    updated: updatedExpenses.length,
    removed: removedExpenses.length
  })

  return (
    <Fragment>
      {combinedExpenses &&
        (workplace ? (
          <WorkplaceExpenseForm
            expenses={combinedExpenses}
            locations={locations}
            employments={employments}
            expenseKinds={expenseKinds}
            expenseSectors={expenseSectors}
            expenseFrequencies={expenseFrequencies}
            onAdd={handleAddExpenses}
            onUpdate={handleUpdateExpenses}
            onRemove={handleRemoveExpense}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            onToggleKind={onInteractionWithForm}
          />
        ) : (
          <GeneralExpenseForm
            expenses={combinedExpenses}
            employments={employments}
            expenseKinds={expenseKinds}
            expenseSectors={expenseSectors}
            expenseFrequencies={expenseFrequencies}
            onAdd={handleAddExpenses}
            onUpdate={handleUpdateExpenses}
            onRemove={handleRemoveExpense}
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            onToggleKind={onInteractionWithForm}
          />
        ))}

      {showIdleHelpPanel(afterSubmitPath) && <IdleHelpPanel page="timelineExpenses" />}
    </Fragment>
  )
}

ExpensesNew.propTypes = {
  afterSubmitPath: PropTypes.string.isRequired
}
