import React from 'react'
import PropTypes from 'prop-types'
import TrustpilotCarousel from './TrustpilotCarousel'

export default function ImageTrustpilotCombo({ data }) {
  const { primaryImgUrl, primaryImgAlt, secondaryImgUrl, secondaryImgAlt, copy, trustPilotSlides } = data

  return (
    <section className="image-trustpilot-combo">
      <div className="image-trustpilot-combo__wrap">
        <TrustpilotCarousel slides={trustPilotSlides} />

        <div className="image-trustpilot-combo__img">
          <div className="image-trustpilot-combo__img-primary">
            <img src={primaryImgUrl} alt={primaryImgAlt} />
          </div>

          <div className="image-trustpilot-combo__img-secondary">
            <img src={secondaryImgUrl} alt={secondaryImgAlt} />
          </div>
        </div>
      </div>

      <div className="image-trustpilot-combo__copy">
        <p>{copy}</p>
      </div>
    </section>
  )
}

ImageTrustpilotCombo.propTypes = {
  data: PropTypes.shape({
    primaryImgUrl: PropTypes.string,
    secondaryImgUrl: PropTypes.string,
    copy: PropTypes.string,
    trustPilotSlides: PropTypes.array
  }).isRequired
}
