import React from 'react'
import PropTypes from 'prop-types'

import { ContentWrapper, ContentWrapperInner, ContentFooter, ButtonGroup } from '../layout/Wrapper'
import Modal from '../display/Modal'
import ButtonGroupItem from './ButtonGroupItem'
import DateQuestion from './date/DateQuestion'

export default function EndedAtModal({
  date,
  error,
  displayed,
  minDate,
  maxDate,
  onClose,
  onChange,
  onConfirm,
  resourceName,
  questionHeading,
  questionText
}) {
  return (
    <Modal displayed={displayed} onClose={onClose}>
      <ContentWrapper>
        <ContentWrapperInner modifiers="longform">
          <DateQuestion
            error={error}
            questionHeading={questionHeading}
            questionText={questionText}
            minDate={minDate}
            maxYear={maxDate?.getFullYear()}
            resource={{ ended_at: date }}
            resourceName={resourceName}
            name="ended_at"
            onChange={onChange}
            includeDay={true}
          />
        </ContentWrapperInner>
        <ContentFooter modifiers="actions">
          <ButtonGroup modifiers="spread">
            <ButtonGroupItem buttonModifiers="default" element="anchor" href="#" onClick={onClose}>
              Cancel
            </ButtonGroupItem>
            <ButtonGroupItem buttonModifiers="primary" disabled={!!error} element="anchor" href="#" onClick={onConfirm}>
              Confirm
            </ButtonGroupItem>
          </ButtonGroup>
        </ContentFooter>
      </ContentWrapper>
    </Modal>
  )
}

EndedAtModal.propTypes = {
  displayed: PropTypes.bool.isRequired,
  error: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  resourceName: PropTypes.string.isRequired,
  questionHeading: PropTypes.string.isRequired,
  questionText: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date)
}

EndedAtModal.defaultProps = {
  maxYear: new Date().getFullYear()
}
