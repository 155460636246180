import React from 'react'
import PropTypes from 'prop-types'

import { generateModifierClasses } from '../../modules/utils'

const OptionsItemText = function ({ label, microcopy, modifiers }) {
  const modifierClasses = generateModifierClasses('options__item__text', modifiers)

  return (
    <span className={modifierClasses}>
      <span className="options__item__text__heading">{label}</span>
      {microcopy && <span className="options__item__text__detail">{microcopy}</span>}
    </span>
  )
}

OptionsItemText.propTypes = {
  label: PropTypes.string.isRequired,
  microcopy: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default OptionsItemText
