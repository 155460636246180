import React from 'react'
import PropTypes from 'prop-types'

import OptionsList from './OptionsList'

import { propagateValue, setStateValueFromPath, fullPath, pick } from '../../modules/utils'

class FilteredSelect extends React.Component {
  constructor(props) {
    super(props)
    this.state = { currentFilterIndex: 0 }
    this.handlePropagation = this.handlePropagation.bind(this)
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData() {
    const { currentFilterIndex } = this.state
    const { pathPrefix, filters } = this.props
    const currentFilter = filters[currentFilterIndex]
    const path = `${pathPrefix}/${currentFilter.path}`
    const queryObject = pick(this.state, currentFilter.params)
    setStateValueFromPath.apply(this, ['GET', fullPath(path, queryObject), `${currentFilter.name}Data`])
  }

  indexOfFilterByName(name) {
    const { filters } = this.props
    return filters.map(filter => filter.name).indexOf(name)
  }

  handlePropagation(name, value) {
    const { filters } = this.props
    const selectedIndex = this.indexOfFilterByName(name)
    let newFilterIndex = Math.min(selectedIndex + 1, filters.length - 1)
    if (value == 'Other') {
      value = ''
      newFilterIndex = selectedIndex
    }

    this.setState({ [name]: value, currentFilterIndex: newFilterIndex }, function () {
      propagateValue.apply(this, [name, value])
      if (selectedIndex + 1 <= filters.length - 1) {
        this.fetchData()
      }
    })
  }

  render() {
    const { currentFilterIndex } = this.state
    const { filters, errors } = this.props
    let lists = []
    for (let i = 0; i <= currentFilterIndex; i++) {
      const filter = filters[i]
      let data = this.state[`${filter.name}Data`]
      if (!data || data.length == 0) continue
      let options
      let selections
      if (filter.popularOptions) {
        selections = data
          .filter(option => filter.popularOptions.indexOf(option) === -1)
          .map(string => ({
            label: string,
            value: string
          }))
        if (filter.selectDefault) {
          selections.unshift({ label: filter.selectDefault, value: '' })
        }
        options = filter.popularOptions.map(string => ({
          label: string,
          value: string
        }))
      } else {
        options = data.map(string => ({
          label: string,
          value: string
        }))
      }
      if (i > 0) {
        lists.push(<hr key={i * 2 - 1} />)
      }
      lists.push(
        <OptionsList
          options={options}
          selections={selections}
          name={filter.name}
          error={errors && errors[filter.name]}
          resourceName={filter.name}
          questionHeading={filter.heading}
          questionText={filter.text}
          modifiers={['columns']}
          value={filter.name}
          handlePropagation={this.handlePropagation}
          key={i * 2}
        />
      )
    }
    return lists
  }
}
FilteredSelect.propTypes = {
  pathPrefix: PropTypes.string,
  errors: PropTypes.object,
  filters: PropTypes.array,
  handlePropagation: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
}
export default FilteredSelect
