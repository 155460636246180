import React, { useEffect, useState } from 'react'
import PreferencesForm from '../../interactive/PreferencesForm'
import BankAccountForm from '../../interactive/BankAccountForm'

import { fetch, redirectTo, isIterable } from '../../../modules/utils'

const INITIAL_BANK_STATE = {
  id: null,
  name: null,
  trading_name: null,
  alt_name: null
}

const INITIAL_BANK_ACCOUNT_STATE = {
  bank_id: null,
  account_number: '',
  sort_code: '',
  account_holder: ''
}

const INITIAL_PREFERENCES_STATE = {
  bank_account_id: null,
  current_address_strings: [],
  gm_refund_method: 'Cheque',
  gm_creation_method: 'MyRIFT',
  communication: {
    email: null,
    sms: null,
    phone: null,
    post: null
  }
}

export default function PreferencesIndex() {
  const [fetching, setFetching] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [showBankDetails, setShowBankDetails] = useState(false)
  const [bank, setBank] = useState(INITIAL_BANK_STATE)
  const [bankAccount, setBankAccount] = useState(INITIAL_BANK_ACCOUNT_STATE)
  const [preferences, setPreferences] = useState(INITIAL_PREFERENCES_STATE)
  const [bankAccountErrors, setBankAccountErrors] = useState({})
  const [onlyShowBankDetails, setOnlyShowBankDetails] = useState(false)

  useEffect(() => {
    setFetching(true)

    fetch('GET', '/preferences.json')
      .then(preferences => {
        setPreferences(preferences)
      })
      .catch(e => {
        if (isIterable(e)) {
          const [message] = e
          console.error('Could not fetch preferences: ' + message)
        }
      })

    // Use this to create a simple variant of this page that only ever renders the
    // bank details form. Used in the wizard.

    setOnlyShowBankDetails(!!window.location.search.match(/bank-details/))
  }, [])

  useEffect(() => {
    if (fetching && preferences !== INITIAL_PREFERENCES_STATE) {
      fetchBankDetails()
      setFetching(false)
    }
  }, [preferences])

  const fetchBankDetails = () => {
    const { bank_account_id } = preferences

    if (!bank_account_id) {
      setFetching(false)
      return
    }
    setFetching(true)
    fetch('GET', `/bank_accounts/${bank_account_id}.json`).then(bankAccount => {
      setBankDetails(bankAccount)
      setFetching(false)
    })
  }

  const setBankDetails = bankAccount => {
    const { id, bank_id, bank_name } = bankAccount

    setBankAccount(bankAccount)
    setBankAccountErrors({})
    setFetching(false)
    setPreferences({ ...preferences, bank_account_id: id })
    setShowBankDetails(onlyShowBankDetails)
    setSubmitting(false)

    if (!bank_id) {
      return setBank({ ...INITIAL_BANK_STATE, name: bank_name })
    }

    return fetch('GET', `/banks/${bank_id}.json`).then(bank => {
      setBank(bank)
    })
  }

  const handleChange = preferencesStatePatch => {
    setPreferences({ ...preferences, ...preferencesStatePatch })
  }

  const toggleBankAccountDisplay = () => {
    setShowBankDetails(!showBankDetails)
  }

  const redirectHome = () => {
    window.location = '/my_rift/dashboard'
  }

  const cancelBankAccountChange = () => {
    if (onlyShowBankDetails) {
      redirectHome()
    } else {
      toggleBankAccountDisplay()
      fetchBankDetails()
    }
  }

  const handleBankAccountChange = (bankAccountChange, bank) => {
    setBankAccount({ ...bankAccount, ...bankAccountChange })

    if (bank) {
      setBank(bank)
    }
  }

  const handleBankAccountSubmit = e => {
    e.preventDefault()

    const requestData = {
      bank_account: bankAccount,
      utf8: '✓'
    }

    setSubmitting(true)

    const httpMethod = bankAccount.id ? 'PUT' : 'POST'
    const url = bankAccount.id ? `/bank_accounts/${bankAccount.id}.json` : `/bank_accounts.json`

    return fetch(httpMethod, url, 'application/json', null, requestData)
      .then(bankAccount => setBankDetails(bankAccount))
      .then(() => {
        if (onlyShowBankDetails) {
          redirectHome()
        }
      })
      .catch(e => {
        if (isIterable(e)) {
          const [, data] = e
          setSubmitting(false)
          setBankAccountErrors(data.errors)
        }
      })
  }

  const handleCancel = e => {
    e.preventDefault()
    window.history.back()
  }

  const handleSubmit = e => {
    e.preventDefault()

    const requestData = {
      preferences,
      utf8: '✓'
    }

    setSubmitting(true)

    return fetch('PUT', '/preferences.json', 'application/json', null, requestData)
      .then(() => {
        return redirectTo(document.referrer || '/users/1')
      })
      .catch(() => {
        alert('There was a problem updating your preferences.')
        return redirectTo(document.referrer || '/users/1')
      })
  }

  // If we're only ever going to render the bank details form
  // wait for all data to finish loading before displaying
  // anything:
  if (onlyShowBankDetails && fetching) {
    return null
  }

  return (
    <>
      {showBankDetails || onlyShowBankDetails ? (
        <BankAccountForm
          onCancel={cancelBankAccountChange}
          onChange={handleBankAccountChange}
          onSubmit={handleBankAccountSubmit}
          bank={bank}
          bankAccount={bankAccount}
          errors={bankAccountErrors}
          submitting={submitting}
        />
      ) : (
        <PreferencesForm
          onEditBankAccountClick={toggleBankAccountDisplay}
          onChange={handleChange}
          onCancel={handleCancel}
          onSubmit={handleSubmit}
          bank={bank}
          bankAccount={bankAccount}
          preferences={preferences}
          submitting={submitting}
          fetching={fetching}
        />
      )}
    </>
  )
}
