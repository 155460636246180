import React from 'react'
import PropTypes from 'prop-types'

export default function FeaturesList({ heading, features }) {
  return (
    <div className="features-list">
      {heading && <h2>{heading}</h2>}
      <ul>
        {features.map(feature => {
          return (
            <li key={feature.title}>
              <p className="features-list__title">{feature.title}</p>
              <p className="features-list__copy">{feature.copy}</p>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

FeaturesList.propTypes = {
  features: PropTypes.array.isRequired,
  heading: PropTypes.string
}

FeaturesList.defaultProps = {
  heading: null
}
