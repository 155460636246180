import React from 'react'
import PropTypes from 'prop-types'

import { iconFromString } from '../../helpers/images'

export default function Stepper({ className, disabled, onChange, value, max }) {
  const stepUp = e => {
    e.preventDefault()

    if (disabled) {
      return
    }

    const nextValue = maxReached() ? max : value + 1

    return onChange(nextValue)
  }

  const stepDown = e => {
    e.preventDefault()

    if (disabled) {
      return
    }

    const nextValue = minReached() ? 0 : value - 1
    return onChange(nextValue)
  }

  const stepToValue = e => {
    const valueString = e.target.value

    if (!valueString) {
      return onChange(0, false)
    }

    const value = parseInt(valueString, 10)

    if (isNaN(value)) {
      return
    }

    return onChange(value)
  }

  const minReached = () => {
    return value <= 0
  }

  const maxReached = () => {
    return max && value >= max
  }

  return (
    <div className={`stepper ${className}`}>
      <div className="stepper__action stepper__action--left">
        <button
          className={`stepper__button ${minReached() || disabled ? 'stepper__button--disabled' : ''}`}
          onClick={stepDown}
        >
          <img className="stepper__icon" alt="Minus" src={iconFromString('minus')} />
        </button>
      </div>
      <input
        disabled={disabled}
        className="stepper__input form-group__input"
        type="text"
        value={value}
        onChange={stepToValue}
      />
      <div className="stepper__action stepper__action--right">
        <button
          className={`stepper__button ${maxReached() || disabled ? 'stepper__button--disabled' : ''}`}
          onClick={stepUp}
        >
          <img className="stepper__icon" alt="Plus" src={iconFromString('plus')} />
        </button>
      </div>
    </div>
  )
}

Stepper.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  value: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  max: PropTypes.number
}

Stepper.defaultProps = {
  className: '',
  disabled: false,
  value: 0
}
