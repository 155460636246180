import React from 'react'

import { generateModifierClasses } from '../../modules/utils'

const FormGroupInput = function (props) {
  let classes = generateModifierClasses('form-group__input', props.modifiers)

  if (props.className) {
    classes = `${classes} ${props.className}`
  }

  return <input {...props} className={classes} />
}

export default FormGroupInput
