import React, { createElement } from 'react'
import PropTypes from 'prop-types'

import { generateModifierClasses } from '../../modules/utils'

function Wrapper({ baseClass, children, className, element, modifiers }) {
  let classes = generateModifierClasses(baseClass, modifiers)

  if (className) {
    classes += ` ${className}`
  }
  return createElement(element, { className: classes }, children)
}

Wrapper.propTypes = {
  baseClass: PropTypes.string.isRequired,
  className: PropTypes.string,
  element: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}
Wrapper.defaultProps = {
  element: 'div'
}
export const ActionList = props => <Wrapper {...props} baseClass="action-list" />
export const AlertText = props => <Wrapper {...props} baseClass="alert__text" element="p" />
export const ButtonGroup = props => <Wrapper {...props} baseClass="button-group" />
export const CardTextSnippet = props => <Wrapper {...props} baseClass="card__text__snippet" element="p" />
export const ContentFooter = props => <Wrapper {...props} baseClass="content__footer" />
export const ContentWrapper = props => <Wrapper {...props} baseClass="content__wrapper" />
export const ContentWrapperInner = props => <Wrapper {...props} baseClass="content__wrapper__inner" />
export const DrawerBody = props => <Wrapper {...props} baseClass="drawer__body" element="div" />
export const Emoji = props => <Wrapper {...props} baseClass="emoji" />
export const FileContent = props => <Wrapper {...props} baseClass="file__content" />
export const Facts = props => <Wrapper {...props} baseClass="facts" element="ul" />
export const FileInner = props => <Wrapper {...props} baseClass="file__inner" />
export const FloatingContainerInner = props => <Wrapper {...props} baseClass="floating-container__inner" />
export const FormGroup = props => <Wrapper {...props} baseClass="form-group" />
export const FormGroupCombo = props => <Wrapper {...props} baseClass="form-group__combo" />
export const FormGroupComboItem = props => <Wrapper {...props} baseClass="form-group__combo__item" />
export const FormGroupErrorMessage = props => (
  <Wrapper {...props} baseClass="form-group__error-message" element="span" />
)
export const Grid = props => <Wrapper {...props} baseClass="grid" />
export const GridInner = props => <Wrapper {...props} baseClass="grid__inner" />
export const Icon = props => <Wrapper {...props} baseClass="icon" />
export const InputGroup = props => <Wrapper {...props} baseClass="input-group" />
export const InputGroupItem = props => <Wrapper {...props} baseClass="input-group__item" />
export const List = props => <Wrapper {...props} baseClass="list" element="ul" />
export const ListItem = props => <Wrapper {...props} baseClass="list__item" element="li" />
export const Options = props => <Wrapper {...props} baseClass="options" />
export const OptionsItem = props => <Wrapper {...props} baseClass="options__item" />
export const OptionsItemIndicator = props => <Wrapper {...props} baseClass="options__item__indicator" element="span" />
export const PreviewSvgWrapper = props => <Wrapper {...props} baseClass="preview-svg__wrapper" />
export const PreviewSvgWrapperInner = props => <Wrapper {...props} baseClass="preview-svg__wrapper__inner" />
export const Tabs = props => <Wrapper {...props} baseClass="tabs" />
export const TabsItem = props => <Wrapper {...props} baseClass="tabs__item" />
export const TabsList = props => <Wrapper {...props} baseClass="tabs__list" />
export const TileAction = props => <Wrapper {...props} baseClass="tile__action" />
export const TileInner = props => <Wrapper {...props} baseClass="tile__inner" />
export const TileList = props => <Wrapper {...props} baseClass="tile-list" />
