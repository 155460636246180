import { values } from './utils'

export const PAGE_CONFIGS = {
  dashboard: {
    name: 'dashboard',
    label: 'Dashboard',
    timelineResource: null,
    href: '/my_rift/dashboard',
    resourcesRequired: ['claimSummary', 'locations', 'expenses', 'documents'],
    showFooter: false,
    showGrid: true,
    showTimeline: false,
    showWrapper: false,
    showIdleUserPanel: false
  },
  addresses: {
    name: 'addresses',
    label: 'Addresses',
    timelineResource: 'Person::Address',
    href: '/my_rift/addresses',
    resourcesRequired: ['timelineGaps', 'addresses'],
    showFooter: true,
    showGrid: false,
    showTimeline: false,
    showWrapper: true,
    showIdleUserPanel: false
  },
  employments: {
    name: 'employments',
    label: 'Employments',
    timelineResource: 'Person::Employment',
    href: '/my_rift/employments',
    resourcesRequired: ['timelineGaps', 'employments'],
    showFooter: true,
    showGrid: false,
    showTimeline: false,
    showWrapper: true,
    showIdleUserPanel: false
  },
  workLocations: {
    name: 'workLocations',
    label: 'Workplaces',
    timelineResource: 'Person::Location',
    href: '/my_rift/work_locations',
    resourcesRequired: ['timelineGaps', 'locations'],
    showFooter: true,
    showGrid: false,
    showTimeline: false,
    showWrapper: true,
    showIdleUserPanel: false
  },
  expenses: {
    name: 'expenses',
    label: 'Expenses',
    timelineResource: 'Person::Expense',
    href: '/my_rift/expenses',
    resourcesRequired: ['timelineGaps', 'expenses', 'locations'],
    showFooter: true,
    showGrid: false,
    showTimeline: false,
    showWrapper: true,
    showIdleUserPanel: false
  },
  vehicles: {
    name: 'vehicles',
    label: 'Vehicles',
    timelineResource: 'Person::Vehicle',
    href: '/my_rift/vehicles',
    resourcesRequired: ['timelineGaps', 'vehicles'],
    showFooter: true,
    showGrid: false,
    showTimeline: false,
    showWrapper: true,
    showIdleUserPanel: false
  },
  review: {
    name: 'review',
    label: 'Review',
    timelineResource: null,
    href: '/my_rift/review',
    resourcesRequired: [],
    showFooter: true,
    showGrid: false,
    showTimeline: false,
    showWrapper: false,
    showIdleUserPanel: false
  }
}

export const TIMELINE_PAGE_CONFIGS = {
  timelineAddresses: {
    name: 'timelineAddresses',
    label: 'Addresses',
    timelineResource: 'Person::Address',
    href: '/my_rift/timelines/addresses',
    timelineStage: true,
    nextStage: 'timelineEmployments',
    prevStage: null,
    prevHref: null,
    nextHref: null,
    resourcesRequired: ['timelineGaps', 'addresses'],
    showFooter: true,
    showGrid: false,
    showTimeline: true,
    showWrapper: true,
    showIdleUserPanel: true
  },
  timelineEmployments: {
    name: 'timelineEmployments',
    label: 'Employments',
    timelineResource: 'Person::Employment',
    href: '/my_rift/timelines/employments',
    timelineStage: true,
    nextStage: 'timelineWorkLocations',
    prevStage: 'timelineAddresses',
    prevHref: null,
    nextHref: null,
    resourcesRequired: ['timelineGaps', 'employments'],
    showFooter: true,
    showGrid: false,
    showTimeline: true,
    showWrapper: true,
    showIdleUserPanel: true
  },
  timelineWorkLocations: {
    name: 'timelineWorkLocations',
    label: 'Workplaces',
    timelineResource: 'Person::Location',
    href: '/my_rift/timelines/work_locations',
    timelineStage: true,
    nextStage: 'timelineExpenses',
    prevStage: 'timelineEmployments',
    prevHref: null,
    nextHref: null,
    resourcesRequired: ['timelineGaps', 'locations'],
    showFooter: true,
    showGrid: false,
    showTimeline: true,
    showWrapper: true,
    showIdleUserPanel: true
  },
  timelineExpenses: {
    name: 'timelineExpenses',
    label: 'Expenses',
    timelineResource: 'Person::Expense',
    href: '/my_rift/timelines/expenses',
    nextStage: 'timelineReview',
    prevStage: 'timelineWorkLocations',
    prevHref: null,
    nextHref: null,
    resourcesRequired: ['timelineGaps', 'expenses', 'locations'],
    showFooter: true,
    showGrid: false,
    timelineStage: true,
    showWrapper: true,
    showIdleUserPanel: true
  },
  timelineReview: {
    name: 'timelineReview',
    label: 'Review',
    timelineResource: null,
    href: '/my_rift/timelines/review',
    nextStage: null,
    prevStage: 'timelineExpenses',
    prevHref: null,
    nextHref: null,
    resourcesRequired: ['timelineGaps'],
    showFooter: true,
    showGrid: false,
    timelineStage: false,
    showWrapper: false,
    showIdleUserPanel: true
  }
}

export const ALL_PAGE_CONFIGS = {
  ...PAGE_CONFIGS,
  ...TIMELINE_PAGE_CONFIGS
}

export function pageConfigForPathname(pathname) {
  const result = values(ALL_PAGE_CONFIGS).find(entry => entry.href === pathname)

  if (!result) {
    return null
  }

  const { prevStage, nextStage } = result

  result.prevHref = ALL_PAGE_CONFIGS[prevStage]?.href
  result.nextHref = ALL_PAGE_CONFIGS[nextStage]?.href

  return result
}
