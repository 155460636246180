import React from 'react'
import PropTypes from 'prop-types'

import FormWrapper from './FormWrapper'
import DateRangeQuestion from './date/DateRangeQuestion'
import AddressLookupWithManualFallback from './addressLookup/AddressLookupWithManualFallback'
import FlashMessage from '../display/FlashMessage'
import ButtonGroupItem from './ButtonGroupItem'
import { ButtonGroup, ContentWrapper, ContentWrapperInner, ContentFooter } from '../layout/Wrapper'

import helperTextData from '../../data/helperTextData.json'

class AddressForm extends React.Component {
  constructor(props) {
    super(props)
    this.handlePropagation = this.handlePropagation.bind(this)
  }

  handlePropagation(name, value, current) {
    this.props.onChange({
      [name]: value,
      current
    })
  }

  renderDates() {
    const { resourceName, locked, endedAtLocked, address, errors } = this.props
    return (
      <DateRangeQuestion
        resourceName={resourceName}
        resource={address}
        questionText="When did you live at this address?"
        helperText={helperTextData.addresses.leaving_date}
        currentName="current"
        currentChecked={address.current}
        fromError={errors.started_at}
        toError={errors.ended_at}
        currentText="I still live at the property"
        handlePropagation={this.handlePropagation}
        fromDisabled={locked}
        toDisabled={endedAtLocked}
      />
    )
  }

  render() {
    const { locked, flash, flashType, address, errors, handleSubmit, onChange, cancelClicked, disabled } = this.props
    return (
      <ContentWrapper modifiers="small">
        <ContentWrapperInner modifiers="longform">
          {flash && <FlashMessage type={flashType} body={flash} />}
          <FormWrapper onSubmit={handleSubmit} id="address-form">
            <AddressLookupWithManualFallback errors={errors} onChange={onChange} address={address} locked={locked} />
            <hr />
            {this.renderDates()}
          </FormWrapper>
        </ContentWrapperInner>
        <ContentFooter modifiers="actions">
          <ButtonGroup modifiers="spread">
            <ButtonGroupItem buttonModifiers="default" element="anchor" href={'#'} onClick={cancelClicked}>
              Cancel
            </ButtonGroupItem>
            <ButtonGroupItem buttonModifiers="primary" element="button" disabled={disabled} onClick={handleSubmit}>
              Save address
            </ButtonGroupItem>
          </ButtonGroup>
        </ContentFooter>
      </ContentWrapper>
    )
  }
}

AddressForm.propTypes = {
  resourceName: PropTypes.string,
  address: PropTypes.object,
  referrer: PropTypes.string,
  cancelClicked: PropTypes.func,
  handleSubmit: PropTypes.func,
  onChange: PropTypes.func,
  locked: PropTypes.bool,
  endedAtLocked: PropTypes.bool,
  action: PropTypes.string,
  disabled: PropTypes.bool
}
AddressForm.defaultProps = {
  resourceName: 'api_person_address',
  locked: false,
  endedAtLocked: false
}
export default AddressForm
