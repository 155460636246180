import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import ScheduledCallback from './scheduledCallback/ScheduledCallback'
import Icon from '../images/Icon'

import { trackEvent } from '../../modules/tracking'

const LiveChatPanel = props => {
  const [minimized, setMinimized] = useState(true)

  useEffect(() => {
    // Assign the open() method to openLiveChatPanel
    if (!window.myRIFT) {
      window.myRIFT = {}
    }
    window.myRIFT.openLiveChatPanel = open
  }, [])

  const toggle = () => {
    if (minimized) {
      trackEvent('Livechat pop event')
    }

    minimized ? setMinimized(false) : setMinimized(true)
  }

  const open = () => {
    setMinimized(false)
    // if there is a liveChatPanel, show it and close the contact-modal
    document.getElementById('contact-modal').classList.remove('modal--visible')
  }

  const renderChat = () => {
    const { callCentreIsOpen, openingData, callCentreWillOpenToday, todaysDay, stepsContent } = props
    if (callCentreIsOpen) {
      return (
        <div className="live-chat-panel__chat-iframe-wrap">
          {!minimized && <iframe className="live-chat-panel__chat-iframe" src="https://alive5.com/P2ccjJT" />}
        </div>
      )
    } else {
      return (
        <ScheduledCallback
          openingData={openingData}
          callCentreWillOpenToday={callCentreWillOpenToday}
          todaysDay={todaysDay}
          stepsContent={stepsContent}
        />
      )
    }
  }

  return (
    <div
      id="liveChatPanel"
      className={`
      live-chat-panel
      ${minimized ? 'live-chat-panel--panel-minimized' : ''}
      ${props.callCentreIsOpen ? 'live-chat-panel--online' : 'live-chat-panel--offline'}
    `}
    >
      <header className="live-chat-panel__header">
        <p>Live Chat</p>
        <div>
          <button className="live-chat-panel__minimize" onClick={() => toggle()}>
            <span />
            <Icon name="arrowWhite" />
          </button>
        </div>
      </header>
      <section>
        {renderChat()}
        <div className="content-loading" />
      </section>
    </div>
  )
}

LiveChatPanel.propTypes = {
  openingData: PropTypes.array,
  callCentreWillOpenToday: PropTypes.bool,
  callCentreIsOpen: PropTypes.bool,
  todaysDay: PropTypes.string,
  stepsContent: PropTypes.array
}

export default LiveChatPanel
