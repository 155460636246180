import React from 'react'
import PropTypes from 'prop-types'

import { generateModifierClasses } from '../../modules/utils'

const DescriptionList = function ({ list, modifiers }) {
  const classes = generateModifierClasses('description-list', modifiers)

  return (
    <dl className={classes}>
      {list.map((item, i) => {
        const modifiers = item.modifiers || []

        if (item.active === false) {
          modifiers.push('inactive')
        }

        const itemClasses = generateModifierClasses('description-list__item', modifiers)

        return item.href && item.active ? (
          <a className={itemClasses} href={item.href} key={`description-list-item-${i}`}>
            <dt>{item.heading}</dt>
            <dd>{item.label}</dd>
          </a>
        ) : (
          <div className={itemClasses} key={`description-list-item-${i}`}>
            <dt>{item.heading}</dt>
            <dd>{item.label}</dd>
          </div>
        )
      })}
    </dl>
  )
}

DescriptionList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      label: PropTypes.string,
      href: PropTypes.string,
      modifiers: PropTypes.arrayOf(PropTypes.string)
    })
  ).isRequired,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
}

export default DescriptionList
