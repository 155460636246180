import React from 'react'
import PropTypes from 'prop-types'

import PreviewSvg from '../display/PreviewSvg'
import ProgressBar from '../display/ProgressBar'

import { CardTextSnippet } from '../layout/Wrapper'

import { generateModifierClasses } from '../../modules/utils'

class Card extends React.Component {
  renderPreview() {
    const { heading, preview } = this.props
    if (!preview) return null

    const previewClasses = generateModifierClasses('card__preview', preview.modifiers)

    return preview.imgSrc ? (
      <div className={previewClasses}>
        <div className="card__preview__inner">
          <img className="card__image" alt={heading} src={preview.imgSrc} />
        </div>
      </div>
    ) : (
      <PreviewSvg className={previewClasses} {...preview.svg} />
    )
  }

  render() {
    const { href, heading, label, microcopy, missingInfoLink, onClick, progress } = this.props

    const modifiers = [].concat(this.props.modifiers)

    if (missingInfoLink) {
      modifiers.push('error')
    }

    const cardClasses = generateModifierClasses('card', modifiers)

    return (
      <a className={cardClasses} href={href} onClick={onClick}>
        {this.renderPreview()}
        <div className="card__content">
          <h4 className="card__heading">{heading}</h4>
          <div className="card__text">
            {!missingInfoLink && label && <CardTextSnippet modifiers="primary">{label}</CardTextSnippet>}
            {!missingInfoLink && microcopy && <CardTextSnippet modifiers="secondary">{microcopy}</CardTextSnippet>}
            {missingInfoLink && (
              <a className="card__link link link--sm" href={missingInfoLink} children="Add missing information" />
            )}
          </div>
        </div>
        {progress && <ProgressBar {...progress} modifiers="card" />}
      </a>
    )
  }
}

Card.propTypes = {
  href: PropTypes.string,
  heading: PropTypes.string,
  label: PropTypes.string,
  microcopy: PropTypes.string,
  missingInfoLink: PropTypes.string,
  modifiers: PropTypes.array,
  onClick: PropTypes.func,
  preview: PropTypes.shape({
    imgSrc: PropTypes.string,
    modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    svg: PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string
    })
  }),
  progress: PropTypes.shape({
    max: PropTypes.number,
    text: PropTypes.string,
    value: PropTypes.number
  })
}

Card.defaultProps = {
  href: '#',
  modifiers: []
}

export default Card
