export function animate(start, middle, end, duration) {
  // Out with the old:
  start()

  // In with the new:
  setTimeout(middle, duration / 2)

  // Reset:
  setTimeout(end, duration)
}

export function getNextIndex(activeIndex, totalSlides, direction) {
  return (direction === 'right' ? activeIndex + 1 : totalSlides + activeIndex - 1) % totalSlides
}
