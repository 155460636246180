import React from 'react'
import PropTypes from 'prop-types'

import { Link } from 'react-router-dom'

import EmojiImage from '../images/EmojiImage'
import Button from '../interactive/Button'
import ProgressBar from './ProgressBar'

import { Emoji, TileAction, TileInner } from '../layout/Wrapper'
import { iconFromString } from '../../helpers/images'
import { generateModifierClasses, redirectTo } from '../../modules/utils'

function Tile(props) {
  const { className, heading, href, icon, modifiers, onClick, progress, text, tileAction, to, status } = props

  let tileClasses = generateModifierClasses('tile', modifiers)

  if (className) {
    tileClasses += ` ${className}`
  }

  const renderStatus = status => {
    return (
      <>
        <div className={`tile__status tile__status--${status.modifier}`}>
          <div className={generateModifierClasses('label', status.modifier)}>{status.label}</div>
          {status.message && <p>{status.message}</p>}
        </div>
      </>
    )
  }

  const renderLink = () => {
    if (to !== null) {
      return (
        <Link className="tile__link" to={to}>
          <span className="tile__link__text sr-only">Go to {heading}</span>
        </Link>
      )
    }

    if (onClick !== null || href !== '#') {
      return (
        <a className="tile__link" href={href} onClick={onClick}>
          <span className="tile__link__text sr-only">Go to {heading}</span>
        </a>
      )
    }
  }

  return (
    <div className={tileClasses}>
      {tileAction && (
        <TileAction>
          <Button
            classes={['tile__button']}
            modifiers={['primary', 'square']}
            onClick={e => {
              tileAction.onClick ? tileAction.onClick(e) : redirectTo(tileAction.href)
            }}
          >
            <img className="title__action__image" alt={tileAction.imgAlt} src={iconFromString(tileAction.imgName)} />
          </Button>
        </TileAction>
      )}
      <TileInner>
        {icon && (
          <Emoji className={icon.className}>
            <EmojiImage {...icon} />
          </Emoji>
        )}
        <div className="tile__content">
          <h2 className="tile__heading">{heading}</h2>
          {text && <p className="tile__text">{text}</p>}
          {status && renderStatus(status)}
        </div>
        {renderLink()}
      </TileInner>
      {progress && <ProgressBar {...progress} modifiers="tile" />}
    </div>
  )
}

Tile.propTypes = {
  className: PropTypes.string,
  heading: PropTypes.string.isRequired,
  href: PropTypes.string,
  to: PropTypes.string,
  icon: PropTypes.shape({
    alt: PropTypes.string,
    className: PropTypes.string,
    modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    name: PropTypes.string.isRequired
  }),
  label: PropTypes.shape({
    modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    text: PropTypes.string.isRequired
  }),
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onClick: PropTypes.func,
  progress: PropTypes.shape({
    max: PropTypes.number,
    text: PropTypes.string,
    value: PropTypes.number
  }),
  text: PropTypes.string,
  tileAction: PropTypes.shape({
    href: PropTypes.string,
    onClick: PropTypes.func,
    imgName: PropTypes.string.isRequired,
    imgAlt: PropTypes.string
  }),
  status: PropTypes.shape({
    key: PropTypes.number,
    label: PropTypes.string.isRequired,
    message: PropTypes.string,
    modifier: PropTypes.string.isRequired
  })
}

Tile.defaultProps = {
  href: '#',
  to: null,
  onClick: null
}

export default Tile
