import React from 'react'

const Block = function ({ children, className, id }) {
  return (
    <div className={`block${className ? ` ${className}` : ''}`} id={id}>
      {children}
    </div>
  )
}

export default Block
