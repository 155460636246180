import React from 'react'
import PropTypes from 'prop-types'

import { generateModifierClasses } from '../../modules/utils'

function ProgressBar({ max, modifiers, text, value }) {
  const classes = generateModifierClasses('progress-container', modifiers)

  return (
    <div className={classes}>
      <progress className="progress-item" max={max} value={value} />
      <span className="progress-span">{text ? text : null}</span>
    </div>
  )
}

ProgressBar.propTypes = {
  max: PropTypes.number.isRequired,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  text: PropTypes.string,
  value: PropTypes.number.isRequired
}

export default ProgressBar
