import React from 'react'
import PropTypes from 'prop-types'

import Question from '../Question'
import DateRangeInput from '../DateRangeInput'
import Helper from '../../display/Helper'
import HelperTrigger from '../../display/HelperTrigger'

import { FormGroup } from '../../layout/Wrapper'

class DateRangeQuestion extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentChecked: this.props.currentChecked,
      fromError: this.props.fromError,
      toError: this.props.toError
    }
    this.toDatePickerRef = React.createRef()
    this.fromDatePickerRef = React.createRef()
  }

  static getDerivedStateFromProps(props, state) {
    let updatedErrors = {}
    if (props.fromError != state.prevFromError) {
      updatedErrors.fromError = props.fromError
      updatedErrors.prevFromError = props.fromError
    }
    if (props.toError != state.prevToError) {
      updatedErrors.toError = props.toError
      updatedErrors.prevToError = props.toError
    }
    return Object.keys(updatedErrors).length != 0 ? updatedErrors : null
  }

  helperId() {
    const { resourceName, fromName, toName } = this.props
    return `helper-${resourceName}-${fromName}-${toName}`
  }

  render() {
    const { fromError, toError } = this.state
    const {
      resource,
      resourceName,
      includeDay,
      fromMinYear,
      fromMaxYear,
      toMinYear,
      toMaxYear,
      handlePropagation,
      currentChecked,
      currentText,
      fromDisabled,
      toDisabled,
      questionHeading,
      questionText,
      helperText
    } = this.props

    return (
      <FormGroup>
        {helperText && <Helper id={this.helperId()} body={helperText} />}
        {(questionHeading || questionText) && (
          <Question heading={questionHeading} text={questionText}>
            {helperText && (
              <HelperTrigger
                helperId={this.helperId()}
                eventLabel={`the '${questionText}' question on a Date Range component`}
              />
            )}
          </Question>
        )}
        <DateRangeInput
          resource={resource}
          resourceName={resourceName}
          fromError={fromError}
          toError={toError}
          includeDay={includeDay}
          fromMinYear={fromMinYear}
          fromMaxYear={fromMaxYear}
          toMinYear={toMinYear}
          toMaxYear={toMaxYear}
          handlePropagation={handlePropagation}
          currentChecked={currentChecked}
          currentText={currentText}
          fromDisabled={fromDisabled}
          toDisabled={toDisabled}
        />
      </FormGroup>
    )
  }
}
DateRangeQuestion.propTypes = {
  defaultDate: PropTypes.instanceOf(Date),
  questionHeading: PropTypes.string,
  questionText: PropTypes.string,
  resource: PropTypes.object,
  resourceName: PropTypes.string,
  fromName: PropTypes.string,
  toName: PropTypes.string,
  fromError: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  toError: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  includeDay: PropTypes.bool,
  fromMinYear: PropTypes.number,
  fromMaxYear: PropTypes.number,
  toMinYear: PropTypes.number,
  toMaxYear: PropTypes.number,
  helperText: PropTypes.string,
  handlePropagation: PropTypes.func,
  currentName: PropTypes.string,
  currentChecked: PropTypes.bool,
  currentText: PropTypes.string,
  fromDisabled: PropTypes.bool,
  toDisabled: PropTypes.bool
}

DateRangeQuestion.defaultProps = {
  questionHeading: 'Dates',
  fromName: 'started_at',
  toName: 'ended_at',
  currentName: 'current',
  fromMinYear: new Date().getFullYear() - 44,
  fromMaxYear: new Date().getFullYear(),
  toMinYear: new Date().getFullYear() - 44,
  toMaxYear: new Date().getFullYear(),
  fromDisabled: false,
  toDisabled: false
}

export default DateRangeQuestion
