import React from 'react'
import PropTypes from 'prop-types'

import { generateModifierClasses } from '../../../modules/utils'

function Alert({ children, modifiers, onClose }) {
  const modifierClasses = generateModifierClasses('alert', modifiers)

  return (
    <div className={`alert alert--visible ${modifierClasses}`}>
      <div className="alert__inner">
        <div className="alert__content">
          <div className="alert__body">{children}</div>
        </div>
        {onClose && <button className="alert__close button--close" type="button" onClick={onClose} />}
      </div>
    </div>
  )
}

Alert.propTypes = {
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onClose: PropTypes.func
}

export default Alert
