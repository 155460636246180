import React from 'react'

const FormGroupHeader = function ({ label, children }) {
  if (!label && !children) return null
  return (
    <div className="form-group__header">
      <label className="form-group__label">{label}</label>
      {children}
    </div>
  )
}

export default FormGroupHeader
