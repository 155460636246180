import React from 'react'
import PropTypes from 'prop-types'

import FormGroupHeader from './FormGroupHeader'

import { FormGroup } from '../layout/Wrapper'

import { capitalize, setStateFromPath } from '../../modules/utils'

class MilitaryDataQuestions extends React.Component {
  constructor(props) {
    super(props)
    this.handleSelectChange = this.handleSelectChange.bind(this)
  }

  componentDidMount() {
    setStateFromPath.apply(this, ['GET', '/mod_data/fetch_state'])
  }

  handleSelectChange({ target }) {
    const { onChange } = this.props
    setStateFromPath.apply(this, ['GET', `/mod_data/select_${target.name}?value=${target.value}`]).then(() => {
      if (onChange) onChange(this.state.ids)
    })
  }

  select(name, margin = true) {
    const { user } = this.props
    const selectData = this.state ? this.state[`${name}_data`] : null
    if (!selectData || !selectData[0] || selectData[0].name == 'N/A') return null
    return (
      <FormGroup className={margin ? 'mt-4' : null}>
        <FormGroupHeader label={this.title(name)} />
        <div className="form-group__input select">
          <select
            className="select__element"
            name={name}
            onChange={this.handleSelectChange}
            value={user[`mod_${name}_id`] || ''}
          >
            <option disabled value="">
              {this.defaultSelectText(name)}
            </option>
            {selectData.map((select_option, index) => (
              <option key={index} value={String(select_option.id)}>
                {select_option.name}
              </option>
            ))}
          </select>
        </div>
      </FormGroup>
    )
  }

  title(name) {
    return (this.state && this.state[`${name}_title`]) || capitalize(name)
  }

  defaultSelectText(name) {
    const basicText = this.title(name).toLowerCase()
    const prefix = /^[aeiou]$/i.test(basicText[0]) ? 'an' : 'a'
    return `Select ${prefix} ${basicText}`
  }

  render() {
    return (
      <React.Fragment>
        {this.select('force', false)}
        {this.select('base')}
        {this.select('unit')}
        {this.select('rank')}
      </React.Fragment>
    )
  }
}
MilitaryDataQuestions.propTypes = {
  onChange: PropTypes.func,
  user: PropTypes.object
}
export default MilitaryDataQuestions
