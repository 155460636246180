import React, { useState } from 'react'
import PropTypes from 'prop-types'

import ActionBar from './ActionBar'
import EndedAtModal from './EndedAtModal'

import { capitalize } from '../../modules/utils'
import { inDateRange, shortDateString } from '../../modules/dates'
import {
  updateAddress,
  updateEmployment,
  updateEmploymentGap,
  updateLocation,
  updateLocationGap,
  confirmAddress,
  confirmEmployment,
  confirmEmploymentGap,
  confirmLocation,
  confirmLocationGap
} from '../../modules/api'

const updaters = {
  updateAddress,
  updateEmployment,
  updateEmploymentGap,
  updateLocation,
  updateLocationGap,
  updateEmploymentGap,
  confirmAddress,
  confirmEmployment,
  confirmEmploymentGap,
  confirmLocation,
  confirmLocationGap
}

export default function TimelineEndedAtPrompt({
  minDate,
  maxDate,
  timelineString,
  resourceType,
  resourceId,
  relatedResourceHeading,
  questionHeading,
  questionText
}) {
  const [modalDisplayed, setModalDisplayed] = useState(false)
  const [date, setDate] = useState(null)
  const [error, setError] = useState(null)

  // TODO: It'd be nicer to merge the updated data with what we already have in state
  // in pages/my_rift/index.js for the next two functions, but at the time of writing
  // the priority is to get this live ASAP, so we're just refreshing the page instead
  // for now.
  const confirmResourceAndRefreshPage = () => {
    const confirmFunction = updaters[`confirm${capitalize(resourceType)}`]
    confirmFunction(resourceId).then(() => window.location.reload())
  }

  const updateDateAndRefreshPage = () => {
    const updateFunction = updaters[`update${capitalize(resourceType)}`]
    updateFunction({ id: resourceId, ended_at: date }).then(() => window.location.reload())
  }

  const handleUpdate = obj => {
    if (obj.ended_at && minDate && maxDate && !inDateRange(obj.ended_at, minDate, maxDate)) {
      const dateRange = `${shortDateString(minDate)} - ${shortDateString(maxDate)}`
      setError(`This date should be during your ${relatedResourceHeading} employment (${dateRange}).`)
    } else {
      setError(null)
    }

    setDate(obj.ended_at)
  }

  return (
    <div className="timeline-gap timeline-gap--warning">
      <div className="timeline-gap__content">
        <span className="timeline-gap__text" dangerouslySetInnerHTML={{ __html: timelineString }} />
        <ActionBar
          actions={[
            {
              componentName: 'AnchorButton',
              classes: ['button-group__item'],
              modifiers: ['warning', 'mini'],
              onClick: confirmResourceAndRefreshPage,
              children: 'Yes'
            },
            {
              componentName: 'AnchorButton',
              classes: ['button-group__item'],
              modifiers: ['warning', 'mini'],
              onClick: () => setModalDisplayed(true),
              children: 'No'
            }
          ]}
        />
      </div>
      <EndedAtModal
        date={date}
        displayed={modalDisplayed}
        onClose={() => setModalDisplayed(false)}
        onChange={handleUpdate}
        onConfirm={updateDateAndRefreshPage}
        error={error}
        minDate={minDate}
        maxDate={maxDate}
        resourceName={`api_person_${resourceType}`}
        questionHeading={questionHeading}
        questionText={questionText}
      />
    </div>
  )
}

TimelineEndedAtPrompt.propTypes = {
  timelineString: PropTypes.string.isRequired,
  questionHeading: PropTypes.string.isRequired,
  questionText: PropTypes.string.isRequired,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  relatedResourceHeading: PropTypes.string,
  resourceType: PropTypes.oneOf(['address', 'employment', 'employmentGap', 'location', 'locationGap']).isRequired,
  resourceId: PropTypes.number.isRequired
}
