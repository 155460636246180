import React, { Fragment, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'

import { updatePageTitle } from '../../../modules/legacyUtils'
import { generateDisplayListActions } from '../../../modules/timelines'

import Lead from '../../display/Lead'
import ConfigurableDisplayList from '../../display/ConfigurableDisplayList'
import EmptyState from '../../display/EmptyState'
import ActionHeader from '../../interactive/ActionHeader'
import { BulletList } from 'react-content-loader'

import helperTextData from '../../../data/helperTextData.json'

const generateActionsForEmployments = (locations, gaps) => {
  return locations.map(({ heading, employment_id, data, started_at, ended_at }) => {
    const employmentGaps = gaps.filter(gap => gap.related_resource_id === employment_id)

    return {
      heading,
      list: generateDisplayListActions(data, employmentGaps, [], false, {
        heading,
        startedAt: started_at,
        endedAt: ended_at
      })
    }
  })
}

export default function WorkLocationsIndex({ lead, locationsGroupedByEmployment, startDate, gaps }) {
  useEffect(() => {
    updatePageTitle('Workplaces')
  }, [])

  const resourcesLoaded = !!locationsGroupedByEmployment
  const lists = useMemo(() => {
    if (locationsGroupedByEmployment?.length) {
      return generateActionsForEmployments(locationsGroupedByEmployment, gaps)
    } else {
      return []
    }
  }, [startDate, locationsGroupedByEmployment, gaps])

  const renderForm = () => (
    <>
      {lists.length ? (
        lists.map(({ heading, list }, i) => (
          <Fragment key={`employment-${i}`}>
            <ActionHeader text={heading}>
              <a href="/work_locations/new" className="button button--mini button--default">
                Add a workplace
              </a>
            </ActionHeader>
            <ConfigurableDisplayList list={list} placeholderName="workplaces" small={true} />
          </Fragment>
        ))
      ) : (
        <EmptyState
          heading="No workplaces"
          detail={`What locations have you worked at since ${startDate.format('MMMM YYYY')}?`}
          href="/work_locations/new"
          buttonText="Add a workplace"
          icon={{
            className: 'mb-1',
            name: 'pin',
            alt: 'No workplaces'
          }}
        />
      )}
    </>
  )

  return (
    <>
      <Lead dangerouslySetInnerHTML={{ __html: lead }} helperText={helperTextData.workplaces.mod.locations} />
      <hr />
      {resourcesLoaded ? renderForm() : <BulletList style={{ width: '66.6%' }} />}
    </>
  )
}

WorkLocationsIndex.propTypes = {
  lead: PropTypes.string.isRequired,
  locationsGroupedByEmployment: PropTypes.arrayOf(PropTypes.object),
  startDate: PropTypes.object.isRequired,
  gaps: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.object,
      endDate: PropTypes.object
    })
  ).isRequired
}
