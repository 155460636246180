import { fetch } from './utils'

export function getUploadUrl(bucketName, personId, uid, fileExtension) {
  return `https://${bucketName}.s3-eu-west-1.amazonaws.com/uploads/${personId}/${uid}.${fileExtension}`
}

export function createPresignedUploadUrl(uid, fileExtension) {
  return fetch('POST', `/documents/temporary_upload_url.json`, 'application/json', null, {
    filename: `${uid}.${fileExtension}`
  }).then(response => response.url)
}

export function uploadFileToS3(file, presignedUrl, onProgress) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('PUT', presignedUrl, true)

    window.ajaxCount = window.ajaxCount == -1 ? 1 : window.ajaxCount + 1

    xhr.setRequestHeader('Content-Type', file.type)
    xhr.onload = () => {
      window.ajaxCount -= 1

      if (xhr.status === 200) {
        return resolve(true)
      }
    }
    xhr.onerror = e => {
      window.ajaxCount -= 1

      return reject(e)
    }

    if (onProgress) {
      xhr.upload.onprogress = onProgress
    }

    xhr.send(file)
  })
}

export function createPersonUpload(data) {
  const params = { document: data }
  return fetch('POST', `/documents.json`, 'application/json', null, params)
}

export function editPersonUpload(id, name) {
  const params = {
    document: {
      original_file_name: name
    }
  }

  return fetch('PUT', `/documents/${id}.json`, 'application/json', null, params)
}

export function deletePersonUpload(id) {
  return fetch('DELETE', `/documents/${id}.json`, 'application/json')
}
