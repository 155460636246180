import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import FormGroupInput from './FormGroupInput'
import DurationInput from './DurationInput'
import { FormGroupErrorMessage } from '../layout/Wrapper'

import { methodFromString } from '../../modules/utils'

class UnstructuredDurationInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      forceStructuredInput: false
    }

    this.handleChange = this.handleChange.bind(this)
    this.forceStructuredInput = this.forceStructuredInput.bind(this)
  }

  handleChange(years, months, weeks) {
    const { onChange } = this.props
    methodFromString(onChange)(years, months, weeks)
  }

  forceStructuredInput() {
    const { years, months, weeks } = this.props
    this.setState({ forceStructuredInput: true })
    this.handleChange(years, months, weeks)
  }

  render() {
    const { forceStructuredInput } = this.state
    const { disabled, error, textValue, years, months, weeks } = this.props
    const showTextInput = !!textValue && !forceStructuredInput

    if (showTextInput) {
      return (
        <Fragment>
          <FormGroupInput value={textValue} disabled={disabled} />
          {!disabled && (
            <a className="link link--sm display-inline-block mt-2" onClick={this.forceStructuredInput}>
              This is incorrect
            </a>
          )}
          {error && <FormGroupErrorMessage>{error}</FormGroupErrorMessage>}
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <DurationInput disabled={disabled} years={years} months={months} weeks={weeks} onChange={this.handleChange} />
          {error && <FormGroupErrorMessage>{error}</FormGroupErrorMessage>}
        </Fragment>
      )
    }
  }
}

UnstructuredDurationInput.propTypes = {
  disabled: PropTypes.bool,
  textValue: PropTypes.string,
  years: PropTypes.number.isRequired,
  months: PropTypes.number.isRequired,
  weeks: PropTypes.number.isRequired,
  onChange: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  error: PropTypes.string
}

UnstructuredDurationInput.defaultProps = {
  disabled: false
}

export default UnstructuredDurationInput
