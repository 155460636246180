import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import ContentFooterLink from '../interactive/ContentFooterLink'
import ClaimTrackerStep from './ClaimTrackerStep'
import TabsLink from '../interactive/TabsLink'

import { ContentFooter, ContentWrapper, ContentWrapperInner, Tabs, TabsItem, TabsList } from '../layout/Wrapper'

class ClaimTracker extends React.Component {
  constructor(props) {
    super(props)

    const lastTab = props.trackers.filter(tracker => tracker.tax_years_label).pop()

    this.state = {
      activeProgressOrder: lastTab ? lastTab.progress_order : -1
    }

    this.tabClicked = this.tabClicked.bind(this)
  }

  static getDerivedStateFromProps(props, state) {
    if (props.trackers != state.prevTrackers) {
      const lastTab = props.trackers.filter(tracker => tracker.tax_years_label).pop()
      return {
        activeProgressOrder: lastTab ? lastTab.progress_order : -1,
        prevTrackers: props.trackers
      }
    }
    return null
  }

  tabClicked(e) {
    e && e.preventDefault()
    this.setState({
      activeProgressOrder: parseInt(e.target.getAttribute('value'))
    })
  }

  getStepModifiers(progressOrder) {
    const { activeProgressOrder } = this.state

    if (progressOrder === activeProgressOrder) {
      return 'current'
    } else if (progressOrder < activeProgressOrder) {
      return 'complete'
    }

    return 'incomplete'
  }

  getExpectedCompleted(stepData) {
    const { activeProgressOrder } = this.state
    const { progress_order, expected_complete_at } = stepData
    return progress_order === activeProgressOrder ? expected_complete_at : null
  }

  renderProgressTabs() {
    const { trackers } = this.props
    const { activeProgressOrder } = this.state

    const tabs = trackers.filter(tracker => tracker.tax_years_label)

    // When we have access to the heading pill from here; we will want to set the value to
    // The year label when there are no tabs so that it shows the user which years they are
    // viewing.

    return (
      tabs.length > 1 && (
        <Tabs>
          <TabsList>
            {tabs.map((data, index) => (
              <TabsItem key={index}>
                <TabsLink
                  modifiers={data.progress_order === activeProgressOrder ? ['active'] : null}
                  children={data.tax_years_label}
                  onClick={this.tabClicked}
                  value={data.progress_order}
                />
              </TabsItem>
            ))}
          </TabsList>
        </Tabs>
      )
    )
  }

  renderPayoutList(refund, paidAt, rapidRefundLabels) {
    if (!refund && !paidAt) {
      return null
    }

    const refundLabel = rapidRefundLabels ? 'Balance of Refund Due' : 'Expected Refund'
    const payoutLabel = rapidRefundLabels ? 'Expected Payout Date of Balance' : 'Expected Payout Date'

    return (
      <ul className="styled-list">
        {refund && (
          <li className="styled-list__item">
            <span className="styled-list__text">
              {refundLabel}: <strong>£{refund}</strong>
            </span>
          </li>
        )}
        {paidAt && (
          <li className="styled-list__item">
            <span className="styled-list__text">
              {payoutLabel}: <strong>{new Date(paidAt).toLocaleDateString('en-GB')}</strong>
            </span>
          </li>
        )}
      </ul>
    )
  }

  renderDisplayStatus() {
    const { trackers } = this.props
    const { activeProgressOrder } = this.state
    const activeProgressTab = trackers.find(tracker => tracker.progress_order === activeProgressOrder)
    const { expected_refund, expected_paid_at, label, description, rapid_refund_payout_labels } = activeProgressTab

    return (
      <Fragment>
        {label && <h2 children={label} />}
        <p className="mb-5" dangerouslySetInnerHTML={{ __html: description }} />
        {this.renderPayoutList(expected_refund, expected_paid_at, rapid_refund_payout_labels)}
      </Fragment>
    )
  }

  render() {
    const { footerLink, trackers } = this.props
    const { activeProgressOrder } = this.state

    return activeProgressOrder >= 0 ? (
      <ContentWrapper modifiers="small">
        {this.renderProgressTabs()}
        <ContentWrapperInner modifiers="longform">
          {this.renderDisplayStatus()}
          {trackers.map((data, index) => (
            <ClaimTrackerStep
              expectedCompleted={this.getExpectedCompleted(data)}
              iconName={data.icon_name}
              key={`claim-tracker-step-${index}`}
              modifiers={this.getStepModifiers(data.progress_order)}
              text={`${index + 1}. ${data.label}`}
            />
          ))}
        </ContentWrapperInner>
        {footerLink && (
          <ContentFooter>
            <ContentFooterLink {...footerLink} />
          </ContentFooter>
        )}
      </ContentWrapper>
    ) : (
      <div className="content-loading" />
    )
  }
}
ClaimTracker.propTypes = {
  trackers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      person_id: PropTypes.number,
      progress_order: PropTypes.number,
      name: PropTypes.string,
      label: PropTypes.string,
      description: PropTypes.string,
      tax_years: PropTypes.arrayOf(PropTypes.number),
      tax_years_label: PropTypes.string,
      expected_refund: PropTypes.number,
      expected_complete_at: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      expected_paid_at: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      last_update_at: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
      icon_name: PropTypes.string.isRequired,
      rapid_refund_payout_labels: PropTypes.bool.isRequired
    })
  ),
  footerLink: PropTypes.shape({
    href: PropTypes.string.isRequired,
    children: PropTypes.string.isRequired
  })
}
export default ClaimTracker
