import React from 'react'
import PropTypes from 'prop-types'

import Collection from '../Collection'
import FormLink from '../FormLink'

import { mapSrc } from '../../../modules/utils'

class AddressLookupAddressDisplay extends React.Component {
  render() {
    const { address, onTryLookupClick, tryLookupLabelText } = this.props

    const collectionText = [address.town, address.county].filter(Boolean).join(', ')

    const media = {
      src: mapSrc(`${address.first_line},${address.postcode}`, 324, 180),
      id: `address-${address.id || address.postcode}`
    }

    return (
      <React.Fragment>
        <Collection heading={address.first_line} text={collectionText} media={media} />
        <FormLink text={tryLookupLabelText} onClick={onTryLookupClick} />
      </React.Fragment>
    )
  }
}

AddressLookupAddressDisplay.defaultProps = {
  tryLookupLabelText: "This isn't the correct location"
}

AddressLookupAddressDisplay.propTypes = {
  address: PropTypes.object.isRequired,
  tryLookupLabelText: PropTypes.string,
  onTryLookupClick: PropTypes.func.isRequired
}

export default AddressLookupAddressDisplay
