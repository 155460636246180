import React from 'react'
import PropTypes from 'prop-types'

import AnchorButton from '../interactive/AnchorButton'
import Button from '../interactive/Button'
import ButtonGroupItem from '../interactive/ButtonGroupItem'
import Icon from '../images/Icon'
import PreviewSvg from '../display/PreviewSvg'
import TabsLink from '../interactive/TabsLink'
import TextQuestion from '../interactive/TextQuestion'

import {
  ButtonGroup,
  ContentFooter,
  ContentWrapper,
  ContentWrapperInner,
  FileInner,
  Tabs,
  TabsItem,
  TabsList
} from '../layout/Wrapper'
import { generateModifierClasses } from '../../modules/utils'

class File extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      activeTabIndex: 0,
      nameValue: props.fileName
    }

    this.fileClicked = this.fileClicked.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
  }

  tabClicked(tab) {
    this.setState({
      activeTabIndex: tab
    })
  }

  fileClicked({ target }) {
    const { onClose } = this.props
    const classes = target.classList
    if (classes.contains('file__inner') || classes.contains('file')) onClose()
  }

  handleNameChange(key, name) {
    this.setState({
      nameValue: name
    })
  }

  renderTabs() {
    const { activeTabIndex } = this.state
    const { onClose, onDelete } = this.props
    return (
      <Tabs className="file__tabs">
        <Button classes={['file__tabs__button', 'delete_file']} onClick={onDelete}>
          <Icon name="trash" />
        </Button>
        <TabsList className="file__tabs__list">
          {['View', 'Edit'].map((tab, index) => (
            <TabsItem key={`tab-${index}`} className="file__tabs__item">
              <TabsLink
                children={tab}
                className="file__tabs__item"
                modifiers={index == activeTabIndex ? ['active'] : null}
                onClick={() => this.tabClicked(index)}
              />
            </TabsItem>
          ))}
        </TabsList>
        <Button classes={['file__tabs__button', 'close_file']} onClick={onClose}>
          <Icon name="close" />
        </Button>
      </Tabs>
    )
  }

  renderView() {
    const { fileSize, href, preview } = this.props

    const previewClasses = generateModifierClasses('file__preview', preview.modifiers)

    return (
      <React.Fragment>
        <ContentWrapperInner modifiers={['file'].concat(preview.modifiers)}>
          {preview.imgSrc ? (
            <img className={previewClasses} src={preview.imgSrc} alt="File preview" />
          ) : (
            <PreviewSvg className={previewClasses} {...preview.svg} />
          )}
        </ContentWrapperInner>
        <ContentFooter modifiers="actions">
          <AnchorButton children={`Open (${fileSize})`} modifiers={['primary', 'block']} href={href} target="_blank" />
        </ContentFooter>
      </React.Fragment>
    )
  }

  renderEdit() {
    const { nameValue } = this.state
    const { onClose, onEdit } = this.props
    return (
      <React.Fragment>
        <ContentWrapperInner modifiers="file">
          <TextQuestion
            autoComplete="off"
            label="Document name"
            resourceName="api_person_upload"
            name="name"
            handlePropagation={this.handleNameChange}
            value={nameValue}
          />
        </ContentWrapperInner>
        <ContentFooter modifiers="actions">
          <ButtonGroup modifiers="spread">
            <ButtonGroupItem buttonModifiers={['default']} element="button" onClick={onClose}>
              Cancel
            </ButtonGroupItem>
            <ButtonGroupItem buttonModifiers={['primary']} element="button" onClick={onEdit} disabled={!nameValue}>
              Save changes
            </ButtonGroupItem>
          </ButtonGroup>
        </ContentFooter>
      </React.Fragment>
    )
  }

  render() {
    const { activeTabIndex } = this.state
    return (
      <div className="file" onClick={this.fileClicked}>
        <FileInner>
          {this.renderTabs()}
          <ContentWrapper>{activeTabIndex === 0 ? this.renderView() : this.renderEdit()}</ContentWrapper>
        </FileInner>
      </div>
    )
  }
}
File.propTypes = {
  fileName: PropTypes.string.isRequired,
  fileSize: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  preview: PropTypes.shape({
    imgSrc: PropTypes.string,
    modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    svg: PropTypes.shape({
      name: PropTypes.string,
      text: PropTypes.string
    })
  })
}
export default File
