import React from 'react'
import PropTypes from 'prop-types'

import Helper from '../display/Helper'
import HelperTrigger from '../display/HelperTrigger'
import { uuidv4 } from '../../modules/utils'

export default function Lead({ children, dangerouslySetInnerHTML, helperText }) {
  const helperId = `helper-${uuidv4()}`
  const renderText = () =>
    dangerouslySetInnerHTML ? (
      <p className="lead" dangerouslySetInnerHTML={dangerouslySetInnerHTML} />
    ) : (
      <p className="lead">{children}</p>
    )

  return (
    <div className={`lead-heading ${helperText ? 'lead-heading--with-helper' : ''}`}>
      {helperText && <Helper id={helperId} body={helperText} />}
      <div className="lead-heading__body">
        {renderText()}
        {helperText && (
          <HelperTrigger
            helperId={helperId}
            eventLabel={dangerouslySetInnerHTML ? 'Lead component' : `the '${children}' question on a Lead component`}
          />
        )}
      </div>
    </div>
  )
}

Lead.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  dangerouslySetInnerHTML: PropTypes.object,
  helperText: PropTypes.string
}
