import React from 'react'
import PropTypes from 'prop-types'

import Question from '../Question'

import AddressLookupAddressDisplay from './AddressLookupAddressDisplay'
import AddressLookupAutomatedLookupFields from './AddressLookupAutomatedLookupFields'
import AddressLookupManualEntryFields from './AddressLookupManualEntryFields'

import { FormGroup } from '../../layout/Wrapper'

import { buildFirstAndSecondLines, postcodeLookup } from '../../../modules/addressLookup'

class AddressLookupWithManualFallback extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      manualEntry: false,
      lookupError: false
    }

    this.lookupNumberRef = React.createRef()
    this.lookupPostcodeRef = React.createRef()

    this.handleAddressSelect = this.handleAddressSelect.bind(this)
    this.handleLookup = this.handleLookup.bind(this)
    this.handleLookupSuccess = this.handleLookupSuccess.bind(this)
    this.handleLookupError = this.handleLookupError.bind(this)
    this.showManualEntry = this.showManualEntry.bind(this)
    this.showLookupEntry = this.showLookupEntry.bind(this)
    this.clearLookupError = this.clearLookupError.bind(this)
  }

  handleAddressSelect({ target }) {
    const { deliveryPoints } = this.state
    const { onChange } = this.props

    onChange(buildFirstAndSecondLines(deliveryPoints[target.value]))
  }

  handleLookup(postcode, houseNumber) {
    if (this.state.loading) return

    const { onChange } = this.props

    if (!postcode) {
      return onChange(undefined, {
        postcode: 'We need postcode to find your address.'
      })
    }

    this.setState({
      loading: true,
      deliveryPoints: null
    })

    return postcodeLookup(postcode, houseNumber).then(this.handleLookupSuccess).catch(this.handleLookupError)
  }

  handleLookupSuccess([streetData, deliveryPoints]) {
    const { onChange } = this.props

    // If we have a definitive result, build the full address
    // object and pass it up for setting in state:
    if (deliveryPoints.length === 1) {
      this.setState({
        loading: false
      })

      onChange({
        ...streetData,
        ...buildFirstAndSecondLines(deliveryPoints[0])
      })

      // If we don't have a definitive results, show the available
      // delivery points and pass up the street data we're certain
      // about for setting in state:
    } else {
      this.setState({
        loading: false,
        deliveryPoints
      })

      onChange(streetData)
    }
  }

  handleLookupError(err) {
    console.error(err)

    this.setState({
      loading: false,
      lookupError: true
    })
  }

  showManualEntry(e) {
    e.preventDefault()

    this.setState({
      manualEntry: true,
      deliveryPoints: null
    })

    this.resetData()
  }

  showLookupEntry(e) {
    e.preventDefault()

    this.setState({
      manualEntry: false,
      deliveryPoints: null
    })

    this.resetData()
  }

  clearLookupError(e) {
    e.preventDefault()

    this.setState({
      lookupError: false
    })
  }

  resetData(data = {}) {
    const { onChange } = this.props

    onChange({
      first_line: null,
      second_line: null,
      town: null,
      county: null,
      postcode: null,
      ...data
    })
  }

  render() {
    const { deliveryPoints, loading, lookupError, manualEntry } = this.state
    const { address, disabled, errors, onChange, questionText, tryLookupLabelText } = this.props

    const showAddress = !manualEntry && address && address.first_line && address.postcode
    const showLookup = !showAddress && !manualEntry
    const showManualEntry = !showAddress && manualEntry

    return (
      <FormGroup>
        <Question heading="Location" text={questionText} />
        {showAddress && (
          <AddressLookupAddressDisplay
            address={address}
            onTryLookupClick={this.showLookupEntry}
            tryLookupLabelText={tryLookupLabelText}
          />
        )}
        {showLookup && (
          <AddressLookupAutomatedLookupFields
            deliveryPoints={deliveryPoints}
            lookupError={lookupError}
            errors={errors}
            disabled={loading}
            onAddressSelect={this.handleAddressSelect}
            onInputChange={this.clearLookupError}
            onLookup={this.handleLookup}
            onTryManualEntryClick={this.showManualEntry}
          />
        )}
        {showManualEntry && (
          <AddressLookupManualEntryFields
            address={address}
            disabled={disabled}
            errors={errors}
            onChange={onChange}
            onTryLookupClick={this.showLookupEntry}
          />
        )}
      </FormGroup>
    )
  }
}

AddressLookupWithManualFallback.defaultProps = {
  questionText: 'What was the address of the property?'
}

AddressLookupWithManualFallback.propTypes = {
  errors: PropTypes.object,
  onChange: PropTypes.func,
  address: PropTypes.object,
  locked: PropTypes.bool,
  tryLookupLabelText: PropTypes.string,
  questionText: PropTypes.string
}

export default AddressLookupWithManualFallback
