const SUN = 0
const SAT = 6
const FRI = 5

function nextOpenTomorrow(date) {
  const day = date.getDay()

  if (day === SUN) {
    return true
  }

  if (day === SAT) {
    return false
  }

  if (day === FRI) {
    return after(date, [17, 30])
  }

  return after(date, [19, 30])
}

function openToday(date) {
  const day = date.getDay()

  if (day === SUN) {
    return false
  }

  if (day === SAT) {
    return before(date, [12, 30])
  }

  if (day === FRI) {
    return before(date, [17, 30])
  }

  return before(date, [19, 30])
}

export function before(date, referenceHourMinute) {
  const hour = date.getHours()
  const minute = date.getMinutes()
  let [refHour, refMinute] = referenceHourMinute

  if (refMinute) {
    return hour < refHour || (hour === refHour && minute < refMinute)
  } else {
    return hour < refHour
  }
}

export function after(date, referenceHourMinute) {
  return !before(date, referenceHourMinute)
}

export function nextOpeningTime(date) {
  if (openToday(date)) {
    return 'shortly'
  } else if (nextOpenTomorrow(date)) {
    return 'tomorrow'
  }
  return 'monday'
}
