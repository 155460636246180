import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { updatePageTitle } from '../../../modules/legacyUtils'
import { memoisedGroupedGeneralExpenses, memoisedGroupedWorkplaceExpenses } from '../../../modules/expense'

import Lead from '../../display/Lead'
import TimelineGap from '../../display/TimelineGap'
import TitledDescriptionList from '../../display/TitledDescriptionList'
import EmptyState from '../../display/EmptyState'
import ActionHeader from '../../interactive/ActionHeader'
import { BulletList } from 'react-content-loader'

export default function ExpensesIndex({ expenses, locationsGroupedByEmployment }) {
  updatePageTitle('Expenses')

  const resourcesLoaded = !!expenses && !!locationsGroupedByEmployment
  const groupedWorkplaceExpenses = memoisedGroupedWorkplaceExpenses(expenses || [])
  const groupedGeneralExpenses = memoisedGroupedGeneralExpenses(expenses || [])

  const haveExpenses = !!expenses?.length
  const haveLocations = !!(locationsGroupedByEmployment || []).filter(l => l.data.length).length
  const haveWorkplaceExpenses = !!Object.keys(groupedWorkplaceExpenses || []).length
  const haveGeneralExpenses = !!Object.keys(groupedGeneralExpenses || []).length

  const renderExpensesGroup = group => {
    return Object.keys(group).map(title => {
      return <TitledDescriptionList list={group[title]} title={title} titleEl="h3" key={title} />
    })
  }

  const renderExpenseTimelineGap = (text, workplace = false) => {
    return (
      <TimelineGap
        actions={[
          {
            modifiers: ['tertiary', 'square'],
            href: `/expenses/new?workplace=${workplace}`,
            imgAlt: 'Add expense',
            imgName: 'plusWhite',
            srText: 'Add expense'
          }
        ]}
        modifiers={['single-action']}
        timelineString={text}
      />
    )
  }

  const renderForm = () => (
    <>
      {haveExpenses ? (
        <Fragment>
          {haveLocations && (
            <Fragment>
              <ActionHeader text="Workplace expenses">
                {haveWorkplaceExpenses && (
                  <a href="/expenses/new?workplace=true" className="button button--mini button--default">
                    Add an expense
                  </a>
                )}
              </ActionHeader>
              {haveWorkplaceExpenses
                ? renderExpensesGroup(groupedWorkplaceExpenses)
                : renderExpenseTimelineGap(
                    'What <strong>workplace costs</strong> did you incur over the claim period?',
                    true
                  )}
              <hr />
            </Fragment>
          )}
          <ActionHeader text="General expenses">
            {haveGeneralExpenses && (
              <a href="/expenses/new?workplace=false" className="button button--mini button--default">
                Add an expense
              </a>
            )}
          </ActionHeader>
          {haveGeneralExpenses
            ? renderExpensesGroup(groupedGeneralExpenses)
            : renderExpenseTimelineGap(
                'What <strong>business costs</strong> did you incur over the claim period?',
                false
              )}
        </Fragment>
      ) : (
        <EmptyState
          heading="No expenses"
          detail="What do you buy on a working day?"
          href={`/expenses/new?workplace=${haveLocations}`}
          buttonText="Add an expense"
          icon={{
            className: 'mb-1',
            name: 'burger',
            alt: 'No expenses'
          }}
        />
      )}
    </>
  )

  return (
    <Fragment>
      <Lead>
        From your lunch to toll charges, you can increase your refund by adding any expenses{' '}
        <strong>you paid for yourself.</strong>
      </Lead>
      <hr />
      {resourcesLoaded ? renderForm() : <BulletList style={{ width: '66.6%' }} />}
    </Fragment>
  )
}

ExpensesIndex.propTypes = {
  expenses: PropTypes.arrayOf(PropTypes.object),
  locationsGroupedByEmployment: PropTypes.arrayOf(PropTypes.object)
}
