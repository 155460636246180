function insertDashBeforeLastCharacter(val) {
  return `${val.substr(0, val.length - 1)}-${val.substr(val.length - 1)}`
}

function appendDash(val) {
  return `${val}-`
}

export function formatTypedAccountNumber(previousAccountNumber, newAccountNumber) {
  const writing = (!previousAccountNumber && newAccountNumber) || previousAccountNumber.length < newAccountNumber.length

  if (!writing) {
    return newAccountNumber
  }

  return /^\d+$/.test(newAccountNumber) && newAccountNumber.length <= 8 ? newAccountNumber : previousAccountNumber
}

// Formats a sort code as you type it. Harder than you'd imagine to
// cover off all the edge cases.
export function formatTypedSortCode(previousSortCode, newSortCode) {
  const numberChar = /^\d$/.test(newSortCode[newSortCode.length - 1])
  const dashChar = /^\-$/.test(newSortCode[newSortCode.length - 1])
  const writing = (!previousSortCode && newSortCode) || previousSortCode.length < newSortCode.length
  const tooLong = newSortCode.length > 8

  if (tooLong) {
    return newSortCode.substr(0, 8)
  }

  if (!writing || (!previousSortCode && numberChar)) {
    return newSortCode
  }

  const typingThirdOrSixthCharacterAsNumber = [3, 6].indexOf(newSortCode.length) !== -1 && numberChar
  const typingThirdOrSixthCharacterAsDash = [3, 6].indexOf(newSortCode.length) !== -1 && dashChar
  const typingSecondOrFifthCharacterAsNumber = [2, 5].indexOf(newSortCode.length) !== -1 && numberChar

  if (typingThirdOrSixthCharacterAsNumber) {
    return insertDashBeforeLastCharacter(newSortCode)
  } else if (typingThirdOrSixthCharacterAsDash) {
    return newSortCode
  } else if (typingSecondOrFifthCharacterAsNumber) {
    return appendDash(newSortCode)
  } else if (!numberChar) {
    return previousSortCode || ''
  }

  return newSortCode
}
