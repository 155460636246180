import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import ButtonGroupItem from './ButtonGroupItem'
import CheckBoxGroupInput from './CheckBoxGroupInput'
import Question from './Question'
import YesNoQuestion from './YesNoQuestion'
import Lead from '../display/Lead'

import helperTextData from '../../data/helperTextData.json'
import { FormGroup, ContentWrapper, ContentWrapperInner, ContentFooter, ButtonGroup } from '../layout/Wrapper'

class FinanceForm extends React.Component {
  options() {
    const { finance, options } = this.props
    return options.map(option => {
      option.checked = finance[option.value]
      return option
    })
  }

  render() {
    const { disabled, finance, leadText, onCancel, onChange, onSubmit, showCancelButton, submitButtonLabel, referrer } =
      this.props

    return (
      <ContentWrapper modifiers="small">
        <ContentWrapperInner modifiers="longform">
          {leadText && (
            <Fragment>
              <Lead dangerouslySetInnerHTML={{ __html: leadText }} />
              <hr />
            </Fragment>
          )}
          <FormGroup>
            <Question heading="Income" text="Have you received income from any of the following?" />
            <CheckBoxGroupInput options={this.options()} onChange={onChange} />
          </FormGroup>
          <hr />
          <YesNoQuestion
            resourceName="finance"
            resource={finance}
            checked={finance.unpaid_tax}
            name="unpaid_tax"
            questionHeading="Unpaid tax"
            questionText="Do you owe HMRC any unpaid tax that you are aware of?"
            helperText={helperTextData.financials.unpaid_tax}
            onChange={onChange}
          />
          <hr />
          <YesNoQuestion
            resourceName="finance"
            resource={finance}
            checked={finance.student_loan}
            name="student_loan"
            questionHeading="Student Loan"
            questionText="Do you have a student or postgraduate loan?"
            helperText={helperTextData.financials.student_loan}
            onChange={onChange}
          />
          <hr />
          <YesNoQuestion
            resourceName="finance"
            resource={finance}
            checked={finance.bankruptcy}
            name="bankruptcy"
            questionHeading="Status"
            questionText="Have you been declared bankrupt, trustee appointed or entered into an IVA (individual voluntary arrangement)?"
            helperText={helperTextData.financials.bankruptcy}
            onChange={onChange}
          />
          <hr />
          <YesNoQuestion
            resourceName="finance"
            resource={finance}
            checked={finance.completed_tax_return}
            name="completed_tax_return"
            questionHeading="Tax Return"
            questionText="Have you ever completed, or been asked to complete, a tax return by HMRC?"
            helperText={helperTextData.financials.tax_return}
            onChange={onChange}
          />
          <hr />
          <YesNoQuestion
            resourceName="finance"
            resource={finance}
            checked={finance.limited_company}
            name="limited_company"
            questionHeading="Limited company"
            questionText="Have you ever set up a limited company?"
            helperText={helperTextData.financials.limited_company}
            onChange={onChange}
          />
          <hr />
          <YesNoQuestion
            resourceName="finance"
            resource={finance}
            checked={finance.working_from_home}
            name="working_from_home"
            questionHeading="Working from home"
            questionText="Have you worked from home for at least one day since April 2020?"
            helperText={helperTextData.financials.working_from_home}
            onChange={onChange}
          />
        </ContentWrapperInner>
        <ContentFooter modifiers="actions">
          <ButtonGroup modifiers="spread">
            {showCancelButton && (
              <ButtonGroupItem buttonModifiers="default" element="anchor" href={referrer || '#'} onClick={onCancel}>
                Cancel
              </ButtonGroupItem>
            )}
            <ButtonGroupItem buttonModifiers="primary" element="button" disabled={disabled} onClick={onSubmit}>
              {submitButtonLabel}
            </ButtonGroupItem>
          </ButtonGroup>
        </ContentFooter>
      </ContentWrapper>
    )
  }
}

FinanceForm.propTypes = {
  disabled: PropTypes.bool,
  finance: PropTypes.object,
  leadText: PropTypes.string,
  showCancelButton: PropTypes.bool,
  onCancel: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      helperText: PropTypes.string,
      label: PropTypes.string,
      microcopy: PropTypes.string,
      value: PropTypes.string
    })
  ).isRequired,
  submitButtonLabel: PropTypes.string,
  referrer: PropTypes.string
}

FinanceForm.defaultProps = {
  disabled: false,
  showCancelButton: true,
  submitButtonLabel: 'Save changes'
}

export default FinanceForm
