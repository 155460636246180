import React from 'react'
import PropTypes from 'prop-types'

import { Options, OptionsItem } from '../layout/Wrapper'
import OptionsItemLabel from '../interactive/OptionsItemLabel'
import OptionsItemImage from '../interactive/OptionsItemImage'
import OptionsItemText from '../interactive/OptionsItemText'
import OptionsItemButton from '../interactive/OptionsItemButton'

import { banks } from '../../helpers/images'

const Bank = function ({ label, microcopy, onEdit }) {
  const imageSrc = banks[label]

  return (
    <Options>
      <OptionsItem>
        <OptionsItemLabel modifiers={['uninteractive', 'image']}>
          {imageSrc && <OptionsItemImage src={imageSrc} alt={label} />}
          {label && <OptionsItemText label={label} microcopy={microcopy} />}
          {onEdit && <OptionsItemButton onClick={onEdit}>Edit</OptionsItemButton>}
        </OptionsItemLabel>
      </OptionsItem>
    </Options>
  )
}

Bank.propTypes = {
  label: PropTypes.string.isRequired,
  microcopy: PropTypes.string.isRequired,
  onEdit: PropTypes.func
}

export default Bank
